import React from 'react'
import { useHistory } from 'react-router-dom'
import { arrowForward } from 'ionicons/icons'

import { AppContext, ROUTES, InputData, isReadyToCompute, InputDataChangeHandler } from '../App'
import { MobilePageEnvelope, PageProps } from './Page'
import { FabButton } from '../components/FabButton'
import { InputContents } from './contents/InputContents'

interface InputPageProps extends PageProps {
  data: InputData
  onChange: InputDataChangeHandler
}

export const InputPage: React.FC<InputPageProps> = ({ data, onChange }) => {
  const history = useHistory()
  const { langResources } = React.useContext(AppContext)

  let calculateButton: React.ReactElement | null = null

  if (isReadyToCompute(data)) {
    calculateButton = 
      <FabButton icon={arrowForward} positionV='top' onClick={() => history.push(ROUTES.output)} title={langResources.GoToOutput}  />
  }

  return (
    <MobilePageEnvelope>
      {calculateButton}
      <InputContents data={data} onChange={onChange} />
    </MobilePageEnvelope>
  )
}

export default InputPage
 