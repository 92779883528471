import { Redirect, Route } from 'react-router-dom'
import {
  IonButtons,
  IonHeader,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
} from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import { calculator, barChart, informationCircle } from 'ionicons/icons'

import React from 'react'
import { AppContext, AppViewProps, isReadyToCompute, ROUTES } from './App'
import { LanguageCodeMap } from './Language'
import { InputPage } from './pages/InputPage'
import { OutputPage } from './pages/OutputPage'
import { AboutPage } from './pages/AboutPage'
import { LanguageSelector } from './components/LanguageSelector'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

import './App.css'

import logoOsciaEn from './logo-oscia-en.png'
import logoOsciaFr from './logo-oscia-fr.png'

/* Theme variables */
import './theme/variables.css'

const logoOscia: LanguageCodeMap<string> = {
	en: logoOsciaEn,
	fr: logoOsciaFr
}

export const AppViewMobile: React.FC<AppViewProps> = ({ inputData, setInputData }) => {
  const { languageCode, langResources } = React.useContext(AppContext)

  return (
    <IonReactRouter>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot='start'>
            <a href={langResources.LogoLink}>
              <img src={logoOscia[languageCode]} height='40px' alt={langResources.LogoOsciaAlt} />
            </a>
          </IonButtons>
          <IonTitle color='oscia-green'>
            {langResources.SoilsCalculator}
          </IonTitle>
          <IonButtons slot='end'>
            <LanguageSelector />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonTabs>
        <IonRouterOutlet>
          <Route exact path={ROUTES.about}>
            <AboutPage />
          </Route>
          <Route exact path={ROUTES.input}>
            <InputPage data={inputData} onChange={setInputData} />
          </Route>
          <Route exact path={ROUTES.output}>
            <OutputPage data={inputData} />
          </Route>
          <Route exact path="/">
            <Redirect to="/about" />
          </Route>
        </IonRouterOutlet>
        <IonTabBar slot="bottom">
          <IonTabButton tab={ROUTES.about} href={ROUTES.about}>
            <IonIcon icon={informationCircle} />
            <IonLabel>{langResources.About}</IonLabel>
          </IonTabButton>
          <IonTabButton tab={ROUTES.input} href={ROUTES.input}>
            <IonIcon icon={calculator} />
            <IonLabel>{langResources.Input}</IonLabel>
          </IonTabButton>
          <IonTabButton tab={ROUTES.output} href={ROUTES.output} disabled={!isReadyToCompute(inputData)}>
            <IonIcon icon={barChart} />
            <IonLabel>{langResources.Output}</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  )
}

export default AppViewMobile
