import React from "react"
import { IonCol, IonGrid, IonRow, IonText } from "@ionic/react"

import './Header.css'

interface HeaderProps {
	headerText?: string
}

export const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({ headerText, children }) => {
	return (
		<IonGrid className='header'>
			<IonRow>
				<IonCol>
					<IonText color='oscia-green'>
						<h1>{headerText}</h1>
					</IonText>
				</IonCol>
				<IonCol>
					{children}
				</IonCol>
			</IonRow>
		</IonGrid>
	)
}

export default Header