import { Topography } from "./topographyTypes"
import { DepthToWaterTable } from "./depthToWaterTableTypes"
import { HydrologicalSoilGroup,  } from "./hydrologicalSoilGroupTypes"
import { SoilInfo } from "./soilData"
import { LanguageCode, LanguageCodeMap } from "../Language"

export type Potential = 1 | 2 | 3 | 4 | '–'

export const PotentialDescriptions: LanguageCodeMap<Record<Potential, string>> = {
	en: {
		1: "1-High",
		2: "2-Mod",
		3: "3-Low",
		4: "4-Very Low", 
		'–': '–'
	}, 
	fr: {
		1: "1 - élevé",
		2: "2 - modéré",
		3: "3 - faible",
		4: "4 - très faible",
		'–': '–'
	}
}


export const PotentialForSurfaceWaterContamination: Record<HydrologicalSoilGroup, Record<Topography, Potential>> = {
	RAPID: {
		Level: 4, 
		Sloping: 4, 
		Hilly: 3
	}, 
	MODERATE: {
		Level: 3, 
		Sloping: 3, 
		Hilly: 2 
	}, 
	SLOW: {
		Level: 2, 
		Sloping: 2, 
		Hilly: 1 
	}, 
	'VERY SLOW': {
		Level: 1, 
		Sloping: 1, 
		Hilly: 1 
	},
	'–': {
		Level: '–', 
		Sloping: '–', 
		Hilly: '–'
	}
}

export function calcPotentialForSurfaceWaterContamination(languageCode: LanguageCode, soilInfo: SoilInfo, topography: Topography): string {
	let potential: Potential = PotentialForSurfaceWaterContamination[soilInfo.hydrologicalGroup][topography]
	let description = PotentialDescriptions[languageCode][potential]
	return description
}

export const PotentialForGroundWaterContamination: Record<HydrologicalSoilGroup, Record<DepthToWaterTable, Potential>> = {
	RAPID: {
		Shallow: 1, 
		Moderate: 1, 
		Deep: 1, 
		VeryDeep: 2
	}, 
	MODERATE: {
		Shallow: 1, 
		Moderate: 1, 
		Deep: 2, 
		VeryDeep: 3 
	}, 
	SLOW: {
		Shallow: 1, 
		Moderate: 2, 
		Deep: 3, 
		VeryDeep: 4 
	}, 
	'VERY SLOW': {
		Shallow: 1, 
		Moderate: 3, 
		Deep: 4, 
		VeryDeep: 4 
	}, 
	'–': {
		Shallow: '–', 
		Moderate: '–', 
		Deep: '–', 
		VeryDeep: '–' 
	}
}

export function calcPotentialForGroundWaterContamination(languageCode: LanguageCode, soilInfo: SoilInfo, depthToWaterTable: DepthToWaterTable): string {
	let potential: Potential = PotentialForGroundWaterContamination[soilInfo.hydrologicalGroup][depthToWaterTable]
	let description = PotentialDescriptions[languageCode][potential]
	return description
}



export interface ErosionFactorShape<T = any> {
	1: T
	2: T
	3: T
	4: T
	'–': T
}

export type ErosionFactor = keyof ErosionFactorShape


export const PotentialForWaterErosion: ErosionFactorShape<Record<Topography, Potential>> = {
	1: {
		Level: 3, 
		Sloping: 2, 
		Hilly: 1 
	}, 
	2: {
		Level: 4, 
		Sloping: 2, 
		Hilly: 1 
	}, 
	3: {
		Level: 4, 
		Sloping: 3, 
		Hilly: 2 
	}, 
	4: {
		Level: 4, 
		Sloping: 4, 
		Hilly: 2 
	}, 
	'–': {
		Level: '–', 
		Sloping: '–', 
		Hilly: '–'
	}
}

export function calcPotentialForWaterErosion(languageCode: LanguageCode, soilInfo: SoilInfo, topography: Topography): string {
	let potential: Potential = PotentialForWaterErosion[soilInfo.erosionFactor][topography]
	let description = PotentialDescriptions[languageCode][potential]
	return description
}