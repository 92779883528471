import React from 'react'
import { IonItemGroup, IonList } from '@ionic/react'

import { AppContext, InputData } from '../../App'
import { PageProps } from '../Page'
import { counties, soilMapSymbols, countySoilMapSymbolsDictionary, countySoilMapSymbolSetsDictionary } from '../../data/soilData'
import { TopographyDescriptions } from '../../data/topographyTypes'
import { DepthToWaterTableDescriptions } from '../../data/depthToWaterTableTypes'
import { Select } from '../../components/Select'
import { ListDivider } from '../../components/ListDivider'

import SearchSelect from '../../components/SearchSelect'
import Header from '../../components/Header'
import { makeTextValues } from '../../components/Search'

type OnInputDataChange = (inputData: InputData) => void

interface InputContentsProps extends PageProps {
  data: InputData
  onChange: OnInputDataChange
}

function getNewData<InputPropType extends keyof InputData>(inputProp: InputPropType, newValue: InputData[InputPropType], oldData: InputData): InputData {
  let newData = Object.assign({}, oldData)
  newData[inputProp] = newValue
  return newData
}

export const InputContents: React.FC<InputContentsProps> = ({ data, onChange }) => {
  const { languageCode, langResources } = React.useContext(AppContext)

  const getChangeHandler = (inputProp: keyof InputData) => (newValue?: string) => {
    let newData = getNewData(inputProp, newValue, data)
    if (inputProp === 'county' && newData.county && newData.soilMapSymbol && !countySoilMapSymbolSetsDictionary[newData.county].has(newData.soilMapSymbol)) {
      delete newData.soilMapSymbol
    }
    onChange(newData)
  }

  const countSoilMapSymbols = data.county ? countySoilMapSymbolsDictionary[data.county] : soilMapSymbols

  const searchPlaceholder = langResources.SearchSoils.replace('{count}', countSoilMapSymbols.length.toString())

  const hideSearch = !data.county

  const hideSiteAdjustments = hideSearch || !data.soilMapSymbol

  return (
    <>
      <Header headerText={langResources.Input} />
      <IonList>
        <IonItemGroup>
          <ListDivider text={langResources.FindYourSoil} />
          <Select label={langResources.Region} note={langResources.FilterByCounty} value={data.county} items={counties} onChange={getChangeHandler('county')}></Select>
          { hideSearch ? null : 
            <SearchSelect value={data.soilMapSymbol} items={countSoilMapSymbols} onChange={getChangeHandler('soilMapSymbol')} placeholder={searchPlaceholder} showMorePrompt={langResources.ShowMore} ></SearchSelect>
          }
        </IonItemGroup>
        {
          hideSiteAdjustments ? null : 
          <IonItemGroup>
            <ListDivider text={langResources.DescribeYourSite} />
            <Select label={langResources.Topography} note={langResources.AdjustIfNeeded} value={data.topography} items={makeTextValues(TopographyDescriptions, languageCode)} onChange={getChangeHandler('topography')}></Select>
            <Select label={langResources.DepthToWaterTable} note={langResources.AdjustIfNeeded} value={data.depthToGroundWater} items={makeTextValues(DepthToWaterTableDescriptions, languageCode)} onChange={getChangeHandler('depthToGroundWater')}></Select>
          </IonItemGroup>
        }
      </IonList>
    </>
  )
}

export default InputContents
