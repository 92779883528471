import React from 'react'
import { IonCol, IonGrid, IonIcon, IonItemGroup, IonRow } from '@ionic/react'
import { arrowBack } from 'ionicons/icons'

import { AppContext } from '../../App'
import { OutputContentsProps } from './OutputContents'
import { Field } from '../../components/Field'
import { ListDivider } from '../../components/ListDivider'

export const OutputNotReadyContents: React.FC<OutputContentsProps> = ({ data }) => {
  const { langResources } = React.useContext(AppContext)

  const showCountyInstructions = !data.county

  const showSearchInstructions = !showCountyInstructions && !data.soilMapSymbol

  const instructions = 
    showCountyInstructions ? langResources.SelectYourCounty : 
    showSearchInstructions ? langResources.FindSoilSymbol : 
    null

  return (
    <>
      <h1 className='hidden'>Space Filler</h1>
      <IonItemGroup>
        <div className='hidden'>
          <ListDivider text='Space Filler' />
        </div>
        {
          // search instructions are bumped down one row to align with search box
          showSearchInstructions ?  
          <Field className='hidden' label='' value={''} titleizeValue></Field> : null
        }
        { instructions ? 
          <IonGrid className='ion-margin'>
            <IonRow className='ion-align-items-center'>
              <IonCol size-lg='2' size-xl='1'>
                <IonIcon size='large' icon={arrowBack} />
              </IonCol>
              <IonCol className='ion-text-justify'>
                {instructions}
              </IonCol>
            </IonRow>
          </IonGrid> : null
        }
      </IonItemGroup>
    </>
  )
}

export default OutputNotReadyContents
