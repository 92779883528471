// adapted from https://loading.io/css/

import React from 'react'

import './SpinnerRaw.scss'

export interface SpinnerProps {
	message?: string
}

export const Spinner: React.FC<SpinnerProps> = ({ message = '' }) => {
	return (
		<div className='spinner'>
			<div className="lds-ring">
				<div></div>
				<div></div>
				<div></div>
				<div></div>
			</div>
			<span className='spinner-message'>
				{message}
			</span>
		</div>
	)
}