import { IonItemDivider } from "@ionic/react";

export interface ListDividerProps  {
	text?: string
}

export const ListDivider: React.FC<ListDividerProps> = ({ text }) => {
	return (
		<IonItemDivider mode='ios'>
			{text}
		</IonItemDivider>
	)
}