import React from "react"
import { IonButton, IonIcon } from "@ionic/react"
import { download } from 'ionicons/icons'

import { AppContext } from "../App"
import { PageProps } from "../pages/Page"

export interface DownloadButtonProps extends PageProps {}

export const DownloadButton: React.FC<DownloadButtonProps> = () => {
	const { langResources, setDownloadMode } = React.useContext(AppContext)

	return (
		<div className='ion-text-right ion-margin'>
			<IonButton color='oscia-yellow' size='small' onClick={() => setDownloadMode(true)}> 
				{langResources.Download}
				<IonIcon slot="end" icon={download}></IonIcon>
			</IonButton>
		</div>
	)
}

export default DownloadButton
