import React from 'react'
import {
	IonCol,
	IonContent,
	IonFooter,
	IonGrid,
	IonHeader,
	IonRow,
	IonTitle,
	IonToolbar,
} from '@ionic/react'
import { LanguageCodeMap } from './Language'
import { AppContext } from './App'
import { AboutContents } from './pages/contents/AboutContents'
import { InputContents } from './pages/contents/InputContents'
import { OutputContents } from './pages/contents/OutputContents'
import { OutputNotReadyContents } from './pages/contents/OutputNotReadyContents'
import { LanguageLinks } from './components/LanguageLinks'
import { AppViewProps, isReadyToCompute } from './App'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/variables.css'

import './App.css'

import logoOsciaEn from './logo-oscia-en.png'
import logoOsciaFr from './logo-oscia-fr.png'
import logoOntario from './logo-ontario.svg'
import logoCanadaLight from './logo-canada-light.svg'
import logoCanadaDark from './logo-canada-dark.svg'
import logoCapEn from './logo-cap-en.svg'
import logoCapFr from './logo-cap-fr.svg'

const logoCap: LanguageCodeMap<string> = {
	en: logoCapEn,
	fr: logoCapFr
}

const logoOscia: LanguageCodeMap<string> = {
	en: logoOsciaEn,
	fr: logoOsciaFr
}

export const AppViewDesktop: React.FC<AppViewProps> = ({ inputData, setInputData }) => {
	const { langResources, languageCode } = React.useContext(AppContext)

	let outputElement = <OutputNotReadyContents data={inputData} />
	if (isReadyToCompute(inputData)) {
		outputElement = <OutputContents data={inputData} />
	}

	return (
		<>
			<IonHeader>
				<IonToolbar>
					<IonTitle color='oscia-green'>
						<IonGrid>
							<IonRow className='ion-align-items-center'>
								<IonCol>
									<a href={langResources.LogoLink}>
										<img src={logoOscia[languageCode]} alt={langResources.LogoOsciaAlt} height='80px' />
									</a>
								</IonCol>
								<IonCol>
									<h1 style={{ fontSize: 'xx-large' }}>{langResources.OntarioSoilsCalculator}</h1>
								</IonCol>
								<IonCol className='ion-text-end' size='3'>
									<LanguageLinks />
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonTitle>
				</IonToolbar>
			</IonHeader>

			<IonContent className='ion-padding'>
				<IonGrid>
					<IonRow>
						<IonCol className='ion-margin-end'>
							<AboutContents />
						</IonCol>
						<IonCol>
							<InputContents data={inputData} onChange={setInputData} />
						</IonCol>
						<IonCol>
							{outputElement}
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>

			<IonFooter>
				<IonGrid>
					<IonRow className='ion-align-items-center ion-text-center'>
						<IonCol className='cap-logo'>
							<img src={logoCap[languageCode]} alt={langResources.LogoCapAlt} height='50px' />
						</IonCol>
						<IonCol className='oscia-logo'>
							<img src={logoOscia[languageCode]} alt={langResources.LogoOsciaAlt} height='60px' />
						</IonCol>
						<IonCol className='ontario-logo'>
							<img src={logoOntario} alt={langResources.LogoOntarioAlt} height='30px' />
						</IonCol>
						<IonCol className='canada-logo'>
							<img className='light-logo' src={logoCanadaLight} alt={langResources.LogoCanadaAlt} height='30px' />
							<img className='dark-logo' src={logoCanadaDark} alt={langResources.LogoCanadaAlt} height='30px' />
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonFooter>
		</>
	)
}

export default AppViewDesktop
