import React from "react"

export type RangePredicate = (anyVal: string) => boolean

// Useful for values we want to persist across sessions, but also override at any time via queryString params
// A user's language setting is a perfect example
export function useOverridableLocalStorage<StringT extends string>(key: string, defaultValue: StringT, valueIsInRange?: RangePredicate): [StringT, (newValue: StringT) => void] {

	// first we check query string
	const urlParams = new URLSearchParams(window.location.search)
	let [queryValue, storageValue] = [urlParams.get(key), localStorage.getItem(key)].map(val => {
		// null value or no range checker and we return the val
		if (!val || !valueIsInRange) return val
		// otherwise we check it's range before return it
		else {
			return valueIsInRange(val) ? val : null
		}
	})

	const [value, setValue] = React.useState(queryValue ?? storageValue ?? defaultValue) 

	if (storageValue !== value) {
		localStorage.setItem(key, value)
	}

	const updateValue = (newValue: StringT) => {
		localStorage.setItem(key, value)
		setValue(newValue)
	}

	return [value as StringT, updateValue]
}