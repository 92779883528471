import React from 'react'

export interface WindowSize {
	width: number
	height: number
	isWideScreen: boolean
}

export function getWindowSize(): WindowSize {
	const { innerWidth, innerHeight } = window
	return {
		width: innerWidth,
		height: innerHeight, 
		isWideScreen: isOnWideScreen(innerWidth)
	}
}

const WIDE_SCREEN_WIDTH = 1024

function isOnWideScreen(windowWidth: number): boolean {
	return windowWidth >= WIDE_SCREEN_WIDTH
}

export function useWindowSize(): WindowSize {
	const [windowSize, setWindowSize] = React.useState(getWindowSize())

	function handleWindowResize() {
		setWindowSize(getWindowSize())
	}

	React.useEffect(() => {
		window.addEventListener('resize', handleWindowResize)

		return () => {
			window.removeEventListener('resize', handleWindowResize)
		}
	}, [])

	return windowSize
}