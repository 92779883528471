import React from 'react'
import { IonText } from '@ionic/react'

import { AppContext } from '../../App'
import { PageProps } from '../Page'
import { Header } from '../../components/Header'

// webpack doesn't like destructuring package.json in import
import packageJson from '../../../package.json'

// so we perform destructuring in additional step
const { version, soils_calculator_config: config } = packageJson

export const AboutContents: React.FC<PageProps> = () => {
  const { langResources } = React.useContext(AppContext)

  const versionDisplay = config.showVersion ?
    <IonText color='oscia-green'>V.{version}</IonText> : null

  return (
    <div className='about-contents'>
      <Header headerText={langResources.About} />
      <p className='ion-text-justify' dangerouslySetInnerHTML={{ __html: langResources.About1 }}></p>
      <p className='ion-text-justify' dangerouslySetInnerHTML={{ __html: langResources.About2 }}></p>
      <p className='ion-text-justify' dangerouslySetInnerHTML={{ __html: langResources.About3 }}></p>
      <p className='footnote' dangerouslySetInnerHTML={{ __html: langResources.AboutFootnote }}></p>
      <p className='version'>{versionDisplay}</p>
    </div>
  )
}

export default AboutContents
