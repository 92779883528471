import React from 'react'
import { IonContent, IonPage, IonToolbar } from '@ionic/react'

export interface PageProps {
	// language: Language
}

export interface MobilePageEnvelopeProps {
	// header: string
}

// we need to add an IonToolbar to get below the shared toolbar in the app
export const MobilePageEnvelope: React.FC<React.PropsWithChildren<MobilePageEnvelopeProps>> = ({ children /*, header */ }) => {
	return (
		<IonPage>
			<IonToolbar>
			</IonToolbar>
			<IonContent className='ion-padding' >
				{children}
			</IonContent>
		</IonPage>
	)
}
