import { IonItem, IonNote } from "@ionic/react";
import React, { PropsWithChildren } from "react";

export interface ListItemProps extends PropsWithChildren  {
	note?: string
	className?: string
}

export const ListItem: React.FC<ListItemProps> = ({ note, children, className }) => {
	let noteElement: React.ReactElement | null = null
	if (note) {
		noteElement = (
			<IonNote slot='end'>
				{note}
			</IonNote>
		)
	}
	return (
		<IonItem className={className}>
			{children}
			{noteElement}
		</IonItem>
	)
}