import React from "react"
import { IonFab, IonFabButton, IonIcon } from "@ionic/react"
import { Color } from "./shared"

import './FabButton.css'

export type HorizontalPosition = 'left' | 'center' | 'right'
export type VerticalPosition = 'top' | 'center' | 'bottom'

export interface FabButtonProps {
	positionH?: HorizontalPosition
	positionV?: VerticalPosition
	icon?: string
	title?: string
	color?: Color
	onClick: () => void
}

const ionHorizontalPosition: { [k in HorizontalPosition]: 'start' | 'center' | 'end' } = {
	left: 'start',
	center: 'center',
	right: 'end'
}

export const FabButton: React.FC<FabButtonProps> = ({ icon, title, positionH, positionV, onClick, color }) => {
	let [h, v] = [positionH ?? 'right', positionV ?? 'bottom']

	return (
      <IonFab vertical={v} horizontal={ionHorizontalPosition[h]} slot='fixed'>
        <IonFabButton onClick={onClick} title={title} color={color ?? 'oscia-yellow'}>
          <IonIcon icon={icon}></IonIcon>
        </IonFabButton>
      </IonFab>
	)
}