import { LanguageCodeMap } from '../Language'

export type DepthToWaterTable = 'Shallow' | 'Moderate' | 'Deep' | 'VeryDeep'

export const DepthToWaterTableDescriptions: LanguageCodeMap<Record<DepthToWaterTable, string>> = {
	en: {
		Shallow: "<3 ft. (1 m)",
		Moderate: "3-15 ft. (1-4.6 m)",
		Deep: "16-45 ft. (5-14 m)",
		VeryDeep: ">45 ft. (14 m)", 
	}, 
	fr: {
		Shallow: "Moins de 3 pi (1 m)",
		Moderate: "3 à 15 pi (1 à 4,6 m)",
		Deep: "16 à 45 pi (5 é 14 m)",
		VeryDeep: "Plus de 45 pi (14 m)",
	}, 
}

