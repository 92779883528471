import React from 'react'
import { useHistory } from 'react-router-dom'
import { arrowForward } from 'ionicons/icons'

import { AppContext, ROUTES } from '../App'
import { PageProps, MobilePageEnvelope } from './Page'
import { AboutContents } from './contents/AboutContents'
import { FabButton } from '../components/FabButton'


export const AboutPage: React.FC<PageProps> = () => {
  const history = useHistory()
  const { langResources } = React.useContext(AppContext)

  return (
    <MobilePageEnvelope>
      <FabButton icon={arrowForward} positionV='top' onClick={() => history.push(ROUTES.input)} title={langResources.Calculate} />
      <AboutContents />
    </MobilePageEnvelope>
  )
}

export default AboutPage
