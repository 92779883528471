import { LanguageCodeMap } from '../Language'

export type Topography = 'Level' | 'Sloping' | 'Hilly'

export const DEFAULT_TOPOGRAPHY: Topography = 'Level'

export const TopographyDescriptions: LanguageCodeMap<Record<Topography, string>> = {
	en: {
		Level: "Level (<2%) (A,a,B,b)",
		Sloping: "Sloping (2-5%) (C,c)",
		Hilly: "Hilly (>5%) (D,d,E,e,F,f)"
	},
	fr: {
		Level: "Terrain plat (moins de 2%) (A,a,B,b)",
		Sloping: "Pente légère (2 à 5%) (C,c)",
		Hilly: "Pente plus forte (plus de 5%) (D,d,E,e,F,f)",
	},
}
