import React from 'react'
import { IonSelect, IonSelectOption } from '@ionic/react'
import { IonSelectCustomEvent, SelectChangeEventDetail  } from '@ionic/core/components'

import { LanguageNames } from '../Language'
import { AppContext } from '../App'

export const LanguageSelector: React.FC = () => {
	const { languageCode, setLanguageCode } = React.useContext(AppContext)

	const handleChange = (e: IonSelectCustomEvent<SelectChangeEventDetail>) => {
		setLanguageCode(e.target.value)
	}

	const options = Object.entries(LanguageNames).map(([languageCode, languageName]) => <IonSelectOption key={languageCode} value={languageCode}>{languageName}</IonSelectOption>)

	return (
		<IonSelect onIonChange={handleChange} value={languageCode} interface='popover'>
			{options}
		</IonSelect>
	)
}
