import { LanguageCodeMap } from "../Language"

export type HydrologicalSoilGroup = 'RAPID' | 'MODERATE' | 'SLOW' | 'VERY SLOW' | '–'

export const HydrologicalSoilGroupDescriptions: LanguageCodeMap<Record<HydrologicalSoilGroup, string>> = {
	en: {
		RAPID: "RAPID", 
		MODERATE: "MODERATE", 
		SLOW: "SLOW", 
		"VERY SLOW": "VERY SLOW",
		'–': '–'
	}, 
	fr: {
		RAPID: "Rapide",
		MODERATE: "Modérée",
		SLOW: "Lente",
		'VERY SLOW': "Très lente",
		'–': '–'
	}, 
}

// export const hydrologicalSoilGroupItems: Array<HydrologicalSoilGroup> = ['RAPID', 'MODERATE', 'SLOW', 'VERY SLOW']