import { IonInput, IonLabel } from "@ionic/react";
import React from "react";
import { ListItem } from "./ListItem";

export interface FieldProps {
	label: string
	value: string
	titleizeValue?: boolean
	className?: string
}

export const Field: React.FC<FieldProps> = ({label, value, titleizeValue, className}) => {
	let val = titleizeValue ? value.toLowerCase() : value
	return (
		<ListItem className={className}>
			<IonLabel position='stacked'>{label}</IonLabel>
			<IonInput readonly className={titleizeValue ? 'ion-text-capitalize' : ''}>{val}</IonInput>
		</ListItem>
	)
}