import { SoilDatum } from "./soilData"

export const soilDataArray: Array<SoilDatum> = [
	['Algoma', 'Ads', 'IMPERFECT', 'MODERATE', 4, 2, 'M', " "],
	['Algoma', 'Agsil', 'POOR', 'VERY SLOW', 2, 3, 'H', "AGNEWE SILT LOAM"],
	['Algoma', 'Al', 'POOR', 'SLOW', 2, 3, 'H', "AZILDA LOAM"],
	['Algoma', 'Alc', 'IMPERFECT', 'SLOW', 4, 4, 'H', "ALBANY CLAY"],
	['Algoma', 'Alcl', 'IMPERFECT', 'SLOW', 4, 4, 'H', "ALBANY CLAY LOAM"],
	['Algoma', 'Ale', 'IMPERFECT', 'SLOW', 4, 4, 'H', " "],
	['Algoma', 'Alel', 'IMPERFECT', 'SLOW', 4, 4, 'H', " "],
	['Algoma', 'All', 'IMPERFECT', 'SLOW', 3, 4, 'M', "ALBANY LOAM"],
	['Algoma', 'Als', 'IMPERFECT', 'SLOW', 3, 2, 'M', "ALBANY SAND"],
	['Algoma', 'Alsil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "ALBANY SILT LOAM"],
	['Algoma', 'Alsl', 'IMPERFECT', 'SLOW', 3, 2, 'M', "ALBANY SANDY LOAM"],
	['Algoma', 'Asil', 'POOR', 'SLOW', 2, 3, 'H', "AZILDA SILT LOAM"],
	['Algoma', 'Bbl', 'POOR', 'SLOW', 3, 3, 'H', " "],
	['Algoma', 'Bfl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRADLEY FINE SANDY LOAM"],
	['Algoma', 'BH', 'VERY POOR', 'SLOW', 3, 2, 'M', "BLYTHE ORGANIC"],
	['Algoma', 'Bl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRADLEY LOAM"],
	['Algoma', 'Bpfl', 'VERY POOR', 'SLOW', 3, 2, 'M', "BURPEE FINE SANDY LOAM"],
	['Algoma', 'Bs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADLEY SAND"],
	['Algoma', 'Bsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADLEY SANDY LOAM"],
	['Algoma', 'Bvl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRADLEY VERY FINE SANDY LOAM"],
	['Algoma', 'Bwfl', 'WELL', 'SLOW', 2, 4, 'L', "BALDWIN FINE SANDY LOAM"],
	['Algoma', 'Bws', 'WELL', 'MODERATE', 3, 2, 'L', "BALDWIN SAND"],
	['Algoma', 'Bwsil', 'WELL', 'SLOW', 2, 4, 'L', "BALDWIN SILT LOAM"],
	['Algoma', 'Cac', 'POOR', 'VERY SLOW', 3, 3, 'H', "CASIMAR CLAY"],
	['Algoma', 'Cacl', 'POOR', 'VERY SLOW', 3, 4, 'H', "CASIMAR CLAY LOAM"],
	['Algoma', 'Cag', 'POOR', 'SLOW', 4, 3, 'M', "CASIMAR GRAVEL"],
	['Algoma', 'Cavl', 'POOR', 'VERY SLOW', 3, 3, 'H', "CASIMAR VERY FINE SANDY LOAM"],
	['Algoma', 'Cc', 'IMPERFECT', 'SLOW', 4, 4, 'H', "CHARTRAND CLAY"],
	['Algoma', 'Ccl', 'IMPERFECT', 'SLOW', 4, 4, 'H', "CHARTRAND CLAY LOAM"],
	['Algoma', 'Cf', 'VERY POOR', 'SLOW', 3, 2, 'M', "CRYSTAL FALLS ORGANIC"],
	['Algoma', 'Cl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CHARTRAND LOAM"],
	['Algoma', 'Cor', 'VERY POOR', 'SLOW', 3, 2, 'M', "CORBEIL ORGANIC"],
	['Algoma', 'Cpfl', 'POOR', 'SLOW', 3, 3, 'H', "CAPREOL FINE SANDY LOAM"],
	['Algoma', 'Cpl', 'POOR', 'SLOW', 3, 3, 'H', "CAPREOL LOAM"],
	['Algoma', 'Cpsl', 'POOR', 'SLOW', 4, 2, 'M', "CAPREOL SANDY LOAM"],
	['Algoma', 'Cpvl', 'POOR', 'SLOW', 3, 3, 'H', "CAPREOL VERY FINE SANDY LOAM"],
	['Algoma', 'Csil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CHARTRAND SILT LOAM"],
	['Algoma', 'Csl', 'IMPERFECT', 'SLOW', 3, 2, 'M', "CHARTRAND SANDY LOAM"],
	['Algoma', 'Ct', 'VERY POOR', 'SLOW', 3, 2, 'M', "CARTERTON ORGANIC"],
	['Algoma', 'Cusil', 'WELL', 'RAPID', 4, 2, 'L', "CUTLER SILT LOAM"],
	['Algoma', 'Cusl', 'WELL', 'RAPID', 4, 1, 'L', "CUTLER SANDY LOAM"],
	['Algoma', 'Das', 'VERY POOR', 'SLOW', 3, 2, 'M', "DAYTON SAND"],
	['Algoma', 'Dasil', 'VERY POOR', 'SLOW', 3, 2, 'M', "DAYTON SILT LOAM"],
	['Algoma', 'Dc', 'WELL', 'SLOW', 3, 3, 'M', "DELAMERE CLAY"],
	['Algoma', 'Dcl', 'WELL', 'SLOW', 4, 4, 'M', "DELAMERE CLAY LOAM"],
	['Algoma', 'Defl', 'WELL', 'RAPID', 4, 2, 'L', "DENMAN FINE SANDY LOAM"],
	['Algoma', 'Del', 'WELL', 'RAPID', 4, 2, 'L', "DENMAN LOAM"],
	['Algoma', 'Des', 'WELL', 'RAPID', 4, 1, 'L', "DENMAN SAND"],
	['Algoma', 'Desl', 'WELL', 'RAPID', 4, 1, 'L', "DENMAN SANDY LOAM"],
	['Algoma', 'Dfl', 'WELL', 'SLOW', 2, 4, 'L', "DELAMERE FINE SANDY LOAM"],
	['Algoma', 'Dkcl', 'WELL', 'SLOW', 3, 3, 'M', "DOKISE CLAY LOAM"],
	['Algoma', 'Dkel', 'WELL', 'SLOW', 3, 3, 'M', " "],
	['Algoma', 'Dkfl', 'WELL', 'RAPID', 4, 2, 'L', "DOKISE FINE SANDY LOAM"],
	['Algoma', 'Dkls', 'WELL', 'RAPID', 4, 1, 'L', "DOKISE LOAMY SAND"],
	['Algoma', 'Dks', 'WELL', 'RAPID', 4, 1, 'L', "DOKISE SAND"],
	['Algoma', 'Dksil', 'WELL', 'RAPID', 4, 2, 'L', "DOKISE SILT LOAM"],
	['Algoma', 'Dksl', 'WELL', 'RAPID', 4, 1, 'L', "DOKISE SANDY LOAM"],
	['Algoma', 'Dsil', 'WELL', 'SLOW', 2, 4, 'L', "DELAMERE SILT LOAM"],
	['Algoma', 'Dsl', 'WELL', 'MODERATE', 4, 2, 'L', "DELAMERE SANDY LOAM"],
	['Algoma', 'Eacl', 'POOR', 'SLOW', 3, 3, 'H', "EAKETT CLAY LOAM"],
	['Algoma', 'Eals', 'POOR', 'SLOW', 4, 2, 'M', "EAKETT LOAMY SAND"],
	['Algoma', 'Eas', 'POOR', 'SLOW', 4, 2, 'M', "EAKETT SAND"],
	['Algoma', 'Easl', 'POOR', 'SLOW', 4, 2, 'M', "EAKETT SANDY LOAM"],
	['Algoma', 'El', 'WELL', 'RAPID', 4, 2, 'L', "ELLICE LOAM"],
	['Algoma', 'Etsil', 'POOR', 'SLOW', 2, 3, 'H', "EVEREND SILT LOAM"],
	['Algoma', 'Fl', 'WELL', 'MODERATE', 1, 3, 'L', "FRECHETTE LOAM"],
	['Algoma', 'Fnfl', 'WELL', 'RAPID', 4, 2, 'L', "FREMLIN FINE SANDY LOAM"],
	['Algoma', 'Fnsl', 'WELL', 'RAPID', 4, 1, 'L', "FREMLIN SANDY LOAM"],
	['Algoma', 'Fsil', 'WELL', 'MODERATE', 1, 3, 'L', "FRECHETTE SILT LOAM"],
	['Algoma', 'Fyc', 'WELL', 'SLOW', 3, 3, 'M', "FYNXAL CLAY"],
	['Algoma', 'Fycl', 'WELL', 'SLOW', 4, 4, 'M', "FYNXAL CLAY LOAM"],
	['Algoma', 'Fyfl', 'WELL', 'SLOW', 2, 4, 'L', "FYNXAL FINE SANDY LOAM"],
	['Algoma', 'Fysil', 'WELL', 'SLOW', 2, 4, 'L', "FYNXAL SILT LOAM"],
	['Algoma', 'Fysl', 'WELL', 'MODERATE', 4, 2, 'L', "FYNXAL SANDY LOAM"],
	['Algoma', 'Gacl', 'POOR', 'VERY SLOW', 3, 3, 'H', "GOUVEREAU CLAY LOAM"],
	['Algoma', 'Gafl', 'POOR', 'SLOW', 3, 3, 'H', "GOUVEREAU FINE SANDY LOAM"],
	['Algoma', 'Gas', 'POOR', 'SLOW', 4, 2, 'M', "GOUVEREAU SAND"],
	['Algoma', 'Gasl', 'POOR', 'SLOW', 4, 2, 'M', "GOUVEREAU SANDY LOAM"],
	['Algoma', 'Gawcl', 'WELL', 'SLOW', 4, 4, 'M', "GAWASE CLAY LOAM"],
	['Algoma', 'Ggsl', 'VERY POOR', 'SLOW', 3, 2, 'M', "GOULAIS GRAVELLY SANDY LOAM"],
	['Algoma', 'Gls', 'VERY POOR', 'SLOW', 3, 2, 'M', "GOULAIS LOAMY SAND"],
	['Algoma', 'Gs', 'VERY POOR', 'SLOW', 3, 2, 'M', "GOULAIS SAND"],
	['Algoma', 'Gsil', 'VERY POOR', 'SLOW', 3, 2, 'M', "GOULAIS SILT LOAM"],
	['Algoma', 'Gsl', 'VERY POOR', 'SLOW', 3, 2, 'M', "GOULAIS SANDY LOAM"],
	['Algoma', 'Hdcl', 'POOR', 'VERY SLOW', 3, 3, 'H', "HADDO CLAY LOAM"],
	['Algoma', 'Hdsil', 'POOR', 'VERY SLOW', 2, 3, 'H', "HADDO SILT LOAM"],
	['Algoma', 'Hdsl', 'POOR', 'SLOW', 4, 3, 'M', "HADDO SANDY LOAM"],
	['Algoma', 'Hfl', 'WELL', 'RAPID', 4, 2, 'L', "HARFRED FINE SANDY LOAM"],
	['Algoma', 'Higsl', 'WELL', 'RAPID', 4, 1, 'L', "HILTON BEACH GRAVELLY SANDY LOAM"],
	['Algoma', 'Hil', 'WELL', 'RAPID', 4, 2, 'L', "HILTON BEACH LOAM"],
	['Algoma', 'HL', 'VERY POOR', 'SLOW', 3, 2, 'M', "HILTON LAKE ORGANIC"],
	['Algoma', 'Issl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ISBESTER SANDY LOAM"],
	['Algoma', 'Jl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "JANDEN LOAM"],
	['Algoma', 'Jy', 'VERY POOR', 'SLOW', 3, 2, 'M', "JOCELYN ORGANIC"],
	['Algoma', 'Kcl', 'POOR', 'VERY SLOW', 3, 3, 'H', "KENABEEK CLAY LOAM"],
	['Algoma', 'Kfl', 'POOR', 'SLOW', 3, 3, 'H', "KENABEEK FINE SANDY LOAM"],
	['Algoma', 'Kgsl', 'POOR', 'SLOW', 4, 2, 'M', "KENABEEK GRAVELLY SANDY LOAM"],
	['Algoma', 'Kisil', 'WELL', 'RAPID', 4, 2, 'L', "KILLABY SILT LOAM"],
	['Algoma', 'Kisl', 'WELL', 'RAPID', 4, 1, 'L', "KILLABY SANDY LOAM"],
	['Algoma', 'Kivl', 'WELL', 'RAPID', 4, 2, 'L', "KILLABY VERY FINE SANDY LOAM"],
	['Algoma', 'Kls', 'POOR', 'SLOW', 4, 2, 'M', "KENABEEK LOAMY SAND"],
	['Algoma', 'Ks', 'POOR', 'SLOW', 4, 2, 'M', "KENABEEK SAND"],
	['Algoma', 'Ksl', 'POOR', 'SLOW', 4, 2, 'M', "KENABEEK SANDY LOAM"],
	['Algoma', 'Kysl', 'WELL', 'RAPID', 4, 1, 'L', "KYNOCH SANDY LOAM"],
	['Algoma', 'M', 'POOR', 'VERY SLOW', 3, 3, 'H', " "],
	['Algoma', 'Ma', 'VERY POOR', 'SLOW', 3, 2, 'M', "MARSH ORGANIC"],
	['Algoma', 'MC', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUDCAT CREEK ORGANIC"],
	['Algoma', 'Mdfl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MALLARD FINE SANDY LOAM"],
	['Algoma', 'Mdgsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD GRAVELLY SANDY LOAM"],
	['Algoma', 'Mdls', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD LOAMY SAND"],
	['Algoma', 'Mds', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD SAND"],
	['Algoma', 'Mdsil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MALLARD SILT LOAM"],
	['Algoma', 'Mdsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD SANDY LOAM"],
	['Algoma', 'Mdvl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MALLARD VERY FINE SANDY LOAM"],
	['Algoma', 'Mefl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MEDETTE FINE SANDY LOAM"],
	['Algoma', 'Mels', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MEDETTE LOAMY SAND"],
	['Algoma', 'Mes', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MEDETTE SAND"],
	['Algoma', 'Mesil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MEDETTE SILT LOAM"],
	['Algoma', 'Mesl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MEDETTE SANDY LOAM"],
	['Algoma', 'Mf', 'VERY POOR', 'SLOW', 3, 2, 'M', "MILFORD ORGANIC"],
	['Algoma', 'Mg', 'WELL', 'RAPID', 4, 1, 'L', "MONTEAGLE GRAVEL"],
	['Algoma', 'Mic', 'VERY POOR', 'SLOW', 3, 2, 'M', "MILLERAND CLAY"],
	['Algoma', 'Mip', 'VERY POOR', 'SLOW', 3, 2, 'M', "MILLERAND ORGANIC"],
	['Algoma', 'Misicl', 'VERY POOR', 'SLOW', 3, 4, 'M', "MILLERAND SILTY CLAY LOAM"],
	['Algoma', 'Misil', 'VERY POOR', 'SLOW', 3, 2, 'M', "MILLERAND SILT LOAM"],
	['Algoma', 'Misl', 'VERY POOR', 'SLOW', 3, 2, 'M', "MILLERAND SANDY LOAM"],
	['Algoma', 'Ms', 'WELL', 'RAPID', 4, 1, 'L', "MONTEAGLE SAND"],
	['Algoma', 'Msl', 'WELL', 'RAPID', 4, 1, 'L', "MONTEAGLE SANDY LOAM"],
	['Algoma', 'Mtsil', 'WELL', 'SLOW', 2, 4, 'L', "MARRITT SILT LOAM"],
	['Algoma', 'Mucl', 'POOR', 'VERY SLOW', 3, 3, 'H', "MUSSELLE CLAY LOAM"],
	['Algoma', 'Muvl', 'POOR', 'SLOW', 3, 3, 'H', "MUSSELLE VERY FINE SANDY LOAM"],
	['Algoma', 'Nnfl', 'WELL', 'RAPID', 4, 2, 'L', "NAIDEN FINE SANDY LOAM"],
	['Algoma', 'Nnvl', 'WELL', 'RAPID', 4, 2, 'L', "NAIDEN VERY FINE SANDY LOAM"],
	['Algoma', 'Nsil', 'IMPERFECT', 'SLOW', 2, 4, 'M', "NOELVILLE SILT LOAM"],
	['Algoma', 'Nsl', 'IMPERFECT', 'SLOW', 3, 2, 'M', "NOELVILLE SANDY LOAM"],
	['Algoma', 'Nvl', 'IMPERFECT', 'SLOW', 2, 4, 'M', "NOELVILLE VERY FINE SANDY LOAM"],
	['Algoma', 'Oc', 'POOR', 'VERY SLOW', 3, 3, 'H', "OUELLETTE CLAY"],
	['Algoma', 'Ocl', 'POOR', 'VERY SLOW', 3, 4, 'H', "OUELLETTE CLAY LOAM"],
	['Algoma', 'Ofl', 'POOR', 'VERY SLOW', 3, 3, 'H', "OUELLETTE FINE SANDY LOAM"],
	['Algoma', 'Osicl', 'POOR', 'VERY SLOW', 3, 4, 'H', "OUELLETTE SILTY CLAY LOAM"],
	['Algoma', 'Osil', 'POOR', 'VERY SLOW', 3, 3, 'H', "OUELLETTE SILT LOAM"],
	['Algoma', 'Osl', 'POOR', 'SLOW', 4, 3, 'M', "OUELLETTE SANDY LOAM"],
	['Algoma', 'Pd', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEDLOW ORGANIC"],
	['Algoma', 'Phg', 'WELL', 'RAPID', 4, 1, 'L', "PHELANS GRAVEL"],
	['Algoma', 'Phgsl', 'WELL', 'RAPID', 4, 1, 'L', "PHELANS GRAVELLY SANDY LOAM"],
	['Algoma', 'Phs', 'WELL', 'RAPID', 4, 1, 'L', "PHELANS SAND"],
	['Algoma', 'Phsil', 'WELL', 'RAPID', 4, 2, 'L', "PHELANS SILT LOAM"],
	['Algoma', 'Phsl', 'WELL', 'RAPID', 4, 1, 'L', "PHELANS SANDY LOAM"],
	['Algoma', 'Phvl', 'WELL', 'RAPID', 4, 2, 'L', "PHELANS VERY FINE SANDY LOAM"],
	['Algoma', 'Pasl', 'POOR', 'SLOW', 4, 3, 'H', "PARKET SANDY LOAM"],
	['Algoma', 'Resl', 'VERY POOR', 'SLOW', 3, 2, 'M', "RECOLLECT SANDY LOAM"],
	['Algoma', 'Rhqsl', 'WELL', 'RAPID', 4, 1, 'L', " "],
	['Algoma', 'Risl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RIDOUT SANDY LOAM"],
	['Algoma', 'Rocl', 'VERY POOR', 'SLOW', 3, 4, 'M', "ROBITAILLE CLAY LOAM"],
	['Algoma', 'Ros', 'VERY POOR', 'SLOW', 3, 2, 'M', "ROBITAILLE SAND"],
	['Algoma', 'Rosl', 'VERY POOR', 'SLOW', 3, 2, 'M', "ROBITAILLE SANDY LOAM"],
	['Algoma', 'SJl', 'WELL', 'RAPID', 1, 4, 'L', "ST JOSEPH'S LOAM"],
	['Algoma', 'Ssil', 'POOR', 'SLOW', 3, 3, 'H', "STURGEON FALLS SILT LOAM"],
	['Algoma', 'Tas', 'POOR', 'SLOW', 4, 2, 'M', "TARBUTT SAND"],
	['Algoma', 'Tscl', 'WELL', 'SLOW', 4, 4, 'M', "TARENTOROUS CLAY LOAM"],
	['Algoma', 'Tssl', 'WELL', 'MODERATE', 4, 2, 'L', "TARENTOROUS SANDY LOAM"],
	['Algoma', 'Twsl', 'VERY POOR', 'SLOW', 3, 2, 'H', "TWYNING SANDY LOAM"],
	['Algoma', 'Vasl', 'WELL', 'RAPID', 3, 2, 'L', "VASEY SANDY LOAM"],
	['Algoma', 'Vrl', 'WELL', 'RAPID', 4, 2, 'L', "VERMILLION LOAM"],
	['Algoma', 'Vrsil', 'WELL', 'RAPID', 4, 2, 'L', "VERMILLION SILT LOAM"],
	['Algoma', 'Vrsl', 'WELL', 'RAPID', 4, 1, 'L', "VERMILLION SANDY LOAM"],
	['Algoma', 'Vsil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "VEUVE RIVER SILT LOAM"],
	['Algoma', 'Vsl', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "VEUVE RIVER SANDY LOAM"],
	['Algoma', 'Wdfl', 'WELL', 'RAPID', 4, 2, 'L', "WENDIGO FINE SANDY LOAM"],
	['Algoma', 'Wdg', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO GRAVEL"],
	['Algoma', 'Wdgsl', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO GRAVELLY SANDY LOAM"],
	['Algoma', 'Wdls', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO LOAMY SAND"],
	['Algoma', 'Wds', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO SAND"],
	['Algoma', 'Wdsil', 'WELL', 'RAPID', 4, 2, 'L', "WENDIGO SILT LOAM"],
	['Algoma', 'Wdsl', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO SANDY LOAM"],
	['Algoma', 'Wdvl', 'WELL', 'RAPID', 4, 2, 'L', "WENDIGO VERY FINE SANDY LOAM"],
	['Algoma', 'Wfl', 'POOR', 'SLOW', 3, 3, 'H', "WARREN FINE SANDY LOAM"],
	['Algoma', 'Wivl', 'POOR', 'SLOW', 3, 3, 'H', "WILLBANK VERY FINE SANDY LOAM"],
	['Algoma', 'Wol', 'POOR', 'VERY SLOW', 2, 3, 'H', "WOLF LOAM"],
	['Algoma', 'Wosil', 'POOR', 'VERY SLOW', 2, 3, 'H', "WOLF SILT LOAM"],
	['Algoma', 'Wosl', 'POOR', 'SLOW', 4, 3, 'M', "WOLF SANDY LOAM"],
	['Algoma', 'Ws', 'POOR', 'SLOW', 4, 2, 'M', "WARREN SAND"],
	['Algoma', 'Wsl', 'POOR', 'SLOW', 4, 2, 'M', "WARREN SANDY LOAM"],
	['Brant', 'AYR', 'POOR', 'SLOW', 3, 3, 'M', "AYR FINE SANDY LOAM"],
	['Brant', 'BAY', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SANDY LOAM"],
	['Brant', 'BFO', 'WELL', 'SLOW', 2, 4, 'L', "BRANTFORD SILT LOAM"],
	['Brant', 'BFO.', 'WELL', 'MODERATE', 3, 2, 'L', "BRANTFORD SILT LOAM"],
	['Brant', 'BOO', 'WELL', 'MODERATE', 3, 2, 'L', "BOOKTON SANDY LOAM"],
	['Brant', 'BOO.T', 'WELL', 'MODERATE', 3, 2, 'L', "BOOKTON SANDY LOAM"],
	['Brant', 'BRR', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['Brant', 'BRT', 'WELL', 'MODERATE', 1, 3, 'L', "BRANT SILT LOAM"],
	['Brant', 'BUF', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD LOAM"],
	['Brant', 'BUF.C', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD LOAM"],
	['Brant', 'BVY', 'IMPERFECT', 'SLOW', 3, 3, 'H', "BEVERLY SILTY CLAY LOAM"],
	['Brant', 'CAD', 'WELL', 'RAPID', 4, 2, 'L', "CALEDON LOAM"],
	['Brant', 'CML', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "CAMILLA SANDY LOAM"],
	['Brant', 'CTG', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "CONESTOGA SILT LOAM"],
	['Brant', 'CWO', 'POOR', 'SLOW', 2, 3, 'H', "COLWOOD SILT LOAM"],
	['Brant', 'DUF', 'WELL', 'RAPID', 4, 2, 'L', "DUMFRIES SILT LOAM"],
	['Brant', 'FOX', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Brant', 'GFD', 'POOR', 'SLOW', 4, 2, 'M', "GILFORD SANDY LOAM"],
	['Brant', 'GNY', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Brant', 'GOB', 'IMPERFECT', 'SLOW', 2, 4, 'M', "GOBLES LOAM"],
	['Brant', 'GUP', 'WELL', 'MODERATE', 1, 3, 'L', "GUELPH LOAM"],
	['Brant', 'GUP.C', 'WELL', 'MODERATE', 4, 1, 'L', "GUELPH SANDY LOAM"],
	['Brant', 'HBG', 'WELL', 'MODERATE', 2, 4, 'L', "HARRISBURG SILT LOAM"],
	['Brant', 'HIG', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "HEIDELBERG VERY FINE SANDY LOAM"],
	['Brant', 'HIM', 'IMPERFECT', 'SLOW', 3, 4, 'H', "HALDIMAND SILTY CLAY"],
	['Brant', 'KVN', 'POOR', 'VERY SLOW', 3, 4, 'H', "KELVIN SILTY CLAY LOAM"],
	['Brant', 'LIC', 'POOR', 'VERY SLOW', 3, 4, 'H', "LINCOLN SILTY CLAY LOAM"],
	['Brant', 'LIY', 'POOR', 'SLOW', 2, 3, 'M', "LILY LOAM"],
	['Brant', 'LOD', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LONDON LOAM"],
	['Brant', 'MUI', 'WELL', 'SLOW', 2, 4, 'L', "MURIEL SILT LOAM"],
	['Brant', 'MYL', 'POOR', 'SLOW', 2, 3, 'H', "MARYHILL SILT LOAM"],
	['Brant', 'OKL', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "OAKLAND SANDY LOAM"],
	['Brant', 'PFD', 'WELL', 'RAPID', 4, 1, 'L', "PLAINFIELD LOAMY SAND"],
	['Brant', 'SHV', 'WELL', 'SLOW', 2, 4, 'M', "SMITHVILLE SILTY CLAY LOAM"],
	['Brant', 'SNA', 'WELL', 'MODERATE', 1, 3, 'L', "SENECA SILT LOAM"],
	['Brant', 'STD', 'WELL', 'RAPID', 1, 3, 'L', "SCOTLAND LOAM"],
	['Brant', 'SYX', 'VERY POOR', 'SLOW', 3, 2, 'M', "STYX ORGANIC"],
	['Brant', 'TEW', 'WELL', 'MODERATE', 4, 2, 'L', "TEESWATER LOAM"],
	['Brant', 'TLD', 'POOR', 'VERY SLOW', 3, 4, 'H', "TOLEDO SILTY CLAY LOAM"],
	['Brant', 'TUC', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA SILT LOAM"],
	['Brant', 'VSS', 'POOR', 'SLOW', 2, 3, 'M', "VANESSA FINE SANDY LOAM"],
	['Brant', 'WAM', 'IMPERFECT', 'RAPID', 4, 2, 'M', "WALSINGHAM LOAMY SAND"],
	['Brant', 'WIL', 'WELL', 'RAPID', 4, 2, 'L', "WILSONVILLE LOAM"],
	['Brant', 'WOW', 'WELL', 'MODERATE', 1, 3, 'L', "WOOLWICH SILT LOAM"],
	['Brant', 'WRN', 'POOR', 'SLOW', 4, 2, 'M', "WATERIN SANDY LOAM"],
	['Brant', 'WTO', 'WELL', 'RAPID', 4, 1, 'L', "WATERLOO SANDY LOAM"],
	['Brant', 'WUS', 'POOR', 'SLOW', 4, 3, 'M', "WAUSEON SANDY LOAM"],
	['Brant', 'WUS.T', 'POOR', 'SLOW', 4, 3, 'M', "WAUSEON SANDY LOAM"],
	['Bruce', 'Bc', 'POOR', 'VERY SLOW', 3, 4, 'H', "BROOKSTON CLAY LOAM"],
	['Bruce', 'Bes', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['Bruce', 'Bg', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD LOAM"],
	['Bruce', 'Bis', 'WELL', 'RAPID', 4, 1, 'L', "BRIDGEMAN SAND"],
	['Bruce', 'Bos', 'WELL', 'MODERATE', 4, 2, 'L', "BOOKTON SANDY LOAM"],
	['Bruce', 'Brl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRISBANE LOAM"],
	['Bruce', 'Bs', 'POOR', 'VERY SLOW', 3, 3, 'H', "BROOKSTON SILT LOAM"],
	['Bruce', 'Bsc', 'POOR', 'VERY SLOW', 3, 4, 'H', "BROOKSTON SILTY CLAY LOAM"],
	['Bruce', 'Bsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SANDY LOAM"],
	['Bruce', 'Cc', 'POOR', 'VERY SLOW', 3, 4, 'H', "CHESLEY CLAY LOAM"],
	['Bruce', 'Crc', 'IMPERFECT', 'SLOW', 3, 4, 'H', "CRAIGLEITH CLAY LOAM"],
	['Bruce', 'Cs', 'POOR', 'VERY SLOW', 3, 3, 'H', "CHESLEY SILT LOAM"],
	['Bruce', 'Csc', 'POOR', 'VERY SLOW', 3, 4, 'H', "CHESLEY SILTY CLAY LOAM"],
	['Bruce', 'Dc', 'WELL', 'SLOW', 3, 3, 'M', "DUNEDIN CLAY"],
	['Bruce', 'Dl', 'WELL', 'RAPID', 4, 2, 'L', "DUMFRIES LOAM"],
	['Bruce', 'Dos', 'WELL', 'RAPID', 4, 1, 'L', "DONNYBROOK SANDY LOAM"],
	['Bruce', 'Ecl', 'IMPERFECT', 'SLOW', 3, 4, 'H', "ELDERSLIE CLAY LOAM"],
	['Bruce', 'Eg', 'WELL', 'RAPID', 4, 1, 'L', "EASTPORT GRAVEL"],
	['Bruce', 'Es', 'WELL', 'RAPID', 4, 1, 'L', "EASTPORT SAND"],
	['Bruce', 'Esc', 'IMPERFECT', 'SLOW', 3, 4, 'H', "ELDERSLIE SILTY CLAY LOAM"],
	['Bruce', 'Esi', 'IMPERFECT', 'SLOW', 3, 4, 'M', "ELDERSLIE SILT LOAM"],
	['Bruce', 'Fec', 'POOR', 'VERY SLOW', 3, 4, 'H', "FERNDALE CLAY LOAM"],
	['Bruce', 'Fes', 'POOR', 'VERY SLOW', 3, 3, 'H', "FERNDALE SILT LOAM"],
	['Bruce', 'Fsl', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Bruce', 'Gil', 'POOR', 'SLOW', 3, 3, 'H', "GILFORD LOAM"],
	['Bruce', 'Gs', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SAND"],
	['Bruce', 'Gsl', 'POOR', 'SLOW', 4, 2, 'L', "GRANBY SANDY LOAM"],
	['Bruce', 'Hal', 'WELL', 'MODERATE', 1, 3, 'L', "HARKAWAY LOAM"],
	['Bruce', 'Hal-s', 'WELL', 'MODERATE', 1, 3, 'L', "HARKAWAY LOAM"],
	['Bruce', 'Has', 'WELL', 'MODERATE', 1, 3, 'L', "HARKAWAY SILT LOAM"],
	['Bruce', 'Has-s', 'WELL', 'MODERATE', 1, 3, 'L', "HARKAWAY SILT LOAM"],
	['Bruce', 'Hl', 'WELL', 'MODERATE', 1, 3, 'L', "HARRISTON LOAM"],
	['Bruce', 'Hs', 'WELL', 'MODERATE', 1, 3, 'L', "HARRISTON SILT LOAM"],
	['Bruce', 'Huc', 'WELL', 'SLOW', 2, 4, 'M', "HURON CLAY LOAM"],
	['Bruce', 'Hus', 'WELL', 'SLOW', 2, 4, 'L', "HURON SILT LOAM"],
	['Bruce', 'Kc', 'IMPERFECT', 'SLOW', 3, 3, 'H', "KEMBLE CLAY LOAM"],
	['Bruce', 'Kl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "KILLEAN LOAM"],
	['Bruce', 'Ks', 'IMPERFECT', 'SLOW', 2, 4, 'M', "KEMBLE SILT LOAM"],
	['Bruce', 'Ll', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LISTOWEL LOAM"],
	['Bruce', 'Ll-s', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LISTOWEL LOAM"],
	['Bruce', 'Ls', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LISTOWEL SILT LOAM"],
	['Bruce', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Bruce', 'Mc', 'POOR', 'VERY SLOW', 3, 3, 'H', "MORLEY CLAY"],
	['Bruce', 'Ol', 'WELL', 'MODERATE', 4, 2, 'L', "OSPREY LOAM"],
	['Bruce', 'Pal', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL LOAM"],
	['Bruce', 'Pas', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL SILT LOAM"],
	['Bruce', 'Pc', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PERTH CLAY LOAM"],
	['Bruce', 'Pls', 'WELL', 'RAPID', 4, 1, 'L', "PLAINFIELD SAND"],
	['Bruce', 'Ps', 'IMPERFECT', 'SLOW', 3, 4, 'M', "PERTH SILT LOAM"],
	['Bruce', 'Sc', 'WELL', 'SLOW', 2, 4, 'M', "SAUGEEN CLAY LOAM"],
	['Bruce', 'Sg', 'WELL', 'RAPID', 4, 2, 'L', "SARGENT LOAM"],
	['Bruce', 'Ss', 'WELL', 'SLOW', 2, 4, 'L', "SAUGEEN SILT LOAM"],
	['Bruce', 'Ssc', 'WELL', 'SLOW', 2, 4, 'M', "SAUGEEN SILTY CLAY LOAM"],
	['Bruce', 'Sul', 'WELL', 'RAPID', 4, 1, 'L', "SULLIVAN SANDY LOAM"],
	['Bruce', 'Sus', 'WELL', 'RAPID', 4, 1, 'L', "SULLIVAN SAND"],
	['Bruce', 'Tc', 'POOR', 'VERY SLOW', 3, 4, 'H', "TOLEDO CLAY LOAM"],
	['Bruce', 'Tes', 'WELL', 'MODERATE', 4, 2, 'L', "TEESWATER SILT LOAM"],
	['Bruce', 'Tis', 'WELL', 'RAPID', 4, 1, 'L', "TIOGA SAND"],
	['Bruce', 'Tisl', 'WELL', 'RAPID', 4, 1, 'L', "TIOGA SANDY LOAM"],
	['Bruce', 'Ts', 'POOR', 'VERY SLOW', 3, 3, 'H', "TOLEDO SILT LOAM"],
	['Bruce', 'Vc', 'WELL', 'SLOW', 2, 4, 'M', "VINCENT CLAY LOAM"],
	['Bruce', 'Vs', 'WELL', 'SLOW', 2, 4, 'L', "VINCENT SILT LOAM"],
	['Bruce', 'Vsc', 'WELL', 'SLOW', 2, 4, 'M', "VINCENT SILTY CLAY LOAM"],
	['Bruce', 'Was', 'POOR', 'SLOW', 4, 3, 'M', "WAUSEON SANDY LOAM"],
	['Bruce', 'Wl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "WIARTON LOAM"],
	['Bruce', 'Ws', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "WIARTON SILT LOAM"],
	['Bruce', 'Wsl', 'WELL', 'RAPID', 4, 1, 'L', "WATERLOO SANDY LOAM"],
	['Cochrane', 'Abgsl', 'WELL', 'RAPID', 4, 1, 'L', "ABITIBI GRAVELLY SANDY LOAM"],
	['Cochrane', 'Abl', 'WELL', 'RAPID', 4, 2, 'L', "ABITIBI LOAM"],
	['Cochrane', 'Abs', 'WELL', 'RAPID', 4, 1, 'L', "ABITIBI SAND"],
	['Cochrane', 'Absl', 'WELL', 'RAPID', 4, 1, 'L', "ABITIBI SANDY LOAM"],
	['Cochrane', 'Acl', 'POOR', 'VERY SLOW', 3, 3, 'H', "AUDREY CLAY LOAM"],
	['Cochrane', 'Al', 'POOR', 'VERY SLOW', 2, 3, 'H', "AUDREY LOAM"],
	['Cochrane', 'Ans', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ANSONVILLE SAND"],
	['Cochrane', 'Ansl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ANSONVILLE SANDY LOAM"],
	['Cochrane', 'Apsil', 'WELL', 'SLOW', 2, 4, 'L', "ALPINE SILT LOAM"],
	['Cochrane', 'Asil', 'POOR', 'VERY SLOW', 2, 3, 'H', "AUDREY SILT LOAM"],
	['Cochrane', 'Bcl', 'POOR', 'SLOW', 3, 3, 'H', "BIZ CLAY LOAM"],
	['Cochrane', 'Bl', 'POOR', 'SLOW', 2, 3, 'H', "BIZ LOAM"],
	['Cochrane', 'Brsl', 'WELL', 'RAPID', 4, 1, 'L', "BLACK RIVER SANDY LOAM"],
	['Cochrane', 'Bsil', 'POOR', 'SLOW', 2, 3, 'H', "BIZ SILT LOAM"],
	['Cochrane', 'Bv', 'VERY POOR', 'SLOW', 3, 2, 'M', "BELLE VALLEE ORGANIC"],
	['Cochrane', 'Cc', 'WELL', 'SLOW', 3, 3, 'M', "CLEGG CLAY"],
	['Cochrane', 'Ccl', 'WELL', 'SLOW', 4, 4, 'M', "CLEGG CLAY LOAM"],
	['Cochrane', 'Cl', 'WELL', 'SLOW', 2, 4, 'L', "CLEGG LOAM"],
	['Cochrane', 'Csil', 'WELL', 'SLOW', 2, 4, 'L', "CLEGG SILT LOAM"],
	['Cochrane', 'Decl', 'WELL', 'SLOW', 4, 4, 'M', "DELRAY CLAY LOAM"],
	['Cochrane', 'Del', 'WELL', 'SLOW', 2, 4, 'L', "DELRAY LOAM"],
	['Cochrane', 'Dtc', 'IMPERFECT', 'SLOW', 3, 3, 'H', "DEVITT CLAY"],
	['Cochrane', 'Dtcl', 'IMPERFECT', 'SLOW', 3, 3, 'H', "DEVITT CLAY LOAM"],
	['Cochrane', 'Dtl', 'IMPERFECT', 'SLOW', 2, 4, 'M', "DEVITT CLAY LOAM"],
	['Cochrane', 'Dtsil', 'IMPERFECT', 'SLOW', 2, 4, 'M', "DEVITT CLAY LOAM"],
	['Cochrane', 'Ens', 'POOR', 'SLOW', 4, 2, 'M', "ENGLISH SAND"],
	['Cochrane', 'Ensl', 'POOR', 'SLOW', 4, 2, 'M', "ENGLISH SANDY LOAM"],
	['Cochrane', 'Esil', 'WELL', 'MODERATE', 1, 3, 'L', "ECCLESTONE SILT LOAM"],
	['Cochrane', 'Focl', 'POOR', 'VERY SLOW', 3, 3, 'H', "FORD CLAY LOAM"],
	['Cochrane', 'Fol', 'POOR', 'VERY SLOW', 2, 3, 'H', "FORD LOAM"],
	['Cochrane', 'Frs', 'POOR', 'SLOW', 4, 2, 'M', "FREDERICK SAND"],
	['Cochrane', 'Frsl', 'POOR', 'SLOW', 4, 2, 'M', "FREDERICK SANDY LOAM"],
	['Cochrane', 'Gas', 'POOR', 'SLOW', 4, 2, 'M', "GAFFNEY SAND"],
	['Cochrane', 'Gasl', 'POOR', 'SLOW', 4, 2, 'M', "GAFFNEY SANDY LOAM"],
	['Cochrane', 'Gf', 'VERY POOR', 'SLOW', 3, 2, 'M', "GAFFNEY LAKE ORGANIC"],
	['Cochrane', 'Hac', 'WELL', 'SLOW', 3, 3, 'M', "HAILEYBURY CLAY"],
	['Cochrane', 'Hec', 'IMPERFECT', 'SLOW', 4, 4, 'H', "HEARST CLAY"],
	['Cochrane', 'Hecl', 'IMPERFECT', 'SLOW', 4, 4, 'H', "HEARST CLAY LOAM"],
	['Cochrane', 'Hel', 'IMPERFECT', 'SLOW', 3, 4, 'M', "HEARST LOAM"],
	['Cochrane', 'Hesil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "HEARST SILT LOAM"],
	['Cochrane', 'Hnl', 'WELL', 'RAPID', 4, 2, 'L', "HANNA LOAM"],
	['Cochrane', 'Hns', 'WELL', 'RAPID', 4, 1, 'L', "HANNA SAND"],
	['Cochrane', 'Hnsl', 'WELL', 'RAPID', 4, 1, 'L', "HANNA SANDY LOAM"],
	['Cochrane', 'Hny', 'VERY POOR', 'SLOW', 3, 2, 'M', " "],
	['Cochrane', 'Hy', 'VERY POOR', 'SLOW', 3, 2, 'M', "HARLEY ORGANIC"],
	['Cochrane', 'Jngl', 'WELL', 'RAPID', 4, 1, 'L', "JEANNIE GRAVELLY LOAM"],
	['Cochrane', 'Jngs', 'WELL', 'RAPID', 4, 1, 'L', "JEANNIE GRAVELLY SAND"],
	['Cochrane', 'Jngsl', 'WELL', 'RAPID', 4, 1, 'L', "JEANNIE GRAVELLY SANDY LOAM"],
	['Cochrane', 'Jns', 'WELL', 'RAPID', 4, 1, 'L', "JEANNIE SAND"],
	['Cochrane', 'Jnsl', 'WELL', 'RAPID', 4, 1, 'L', "JEANNIE SANDY LOAM"],
	['Cochrane', 'Kal', 'POOR', 'SLOW', 3, 3, 'H', "KEENOA LOAM"],
	['Cochrane', 'Kasl', 'POOR', 'SLOW', 4, 2, 'M', "KEENOA SANDY LOAM"],
	['Cochrane', 'Kg', 'VERY POOR', 'SLOW', 3, 2, 'M', "KENOGAMI ORGANIC"],
	['Cochrane', 'Ksil', 'WELL', 'SLOW', 2, 4, 'L', "KAPUSKASING SILT LOAM"],
	['Cochrane', 'Ku', 'VERY POOR', 'SLOW', 3, 2, 'M', "KUSHOG ORGANIC"],
	['Cochrane', 'Ld', 'VERY POOR', 'SLOW', 3, 2, 'M', "LARDER ORGANIC"],
	['Cochrane', 'Le', 'VERY POOR', 'SLOW', 3, 2, 'M', "LEEVILLE ORGANIC"],
	['Cochrane', 'Loc', 'WELL', 'SLOW', 3, 3, 'M', "LOWTHER CLAY"],
	['Cochrane', 'Locl', 'WELL', 'SLOW', 4, 4, 'M', "LOWTHER CLAY LOAM"],
	['Cochrane', 'Ma', 'VERY POOR', 'SLOW', 3, 2, 'M', "MARSH ORGANIC"],
	['Cochrane', 'Mksl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MAKOBE SANDY LOAM"],
	['Cochrane', 'Ms', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD SAND"],
	['Cochrane', 'Mys', 'WELL', 'RAPID', 4, 1, 'L', "MARY SAND"],
	['Cochrane', 'Mysl', 'WELL', 'RAPID', 4, 1, 'L', "MARY SANDY LOAM"],
	['Cochrane', 'Myvfs', 'WELL', 'RAPID', 4, 1, 'L', "MARY VERY FINE SAND"],
	['Cochrane', 'Ng', 'VERY POOR', 'SLOW', 3, 2, 'M', "NOREMBEGA ORGANIC"],
	['Cochrane', 'Nsl', 'POOR', 'SLOW', 4, 2, 'M', "NEWFELD SANDY LOAM"],
	['Cochrane', 'Pesil', 'IMPERFECT', 'SLOW', 2, 4, 'M', "PENSE SILT LOAM"],
	['Cochrane', 'Psil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "PORQUIS SILT LOAM"],
	['Cochrane', 'Pygsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "PYNE GRAVELLY SANDY LOAM"],
	['Cochrane', 'Pys', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "PYNE SAND"],
	['Cochrane', 'Pysl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "PYNE SANDY LOAM"],
	['Cochrane', 'Ryc', 'POOR', 'VERY SLOW', 3, 3, 'H', "RYLAND CLAY"],
	['Cochrane', 'Rycl', 'POOR', 'VERY SLOW', 3, 4, 'H', "RYLAND CLAY LOAM"],
	['Cochrane', 'Ryl', 'POOR', 'VERY SLOW', 3, 3, 'H', "RYLAND LOAM"],
	['Cochrane', 'Rysil', 'POOR', 'VERY SLOW', 3, 3, 'H', "RYLAND SILT LOAM"],
	['Cochrane', 'Sas', 'VERY POOR', 'SLOW', 3, 2, 'M', "SASAGINAGA ORGANIC"],
	['Cochrane', 'Shc', 'POOR', 'VERY SLOW', 3, 3, 'H', "SHETLAND CLAY"],
	['Cochrane', 'Shcl', 'POOR', 'VERY SLOW', 3, 4, 'H', "SHETLAND CLAY LOAM"],
	['Cochrane', 'Shl', 'POOR', 'VERY SLOW', 3, 3, 'H', "SHETLAND LOAM"],
	['Cochrane', 'Shsil', 'POOR', 'VERY SLOW', 3, 3, 'H', "SHETLAND SILT LOAM"],
	['Cochrane', 'Sisil', 'POOR', 'VERY SLOW', 3, 3, 'H', "SIAMESE SILT LOAM"],
	['Cochrane', 'Sk', 'VERY POOR', 'SLOW', 3, 2, 'M', "SESEKINIKA ORGANIC"],
	['Cochrane', 'Sn', 'VERY POOR', 'SLOW', 3, 2, 'M', "SUNSTRUM ORGANIC"],
	['Cochrane', 'Tom', 'VERY POOR', 'SLOW', 3, 2, 'M', "TOMSTOWN ORGANIC"],
	['Cochrane', 'Tusil', 'POOR', 'VERY SLOW', 3, 3, 'H', "TUNIS SILT LOAM"],
	['Cochrane', 'U', 'VERY POOR', 'SLOW', 3, 2, 'M', "UNO PARK ORGANIC"],
	['Cochrane', 'Vcc', 'WELL', 'SLOW', 3, 3, 'M', "VAL COTE CLAY"],
	['Cochrane', 'Vccl', 'WELL', 'SLOW', 4, 4, 'M', "VAL COTE CLAY LOAM"],
	['Cochrane', 'Vcl', 'WELL', 'SLOW', 2, 4, 'L', "VAL COTE LOAM"],
	['Cochrane', 'Wds', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO SAND"],
	['Cochrane', 'We', 'VERY POOR', 'SLOW', 3, 2, 'M', "WADE CREEK ORGANIC"],
	['Cochrane', 'Wns', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "WITHINGTON SAND"],
	['Cochrane', 'Wnsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "WITHINGTON SANDY LOAM"],
	['Cochrane', 'Wos', 'WELL', 'RAPID', 4, 1, 'L', "WOOLLEY SAND"],
	['Cochrane', 'Wosl', 'WELL', 'RAPID', 4, 1, 'L', "WOOLLEY SANDY LOAM"],
	['Cochrane', 'Ww', 'VERY POOR', 'SLOW', 3, 2, 'M', "WISTIWASING ORGANIC"],
	['Dufferin', 'Ans', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ALLISTON SANDY LOAM"],
	['Dufferin', 'Bds', 'WELL', 'SLOW', 2, 4, 'L', "BRANTFORD SILT LOAM"],
	['Dufferin', 'Bg', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD LOAM"],
	['Dufferin', 'Bhs', 'WELL', 'MODERATE', 4, 1, 'L', "BONDHEAD SANDY LOAM"],
	['Dufferin', 'Bif', 'WELL', 'MODERATE', 2, 4, 'L', "BENNINGTON FINE SANDY LOAM"],
	['Dufferin', 'Bis', 'WELL', 'MODERATE', 2, 4, 'L', "BENNINGTON SILT LOAM"],
	['Dufferin', 'Bl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRISBANE LOAM"],
	['Dufferin', 'Bnl', 'POOR', 'VERY SLOW', 2, 3, 'H', "BROOKSTON LOAM"],
	['Dufferin', 'Bns', 'POOR', 'VERY SLOW', 2, 3, 'H', "BROOKSTON SILT LOAM"],
	['Dufferin', 'Bos', 'WELL', 'MODERATE', 4, 2, 'L', "BOOKTON SANDY LOAM"],
	['Dufferin', 'Bs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SANDY LOAM"],
	['Dufferin', 'Btf', 'WELL', 'MODERATE', 1, 3, 'L', "BRANT FINE SANDY LOAM"],
	['Dufferin', 'Bys', 'IMPERFECT', 'SLOW', 2, 4, 'M', "BEVERLY SILT LOAM"],
	['Dufferin', 'Caf', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "CAMILLA FINE SANDY LOAM"],
	['Dufferin', 'Cas', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "CAMILLA SILT LOAM"],
	['Dufferin', 'Cg', 'WELL', 'RAPID', 4, 1, 'L', "CALEDON SANDY LOAM"],
	['Dufferin', 'Cgf', 'WELL', 'RAPID', 4, 2, 'L', "CALEDON FINE SANDY LOAM"],
	['Dufferin', 'Cof', 'POOR', 'SLOW', 2, 3, 'H', "COLWOOD FINE SANDY LOAM"],
	['Dufferin', 'Cos', 'POOR', 'SLOW', 2, 3, 'H', "COLWOOD SILT LOAM"],
	['Dufferin', 'Crf', 'POOR', 'SLOW', 2, 3, 'H', "CROMBIE FINE SANDY LOAM"],
	['Dufferin', 'Crs', 'POOR', 'SLOW', 2, 3, 'H', "CROMBIE SILT LOAM"],
	['Dufferin', 'Db', 'WELL', 'RAPID', 4, 1, 'L', "DONNYBROOK SANDY LOAM"],
	['Dufferin', 'Dds', 'WELL', 'MODERATE', 4, 1, 'L', "DUNDONALD SANDY LOAM"],
	['Dufferin', 'Dl', 'WELL', 'RAPID', 4, 2, 'L', "DUMFRIES LOAM"],
	['Dufferin', 'Duc', 'WELL', 'SLOW', 3, 3, 'M', "DUNEDIN CLAY"],
	['Dufferin', 'Ef', 'IMPERFECT', 'SLOW', 2, 3, 'M', "EMBRO FINE SANDY LOAM"],
	['Dufferin', 'Es', 'IMPERFECT', 'SLOW', 2, 3, 'M', "EMBRO SILT LOAM"],
	['Dufferin', 'Fs', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Dufferin', 'Gil', 'POOR', 'SLOW', 3, 3, 'H', "GILFORD LOAM"],
	['Dufferin', 'Gl', 'WELL', 'MODERATE', 1, 3, 'L', "GUELPH LOAM"],
	['Dufferin', 'Grs', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Dufferin', 'Hal', 'WELL', 'MODERATE', 1, 3, 'L', "HARKAWAY LOAM"],
	['Dufferin', 'Hif', 'WELL', 'RAPID', 4, 2, 'L', "HILLSBURGH FINE SANDY LOAM"],
	['Dufferin', 'His', 'WELL', 'RAPID', 4, 2, 'L', "HILLSBURGH SANDY LOAM"],
	['Dufferin', 'Hl', 'WELL', 'MODERATE', 1, 3, 'L', "HARRISTON LOAM"],
	['Dufferin', 'Hof', 'WELL', 'MODERATE', 1, 3, 'L', "HONEYWOOD FINE SANDY LOAM"],
	['Dufferin', 'Hos', 'WELL', 'MODERATE', 1, 3, 'L', "HONEYWOOD SILT LOAM"],
	['Dufferin', 'Hs', 'WELL', 'MODERATE', 1, 3, 'L', "HARRISTON SILT LOAM"],
	['Dufferin', 'Hul', 'WELL', 'SLOW', 2, 4, 'L', "HURON LOAM"],
	['Dufferin', 'Hus', 'WELL', 'SLOW', 2, 4, 'L', "HURON SILT LOAM"],
	['Dufferin', 'Lil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LISTOWEL LOAM"],
	['Dufferin', 'Lis', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LISTOWEL SILT LOAM"],
	['Dufferin', 'Ll', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LONDON LOAM"],
	['Dufferin', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Dufferin', 'Mwf', 'POOR', 'SLOW', 2, 3, 'H', "MAPLEWOOD FINE SANDY LOAM"],
	['Dufferin', 'Opl', 'WELL', 'MODERATE', 1, 3, 'L', "OSPREY LOAM"],
	['Dufferin', 'P', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEAT ORGANIC"],
	['Dufferin', 'Pal', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL LOAM"],
	['Dufferin', 'Pl', 'IMPERFECT', 'SLOW', 2, 4, 'M', "PERTH LOAM"],
	['Dufferin', 'Ps', 'IMPERFECT', 'SLOW', 2, 4, 'M', "PERTH SILT LOAM"],
	['Dufferin', 'Sms', 'IMPERFECT', 'SLOW', 3, 4, 'M', "SMITHFIELD SILT LOAM"],
	['Dufferin', 'Taf', 'IMPERFECT', 'SLOW', 2, 4, 'M', "TAVISTOCK FINE SANDY LOAM"],
	['Dufferin', 'Tas', 'IMPERFECT', 'SLOW', 2, 4, 'M', "TAVISTOCK SILT LOAM"],
	['Dufferin', 'Tc', 'POOR', 'VERY SLOW', 3, 3, 'H', "TOLEDO CLAY LOAM"],
	['Dufferin', 'Tif', 'WELL', 'RAPID', 4, 2, 'L', "TIOGA FINE SANDY LOAM"],
	['Dufferin', 'Tis', 'WELL', 'RAPID', 4, 1, 'L', "TIOGA LOAMY SAND"],
	['Dufferin', 'Ts', 'POOR', 'VERY SLOW', 3, 3, 'H', "TOLEDO SILTY CLAY LOAM"],
	['Dufferin', 'Tuf', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA FINE SANDY LOAM"],
	['Dufferin', 'Tus', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA SILT LOAM"],
	['Dufferin', 'Was', 'POOR', 'SLOW', 4, 3, 'M', "WAUSEON SANDY LOAM"],
	['Dufferin', 'Whf', 'WELL', 'MODERATE', 1, 4, 'L', "WHITFIELD FINE SANDY LOAM"],
	['Dufferin', 'Wil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "WIARTON LOAM"],
	['Dundas', 'Asl', 'POOR', 'SLOW', 4, 3, 'M', "ALLENDALE SANDY LOAM"],
	['Dundas', 'Bm', 'POOR', 'VERY SLOW', 3, 2, 'M', "BELMEADE ORGANIC"],
	['Dundas', 'Ccl', 'IMPERFECT', 'SLOW', 3, 4, 'H', "CARP CLAY LOAM"],
	['Dundas', 'F', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Dundas', 'Gl', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Dundas', 'Gl-b', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Dundas', 'Gl-s', 'WELL', 'RAPID', 1, 4, 'L', "GRENVILLE LOAM"],
	['Dundas', 'Grs', 'WELL', 'MODERATE', 4, 1, 'L', "GRENVILLE SANDY LOAM"],
	['Dundas', 'Gs', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SAND"],
	['Dundas', 'Gsl', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Dundas', 'Kg', 'WELL', 'RAPID', 4, 1, 'L', "KARS GRAVELLY SANDY LOAM"],
	['Dundas', 'Ll', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Dundas', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Dundas', 'Masl', 'WELL', 'MODERATE', 4, 2, 'L', "MANOTICK SANDY LOAM"],
	['Dundas', 'Mfsl', 'POOR', 'SLOW', 3, 3, 'H', "MARIONVILLE FINE SANDY LOAM"],
	['Dundas', 'Ml', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MATILDA LOAM"],
	['Dundas', 'Mrl', 'IMPERFECT', 'SLOW', 3, 3, 'H', "MORRISBURG CLAY LOAM"],
	['Dundas', 'Msl', 'IMPERFECT', 'SLOW', 3, 2, 'M', "MOUNTAIN SANDY LOAM"],
	['Dundas', 'NGc', 'POOR', 'VERY SLOW', 3, 3, 'H', "NORTH GOWER CLAY"],
	['Dundas', 'NGcl', 'POOR', 'VERY SLOW', 3, 4, 'H', "NORTH GOWER CLAY LOAM"],
	['Dundas', 'Obcl', 'POOR', 'VERY SLOW', 3, 3, 'H', "OSNABRUCK CLAY LOAM"],
	['Dundas', 'Ol', 'POOR', 'SLOW', 2, 3, 'H', "OSGOODE LOAM"],
	['Dundas', 'Osil', 'POOR', 'SLOW', 2, 3, 'H', "OSGOODE SILT LOAM"],
	['Dundas', 'Rs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RUBICON SAND"],
	['Dundas', 'Rsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RUBICON SANDY LOAM"],
	['Dundas', 'Us', 'WELL', 'RAPID', 4, 1, 'L', "UPLANDS SAND"],
	['Dundas', 'Usl', 'WELL', 'RAPID', 4, 1, 'L', "UPLANDS SANDY LOAM"],
	['Dundas', 'Wl', 'WELL', 'SLOW', 2, 4, 'M', "WOLFORD CLAY LOAM"],
	['Durham', 'B', 'WELL', 'RAPID', 4, 1, 'L', "BRIDGEMAN SAND"],
	['Durham', 'B-b', 'WELL', 'MODERATE', 4, 1, 'L', "BONDHEAD FINE SANDY LOAM"],
	['Durham', 'Bg', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON GRAVELLY SAND"],
	['Durham', 'Bl', 'WELL', 'MODERATE', 1, 3, 'L', "BONDHEAD LOAM"],
	['Durham', 'Br-b', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON SANDY LOAM"],
	['Durham', 'Brs', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON SANDY LOAM"],
	['Durham', 'Bs', 'WELL', 'RAPID', 4, 1, 'L', "BONDHEAD SANDY LOAM"],
	['Durham', 'Bsl', 'WELL', 'MODERATE', 1, 3, 'L', "BONDHEAD FINE SANDY LOAM"],
	['Durham', 'Dal', 'WELL', 'MODERATE', 1, 3, 'L', "DARLINGTON LOAM"],
	['Durham', 'Dasl', 'WELL', 'MODERATE', 4, 1, 'L', "DARLINGTON SANDY LOAM"],
	['Durham', 'Dsl', 'WELL', 'MODERATE', 4, 1, 'L', "DUNDONALD SANDY LOAM"],
	['Durham', 'Gs-b', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Durham', 'Gsl', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Durham', 'Gu-b', 'POOR', 'MODERATE', 4, 2, 'M', "GUERIN SANDY LOAM"],
	['Durham', 'Gul', 'POOR', 'MODERATE', 2, 3, 'H', "GUERIN LOAM"],
	['Durham', 'Gusl', 'POOR', 'MODERATE', 4, 2, 'M', "GUERIN SANDY LOAM"],
	['Durham', 'Ll', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Durham', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Durham', 'Ncl', 'WELL', 'MODERATE', 2, 4, 'M', "NEWCASTLE CLAY LOAM"],
	['Durham', 'Nl', 'WELL', 'MODERATE', 2, 4, 'L', "NEWCASTLE LOAM"],
	['Durham', 'Ol', 'WELL', 'MODERATE', 1, 3, 'L', "OTONABEE LOAM"],
	['Durham', 'Ol-s', 'WELL', 'MODERATE', 1, 3, 'L', "OTONABEE LOAM"],
	['Durham', 'Pg', 'WELL', 'RAPID', 4, 1, 'L', "PONTYPOOL GRAVELLY SAND"],
	['Durham', 'Pl', 'WELL', 'MODERATE', 4, 2, 'L', "PERCY LOAM"],
	['Durham', 'P-s', 'WELL', 'RAPID', 4, 1, 'L', "PONTYPOOL SAND"],
	['Durham', 'Psl', 'WELL', 'RAPID', 4, 1, 'L', "PONTYPOOL SANDY LOAM"],
	['Durham', 'Scl', 'IMPERFECT', 'SLOW', 3, 4, 'H', "SMITHFIELD CLAY LOAM"],
	['Durham', 'Shcl', 'WELL', 'SLOW', 2, 4, 'M', "SCHOMBERG CLAY LOAM"],
	['Durham', 'Shsi', 'WELL', 'SLOW', 2, 4, 'L', "SCHOMBERG SILT LOAM"],
	['Durham', 'Tsl', 'POOR', 'MODERATE', 4, 2, 'M', "TECUMSETH SANDY LOAM"],
	['Elgin', 'AY', 'POOR', 'SLOW', 4, 2, 'M', "AYR LOAMY SAND"],
	['Elgin', 'AY.F', 'POOR', 'SLOW', 4, 2, 'H', "AYR CLAY LOAM"],
	['Elgin', 'AY.L', 'POOR', 'SLOW', 3, 3, 'H', "AYR SILT LOAM"],
	['Elgin', 'BE', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['Elgin', 'BE.T', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN FINE SANDY LOAM"],
	['Elgin', 'BF', 'WELL', 'SLOW', 2, 4, 'M', "BRANTFORD SILTY CLAY LOAM"],
	['Elgin', 'BF.C', 'WELL', 'SLOW', 2, 4, 'L', "BRANTFORD FINE SANDY LOAM"],
	['Elgin', 'BF.L', 'WELL', 'SLOW', 2, 4, 'L', "BRANTFORD LOAM"],
	['Elgin', 'BI', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRISBANE SANDY LOAM"],
	['Elgin', 'BN', 'WELL', 'MODERATE', 2, 4, 'L', "BENNINGTON SILT LOAM"],
	['Elgin', 'BN.T', 'WELL', 'MODERATE', 2, 4, 'L', "BENNINGTON LOAM"],
	['Elgin', 'BO', 'WELL', 'MODERATE', 4, 2, 'L', "BOOKTON SAND"],
	['Elgin', 'BO.T', 'WELL', 'MODERATE', 4, 2, 'L', "BOOKTON LOAMY FINE SAND"],
	['Elgin', 'BT', 'WELL', 'MODERATE', 1, 3, 'L', "BRANT SILT LOAM"],
	['Elgin', 'BU', 'WELL', 'RAPID', 4, 1, 'L', "BURFORD SANDY LOAM"],
	['Elgin', 'BV', 'IMPERFECT', 'SLOW', 3, 4, 'H', "BEVERLY SILTY CLAY LOAM"],
	['Elgin', 'BV.C', 'IMPERFECT', 'SLOW', 3, 4, 'M', "BEVERLY FINE SANDY LOAM"],
	['Elgin', 'BV.L', 'IMPERFECT', 'SLOW', 2, 4, 'M', "BEVERLY LOAM"],
	['Elgin', 'BY', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY LOAMY SAND"],
	['Elgin', 'CA', 'WELL', 'RAPID', 4, 1, 'L', "CALEDON SANDY LOAM"],
	['Elgin', 'CA.F', 'WELL', 'RAPID', 2, 4, 'M', "CALEDON SILTY CLAY LOAM"],
	['Elgin', 'CH', 'VERY POOR', 'MODERATE', 3, 3, 'H', "CHURCHVILLE VERY FINE SANDY LOAM"],
	['Elgin', 'CH.P', 'VERY POOR', 'SLOW', 3, 2, 'M', "CHURCHVILLE ORGANIC"],
	['Elgin', 'CM', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "CAMILLA SANDY LOAM"],
	['Elgin', 'CM.L', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "CAMILLA LOAM"],
	['Elgin', 'CW', 'POOR', 'SLOW', 2, 3, 'H', "COLWOOD LOAM"],
	['Elgin', 'CW.C', 'POOR', 'SLOW', 4, 2, 'M', "COLWOOD SANDY LOAM"],
	['Elgin', 'CW.P', 'POOR', 'SLOW', 3, 2, 'M', "COLWOOD ORGANIC"],
	['Elgin', 'EK', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "EKFRID CLAY"],
	['Elgin', 'EK.C', 'IMPERFECT', 'SLOW', 3, 4, 'M', "EKFRID SANDY LOAM"],
	['Elgin', 'EK.L', 'IMPERFECT', 'SLOW', 3, 4, 'M', "EKFRID LOAM"],
	['Elgin', 'FR', 'VERY POOR', 'SLOW', 4, 2, 'M', "FROME LOAMY SAND"],
	['Elgin', 'FR.P', 'VERY POOR', 'SLOW', 3, 2, 'M', "FROME ORGANIC"],
	['Elgin', 'FX', 'WELL', 'RAPID', 4, 1, 'L', "FOX LOAMY SAND"],
	['Elgin', 'GO', 'IMPERFECT', 'SLOW', 3, 4, 'H', "GOBLES CLAY LOAM"],
	['Elgin', 'GO.C', 'IMPERFECT', 'SLOW', 3, 4, 'M', "GOBLES FINE SANDY LOAM"],
	['Elgin', 'GO.L', 'IMPERFECT', 'SLOW', 2, 4, 'M', "GOBLES LOAM"],
	['Elgin', 'GO.W', 'IMPERFECT', 'SLOW', 2, 3, 'M', "GOBLES LOAM"],
	['Elgin', 'GY', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Elgin', 'HI', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "HIGHGATE FINE SANDY LOAM"],
	['Elgin', 'KE', 'POOR', 'VERY SLOW', 3, 4, 'H', "KELVIN CLAY LOAM"],
	['Elgin', 'KE.C', 'POOR', 'VERY SLOW', 3, 3, 'H', "KELVIN FINE SANDY LOAM"],
	['Elgin', 'KE.L', 'POOR', 'VERY SLOW', 3, 3, 'H', "KELVIN LOAM"],
	['Elgin', 'KE.W', 'POOR', 'VERY SLOW', 2, 3, 'H', "KELVIN SILT LOAM"],
	['Elgin', 'KT', 'WELL', 'MODERATE', 4, 2, 'L', "KINTYRE FINE SANDY LOAM"],
	['Elgin', 'MA', 'POOR', 'SLOW', 2, 3, 'H', "MAPLEWOOD LOAM"],
	['Elgin', 'MA.T', 'POOR', 'SLOW', 2, 3, 'H', "MAPLEWOOD VERY FINE SANDY LOAM"],
	['Elgin', 'ME', 'WELL', 'VERY SLOW', 2, 4, 'M', "MELBOURNE SILTY CLAY"],
	['Elgin', 'MI', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MIDDLEMARCH FINE SANDY LOAM"],
	['Elgin', 'MK', 'POOR', 'SLOW', 3, 3, 'H', "MUIRKIRK FINE SANDY LOAM"],
	['Elgin', 'MU', 'WELL', 'SLOW', 2, 4, 'M', "MURIEL SILTY CLAY LOAM"],
	['Elgin', 'MU.L', 'WELL', 'SLOW', 2, 4, 'L', "MURIEL LOAM"],
	['Elgin', 'MU.W', 'WELL', 'SLOW', 1, 3, 'L', "MURIEL LOAM"],
	['Elgin', 'NO', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "NORMANDALE FINE SANDY LOAM"],
	['Elgin', 'OR', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC ORGANIC"],
	['Elgin', 'PF', 'WELL', 'RAPID', 4, 1, 'L', "PLAINFIELD FINE SAND"],
	['Elgin', 'SH', 'WELL', 'MODERATE', 1, 3, 'L', "SHEDDEN FINE SANDY LOAM"],
	['Elgin', 'SL', 'POOR', 'SLOW', 2, 3, 'H', "SILVER HILL FINE SANDY LOAM"],
	['Elgin', 'SO', 'VERY POOR', 'VERY SLOW', 3, 3, 'H', "SOUTHWOLD SILTY CLAY LOAM"],
	['Elgin', 'SP', 'VERY POOR', 'SLOW', 4, 2, 'M', "SPRINGWATER LOAMY FINE SAND"],
	['Elgin', 'ST', 'POOR', 'VERY SLOW', 3, 3, 'H', "STRATHBURN CLAY"],
	['Elgin', 'ST.C', 'POOR', 'VERY SLOW', 3, 3, 'H', "STRATHBURN FINE SANDY LOAM"],
	['Elgin', 'ST.L', 'POOR', 'VERY SLOW', 3, 3, 'H', "STRATHBURN SILT LOAM"],
	['Elgin', 'SW', 'POOR', 'SLOW', 3, 3, 'H', "ST WILLIAMS FINE SANDY LOAM"],
	['Elgin', 'TA', 'IMPERFECT', 'SLOW', 3, 4, 'M', "TAVISTOCK LOAM"],
	['Elgin', 'TA.T', 'IMPERFECT', 'SLOW', 3, 4, 'M', "TAVISTOCK SILT LOAM"],
	['Elgin', 'TO', 'POOR', 'VERY SLOW', 3, 3, 'H', "TOLEDO CLAY"],
	['Elgin', 'TO.C', 'POOR', 'VERY SLOW', 3, 3, 'H', "TOLEDO SANDY LOAM"],
	['Elgin', 'TO.L', 'POOR', 'VERY SLOW', 3, 3, 'H', "TOLEDO LOAM"],
	['Elgin', 'TU', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA SILT LOAM"],
	['Elgin', 'TU.C', 'IMPERFECT', 'SLOW', 3, 2, 'M', "TUSCOLA FINE SANDY LOAM"],
	['Elgin', 'VI', 'IMPERFECT', 'SLOW', 2, 3, 'M', "VITTORIA FINE SANDY LOAM"],
	['Elgin', 'WA', 'WELL', 'SLOW', 4, 1, 'L', "WALSHER LOAMY FINE SAND"],
	['Elgin', 'WF', 'WELL', 'RAPID', 1, 3, 'L', "WATTFORD VERY FINE SANDY LOAM"],
	['Elgin', 'WM', 'IMPERFECT', 'RAPID', 4, 2, 'M', "WALSINGHAM LOAMY FINE SAND"],
	['Elgin', 'WN', 'POOR', 'SLOW', 4, 2, 'M', "WATERIN LOAMY FINE SAND"],
	['Elgin', 'WU', 'POOR', 'SLOW', 3, 3, 'H', "WAUSEON FINE SANDY LOAM"],
	['Elgin', 'WU.T', 'POOR', 'SLOW', 3, 3, 'H', "WAUSEON FINE SANDY LOAM"],
	['Essex', 'Bc', 'POOR', 'VERY SLOW', 3, 3, 'H', "BROOKSTON CLAY"],
	['Essex', 'Bcl', 'POOR', 'VERY SLOW', 3, 4, 'H', "BROOKSTON CLAY LOAM"],
	['Essex', 'Bel', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['Essex', 'Bes', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SAND"],
	['Essex', 'Bg', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD LOAM"],
	['Essex', 'Bg-s', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD LOAM"],
	['Essex', 'B-s', 'POOR', 'VERY SLOW', 4, 3, 'M', "BROOKSTON CLAY"],
	['Essex', 'Cac', 'IMPERFECT', 'SLOW', 3, 4, 'H', "CAISTOR CLAY"],
	['Essex', 'Cacl', 'IMPERFECT', 'SLOW', 3, 4, 'H', "CAISTOR CLAY LOAM"],
	['Essex', 'Cc', 'POOR', 'VERY SLOW', 3, 3, 'H', "CLYDE CLAY"],
	['Essex', 'Cdl', 'POOR', 'SLOW', 3, 3, 'H', "COLWOOD FINE SANDY LOAM"],
	['Essex', 'C-s', 'IMPERFECT', 'SLOW', 3, 2, 'M', "CAISTOR CLAY LOAM"],
	['Essex', 'Es', 'WELL', 'RAPID', 4, 1, 'L', "EASTPORT SAND"],
	['Essex', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Essex', 'Fsl', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Essex', 'Gs', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SAND"],
	['Essex', 'Hl', 'WELL', 'RAPID', 4, 2, 'L', "HARROW LOAM"],
	['Essex', 'Hs', 'WELL', 'RAPID', 4, 1, 'L', "HARROW SANDY LOAM"],
	['Essex', 'Jc', 'POOR', 'VERY SLOW', 3, 3, 'H', "JEDDO CLAY"],
	['Essex', 'Pc', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PERTH CLAY"],
	['Essex', 'Pcl', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PERTH CLAY LOAM"],
	['Essex', 'Pl', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL LOAM"],
	['Essex', 'P-r', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL SAND"],
	['Essex', 'Ps', 'WELL', 'RAPID', 4, 1, 'L', "PLAINFIELD SAND"],
	['Essex', 'Tfs', 'POOR', 'SLOW', 3, 3, 'H', "TUSCOLA FINE SANDY LOAM"],
	['Essex', 'Toc', 'POOR', 'VERY SLOW', 3, 3, 'H', "TOLEDO CLAY"],
	['Essex', 'Tos', 'POOR', 'VERY SLOW', 3, 3, 'H', "TOLEDO SILT LOAM"],
	['Essex', 'Was', 'POOR', 'SLOW', 4, 3, 'M', "WAUSEON SANDY LOAM"],
	['Frontenac', 'Bes', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['Frontenac', 'Bl', 'WELL', 'MODERATE', 1, 3, 'L', "BONDHEAD LOAM"],
	['Frontenac', 'Bl-sh', 'WELL', 'RAPID', 1, 4, 'L', "BONDHEAD LOAM"],
	['Frontenac', 'Bsl', 'WELL', 'MODERATE', 4, 1, 'L', "BONDHEAD SANDY LOAM"],
	['Frontenac', 'Bsl-sh', 'WELL', 'MODERATE', 3, 2, 'L', "BONDHEAD SANDY LOAM"],
	['Frontenac', 'Btsil', 'IMPERFECT', 'SLOW', 2, 4, 'M', "BATTERSEA SILT LOAM"],
	['Frontenac', 'Dl-sh', 'WELL', 'RAPID', 1, 4, 'L', "DUMMER LOAM"],
	['Frontenac', 'Es', 'WELL', 'RAPID', 4, 1, 'L', "EASTPORT SAND"],
	['Frontenac', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Frontenac', 'Gc', 'WELL', 'SLOW', 3, 3, 'M', "GANANOQUE CLAY"],
	['Frontenac', 'Gc-r', 'WELL', 'SLOW', 3, 3, 'M', "GANANOQUE CLAY"],
	['Frontenac', 'Gc-sh', 'WELL', 'MODERATE', 2, 4, 'M', "GANANOQUE CLAY"],
	['Frontenac', 'Gul', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "GUERIN LOAM"],
	['Frontenac', 'Gul-sh', 'IMPERFECT', 'RAPID', 2, 4, 'M', "GUERIN LOAM"],
	['Frontenac', 'Hl', 'POOR', 'SLOW', 2, 3, 'H', "HINCHINBROOKE LOAM"],
	['Frontenac', 'Hl-r', 'POOR', 'SLOW', 2, 3, 'H', "HINCHINBROOKE LOAM"],
	['Frontenac', 'Hsil', 'POOR', 'SLOW', 2, 3, 'H', "HINCHINBROOKE SILT LOAM"],
	['Frontenac', 'Hsil-r', 'POOR', 'SLOW', 2, 3, 'H', "HINCHINBROOKE SILT LOAM"],
	['Frontenac', 'Lac', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "LANDSDOWNE CLAY"],
	['Frontenac', 'Lac-r', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "LANDSDOWNE CLAY"],
	['Frontenac', 'Lac-sh', 'IMPERFECT', 'SLOW', 2, 4, 'H', "LANDSDOWNE CLAY"],
	['Frontenac', 'Lc', 'POOR', 'VERY SLOW', 3, 3, 'H', "LINDSAY CLAY"],
	['Frontenac', 'Lcl', 'POOR', 'VERY SLOW', 3, 3, 'H', "LINDSAY CLAY LOAM"],
	['Frontenac', 'Lyl', 'POOR', 'SLOW', 2, 3, 'M', "LYONS LOAM"],
	['Frontenac', 'Lyl-r', 'POOR', 'SLOW', 2, 3, 'M', "LYONS LOAM"],
	['Frontenac', 'Lyl-sh', 'POOR', 'MODERATE', 3, 3, 'M', "LYONS LOAM"],
	['Frontenac', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Frontenac', 'Mc', 'POOR', 'VERY SLOW', 3, 3, 'H', "MOSCOW CLAY"],
	['Frontenac', 'Mc-sh', 'POOR', 'SLOW', 2, 3, 'H', "MOSCOW CLAY"],
	['Frontenac', 'Msl', 'WELL', 'MODERATE', 4, 1, 'L', "MONTEAGLE SANDY LOAM"],
	['Frontenac', 'Nc', 'POOR', 'VERY SLOW', 3, 3, 'H', "NAPANEE ORGANIC"],
	['Frontenac', 'Nc-r', 'POOR', 'VERY SLOW', 3, 3, 'H', "NAPANEE CLAY"],
	['Frontenac', 'Nc-sh', 'POOR', 'SLOW', 2, 3, 'H', "NAPANEE CLAY"],
	['Frontenac', 'Nufsl', 'WELL', 'MODERATE', 2, 4, 'L', "NEWBURGH FINE SANDY LOAM"],
	['Frontenac', 'Nusil', 'WELL', 'MODERATE', 2, 4, 'L', "NEWBURGH SILT LOAM"],
	['Frontenac', 'Ol-sh', 'WELL', 'RAPID', 1, 4, 'L', "OTONABEE LOAM"],
	['Frontenac', 'P', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEAT ORGANIC"],
	['Frontenac', 'Pfsl', 'IMPERFECT', 'VERY SLOW', 2, 3, 'M', "PICADILLY FINE SANDY LOAM"],
	['Frontenac', 'SBsil', 'WELL', 'SLOW', 2, 4, 'L', "SEELEY'S BAY SILT LOAM"],
	['Frontenac', 'SBsil-r', 'WELL', 'SLOW', 2, 4, 'L', "SEELEY'S BAY SILT LOAM"],
	['Frontenac', 'SPg', 'WELL', 'RAPID', 4, 1, 'L', "ST PETERS GRAVELLY SANDY LOAM"],
	['Frontenac', 'SPg-r', 'WELL', 'RAPID', 4, 1, 'L', "ST PETERS GRAVELLY SANDY LOAM"],
	['Frontenac', 'Tsl', 'WELL', 'MODERATE', 4, 1, 'L', "TENNYSON SANDY LOAM"],
	['Frontenac', 'Tws', 'WELL', 'RAPID', 3, 2, 'L', "TWEED SANDY LOAM"],
	['Frontenac', 'Was', 'POOR', 'SLOW', 4, 3, 'M', "WAUSEON SANDY LOAM"],
	['Frontenac', 'Wes', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO LOAMY SAND"],
	['Frontenac', 'Wes-r', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO LOAMY SAND"],
	['Frontenac', 'WLs', 'WELL', 'RAPID', 4, 1, 'L', "WHITE LAKE GRAVELLY SANDY LOAM"],
	['Frontenac', 'WLs-r', 'WELL', 'RAPID', 4, 1, 'L', "WHITE LAKE GRAVELLY SANDY LOAM"],
	['Glengarry', 'Asl', 'POOR', 'SLOW', 4, 3, 'M', "ALLENDALE SANDY LOAM"],
	['Glengarry', 'Bc', 'POOR', 'VERY SLOW', 3, 3, 'H', "BEARBROOK CLAY"],
	['Glengarry', 'Bm', 'POOR', 'SLOW', 3, 2, 'M', "BELMEADE ORGANIC"],
	['Glengarry', 'Bsil', 'POOR', 'SLOW', 2, 3, 'H', "BAINSVILLE SILT LOAM"],
	['Glengarry', 'Csil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CASTOR SILT LOAM"],
	['Glengarry', 'Cvfsl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CASTOR VERY FINE SANDY LOAM"],
	['Glengarry', 'Cvfsl-s', 'IMPERFECT', 'MODERATE', 2, 4, 'M', "CASTOR VERY FINE SANDY LOAM"],
	['Glengarry', 'El', 'WELL', 'MODERATE', 1, 3, 'L', "EAMER LOAM"],
	['Glengarry', 'El-b', 'WELL', 'MODERATE', 1, 3, 'L', "EAMER LOAM"],
	['Glengarry', 'Gl', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Glengarry', 'Gl-s', 'WELL', 'RAPID', 1, 4, 'L', "GRENVILLE LOAM"],
	['Glengarry', 'Gsl', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Glengarry', 'Kg', 'WELL', 'RAPID', 4, 2, 'L', "KARS GRAVELLY SANDY LOAM"],
	['Glengarry', 'Ll', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Glengarry', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Glengarry', 'Ml', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MATILDA LOAM"],
	['Glengarry', 'Msil', 'POOR', 'SLOW', 2, 3, 'H', "MARIONVILLE SILT LOAM"],
	['Glengarry', 'NGc', 'POOR', 'VERY SLOW', 3, 3, 'H', "NORTH GOWER CLAY"],
	['Glengarry', 'NGcl', 'POOR', 'VERY SLOW', 3, 3, 'H', "NORTH GOWER CLAY LOAM"],
	['Glengarry', 'Osicl', 'POOR', 'SLOW', 3, 2, 'H', "OSGOODE SILTY CLAY LOAM"],
	['Glengarry', 'Osil', 'POOR', 'SLOW', 2, 3, 'H', "OSGOODE SILT LOAM"],
	['Glengarry', 'Rc', 'POOR', 'VERY SLOW', 3, 3, 'H', "STE ROSALIE CLAY"],
	['Glengarry', 'Rsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RUBICON SANDY LOAM"],
	['Glengarry', 'Tfsl', 'WELL', 'RAPID', 4, 2, 'L', "ST THOMAS FINE SANDY LOAM"],
	['Glengarry', 'Usl', 'WELL', 'RAPID', 4, 1, 'L', "UPLANDS SANDY LOAM"],
	['Glengarry', 'Wc', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "WENDOVER CLAY"],
	['Grenville', 'Asl', 'POOR', 'SLOW', 4, 3, 'M', "ALLENDALE SANDY LOAM"],
	['Grenville', 'Asl-s', 'POOR', 'MODERATE', 4, 3, 'M', "ALLENDALE SANDY LOAM"],
	['Grenville', 'Ccl', 'IMPERFECT', 'SLOW', 3, 4, 'H', "CARP CLAY LOAM"],
	['Grenville', 'F', 'VARIABLE', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Grenville', 'Gr-b', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Grenville', 'Grl', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Grenville', 'Grs', 'WELL', 'MODERATE', 4, 1, 'L', "GRENVILLE SANDY LOAM"],
	['Grenville', 'Gr-s', 'WELL', 'RAPID', 1, 4, 'L', "GRENVILLE LOAM"],
	['Grenville', 'Grs-s', 'WELL', 'RAPID', 3, 2, 'L', "GRENVILLE SANDY LOAM"],
	['Grenville', 'Gs', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SAND"],
	['Grenville', 'Gsl', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Grenville', 'Kg', 'WELL', 'RAPID', 4, 1, 'L', "KARS GRAVELLY SANDY LOAM"],
	['Grenville', 'Ll', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Grenville', 'Ll-s', 'POOR', 'MODERATE', 3, 3, 'H', "LYONS LOAM"],
	['Grenville', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Grenville', 'Ml', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MATILDA LOAM"],
	['Grenville', 'Ml-b', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MATILDA LOAM"],
	['Grenville', 'Ml-s', 'IMPERFECT', 'RAPID', 2, 4, 'M', "MATILDA LOAM"],
	['Grenville', 'Msl', 'WELL', 'MODERATE', 4, 2, 'L', "MANOTICK SANDY LOAM"],
	['Grenville', 'NGc', 'POOR', 'VERY SLOW', 3, 4, 'H', "NORTH GOWER CLAY LOAM"],
	['Grenville', 'NGc-s', 'POOR', 'SLOW', 3, 4, 'H', "NORTH GOWER CLAY LOAM"],
	['Grenville', 'Ol', 'POOR', 'SLOW', 2, 3, 'H', "OSGOODE LOAM"],
	['Grenville', 'Os', 'POOR', 'SLOW', 2, 3, 'H', "OSGOODE SILT LOAM"],
	['Grenville', 'P', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEAT ORGANIC"],
	['Grenville', 'Rs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RUBICON SAND"],
	['Grenville', 'Rsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RUBICON SANDY LOAM"],
	['Grenville', 'Rs-s', 'IMPERFECT', 'RAPID', 3, 2, 'M', "RUBICON SAND"],
	['Grenville', 'Us', 'WELL', 'RAPID', 4, 1, 'L', "UPLANDS SAND"],
	['Grenville', 'Usl', 'WELL', 'RAPID', 4, 1, 'L', "UPLANDS SANDY LOAM"],
	['Grenville', 'Wc', 'WELL', 'SLOW', 2, 3, 'M', "WOLFORD CLAY LOAM"],
	['Grey', 'Bc', 'POOR', 'VERY SLOW', 3, 4, 'H', "BROOKSTON CLAY LOAM"],
	['Grey', 'Bc-b', 'POOR', 'VERY SLOW', 3, 4, 'H', "BROOKSTON CLAY LOAM"],
	['Grey', 'Bg', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD LOAM"],
	['Grey', 'Brl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRISBANE LOAM"],
	['Grey', 'Brs', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON SAND"],
	['Grey', 'Brs/g', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON SAND"],
	['Grey', 'Brsl/g', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON SANDY LOAM"],
	['Grey', 'Bsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SANDY LOAM"],
	['Grey', 'Csc', 'POOR', 'VERY SLOW', 3, 4, 'H', "CHESLEY SILTY CLAY LOAM"],
	['Grey', 'Dc', 'WELL', 'SLOW', 3, 3, 'M', "DUNEDIN CLAY"],
	['Grey', 'Dc-e', 'WELL', 'SLOW', 3, 3, 'M', "DUNEDIN CLAY"],
	['Grey', 'Dos', 'WELL', 'RAPID', 4, 1, 'L', "DONNYBROOK SANDY LOAM"],
	['Grey', 'Esc', 'IMPERFECT', 'SLOW', 3, 4, 'H', "ELDERSLIE SILTY CLAY LOAM"],
	['Grey', 'Fsl', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Grey', 'Gil', 'POOR', 'SLOW', 3, 3, 'H', "GILFORD LOAM"],
	['Grey', 'Gs', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SAND"],
	['Grey', 'Hal', 'WELL', 'MODERATE', 1, 3, 'L', "HARKAWAY LOAM"],
	['Grey', 'Hal-st', 'WELL', 'MODERATE', 1, 3, 'L', "HARKAWAY LOAM"],
	['Grey', 'Has', 'WELL', 'MODERATE', 1, 3, 'L', "HARKAWAY SILT LOAM"],
	['Grey', 'Has-sh', 'WELL', 'RAPID', 1, 4, 'L', "HARKAWAY SILT LOAM"],
	['Grey', 'Hl', 'WELL', 'MODERATE', 1, 3, 'L', "HARRISTON LOAM"],
	['Grey', 'Hs', 'WELL', 'MODERATE', 1, 3, 'L', "HARRISTON SILT LOAM"],
	['Grey', 'Ksc', 'IMPERFECT', 'SLOW', 3, 3, 'H', "KEMBLE SILTY CLAY LOAM"],
	['Grey', 'Les', 'WELL', 'MODERATE', 2, 4, 'M', "LEITH SILTY CLAY LOAM"],
	['Grey', 'Ll', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LISTOWEL LOAM"],
	['Grey', 'Ls', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LISTOWEL SILT LOAM"],
	['Grey', 'Lyl', 'POOR', 'SLOW', 2, 3, 'H', "LILY LOAM"],
	['Grey', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Grey', 'Mc', 'POOR', 'VERY SLOW', 3, 3, 'H', "MORLEY CLAY"],
	['Grey', 'Mc-b', 'POOR', 'VERY SLOW', 3, 3, 'H', "MORLEY CLAY"],
	['Grey', 'Ol', 'WELL', 'MODERATE', 2, 4, 'L', "OSPREY LOAM"],
	['Grey', 'Ol-b', 'WELL', 'MODERATE', 2, 4, 'L', "OSPREY LOAM"],
	['Grey', 'Osl', 'WELL', 'MODERATE', 3, 2, 'L', "OSPREY SANDY LOAM"],
	['Grey', 'P', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEAT ORGANIC"],
	['Grey', 'P.L.I.', 'WELL', 'RAPID', 4, 2, 'L', "PIKE LAKE LOAM"],
	['Grey', 'Pal', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL LOAM"],
	['Grey', 'Sg', 'WELL', 'RAPID', 4, 2, 'L', "SARGENT LOAM"],
	['Grey', 'Ssc', 'WELL', 'SLOW', 2, 4, 'M', "SAUGEEN SILTY CLAY LOAM"],
	['Grey', 'Sus', 'WELL', 'RAPID', 4, 1, 'L', "SULLIVAN SAND"],
	['Grey', 'Tc', 'POOR', 'VERY SLOW', 3, 4, 'H', "TOLEDO CLAY LOAM"],
	['Grey', 'Tisl', 'WELL', 'RAPID', 4, 1, 'L', "TIOGA SANDY LOAM"],
	['Grey', 'Ts', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "TECUMSETH SANDY LOAM"],
	['Grey', 'Vsc', 'WELL', 'SLOW', 2, 4, 'M', "VINCENT SILTY CLAY LOAM"],
	['Grey', 'Vsc-b', 'WELL', 'SLOW', 2, 4, 'M', "VINCENT SILTY CLAY LOAM"],
	['Grey', 'Vsc-e', 'WELL', 'SLOW', 2, 4, 'M', "VINCENT SILTY CLAY LOAM"],
	['Grey', 'Wl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "WIARTON LOAM"],
	['Grey', 'Ws', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "WIARTON SILT LOAM"],
	['Grey', 'Wsl', 'WELL', 'RAPID', 4, 1, 'L', "WATERLOO SANDY LOAM"],
	['Haldimand-Norfolk', 'BAY', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY LOAMY SAND"],
	['Haldimand-Norfolk', 'BAY.L', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRADY LOAM"],
	['Haldimand-Norfolk', 'BFO', 'WELL', 'SLOW', 2, 4, 'M', "BRANTFORD SILT LOAM"],
	['Haldimand-Norfolk', 'BFO.C', 'WELL', 'SLOW', 4, 2, 'L', "BRANTFORD SANDY LOAM"],
	['Haldimand-Norfolk', 'BFO.L', 'WELL', 'SLOW', 2, 4, 'L', "BRANTFORD LOAM"],
	['Haldimand-Norfolk', 'BFO.S', 'WELL', 'MODERATE', 1, 4, 'M', "BRANTFORD SILTY CLAY LOAM"],
	['Haldimand-Norfolk', 'BFO.V', 'WELL', 'MODERATE', 1, 4, 'M', "BRANTFORD SILTY CLAY LOAM"],
	['Haldimand-Norfolk', 'BOK', 'POOR', 'MODERATE', 3, 2, 'L', "BROOKE FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'BOO', 'WELL', 'MODERATE', 2, 4, 'L', "BOOKTON FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'BOO.T', 'WELL', 'MODERATE', 2, 4, 'L', "BOOKTON FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'BRR', 'IMPERFECT', 'SLOW', 3, 4, 'M', "BERRIEN FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'BRR.T', 'IMPERFECT', 'SLOW', 2, 4, 'M', "BERRIEN FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'BRT', 'WELL', 'MODERATE', 1, 3, 'L', "BRANT LOAM"],
	['Haldimand-Norfolk', 'BRT.C', 'WELL', 'MODERATE', 1, 3, 'L', "BRANT FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'BRT.V', 'WELL', 'MODERATE', 1, 4, 'L', "BRANT LOAM"],
	['Haldimand-Norfolk', 'BUF', 'WELL', 'RAPID', 4, 1, 'L', "BURFORD SANDY LOAM"],
	['Haldimand-Norfolk', 'BVY', 'IMPERFECT', 'SLOW', 3, 4, 'H', "BEVERLY SILTY CLAY LOAM"],
	['Haldimand-Norfolk', 'BVY.C', 'IMPERFECT', 'SLOW', 3, 4, 'M', "BEVERLY FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'BVY.L', 'IMPERFECT', 'SLOW', 3, 4, 'M', "BEVERLY SILTY CLAY LOAM"],
	['Haldimand-Norfolk', 'BVY.S', 'IMPERFECT', 'MODERATE', 3, 4, 'H', "BEVERLY SILTY CLAY LOAM"],
	['Haldimand-Norfolk', 'BVY.V', 'IMPERFECT', 'MODERATE', 3, 4, 'H', "BEVERLY SILTY CLAY LOAM"],
	['Haldimand-Norfolk', 'CWO.C', 'POOR', 'SLOW', 2, 3, 'H', "COLWOOD SANDY LOAM"],
	['Haldimand-Norfolk', 'FOX', 'WELL', 'RAPID', 4, 1, 'L', "FOX LOAMY SAND"],
	['Haldimand-Norfolk', 'FOX.S', 'WELL', 'RAPID', 3, 2, 'L', "FOX LOAMY SAND"],
	['Haldimand-Norfolk', 'FOX.V', 'WELL', 'RAPID', 3, 2, 'L', "FOX LOAMY SAND"],
	['Haldimand-Norfolk', 'FRM', 'WELL', 'MODERATE', 1, 4, 'M', "FARMINGTON CLAY LOAM"],
	['Haldimand-Norfolk', 'GNY', 'POOR', 'MODERATE', 4, 2, 'M', "GRANBY LOAMY SAND"],
	['Haldimand-Norfolk', 'GNY.L', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY LOAM"],
	['Haldimand-Norfolk', 'GNY.P', 'VERY POOR', 'SLOW', 3, 2, 'M', "GRANBY ORGANIC"],
	['Haldimand-Norfolk', 'GNY.V', 'POOR', 'MODERATE', 4, 3, 'M', "GRANBY LOAMY SAND"],
	['Haldimand-Norfolk', 'GOB', 'IMPERFECT', 'SLOW', 3, 3, 'H', "GOBLES CLAY LOAM"],
	['Haldimand-Norfolk', 'GOB.C', 'IMPERFECT', 'SLOW', 2, 4, 'M', "GOBLES FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'GOB.L', 'IMPERFECT', 'SLOW', 2, 4, 'M', "GOBLES LOAM"],
	['Haldimand-Norfolk', 'HIM', 'IMPERFECT', 'SLOW', 3, 4, 'H', "HALDIMAND SILTY CLAY"],
	['Haldimand-Norfolk', 'HIM.C', 'IMPERFECT', 'SLOW', 3, 4, 'M', "HALDIMAND FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'HIM.L', 'IMPERFECT', 'SLOW', 3, 4, 'H', "HALDIMAND SILTY CLAY LOAM"],
	['Haldimand-Norfolk', 'HIM.S', 'IMPERFECT', 'MODERATE', 2, 4, 'H', "HALDIMAND FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'KVN', 'POOR', 'VERY SLOW', 3, 3, 'H', "KELVIN CLAY LOAM"],
	['Haldimand-Norfolk', 'KVN.C', 'POOR', 'VERY SLOW', 2, 3, 'H', "KELVIN FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'KVN.L', 'POOR', 'VERY SLOW', 2, 3, 'H', "KELVIN LOAM"],
	['Haldimand-Norfolk', 'LIC', 'POOR', 'VERY SLOW', 3, 3, 'H', "LINCOLN SILTY CLAY"],
	['Haldimand-Norfolk', 'LIC.C', 'POOR', 'VERY SLOW', 3, 3, 'H', "LINCOLN FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'LIC.L', 'POOR', 'VERY SLOW', 3, 4, 'H', "LINCOLN CLAY LOAM"],
	['Haldimand-Norfolk', 'LIC.P', 'VERY POOR', 'VERY SLOW', 3, 2, 'M', "LINCOLN ORGANIC"],
	['Haldimand-Norfolk', 'LIC.S', 'POOR', 'SLOW', 2, 3, 'H', "LINCOLN SILTY CLAY"],
	['Haldimand-Norfolk', 'LIC.V', 'POOR', 'SLOW', 2, 3, 'H', "LINCOLN SILTY CLAY"],
	['Haldimand-Norfolk', 'LOW', 'POOR', 'MODERATE', 3, 3, 'H', "LOWBANKS FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'MPW', 'POOR', 'SLOW', 3, 3, 'H', "MAPLEWOOD LOAM"],
	['Haldimand-Norfolk', 'MUI', 'WELL', 'SLOW', 2, 4, 'M', "MURIEL CLAY LOAM"],
	['Haldimand-Norfolk', 'MUI.C', 'WELL', 'SLOW', 2, 4, 'L', "MURIEL FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'MUI.L', 'WELL', 'SLOW', 2, 4, 'L', "MURIEL LOAM"],
	['Haldimand-Norfolk', 'NDE.C', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "NORMANDALE LOAMY FINE SAND"],
	['Haldimand-Norfolk', 'NGR', 'IMPERFECT', 'SLOW', 3, 4, 'H', "NIAGARA SILTY CLAY LOAM"],
	['Haldimand-Norfolk', 'NGR.C', 'IMPERFECT', 'SLOW', 3, 4, 'M', "NIAGARA FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'NGR.L', 'IMPERFECT', 'SLOW', 3, 4, 'M', "NIAGARA LOAM"],
	['Haldimand-Norfolk', 'OKL', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "OAKLAND FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'OTI', 'WELL', 'SLOW', 2, 4, 'L', "ONTARIO SILT LOAM"],
	['Haldimand-Norfolk', 'OTI.C', 'WELL', 'SLOW', 4, 2, 'L', "ONTARIO SANDY LOAM"],
	['Haldimand-Norfolk', 'PFD', 'WELL', 'RAPID', 4, 1, 'L', "PLAINFIELD FINE SAND"],
	['Haldimand-Norfolk', 'PFD.D', 'WELL', 'RAPID', 4, 1, 'L', "PLAINFIELD FINE SAND"],
	['Haldimand-Norfolk', 'SHV', 'WELL', 'SLOW', 2, 4, 'M', "SMITHVILLE SILTY CLAY LOAM"],
	['Haldimand-Norfolk', 'SHV.C', 'WELL', 'SLOW', 2, 4, 'L', "SMITHVILLE FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'SHV.L', 'WELL', 'SLOW', 2, 4, 'L', "SMITHVILLE LOAMY FINE SAND"],
	['Haldimand-Norfolk', 'SHV.S', 'WELL', 'MODERATE', 1, 4, 'M', "SMITHVILLE SILTY CLAY LOAM"],
	['Haldimand-Norfolk', 'SHV.V', 'WELL', 'MODERATE', 1, 4, 'M', "SMITHVILLE SILTY CLAY LOAM"],
	['Haldimand-Norfolk', 'SIH', 'POOR', 'MODERATE', 2, 3, 'H', "SILVER HILL FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'SLI', 'POOR', 'MODERATE', 3, 3, 'H', "ST WILLIAMS FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'SNA', 'WELL', 'MODERATE', 1, 3, 'L', "SENECA SILT LOAM"],
	['Haldimand-Norfolk', 'SNA.C', 'WELL', 'MODERATE', 4, 1, 'L', "SENECA LOAMY SAND"],
	['Haldimand-Norfolk', 'SNA.S', 'WELL', 'RAPID', 1, 4, 'L', "SENECA SILT LOAM"],
	['Haldimand-Norfolk', 'SNA.V', 'WELL', 'RAPID', 1, 4, 'L', "SENECA SILT LOAM"],
	['Haldimand-Norfolk', 'STD', 'WELL', 'RAPID', 4, 1, 'L', "SCOTLAND LOAMY SAND"],
	['Haldimand-Norfolk', 'TLD', 'POOR', 'VERY SLOW', 3, 4, 'H', "TOLEDO SILTY CLAY LOAM"],
	['Haldimand-Norfolk', 'TLD.C', 'POOR', 'VERY SLOW', 3, 3, 'H', "TOLEDO FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'TLD.P', 'VERY POOR', 'VERY SLOW', 3, 2, 'M', "TOLEDO ORGANIC"],
	['Haldimand-Norfolk', 'TLD.S', 'POOR', 'SLOW', 3, 4, 'H', "TOLEDO SILTY CLAY LOAM"],
	['Haldimand-Norfolk', 'TLD.V', 'POOR', 'SLOW', 3, 4, 'H', "TOLEDO SILTY CLAY LOAM"],
	['Haldimand-Norfolk', 'TUC', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA LOAM"],
	['Haldimand-Norfolk', 'TUC.C', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'TUC.S', 'IMPERFECT', 'MODERATE', 2, 4, 'M', "TUSCOLA LOAM"],
	['Haldimand-Norfolk', 'TUC.V', 'IMPERFECT', 'MODERATE', 2, 4, 'M', "TUSCOLA LOAM"],
	['Haldimand-Norfolk', 'TVK', 'IMPERFECT', 'SLOW', 3, 4, 'M', "TAVISTOCK LOAM"],
	['Haldimand-Norfolk', 'VIT', 'IMPERFECT', 'SLOW', 2, 3, 'M', "VITTORIA VERY FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'VSS', 'POOR', 'SLOW', 3, 3, 'H', "VANESSA FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'WAM', 'IMPERFECT', 'RAPID', 4, 2, 'M', "WALSINGHAM FINE SAND"],
	['Haldimand-Norfolk', 'WAT', 'WELL', 'RAPID', 4, 1, 'L', "WATTFORD LOAMY FINE SAND"],
	['Haldimand-Norfolk', 'WIL', 'WELL', 'RAPID', 4, 1, 'L', "WILSONVILLE FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'WIL.C', 'WELL', 'RAPID', 4, 1, 'L', "WILSONVILLE SANDY LOAM"],
	['Haldimand-Norfolk', 'WIL.C', 'WELL', 'RAPID', 4, 2, 'L', "WILSONVILLE SANDY LOAM"],
	['Haldimand-Norfolk', 'WIL.V', 'WELL', 'RAPID', 1, 4, 'L', "WILSONVILLE FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'WLL', 'POOR', 'VERY SLOW', 3, 3, 'H', "WELLAND SILTY CLAY"],
	['Haldimand-Norfolk', 'WLL.C', 'POOR', 'VERY SLOW', 3, 3, 'H', "WELLAND SANDY LOAM"],
	['Haldimand-Norfolk', 'WLL.L', 'POOR', 'VERY SLOW', 3, 3, 'H', "WELLAND FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'WRN', 'POOR', 'SLOW', 4, 2, 'M', "WATERIN LOAMY FINE SAND"],
	['Haldimand-Norfolk', 'WRN.P', 'VERY POOR', 'SLOW', 3, 2, 'M', "WATERIN ORGANIC"],
	['Haldimand-Norfolk', 'WSH', 'WELL', 'SLOW', 1, 3, 'L', "WALSHER FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'WUS', 'POOR', 'SLOW', 3, 3, 'H', "WAUSEON FINE SANDY LOAM"],
	['Haldimand-Norfolk', 'WUS.P', 'VERY POOR', 'SLOW', 3, 2, 'M', "WAUSEON ORGANIC"],
	['Haldimand-Norfolk', 'WUS.T', 'POOR', 'SLOW', 3, 3, 'H', "WAUSEON FINE SANDY LOAM"],
	['Halton', 'Ba', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SANDY LOAM"],
	['Halton', 'Be', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['Halton', 'Bl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRISBANE LOAM"],
	['Halton', 'Br', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD LOAM"],
	['Halton', 'Bs', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "BRADY SANDY LOAM"],
	['Halton', 'Bu', 'WELL', 'MODERATE', 4, 2, 'L', "BURFORD LOAM"],
	['Halton', 'Cd', 'POOR', 'SLOW', 2, 3, 'H', "COLWOOD LOAM"],
	['Halton', 'Ch', 'IMPERFECT', 'SLOW', 3, 4, 'H', "CHINGUACOUSY CLAY LOAM"],
	['Halton', 'Ci', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CHINGUACOUSY SILT LOAM"],
	['Halton', 'Ck', 'WELL', 'MODERATE', 2, 4, 'M', "COOKSVILLE CLAY"],
	['Halton', 'Cl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CHINGUACOUSY LOAM"],
	['Halton', 'Co', 'POOR', 'SLOW', 2, 3, 'H', "COLWOOD SILT LOAM"],
	['Halton', 'Cr', 'IMPERFECT', 'SLOW', 3, 4, 'H', "CHINGUACOUSY CLAY LOAM"],
	['Halton', 'Cs', 'POOR', 'MODERATE', 2, 3, 'H', "COLWOOD LOAM"],
	['Halton', 'Dk', 'WELL', 'RAPID', 4, 2, 'L', "DONNYBROOK LOAM"],
	['Halton', 'Dl', 'WELL', 'RAPID', 1, 3, 'L', "DUMFRIES LOAM"],
	['Halton', 'Dr', 'WELL', 'RAPID', 1, 3, 'L', "DUMFRIES LOAM"],
	['Halton', 'Ds', 'WELL', 'RAPID', 1, 4, 'L', "DUMFRIES LOAM"],
	['Halton', 'Du', 'WELL', 'RAPID', 4, 1, 'L', "DUMFRIES SANDY LOAM"],
	['Halton', 'Fl', 'VARIABLE', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Halton', 'Fn', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Halton', 'Fo', 'WELL', 'RAPID', 4, 1, 'L', "FONT SANDY LOAM"],
	['Halton', 'Fp', 'WELL', 'RAPID', 3, 2, 'L', "FOX SANDY LOAM"],
	['Halton', 'Fr', 'VARIABLE', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Halton', 'Fs', 'POOR', 'SLOW', 4, 3, 'M', "FLAMBOROUGH SANDY LOAM"],
	['Halton', 'Gf', 'POOR', 'SLOW', 3, 3, 'H', "GILFORD LOAM"],
	['Halton', 'Gi', 'WELL', 'RAPID', 4, 1, 'L', "GRIMSBY SANDY LOAM"],
	['Halton', 'Gl', 'WELL', 'MODERATE', 1, 3, 'L', "GUELPH LOAM"],
	['Halton', 'Gp', 'WELL', 'RAPID', 3, 2, 'L', "GRIMSBY SANDY LOAM"],
	['Halton', 'Gr', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Halton', 'Gs', 'WELL', 'RAPID', 1, 4, 'L', "GUELPH LOAM"],
	['Halton', 'Gu', 'WELL', 'MODERATE', 4, 1, 'L', "GUELPH SANDY LOAM"],
	['Halton', 'Jc', 'POOR', 'VERY SLOW', 3, 4, 'H', "JEDDO CLAY LOAM"],
	['Halton', 'Kl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "KILLEAN LOAM"],
	['Halton', 'Lc', 'WELL', 'MODERATE', 3, 3, 'M', "LOCKPORT CLAY"],
	['Halton', 'Li', 'POOR', 'SLOW', 2, 3, 'H', "LILY LOAM"],
	['Halton', 'Ll', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LONDON LOAM"],
	['Halton', 'Lo', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LONDON SILT LOAM"],
	['Halton', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC ORGANIC"],
	['Halton', 'Ml', 'POOR', 'VERY SLOW', 3, 4, 'H', "MORLEY CLAY LOAM"],
	['Halton', 'Ms', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC ORGANIC"],
	['Halton', 'Oi', 'WELL', 'SLOW', 2, 4, 'L', "ONEIDA SILT LOAM"],
	['Halton', 'Ol', 'WELL', 'SLOW', 2, 4, 'L', "ONEIDA LOAM"],
	['Halton', 'On', 'WELL', 'SLOW', 2, 4, 'M', "ONEIDA CLAY LOAM"],
	['Halton', 'Or', 'WELL', 'SLOW', 2, 4, 'M', "ONEIDA CLAY LOAM"],
	['Halton', 'P', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC ORGANIC"],
	['Halton', 'Pl', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL LOAM"],
	['Halton', 'Sp', 'WELL', 'RAPID', 4, 1, 'L', "SPRINGVALE SANDY LOAM"],
	['Halton', 'Tc', 'IMPERFECT', 'MODERATE', 3, 4, 'H', "TRAFALGAR CLAY"],
	['Halton', 'Tr', 'IMPERFECT', 'MODERATE', 3, 4, 'H', "TRAFALGAR SILTY CLAY LOAM"],
	['Halton', 'Tu', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA SILT LOAM"],
	['Halton', 'Vi', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "VINELAND SANDY LOAM"],
	['Halton', 'Wi', 'IMPERFECT', 'SLOW', 3, 2, 'M', "WINONA SANDY LOAM"],
	['Hamilton-Wentworth', 'An', 'WELL', 'MODERATE', 2, 4, 'L', "ANCASTER SILT LOAM"],
	['Hamilton-Wentworth', 'Bi', 'IMPERFECT', 'SLOW', 3, 4, 'M', "BINBROOK SILT LOAM"],
	['Hamilton-Wentworth', 'Bl', 'IMPERFECT', 'SLOW', 2, 4, 'M', "BEVERLY SILT LOAM"],
	['Hamilton-Wentworth', 'Bp', 'IMPERFECT', 'MODERATE', 2, 4, 'M', "BEVERLY SILT LOAM"],
	['Hamilton-Wentworth', 'Br', 'WELL', 'SLOW', 2, 4, 'L', "BRANTFORD SILT LOAM"],
	['Hamilton-Wentworth', 'Bs', 'WELL', 'MODERATE', 1, 4, 'L', "BRANT SILT LOAM"],
	['Hamilton-Wentworth', 'Bt', 'WELL', 'MODERATE', 1, 3, 'L', "BRANT SILT LOAM"],
	['Hamilton-Wentworth', 'Bu', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD LOAM"],
	['Hamilton-Wentworth', 'Bv', 'IMPERFECT', 'SLOW', 3, 3, 'H', "BEVERLY SILTY CLAY LOAM"],
	['Hamilton-Wentworth', 'Ci', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CHINGUACOUSY SILT LOAM"],
	['Hamilton-Wentworth', 'Cl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CHINGUACOUSY LOAM"],
	['Hamilton-Wentworth', 'Co', 'POOR', 'SLOW', 2, 3, 'H', "COLWOOD SILT LOAM"],
	['Hamilton-Wentworth', 'Cp', 'POOR', 'MODERATE', 3, 3, 'H', "COLWOOD SILT LOAM"],
	['Hamilton-Wentworth', 'Cs', 'IMPERFECT', 'MODERATE', 2, 4, 'M', "CHINGUACOUSY SILT LOAM"],
	['Hamilton-Wentworth', 'Dk', 'WELL', 'RAPID', 4, 2, 'L', "DONNYBROOK GRAVELLY LOAM"],
	['Hamilton-Wentworth', 'Dl', 'WELL', 'RAPID', 4, 2, 'L', "DUMFRIES LOAM"],
	['Hamilton-Wentworth', 'Dr', 'WELL', 'RAPID', 4, 2, 'L', "DUMFRIES LOAM"],
	['Hamilton-Wentworth', 'Ds', 'WELL', 'RAPID', 1, 4, 'L', "DUMFRIES LOAM"],
	['Hamilton-Wentworth', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Hamilton-Wentworth', 'Fo', 'POOR', 'SLOW', 4, 3, 'M', "FLAMBOROUGH SANDY LOAM"],
	['Hamilton-Wentworth', 'Fs', 'POOR', 'SLOW', 4, 3, 'M', "FLAMBOROUGH SANDY LOAM"],
	['Hamilton-Wentworth', 'Gi', 'WELL', 'RAPID', 4, 1, 'L', "GRIMSBY SANDY LOAM"],
	['Hamilton-Wentworth', 'Gr', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Hamilton-Wentworth', 'Gs', 'WELL', 'RAPID', 1, 4, 'L', "GUELPH LOAM"],
	['Hamilton-Wentworth', 'Hi', 'IMPERFECT', 'SLOW', 3, 4, 'M', "HALDIMAND SILT LOAM"],
	['Hamilton-Wentworth', 'Hl', 'IMPERFECT', 'SLOW', 3, 4, 'H', "HALDIMAND SILTY CLAY LOAM"],
	['Hamilton-Wentworth', 'Jl', 'POOR', 'VERY SLOW', 2, 3, 'H', "JEDDO LOAM"],
	['Hamilton-Wentworth', 'Jo', 'POOR', 'VERY SLOW', 4, 3, 'M', "JEDDO SANDY LOAM"],
	['Hamilton-Wentworth', 'Kl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "KILLEAN LOAM"],
	['Hamilton-Wentworth', 'Li', 'POOR', 'SLOW', 3, 3, 'H', "LILY LOAM"],
	['Hamilton-Wentworth', 'Ll', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LONDON LOAM"],
	['Hamilton-Wentworth', 'Ln', 'POOR', 'VERY SLOW', 3, 4, 'H', "LINCOLN SILTY CLAY LOAM"],
	['Hamilton-Wentworth', 'Ls', 'IMPERFECT', 'RAPID', 2, 4, 'M', "LONDON LOAM"],
	['Hamilton-Wentworth', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Hamilton-Wentworth', 'Mo', 'POOR', 'VERY SLOW', 3, 3, 'H', "MORLEY SILTY CLAY LOAM"],
	['Hamilton-Wentworth', 'Oi', 'WELL', 'SLOW', 2, 4, 'L', "ONEIDA SILT LOAM"],
	['Hamilton-Wentworth', 'Ol', 'WELL', 'SLOW', 2, 4, 'L', "ONEIDA LOAM"],
	['Hamilton-Wentworth', 'Pl', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL LOAM"],
	['Hamilton-Wentworth', 'Ps', 'POOR', 'MODERATE', 3, 3, 'H', "PARKHILL LOAM"],
	['Hamilton-Wentworth', 'Sm', 'WELL', 'SLOW', 2, 4, 'L', "SMITHVILLE SILT LOAM"],
	['Hamilton-Wentworth', 'Sp', 'WELL', 'RAPID', 4, 1, 'L', "SPRINGVALE SANDY LOAM"],
	['Hamilton-Wentworth', 'Ti', 'POOR', 'VERY SLOW', 3, 3, 'H', "TOLEDO SILT LOAM"],
	['Hamilton-Wentworth', 'To', 'POOR', 'VERY SLOW', 3, 4, 'H', "TOLEDO SILTY CLAY LOAM"],
	['Hamilton-Wentworth', 'Tp', 'IMPERFECT', 'MODERATE', 2, 4, 'M', "TUSCOLA SILT LOAM"],
	['Hamilton-Wentworth', 'Tr', 'IMPERFECT', 'MODERATE', 3, 3, 'H', "TRAFALGAR SILTY CLAY LOAM"],
	['Hamilton-Wentworth', 'Ts', 'POOR', 'SLOW', 2, 3, 'H', "TOLEDO SILT LOAM"],
	['Hamilton-Wentworth', 'Tu', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA SILT LOAM"],
	['Hamilton-Wentworth', 'Vi', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "VINELAND SANDY LOAM"],
	['Hamilton-Wentworth', 'Vs', 'IMPERFECT', 'RAPID', 3, 2, 'M', "VINELAND SANDY LOAM"],
	['Hamilton-Wentworth', 'Wi', 'IMPERFECT', 'SLOW', 3, 2, 'M', "WINONA SANDY LOAM"],
	['Hastings', 'Altsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ALLISTON SANDY LOAM"],
	['Hastings', 'Altsl-sh', 'IMPERFECT', 'RAPID', 3, 2, 'M', "ALLISTON SANDY LOAM"],
	['Hastings', 'Augcl', 'WELL', 'VERY SLOW', 1, 4, 'M', "AMELIASBURG CLAY LOAM"],
	['Hastings', 'Augcl-b', 'WELL', 'VERY SLOW', 1, 4, 'M', "AMELIASBURG CLAY LOAM"],
	['Hastings', 'Bcfsl', 'WELL', 'RAPID', 4, 1, 'L', "BANCROFT FINE SANDY LOAM"],
	['Hastings', 'Bdhl', 'WELL', 'MODERATE', 4, 2, 'L', "BONDHEAD LOAM"],
	['Hastings', 'Bdhl-s', 'WELL', 'MODERATE', 4, 2, 'L', "BONDHEAD LOAM"],
	['Hastings', 'Bdhl-st', 'WELL', 'MODERATE', 4, 2, 'L', "BONDHEAD LOAM"],
	['Hastings', 'Bdhsl', 'WELL', 'MODERATE', 4, 1, 'L', "BONDHEAD SANDY LOAM"],
	['Hastings', 'Bdhsl-s', 'WELL', 'MODERATE', 4, 1, 'L', "BONDHEAD SANDY LOAM"],
	['Hastings', 'Bdhsl-st', 'WELL', 'MODERATE', 4, 1, 'L', "BONDHEAD SANDY LOAM"],
	['Hastings', 'Boosl', 'WELL', 'MODERATE', 4, 2, 'L', "BOOKTON SANDY LOAM"],
	['Hastings', 'Brrsl', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['Hastings', 'Cmhgs', 'WELL', 'RAPID', 4, 1, 'L', "CRAMAHE GRAVELLY SAND"],
	['Hastings', 'Dlol', 'WELL', 'MODERATE', 1, 3, 'L', "DELORO LOAM"],
	['Hastings', 'Dlol-s', 'WELL', 'MODERATE', 1, 3, 'L', "DELORO LOAM"],
	['Hastings', 'Dlol-sh', 'WELL', 'RAPID', 1, 4, 'L', "DELORO LOAM"],
	['Hastings', 'Dmml', 'WELL', 'MODERATE', 1, 3, 'L', "DUMMER LOAM"],
	['Hastings', 'Dmml-sh', 'WELL', 'RAPID', 1, 4, 'L', "DUMMER LOAM"],
	['Hastings', 'Dmmsl', 'WELL', 'MODERATE', 4, 1, 'L', "DUMMER SANDY LOAM"],
	['Hastings', 'Dulsl', 'WELL', 'MODERATE', 4, 1, 'L', "DUNDONALD SANDY LOAM"],
	['Hastings', 'Dulsl-st', 'WELL', 'MODERATE', 4, 1, 'L', "DUNDONALD SANDY LOAM"],
	['Hastings', 'Edol', 'WELL', 'MODERATE', 2, 4, 'L', "ELDORADO LOAM"],
	['Hastings', 'Edol-s', 'WELL', 'MODERATE', 2, 4, 'L', "ELDORADO LOAM"],
	['Hastings', 'Edosl', 'WELL', 'MODERATE', 3, 2, 'L', "ELDORADO SANDY LOAM"],
	['Hastings', 'Emyl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "EMILY LOAM"],
	['Hastings', 'Emyl-st', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "EMILY LOAM"],
	['Hastings', 'Eokc', 'IMPERFECT', 'SLOW', 3, 4, 'H', "ELMBROOK CLAY"],
	['Hastings', 'Eokc-st', 'IMPERFECT', 'SLOW', 3, 4, 'H', "ELMBROOK CLAY"],
	['Hastings', 'Eoksil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "ELMBROOK SILT LOAM"],
	['Hastings', 'Frml', 'VARIABLE', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Hastings', 'Fxbfsl', 'POOR', 'SLOW', 3, 3, 'H', "FOXBORO FINE SANDY LOAM"],
	['Hastings', 'Gnysl', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Hastings', 'Lsyc', 'POOR', 'VERY SLOW', 3, 3, 'H', "LINDSAY CLAY"],
	['Hastings', 'Lsycl', 'POOR', 'VERY SLOW', 3, 3, 'H', "LINDSAY CLAY LOAM"],
	['Hastings', 'Lsyc-sh', 'POOR', 'SLOW', 2, 3, 'H', "LINDSAY CLAY"],
	['Hastings', 'Lsyc-st', 'POOR', 'VERY SLOW', 3, 3, 'H', "LINDSAY CLAY"],
	['Hastings', 'Lysl', 'POOR', 'SLOW', 2, 3, 'H', "LYONS SANDY LOAM"],
	['Hastings', 'Lysl-st', 'POOR', 'SLOW', 2, 3, 'H', "LYONS SANDY LOAM"],
	['Hastings', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Hastings', 'Mglsl', 'WELL', 'MODERATE', 4, 1, 'L', "MONTEAGLE SANDY LOAM"],
	['Hastings', 'Obel', 'WELL', 'MODERATE', 1, 3, 'L', "OTONABEE LOAM"],
	['Hastings', 'Obel-s', 'WELL', 'MODERATE', 1, 3, 'L', "OTONABEE LOAM"],
	['Hastings', 'Obel-sh', 'WELL', 'RAPID', 1, 4, 'L', "OTONABEE LOAM"],
	['Hastings', 'Obel-st', 'WELL', 'MODERATE', 1, 3, 'L', "OTONABEE LOAM"],
	['Hastings', 'Obesl', 'WELL', 'MODERATE', 4, 1, 'L', "OTONABEE SANDY LOAM"],
	['Hastings', 'P', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEAT ORGANIC"],
	['Hastings', 'Pcyfsl', 'WELL', 'MODERATE', 1, 3, 'L', "PERCY FINE SANDY LOAM"],
	['Hastings', 'Shyc', 'WELL', 'SLOW', 3, 3, 'M', "SOUTH BAY CLAY"],
	['Hastings', 'Shyc-R', 'WELL', 'SLOW', 3, 3, 'M', "SOUTH BAY CLAY"],
	['Hastings', 'Shysil', 'WELL', 'SLOW', 2, 4, 'L', "SOUTH BAY SILT LOAM"],
	['Hastings', 'Siyc', 'POOR', 'VERY SLOW', 3, 3, 'H', "SIDNEY CLAY"],
	['Hastings', 'Siyc-st', 'POOR', 'VERY SLOW', 3, 3, 'H', "SIDNEY CLAY"],
	['Hastings', 'Smvcl', 'IMPERFECT', 'SLOW', 2, 3, 'H', "SOLMESVILLE CLAY LOAM"],
	['Hastings', 'Strsl', 'WELL', 'RAPID', 4, 1, 'L', "ST PETERS SANDY LOAM"],
	['Hastings', 'T', 'IMPERFECT', 'SLOW', 2, 3, 'M', " "],
	['Hastings', 'Tigsl', 'WELL', 'RAPID', 4, 1, 'L', "TIOGA SANDY LOAM"],
	['Hastings', 'Tigsl-st', 'WELL', 'RAPID', 4, 1, 'L', "TIOGA SANDY LOAM"],
	['Hastings', 'Wdgls', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO LOAMY SAND"],
	['Hastings', 'Wdgls-sh', 'WELL', 'RAPID', 3, 2, 'L', "WENDIGO LOAMY SAND"],
	['Hastings', 'Wpoc', 'WELL', 'SLOW', 3, 3, 'M', "WAUPOOS CLAY"],
	['Hastings', 'Wpocl', 'WELL', 'SLOW', 3, 4, 'M', "WAUPOOS CLAY LOAM"],
	['Hastings', 'Wussl', 'POOR', 'SLOW', 4, 3, 'M', "WAUSEON SANDY LOAM"],
	['Huron', 'Bc', 'POOR', 'VERY SLOW', 3, 4, 'H', "BROOKSTON CLAY LOAM"],
	['Huron', 'Bes', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['Huron', 'Bg', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD LOAM"],
	['Huron', 'Bos', 'WELL', 'MODERATE', 4, 2, 'L', "BOOKTON SANDY LOAM"],
	['Huron', 'Brl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRISBANE LOAM"],
	['Huron', 'Brs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SANDY LOAM"],
	['Huron', 'Bs', 'POOR', 'VERY SLOW', 3, 3, 'H', "BROOKSTON SILT LOAM"],
	['Huron', 'Bsc', 'POOR', 'VERY SLOW', 3, 4, 'H', "BROOKSTON SILTY CLAY LOAM"],
	['Huron', 'Dl', 'WELL', 'RAPID', 4, 2, 'L', "DUMFRIES LOAM"],
	['Huron', 'Dos', 'WELL', 'RAPID', 4, 1, 'L', "DONNYBROOK SANDY LOAM"],
	['Huron', 'Ds', 'WELL', 'RAPID', 4, 1, 'L', "DUMFRIES SANDY LOAM"],
	['Huron', 'Fs', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Huron', 'Gil', 'POOR', 'SLOW', 3, 3, 'H', "GILFORD LOAM"],
	['Huron', 'Gs', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Huron', 'Gul', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "GUERIN LOAM"],
	['Huron', 'Hl', 'WELL', 'MODERATE', 1, 3, 'L', "HARRISTON LOAM"],
	['Huron', 'Hs', 'WELL', 'MODERATE', 1, 3, 'L', "HARRISTON SILT LOAM"],
	['Huron', 'Huc', 'WELL', 'SLOW', 2, 4, 'M', "HURON CLAY LOAM"],
	['Huron', 'Hus', 'WELL', 'SLOW', 2, 4, 'L', "HURON SILT LOAM"],
	['Huron', 'Ll', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LISTOWEL LOAM"],
	['Huron', 'Ls', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LISTOWEL SILT LOAM"],
	['Huron', 'Lyl', 'POOR', 'SLOW', 3, 3, 'H', "LYONS LOAM"],
	['Huron', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Huron', 'Pal', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL LOAM"],
	['Huron', 'Pas', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL SILT LOAM"],
	['Huron', 'Pc', 'IMPERFECT', 'SLOW', 3, 3, 'H', "PERTH CLAY LOAM"],
	['Huron', 'Ps', 'IMPERFECT', 'SLOW', 2, 4, 'M', "PERTH SILT LOAM"],
	['Huron', 'Psc', 'IMPERFECT', 'SLOW', 3, 3, 'H', "PERTH SILTY CLAY LOAM"],
	['Huron', 'Tc', 'POOR', 'VERY SLOW', 3, 4, 'H', "TOLEDO CLAY LOAM"],
	['Huron', 'Tes', 'WELL', 'MODERATE', 4, 2, 'L', "TEESWATER SILT LOAM"],
	['Huron', 'Ts', 'POOR', 'VERY SLOW', 3, 3, 'H', "TOLEDO SILT LOAM"],
	['Huron', 'Was', 'POOR', 'SLOW', 4, 3, 'M', "WAUSEON SANDY LOAM"],
	['Kenora', 'Abcl', 'IMPERFECT', 'SLOW', 4, 4, 'H', "ABRAM CLAY LOAM"],
	['Kenora', 'Abl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "ABRAM LOAM"],
	['Kenora', 'Abs', 'IMPERFECT', 'SLOW', 3, 2, 'M', "ABRAM SAND"],
	['Kenora', 'Absic', 'IMPERFECT', 'SLOW', 4, 4, 'H', "ABRAM SILTY CLAY"],
	['Kenora', 'Absicl', 'IMPERFECT', 'SLOW', 4, 4, 'H', "ABRAM SILTY CLAY LOAM"],
	['Kenora', 'Alfs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ALCOCK FINE SAND"],
	['Kenora', 'Als', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ALCOCK SAND"],
	['Kenora', 'Alsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ALCOCK SANDY LOAM"],
	['Kenora', 'Avfsl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "AVERY FINE SANDY LOAM"],
	['Kenora', 'Avsil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "AVERY SILT LOAM"],
	['Kenora', 'Bccl', 'WELL', 'SLOW', 4, 4, 'M', "BLACK STURGEON CLAY LOAM"],
	['Kenora', 'Be-P', 'POOR', 'SLOW', 4, 2, 'M', "BEARTRACK ORGANIC"],
	['Kenora', 'Besl', 'POOR', 'SLOW', 4, 2, 'M', "BEARTRACK SANDY LOAM"],
	['Kenora', 'Bk-P', 'POOR', 'SLOW', 4, 3, 'M', "BREAKNECK ORGANIC"],
	['Kenora', 'Bksl', 'POOR', 'SLOW', 4, 3, 'M', "BREAKNECK SANDY LOAM"],
	['Kenora', 'Brfsl', 'WELL', 'RAPID', 4, 2, 'L', "BROADTAIL FINE SANDY LOAM"],
	['Kenora', 'Brsil', 'WELL', 'RAPID', 4, 2, 'L', "BROADTAIL SILT LOAM"],
	['Kenora', 'Brsl', 'WELL', 'RAPID', 4, 1, 'L', "BROADTAIL SANDY LOAM"],
	['Kenora', 'Brsl-sh', 'WELL', 'RAPID', 3, 2, 'L', "BROADTAIL SANDY LOAM"],
	['Kenora', 'Bsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BOB LAKE SANDY LOAM"],
	['Kenora', 'Busic', 'VERY POOR', 'SLOW', 3, 2, 'M', "BURNING SILTY CLAY"],
	['Kenora', 'Busicl', 'VERY POOR', 'SLOW', 3, 4, 'M', "BURNING SILTY CLAY LOAM"],
	['Kenora', 'Busil', 'VERY POOR', 'SLOW', 3, 2, 'M', "BURNING SILT LOAM"],
	['Kenora', 'Cbsl', 'WELL', 'MODERATE', 4, 1, 'L', "CONTACT BAY SANDY LOAM"],
	['Kenora', 'Ccl', 'IMPERFECT', 'SLOW', 4, 4, 'H', "CORN CLAY LOAM"],
	['Kenora', 'Cl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CORN LOAM"],
	['Kenora', 'Csil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CORN SILT LOAM"],
	['Kenora', 'Csl', 'IMPERFECT', 'SLOW', 3, 2, 'M', "CORN SANDY LOAM"],
	['Kenora', 'Degs', 'WELL', 'RAPID', 4, 1, 'L', "DECEPTION GRAVELLY SAND"],
	['Kenora', 'Des', 'WELL', 'RAPID', 4, 1, 'L', "DECEPTION SAND"],
	['Kenora', 'Desl', 'WELL', 'RAPID', 4, 1, 'L', "DECEPTION SANDY LOAM"],
	['Kenora', 'Dy', 'VERY POOR', 'SLOW', 3, 2, 'M', "DRYDEN ORGANIC"],
	['Kenora', 'Eg', 'VERY POOR', 'SLOW', 3, 2, 'M', "EAGLE LAKE ORGANIC"],
	['Kenora', 'Gw', 'VERY POOR', 'SLOW', 3, 2, 'M', "GULLWING LAKE ORGANIC"],
	['Kenora', 'Gysl', 'POOR', 'SLOW', 4, 2, 'M', "GUY LAKE SANDY LOAM"],
	['Kenora', 'Hhs', 'VERY POOR', 'SLOW', 4, 2, 'M', "HUGHES ORGANIC"],
	['Kenora', 'Hsil', 'POOR', 'VERY SLOW', 3, 3, 'H', "HARTMAN SILT LOAM"],
	['Kenora', 'Jc', 'WELL', 'SLOW', 3, 2, 'M', "JAFFRAY CLAY"],
	['Kenora', 'Jcl', 'WELL', 'SLOW', 3, 2, 'M', "JAFFRAY CLAY LOAM"],
	['Kenora', 'Jgsl', 'WELL', 'RAPID', 4, 1, 'L', "JAFFRAY GRAVELLY SANDY LOAM"],
	['Kenora', 'Jsl', 'WELL', 'RAPID', 4, 1, 'L', "JAFFRAY SANDY LOAM"],
	['Kenora', 'Lfsl', 'WELL', 'RAPID', 4, 2, 'L', "LAURENCE FINE SANDY LOAM"],
	['Kenora', 'Ma', 'VERY POOR', 'SLOW', 3, 2, 'M', "MARSH ORGANIC"],
	['Kenora', 'Mincl', 'WELL', 'SLOW', 4, 4, 'M', "MENNIN CLAY LOAM"],
	['Kenora', 'Minl', 'WELL', 'SLOW', 2, 4, 'L', "MENNIN LOAM"],
	['Kenora', 'Minsil', 'WELL', 'SLOW', 2, 4, 'L', "MENNIN SILT LOAM"],
	['Kenora', 'Mnc', 'POOR', 'VERY SLOW', 3, 3, 'H', "MINNITAKI CLAY"],
	['Kenora', 'Mncl', 'POOR', 'VERY SLOW', 3, 4, 'H', "MINNITAKI CLAY LOAM"],
	['Kenora', 'Mnl', 'POOR', 'VERY SLOW', 3, 3, 'H', "MINNITAKI LOAM"],
	['Kenora', 'Mnsil', 'POOR', 'VERY SLOW', 3, 3, 'H', "MINNITAKI SILT LOAM"],
	['Kenora', 'Mtgls', 'WELL', 'RAPID', 4, 1, 'L', "MATHESON GRAVELLY LOAMY SAND"],
	['Kenora', 'Mtsl', 'WELL', 'RAPID', 4, 1, 'L', "MATHESON SANDY LOAM"],
	['Kenora', 'Ns', 'POOR', 'SLOW', 4, 2, 'M', "NUGGET SAND"],
	['Kenora', 'Nsl', 'POOR', 'SLOW', 4, 2, 'M', "NUGGET SANDY LOAM"],
	['Kenora', 'O', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC ORGANIC"],
	['Kenora', 'Pg', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEGANEIS LAKE ORGANIC"],
	['Kenora', 'Pmcl', 'WELL', 'SLOW', 4, 4, 'M', "PLUM CLAY LOAM"],
	['Kenora', 'Rc', 'VERY POOR', 'SLOW', 3, 2, 'M', "ROCKBOUND LAKE ORGANIC"],
	['Kenora', 'Rdcl', 'WELL', 'SLOW', 3, 2, 'M', "REDVERS CLAY LOAM"],
	['Kenora', 'Rdl', 'WELL', 'RAPID', 4, 2, 'L', "REDVERS LOAM"],
	['Kenora', 'Sic', 'WELL', 'SLOW', 3, 3, 'M', "SIOUX CLAY"],
	['Kenora', 'Sicl', 'WELL', 'SLOW', 4, 4, 'M', "SIOUX CLAY LOAM"],
	['Kenora', 'Sil', 'WELL', 'SLOW', 2, 4, 'L', "SIOUX LOAM"],
	['Kenora', 'Sisicl', 'WELL', 'SLOW', 4, 4, 'M', "SIOUX SILTY CLAY LOAM"],
	['Kenora', 'Sisil', 'WELL', 'SLOW', 2, 4, 'L', "SIOUX SILT LOAM"],
	['Kenora', 'Td', 'VERY POOR', 'SLOW', 3, 2, 'M', "THUNDER LAKE ORGANIC"],
	['Kenora', 'Trfsl', 'POOR', 'SLOW', 3, 3, 'H', "TREATY FINE SANDY LOAM"],
	['Kenora', 'Trs', 'POOR', 'SLOW', 4, 2, 'M', "TREATY SAND"],
	['Kenora', 'Trsl', 'POOR', 'SLOW', 4, 2, 'M', "TREATY SANDY LOAM"],
	['Kenora', 'Wd', 'VERY POOR', 'SLOW', 3, 2, 'M', "WILDRICE LAKE ORGANIC"],
	['Kenora', 'Wkgs', 'POOR', 'SLOW', 4, 2, 'M', "WICKENS GRAVELLY SAND"],
	['Kenora', 'Wks', 'POOR', 'SLOW', 4, 2, 'M', "WICKENS SAND"],
	['Kenora', 'Zs', 'POOR', 'SLOW', 4, 2, 'M', "ZEALAND SAND"],
	['Kenora', 'Zsl', 'POOR', 'SLOW', 4, 2, 'M', "ZEALAND SANDY LOAM"],
	['Kent', 'BE', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN LOAMY FINE SAND"],
	['Kent', 'BE.T', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN LOAMY FINE SAND"],
	['Kent', 'BF', 'WELL', 'SLOW', 2, 4, 'M', "BRANTFORD SILTY CLAY LOAM"],
	['Kent', 'BF.W', 'WELL', 'SLOW', 2, 4, 'M', "BRANTFORD SILTY CLAY LOAM"],
	['Kent', 'BN', 'WELL', 'MODERATE', 2, 4, 'L', "BENNINGTON LOAM"],
	['Kent', 'BN.T', 'WELL', 'MODERATE', 2, 4, 'L', "BENNINGTON LOAM"],
	['Kent', 'BO.T', 'WELL', 'MODERATE', 4, 2, 'L', "BOOKTON LOAMY FINE SAND"],
	['Kent', 'BR', 'POOR', 'VERY SLOW', 3, 3, 'H', "BROOKSTON CLAY"],
	['Kent', 'BR.L', 'POOR', 'VERY SLOW', 3, 3, 'M', "BROOKSTON LOAM"],
	['Kent', 'BR.LY', 'POOR', 'VERY SLOW', 3, 3, 'H', "BROOKSTON LOAM"],
	['Kent', 'BR.W', 'POOR', 'VERY SLOW', 3, 3, 'H', "BROOKSTON CLAY"],
	['Kent', 'BR.Y', 'POOR', 'VERY SLOW', 3, 4, 'H', "BROOKSTON CLAY"],
	['Kent', 'BT', 'WELL', 'MODERATE', 1, 3, 'L', "BRANT LOAM"],
	['Kent', 'BV', 'IMPERFECT', 'SLOW', 3, 4, 'H', "BEVERLY SILTY CLAY LOAM"],
	['Kent', 'BV.C', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BEVERLY LOAMY FINE SAND"],
	['Kent', 'BV.K', 'IMPERFECT', 'SLOW', 3, 4, 'H', "BEVERLY SILTY CLAY LOAM"],
	['Kent', 'BV.L', 'IMPERFECT', 'SLOW', 3, 4, 'M', "BEVERLY SILT LOAM"],
	['Kent', 'BY', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SANDY LOAM"],
	['Kent', 'BY.K', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SANDY LOAM"],
	['Kent', 'CH', 'VERY POOR', 'SLOW', 4, 2, 'M', "CHURCHVILLE LOAMY FINE SAND"],
	['Kent', 'CW', 'POOR', 'SLOW', 2, 3, 'M', "COLWOOD SILT LOAM"],
	['Kent', 'CW.K', 'POOR', 'SLOW', 2, 3, 'M', "COLWOOD LOAM"],
	['Kent', 'CY', 'POOR', 'VERY SLOW', 3, 3, 'H', "CLYDE SILTY CLAY"],
	['Kent', 'CY.L', 'POOR', 'VERY SLOW', 3, 3, 'M', "CLYDE LOAM"],
	['Kent', 'FR', 'VERY POOR', 'SLOW', 4, 2, 'M', "FROME LOAMY SAND"],
	['Kent', 'FR.P', 'VERY POOR', 'SLOW', 4, 2, 'M', "FROME ORGANIC"],
	['Kent', 'FX', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Kent', 'FX.K', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Kent', 'GO', 'IMPERFECT', 'SLOW', 3, 4, 'H', "GOBLES SILTY CLAY"],
	['Kent', 'GO.C', 'IMPERFECT', 'SLOW', 3, 4, 'M', "GOBLES LOAMY FINE SAND"],
	['Kent', 'GO.L', 'IMPERFECT', 'SLOW', 3, 4, 'M', "GOBLES LOAM"],
	['Kent', 'GO.W', 'IMPERFECT', 'SLOW', 3, 4, 'M', "GOBLES LOAM"],
	['Kent', 'GY', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Kent', 'GY.K', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SAND"],
	['Kent', 'HI', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "HIGHGATE SANDY LOAM"],
	['Kent', 'KE', 'POOR', 'VERY SLOW', 3, 4, 'H', "KELVIN CLAY LOAM"],
	['Kent', 'KE.L', 'POOR', 'VERY SLOW', 3, 3, 'M', "KELVIN LOAM"],
	['Kent', 'KT', 'WELL', 'RAPID', 4, 1, 'L', "KINTYRE SANDY LOAM"],
	['Kent', 'KT.T', 'WELL', 'RAPID', 4, 2, 'L', "KINTYRE SANDY LOAM"],
	['Kent', 'MA', 'POOR', 'SLOW', 2, 3, 'M', "MAPLEWOOD FINE SANDY LOAM"],
	['Kent', 'MA.T', 'POOR', 'SLOW', 2, 3, 'M', "MAPLEWOOD VERY FINE SANDY LOAM"],
	['Kent', 'MB', 'POOR', 'VERY SLOW', 3, 3, 'H', "MITCHELLS' BAY VERY FINE SANDY LOAM"],
	['Kent', 'MK', 'POOR', 'SLOW', 3, 3, 'M', "MUIRKIRK LOAMY FINE SAND"],
	['Kent', 'MK.P', 'POOR', 'SLOW', 3, 2, 'M', "MUIRKIRK ORGANIC"],
	['Kent', 'MU', 'WELL', 'SLOW', 2, 4, 'M', "MURIEL CLAY LOAM"],
	['Kent', 'MU.L', 'WELL', 'SLOW', 2, 4, 'L', "MURIEL SILT LOAM"],
	['Kent', 'MU.W', 'WELL', 'SLOW', 2, 4, 'L', "MURIEL LOAM"],
	['Kent', 'NO', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "NORMANDALE LOAMY VERY FINE SAND"],
	['Kent', 'NO.K', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "NORMANDALE FINE SANDY LOAM"],
	['Kent', 'ORG', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC ORGANIC"],
	['Kent', 'PE', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PERTH SILTY CLAY LOAM"],
	['Kent', 'PE.L', 'IMPERFECT', 'SLOW', 3, 4, 'M', "PERTH LOAM"],
	['Kent', 'PE.W', 'IMPERFECT', 'SLOW', 3, 4, 'M', "PERTH LOAM"],
	['Kent', 'PF', 'WELL', 'RAPID', 4, 1, 'L', "PLAINFIELD FINE SAND"],
	['Kent', 'RV', 'POOR', 'VERY SLOW', 3, 3, 'H', "RIVARD SILTY CLAY"],
	['Kent', 'SC', 'POOR', 'SLOW', 4, 2, 'M', "ST CLAIR VERY FINE SANDY LOAM"],
	['Kent', 'SP', 'VERY POOR', 'RAPID', 4, 2, 'M', "SPRINGWATER FINE SAND"],
	['Kent', 'SW', 'POOR', 'MODERATE', 3, 3, 'M', "ST WILLIAMS VERY FINE SANDY LOAM"],
	['Kent', 'TA', 'IMPERFECT', 'SLOW', 3, 4, 'M', "TAVISTOCK LOAM"],
	['Kent', 'TA.T', 'IMPERFECT', 'SLOW', 3, 4, 'M', "TAVISTOCK VERY FINE SANDY LOAM"],
	['Kent', 'TO', 'POOR', 'VERY SLOW', 3, 4, 'H', "TOLEDO SILTY CLAY LOAM"],
	['Kent', 'TO.K', 'POOR', 'VERY SLOW', 3, 4, 'H', "TOLEDO SILTY CLAY LOAM"],
	['Kent', 'TO.KL', 'POOR', 'VERY SLOW', 3, 4, 'H', "TOLEDO LOAM"],
	['Kent', 'TO.L', 'POOR', 'VERY SLOW', 3, 3, 'M', "TOLEDO LOAM"],
	['Kent', 'TU', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA SILT LOAM"],
	['Kent', 'TU.K', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA SILT LOAM"],
	['Kent', 'WF', 'WELL', 'RAPID', 4, 1, 'L', "WATTFORD LOAMY FINE SAND"],
	['Kent', 'WM', 'IMPERFECT', 'RAPID', 4, 2, 'M', "WALSINGHAM LOAMY FINE SAND"],
	['Kent', 'WN', 'POOR', 'SLOW', 4, 2, 'M', "WATERIN LOAMY FINE SAND"],
	['Kent', 'WU', 'POOR', 'VERY SLOW', 4, 3, 'M', "WAUSEON LOAMY FINE SAND"],
	['Kent', 'WU.P', 'POOR', 'SLOW', 3, 2, 'M', "WAUSEON ORGANIC"],
	['Kent', 'WU.T', 'POOR', 'VERY SLOW', 4, 3, 'M', "WAUSEON LOAMY FINE SAND"],
	['Lambton', 'Bc', 'POOR', 'VERY SLOW', 3, 3, 'H', "BROOKSTON CLAY"],
	['Lambton', 'Bc-st', 'POOR', 'VERY SLOW', 3, 3, 'H', "BROOKSTON CLAY"],
	['Lambton', 'Bes', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SAND"],
	['Lambton', 'Besl', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['Lambton', 'Blac', 'POOR', 'VERY SLOW', 3, 3, 'H', "BLACKWELL CLAY"],
	['Lambton', 'Bul', 'WELL', 'RAPID', 1, 3, 'L', "BURFORD LOAM"],
	['Lambton', 'Bul-sh', 'WELL', 'RAPID', 2, 4, 'L', "BURFORD LOAM"],
	['Lambton', 'Bxl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRISBANE LOAM"],
	['Lambton', 'Bxl-sh', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "BRISBANE LOAM"],
	['Lambton', 'Bys', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SAND"],
	['Lambton', 'Bysl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SANDY LOAM"],
	['Lambton', 'Cc', 'IMPERFECT', 'SLOW', 3, 4, 'H', "CAISTOR CLAY"],
	['Lambton', 'Ccl', 'IMPERFECT', 'SLOW', 3, 4, 'H', "CAISTOR CLAY LOAM"],
	['Lambton', 'Cwfsl', 'POOR', 'SLOW', 2, 3, 'H', "COLWOOD FINE SANDY LOAM"],
	['Lambton', 'Czc', 'POOR', 'VERY SLOW', 3, 3, 'H', "CLYDE CLAY"],
	['Lambton', 'Ets', 'WELL', 'RAPID', 4, 1, 'L', "EASTPORT SAND"],
	['Lambton', 'Fxsl', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Lambton', 'Gil', 'POOR', 'SLOW', 2, 3, 'H', "GILFORD LOAM"],
	['Lambton', 'Gl', 'WELL', 'MODERATE', 1, 3, 'L', "GUELPH LOAM"],
	['Lambton', 'Gl-sh', 'WELL', 'MODERATE', 2, 4, 'L', "GUELPH LOAM"],
	['Lambton', 'Grsl', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Lambton', 'Huc', 'WELL', 'SLOW', 3, 3, 'M', "HURON CLAY"],
	['Lambton', 'Huc-e', 'WELL', 'SLOW', 3, 3, 'M', "HURON CLAY"],
	['Lambton', 'Lal', 'WELL', 'SLOW', 2, 4, 'L', "LAMBTON LOAM"],
	['Lambton', 'Lasil', 'WELL', 'SLOW', 2, 4, 'L', "LAMBTON SILT LOAM"],
	['Lambton', 'Pc', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PERTH CLAY"],
	['Lambton', 'Pc-e', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PERTH CLAY"],
	['Lambton', 'Pcl', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PERTH CLAY LOAM"],
	['Lambton', 'Pds', 'WELL', 'RAPID', 4, 1, 'L', "PLAINFIELD SAND"],
	['Lambton', 'Sml', 'WELL', 'MODERATE', 2, 4, 'L', "SHASHAWANDA LOAM"],
	['Lambton', 'Toc', 'POOR', 'VERY SLOW', 3, 3, 'H', "TOLEDO CLAY"],
	['Lambton', 'Tocl', 'POOR', 'VERY SLOW', 3, 4, 'H', "TOLEDO CLAY LOAM"],
	['Lanark', 'Afsl', 'POOR', 'SLOW', 3, 3, 'H', "ALLENDALE FINE SANDY LOAM"],
	['Lanark', 'Als', 'WELL', 'SLOW', 2, 4, 'L', "ALMONTE SILT LOAM"],
	['Lanark', 'Aps', 'WELL', 'MODERATE', 1, 3, 'L', "APPLETON SILT LOAM"],
	['Lanark', 'Asl', 'POOR', 'SLOW', 4, 3, 'M', "ALLENDALE SANDY LOAM"],
	['Lanark', 'Bas', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BALDERSON SANDY LOAM"],
	['Lanark', 'Bks', 'POOR', 'SLOW', 4, 3, 'M', "BROOKE SANDY LOAM"],
	['Lanark', 'Bs', 'WELL', 'RAPID', 4, 1, 'L', "BOLINGBROKE SANDY LOAM"],
	['Lanark', 'Cs', 'POOR', 'SLOW', 4, 2, 'M', "CHRISTY SANDY LOAM"],
	['Lanark', 'Es', 'WELL', 'MODERATE', 3, 2, 'L', "ELMSLEY SANDY LOAM"],
	['Lanark', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Lanark', 'Fns', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "FRANKTOWN SANDY LOAM"],
	['Lanark', 'Fsl', 'WELL', 'MODERATE', 3, 2, 'L', "FARMINGTON SANDY LOAM"],
	['Lanark', 'Fsl-ru', 'WELL', 'MODERATE', 3, 2, 'L', "FARMINGTON SANDY LOAM"],
	['Lanark', 'Grl', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Lanark', 'Grl-sh', 'WELL', 'RAPID', 1, 4, 'L', "GRENVILLE LOAM"],
	['Lanark', 'Gs', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SAND"],
	['Lanark', 'Ins', 'POOR', 'SLOW', 4, 2, 'M', "INNISVILLE SANDY LOAM"],
	['Lanark', 'Kg', 'WELL', 'RAPID', 4, 1, 'L', "KARS GRAVELLY SANDY LOAM"],
	['Lanark', 'Lac', 'IMPERFECT', 'SLOW', 3, 3, 'H', "LANARK CLAY LOAM"],
	['Lanark', 'Lyl', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Lanark', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Lanark', 'Masl', 'WELL', 'MODERATE', 4, 2, 'L', "MANOTICK SANDY LOAM"],
	['Lanark', 'Mnf', 'IMPERFECT', 'SLOW', 3, 4, 'M', "MOUNTAIN FINE SANDY LOAM"],
	['Lanark', 'Mns', 'IMPERFECT', 'SLOW', 3, 2, 'M', "MOUNTAIN SANDY LOAM"],
	['Lanark', 'Msl', 'WELL', 'MODERATE', 4, 1, 'L', "MONTEAGLE SANDY LOAM"],
	['Lanark', 'Msl-sh', 'WELL', 'RAPID', 3, 2, 'L', "MONTEAGLE SANDY LOAM"],
	['Lanark', 'Mtl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MATILDA LOAM"],
	['Lanark', 'NGcl', 'POOR', 'VERY SLOW', 3, 4, 'H', "NORTH GOWER CLAY LOAM"],
	['Lanark', 'NGcl-r', 'POOR', 'VERY SLOW', 3, 4, 'H', "NORTH GOWER CLAY LOAM"],
	['Lanark', 'Os', 'POOR', 'SLOW', 2, 3, 'H', "OSGOODE SILT LOAM"],
	['Lanark', 'Rc', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "RIDEAU CLAY"],
	['Lanark', 'Rc-s', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "RIDEAU CLAY"],
	['Lanark', 'Rs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RUBICON SAND"],
	['Lanark', 'Sns', 'IMPERFECT', 'VERY SLOW', 2, 4, 'M', "SNEDDEN SILT LOAM"],
	['Lanark', 'SPg', 'WELL', 'RAPID', 4, 1, 'L', "ST PETERS GRAVELLY SANDY LOAM"],
	['Lanark', 'Tsl', 'WELL', 'MODERATE', 4, 1, 'L', "TENNYSON SANDY LOAM"],
	['Lanark', 'Tsl-sh', 'WELL', 'RAPID', 3, 2, 'L', "TENNYSON SANDY LOAM"],
	['Lanark', 'Tws', 'WELL', 'RAPID', 4, 1, 'L', "TWEED SANDY LOAM"],
	['Lanark', 'Us', 'WELL', 'RAPID', 4, 1, 'L', "UPLANDS SAND"],
	['Lanark', 'WLs', 'WELL', 'RAPID', 4, 1, 'L', "WHITE LAKE GRAVELLY SANDY LOAM"],
	['Lanark', 'Ws', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "WEMYSS SANDY LOAM"],
	['Lanark', 'Wys', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "WAYSIDE SANDY LOAM"],
	['Leeds', 'Asl', 'POOR', 'SLOW', 4, 2, 'M', "ALLENDALE SANDY LOAM"],
	['Leeds', 'Btfsl', 'IMPERFECT', 'SLOW', 2, 4, 'M', "BATTERSEA FINE SANDY LOAM"],
	['Leeds', 'Btsil', 'IMPERFECT', 'SLOW', 2, 4, 'M', "BATTERSEA SILT LOAM"],
	['Leeds', 'Ccl', 'IMPERFECT', 'SLOW', 3, 3, 'H', "CARP CLAY LOAM"],
	['Leeds', 'Ccl-sh', 'IMPERFECT', 'MODERATE', 3, 4, 'H', "CARP CLAY LOAM"],
	['Leeds', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Leeds', 'Fsl', 'WELL', 'MODERATE', 3, 2, 'L', "FARMINGTON SANDY LOAM"],
	['Leeds', 'Gc', 'WELL', 'SLOW', 3, 3, 'M', "GANANOQUE CLAY"],
	['Leeds', 'Grl', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Leeds', 'Grl-sh', 'WELL', 'RAPID', 1, 4, 'L', "GRENVILLE LOAM"],
	['Leeds', 'Grsl', 'WELL', 'MODERATE', 4, 1, 'L', "GRENVILLE SANDY LOAM"],
	['Leeds', 'Gs', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Leeds', 'Hfsl', 'POOR', 'SLOW', 2, 3, 'H', "HINCHINBROOKE FINE SANDY LOAM"],
	['Leeds', 'Hl', 'POOR', 'SLOW', 2, 3, 'H', "HINCHINBROOKE LOAM"],
	['Leeds', 'Hl-sh', 'POOR', 'MODERATE', 3, 3, 'H', "HINCHINBROOKE LOAM"],
	['Leeds', 'Hsil', 'POOR', 'SLOW', 2, 3, 'H', "HINCHINBROOKE SILT LOAM"],
	['Leeds', 'Kg', 'WELL', 'RAPID', 4, 2, 'L', "KARS GRAVELLY LOAM"],
	['Leeds', 'Lac', 'IMPERFECT', 'SLOW', 3, 4, 'H', "LANDSDOWNE CLAY"],
	['Leeds', 'Lyl', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Leeds', 'Lyl-sh', 'POOR', 'MODERATE', 3, 3, 'H', "LYONS LOAM"],
	['Leeds', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Leeds', 'Ml', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MATILDA LOAM"],
	['Leeds', 'Ml-sh', 'IMPERFECT', 'RAPID', 2, 4, 'M', "MATILDA LOAM"],
	['Leeds', 'M-sh', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Leeds', 'Msl', 'WELL', 'MODERATE', 4, 1, 'L', "MONTEAGLE SANDY LOAM"],
	['Leeds', 'Nc', 'POOR', 'VERY SLOW', 3, 3, 'H', "NAPANEE CLAY"],
	['Leeds', 'Nc-sh', 'POOR', 'SLOW', 2, 3, 'H', "NAPANEE CLAY"],
	['Leeds', 'NGcl', 'POOR', 'VERY SLOW', 3, 4, 'H', "NORTH GOWER CLAY LOAM"],
	['Leeds', 'NGcl-sh', 'POOR', 'SLOW', 3, 4, 'H', "NORTH GOWER CLAY LOAM"],
	['Leeds', 'P', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEAT ORGANIC"],
	['Leeds', 'Pfsl', 'IMPERFECT', 'VERY SLOW', 2, 3, 'M', "PICADILLY FINE SANDY LOAM"],
	['Leeds', 'Pl', 'IMPERFECT', 'VERY SLOW', 2, 3, 'M', "PICADILLY LOAM"],
	['Leeds', 'Psil', 'IMPERFECT', 'VERY SLOW', 2, 3, 'M', "PICADILLY SILT LOAM"],
	['Leeds', 'Rfs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RUBICON FINE SAND"],
	['Leeds', 'Rs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RUBICON SAND"],
	['Leeds', 'SBsil', 'WELL', 'SLOW', 2, 4, 'L', "SEELEY'S BAY SILT LOAM"],
	['Leeds', 'Sfs', 'POOR', 'SLOW', 4, 2, 'M', "ST SAMUEL FINE SAND"],
	['Leeds', 'Ssl', 'POOR', 'SLOW', 4, 2, 'M', "ST SAMUEL SANDY LOAM"],
	['Leeds', 'Ssl-sh', 'POOR', 'MODERATE', 4, 3, 'M', "ST SAMUEL SANDY LOAM"],
	['Leeds', 'Tsl', 'WELL', 'MODERATE', 4, 1, 'L', "TENNYSON SANDY LOAM"],
	['Leeds', 'Tsl-sh', 'WELL', 'RAPID', 3, 2, 'L', "TENNYSON SANDY LOAM"],
	['Leeds', 'Tws', 'WELL', 'MODERATE', 4, 1, 'L', "TWEED SANDY LOAM"],
	['Leeds', 'Ufs', 'WELL', 'RAPID', 4, 1, 'L', "UPLANDS FINE SANDY LOAM"],
	['Leeds', 'Us-sh', 'WELL', 'RAPID', 3, 2, 'L', "UPLANDS SAND"],
	['Leeds', 'WLs', 'WELL', 'RAPID', 4, 1, 'L', "WHITE LAKE GRAVELLY SANDY LOAM"],
	['Leeds', 'WLs-sh', 'WELL', 'RAPID', 3, 2, 'L', "WHITE LAKE GRAVELLY SANDY LOAM"],
	['Lennox-Addington', 'Basl', 'WELL', 'RAPID', 4, 1, 'L', "BANCROFT SAND"],
	['Lennox-Addington', 'Bes', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['Lennox-Addington', 'Bl', 'WELL', 'MODERATE', 1, 3, 'L', "BONDHEAD LOAM"],
	['Lennox-Addington', 'Bl-sh', 'WELL', 'RAPID', 1, 4, 'L', "BONDHEAD LOAM"],
	['Lennox-Addington', 'Bos', 'WELL', 'MODERATE', 4, 2, 'L', "BOOKTON SANDY LOAM"],
	['Lennox-Addington', 'Bsl', 'WELL', 'MODERATE', 4, 1, 'L', "BONDHEAD SANDY LOAM"],
	['Lennox-Addington', 'Bsl-sh', 'WELL', 'RAPID', 3, 2, 'L', "BONDHEAD SANDY LOAM"],
	['Lennox-Addington', 'Cs', 'WELL', 'RAPID', 4, 2, 'L', "CRAMAHE GRAVELLY LOAM"],
	['Lennox-Addington', 'Dl', 'WELL', 'MODERATE', 1, 3, 'L', "DUMMER LOAM"],
	['Lennox-Addington', 'Dl-sh', 'WELL', 'RAPID', 1, 4, 'L', "DUMMER CLAY"],
	['Lennox-Addington', 'Ec', 'IMPERFECT', 'SLOW', 3, 4, 'H', "ELMBROOK SANDY LOAM"],
	['Lennox-Addington', 'El', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "EMILY LOAM"],
	['Lennox-Addington', 'El-sh', 'IMPERFECT', 'RAPID', 2, 4, 'M', "EMILY LOAM"],
	['Lennox-Addington', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Lennox-Addington', 'Gc', 'WELL', 'SLOW', 3, 3, 'M', "GANANOQUE CLAY"],
	['Lennox-Addington', 'Grsl', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Lennox-Addington', 'Gul', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "GUERIN LOAM"],
	['Lennox-Addington', 'Gul-sh', 'IMPERFECT', 'RAPID', 2, 4, 'M', "GUERIN LOAM"],
	['Lennox-Addington', 'Hsil', 'POOR', 'SLOW', 2, 3, 'H', "HINCHINBROOKE SILT LOAM"],
	['Lennox-Addington', 'Lac', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "LANDSDOWNE CLAY"],
	['Lennox-Addington', 'Lac-sh', 'IMPERFECT', 'SLOW', 2, 4, 'H', "LANDSDOWNE CLAY"],
	['Lennox-Addington', 'Lc', 'POOR', 'VERY SLOW', 3, 3, 'H', "LINDSAY CLAY"],
	['Lennox-Addington', 'Lc-sh', 'POOR', 'SLOW', 2, 3, 'H', "LINDSAY LOAM"],
	['Lennox-Addington', 'Lyl', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Lennox-Addington', 'Lyl-sh', 'POOR', 'MODERATE', 3, 3, 'H', "LYONS LOAM"],
	['Lennox-Addington', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Lennox-Addington', 'Mc', 'POOR', 'VERY SLOW', 3, 3, 'H', "MOSCOW CLAY"],
	['Lennox-Addington', 'Mc-R', 'POOR', 'VERY SLOW', 3, 3, 'H', "MOSCOW CLAY"],
	['Lennox-Addington', 'Msl', 'WELL', 'MODERATE', 4, 1, 'L', "MONTEAGLE SANDY LOAM"],
	['Lennox-Addington', 'Ms-sh', 'POOR', 'SLOW', 2, 3, 'H', " "],
	['Lennox-Addington', 'Nc', 'POOR', 'VERY SLOW', 3, 3, 'H', "NAPANEE CLAY"],
	['Lennox-Addington', 'Nc-sh', 'POOR', 'SLOW', 2, 3, 'H', "NAPANEE CLAY"],
	['Lennox-Addington', 'Nufsl', 'WELL', 'MODERATE', 1, 3, 'L', "NEWBURGH FINE SANDY LOAM"],
	['Lennox-Addington', 'Ol', 'WELL', 'MODERATE', 1, 3, 'L', "OTONABEE LOAM"],
	['Lennox-Addington', 'Ol-s', 'WELL', 'MODERATE', 1, 3, 'L', "OTONABEE LOAM"],
	['Lennox-Addington', 'Ol-sh', 'WELL', 'RAPID', 1, 4, 'L', "OTONABEE LOAM"],
	['Lennox-Addington', 'Ol-st', 'WELL', 'RAPID', 1, 3, 'L', "OTONABEE LOAM"],
	['Lennox-Addington', 'P', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEAT ORGANIC"],
	['Lennox-Addington', 'Pl', 'IMPERFECT', 'VERY SLOW', 2, 3, 'M', "PICADILLY LOAM"],
	['Lennox-Addington', 'Sc', 'POOR', 'VERY SLOW', 3, 3, 'H', "SIDNEY CLAY"],
	['Lennox-Addington', 'Sc-sh', 'POOR', 'SLOW', 2, 3, 'H', "SIDNEY CLAY"],
	['Lennox-Addington', 'Sl', 'WELL', 'RAPID', 4, 1, 'L', " "],
	['Lennox-Addington', 'Soc', 'IMPERFECT', 'SLOW', 3, 3, 'H', "SOLMESVILLE CLAY"],
	['Lennox-Addington', 'Spg', 'WELL', 'RAPID', 4, 1, 'L', "ST PETERS GRAVELLY SANDY LOAM"],
	['Lennox-Addington', 'Tis', 'WELL', 'RAPID', 4, 1, 'L', "TIOGA SANDY LOAM"],
	['Lennox-Addington', 'Was', 'POOR', 'SLOW', 4, 3, 'M', "WAUSEON SANDY LOAM"],
	['Lennox-Addington', 'Wcl', 'WELL', 'SLOW', 3, 3, 'M', "WAUPOOS CLAY LOAM"],
	['Lennox-Addington', 'Wes', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO LOAMY SAND"],
	['Lennox-Addington', 'Wes-R', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO LOAMY SAND"],
	['Lennox-Addington', 'WLs', 'WELL', 'RAPID', 4, 1, 'L', "WHITE LAKE GRAVELLY SANDY LOAM"],
	['Manitoulin Island', 'Basc', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "BASS CLAY"],
	['Manitoulin Island', 'Basc-st', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "BASS CLAY"],
	['Manitoulin Island', 'Bis', 'WELL', 'RAPID', 4, 1, 'L', "BRIDGEMAN SAND"],
	['Manitoulin Island', 'Bul', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD LOAM"],
	['Manitoulin Island', 'Bul-sh', 'WELL', 'RAPID', 1, 4, 'L', "BURFORD LOAM"],
	['Manitoulin Island', 'Bur', 'POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Manitoulin Island', 'Buscz', 'WELL', 'SLOW', 2, 4, 'M', "BUZWAH SILTY CLAY LOAM"],
	['Manitoulin Island', 'Busl', 'WELL', 'RAPID', 4, 1, 'L', "BURFORD LOAM"],
	['Manitoulin Island', 'Buszc_st', 'WELL', 'SLOW', 2, 4, 'M', "BUZWAH SILTY CLAY LOAM"],
	['Manitoulin Island', 'Buzc', 'WELL', 'SLOW', 2, 4, 'M', "BUZWAH CLAY LOAM"],
	['Manitoulin Island', 'Buzc-sh', 'WELL', 'SLOW', 1, 4, 'M', "BUZWAH CLAY LOAM"],
	['Manitoulin Island', 'Bxl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRISBANE LOAM"],
	['Manitoulin Island', 'Bzfs', 'WELL', 'MODERATE', 2, 4, 'L', "BUCKE FINE SANDY LOAM"],
	['Manitoulin Island', 'Bzs', 'WELL', 'MODERATE', 4, 2, 'L', "BUCKE SANDY LOAM"],
	['Manitoulin Island', 'Camc', 'WELL', 'SLOW', 2, 4, 'M', "CAMPBELL SILTY CLAY LOAM"],
	['Manitoulin Island', 'Camc-sh', 'WELL', 'SLOW', 1, 4, 'M', "CAMPBELL SILTY CLAY LOAM"],
	['Manitoulin Island', 'Camc-sh-st', 'WELL', 'SLOW', 1, 4, 'M', "CAMPBELL SILTY CLAY LOAM"],
	['Manitoulin Island', 'Camc-st', 'WELL', 'SLOW', 2, 4, 'M', "CAMPBELL SILTY CLAY LOAM"],
	['Manitoulin Island', 'Cns', 'POOR', 'VERY SLOW', 2, 3, 'H', "CANE SILT LOAM"],
	['Manitoulin Island', 'Cns-sh', 'POOR', 'VERY SLOW', 3, 3, 'H', "CANE SILT LOAM"],
	['Manitoulin Island', 'Cns-st', 'POOR', 'VERY SLOW', 2, 3, 'H', "CANE SILT LOAM"],
	['Manitoulin Island', 'Cys', 'IMPERFECT', 'MODERATE', 3, 4, 'M', "CASEY SILT LOAM"],
	['Manitoulin Island', 'Dbs', 'WELL', 'RAPID', 4, 1, 'L', "DONNYBROOK SANDY LOAM"],
	['Manitoulin Island', 'Dl', 'WELL', 'RAPID', 4, 2, 'L', "DUMFRIES LOAM"],
	['Manitoulin Island', 'Eas', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "EARLTON SILT LOAM"],
	['Manitoulin Island', 'Eas-sh', 'IMPERFECT', 'MODERATE', 2, 4, 'M', "EARLTON LOAM"],
	['Manitoulin Island', 'Eas-st', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "EARLTON SILT LOAM"],
	['Manitoulin Island', 'Enfs', 'POOR', 'SLOW', 3, 3, 'H', "ENGLEHART FINE SANDY LOAM"],
	['Manitoulin Island', 'Ens', 'POOR', 'SLOW', 4, 3, 'M', "ENGLEHART SANDY LOAM"],
	['Manitoulin Island', 'Evs', 'WELL', 'MODERATE', 1, 3, 'L', "EVANTUREL SILT LOAM"],
	['Manitoulin Island', 'Evs-sh', 'WELL', 'MODERATE', 1, 4, 'L', "EVANTUREL SILT LOAM"],
	['Manitoulin Island', 'Evs-st', 'WELL', 'MODERATE', 1, 3, 'L', "EVANTUREL SILT LOAM"],
	['Manitoulin Island', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Manitoulin Island', 'Gil', 'POOR', 'SLOW', 3, 3, 'H', "GILFORD LOAM"],
	['Manitoulin Island', 'Gis', 'POOR', 'SLOW', 4, 2, 'M', "GILFORD SANDY LOAM"],
	['Manitoulin Island', 'Gnc', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "GORDON SILTY CLAY LOAM"],
	['Manitoulin Island', 'Gnc-sh', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "GORDON SILTY CLAY LOAM"],
	['Manitoulin Island', 'Gnc-sh-st', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "GORDON SILTY CLAY LOAM"],
	['Manitoulin Island', 'Gnc-st', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "GORDON SILTY CLAY LOAM"],
	['Manitoulin Island', 'Hxl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "HOWLAND LOAM"],
	['Manitoulin Island', 'Hxl-sh', 'IMPERFECT', 'MODERATE', 2, 4, 'M', "HOWLAND LOAM"],
	['Manitoulin Island', 'Hxsl', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "HOWLAND SANDY LOAM"],
	['Manitoulin Island', 'Kas', 'WELL', 'MODERATE', 1, 4, 'L', "KAGAWONG SILT LOAM"],
	['Manitoulin Island', 'Knl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "KILLEAN LOAM"],
	['Manitoulin Island', 'Ksl', 'POOR', 'SLOW', 4, 2, 'M', "KENABEEK SANDY LOAM"],
	['Manitoulin Island', 'LCc', 'WELL', 'MODERATE', 2, 4, 'M', "LITTLE CURRENT CLAY"],
	['Manitoulin Island', 'Lhc', 'IMPERFECT', 'VERY SLOW', 3, 3, 'H', "LEECH CLAY LOAM"],
	['Manitoulin Island', 'Lhc-sh', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "LEECH CLAY LOAM"],
	['Manitoulin Island', 'Lhsc', 'IMPERFECT', 'VERY SLOW', 3, 3, 'H', "LEECH SILTY CLAY LOAM"],
	['Manitoulin Island', 'Lhsc-sh', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "LEECH SILTY CLAY LOAM"],
	['Manitoulin Island', 'Lhsc-st', 'IMPERFECT', 'VERY SLOW', 3, 3, 'H', "LEECH SILTY CLAY LOAM"],
	['Manitoulin Island', 'Ll', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Manitoulin Island', 'Ll-sh', 'POOR', 'SLOW', 3, 3, 'H', "LYONS LOAM"],
	['Manitoulin Island', 'Lyl', 'POOR', 'SLOW', 3, 3, 'H', "LILY LOAM"],
	['Manitoulin Island', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Manitoulin Island', 'Mal', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD SANDY LOAM"],
	['Manitoulin Island', 'Mal-sh', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "MALLARD SANDY LOAM"],
	['Manitoulin Island', 'Mal-st', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD SANDY LOAM"],
	['Manitoulin Island', 'Otfs', 'IMPERFECT', 'SLOW', 3, 4, 'M', "OTTERSKIN FINE SANDY LOAM"],
	['Manitoulin Island', 'Ots', 'IMPERFECT', 'SLOW', 3, 2, 'M', "OTTERSKIN SANDY LOAM"],
	['Manitoulin Island', 'Phc', 'POOR', 'VERY SLOW', 3, 3, 'H', "PHIPPS CLAY LOAM"],
	['Manitoulin Island', 'Phc-sh', 'POOR', 'VERY SLOW', 3, 4, 'H', "PHIPPS CLAY LOAM"],
	['Manitoulin Island', 'Phsc', 'POOR', 'VERY SLOW', 3, 3, 'H', "PHIPPS SILTY CLAY LOAM"],
	['Manitoulin Island', 'Phsc-st', 'POOR', 'VERY SLOW', 3, 3, 'H', "PHIPPS SILTY CLAY LOAM"],
	['Manitoulin Island', 'Pkc', 'WELL', 'SLOW', 3, 3, 'M', "PIKE CLAY"],
	['Manitoulin Island', 'Pkc-st', 'WELL', 'SLOW', 3, 3, 'M', "PIKE CLAY"],
	['Manitoulin Island', 'Prc', 'POOR', 'VERY SLOW', 3, 3, 'H', "PERCH CLAY"],
	['Manitoulin Island', 'Prc-st', 'POOR', 'VERY SLOW', 3, 3, 'H', "PERCH CLAY"],
	['Manitoulin Island', 'Ths', 'WELL', 'MODERATE', 2, 4, 'L', "THWATIES SILT LOAM"],
	['Manitoulin Island', 'Val', 'WELL', 'MODERATE', 1, 3, 'L', "VASEY LOAM"],
	['Manitoulin Island', 'Val-sh', 'WELL', 'MODERATE', 1, 4, 'L', "VASEY LOAM"],
	['Manitoulin Island', 'Wdfs', 'WELL', 'RAPID', 4, 2, 'L', "WENDIGO FINE SANDY LOAM"],
	['Manitoulin Island', 'Wdl', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO SANDY LOAM"],
	['Manitoulin Island', 'Wds', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO SAND"],
	['Manitoulin Island', 'Wyc', 'POOR', 'VERY SLOW', 3, 4, 'H', "WOLSEY SILTY CLAY LOAM"],
	['Manitoulin Island', 'Wyc-sh', 'POOR', 'VERY SLOW', 3, 4, 'H', "WOLSEY SILTY CLAY LOAM"],
	['Manitoulin Island', 'Wyc-sh-st', 'POOR', 'VERY SLOW', 3, 4, 'H', "WOLSEY SILTY CLAY LOAM"],
	['Manitoulin Island', 'Wyc-st', 'POOR', 'VERY SLOW', 3, 4, 'H', "WOLSEY SILTY CLAY LOAM"],
	['Middlesex', 'BAY', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SANDY LOAM"],
	['Middlesex', 'BCW', 'POOR', 'VERY SLOW', 3, 3, 'H', "BLACKWELL SILTY CLAY"],
	['Middlesex', 'BFO', 'WELL', 'SLOW', 2, 4, 'M', "BRANTFORD SILTY CLAY LOAM"],
	['Middlesex', 'BKN', 'POOR', 'VERY SLOW', 3, 3, 'H', "BROOKSTON SILTY CLAY LOAM"],
	['Middlesex', 'BLL', 'POOR', 'SLOW', 3, 3, 'M', "BALLYMOTE LOAM"],
	['Middlesex', 'BNG', 'WELL', 'MODERATE', 2, 4, 'L', "BENNINGTON SILT LOAM"],
	['Middlesex', 'BNG.T', 'WELL', 'MODERATE', 2, 4, 'L', "BENNINGTON SILT LOAM"],
	['Middlesex', 'BOO', 'WELL', 'MODERATE', 2, 4, 'L', "BOOKTON FINE SANDY LOAM"],
	['Middlesex', 'BOO.T', 'WELL', 'MODERATE', 2, 4, 'L', "BOOKTON FINE SANDY LOAM"],
	['Middlesex', 'BRR', 'IMPERFECT', 'SLOW', 2, 4, 'M', "BERRIEN FINE SANDY LOAM"],
	['Middlesex', 'BRR.T', 'IMPERFECT', 'SLOW', 2, 4, 'M', "BERRIEN FINE SANDY LOAM"],
	['Middlesex', 'BRT', 'WELL', 'MODERATE', 1, 3, 'L', "BRANT SILT LOAM"],
	['Middlesex', 'BRY', 'WELL', 'MODERATE', 1, 3, 'L', "BRYANSTON SILT LOAM"],
	['Middlesex', 'BUF', 'WELL', 'RAPID', 4, 1, 'L', "BURFORD GRAVELLY SANDY LOAM"],
	['Middlesex', 'BVY', 'IMPERFECT', 'SLOW', 3, 4, 'H', "BEVERLY SILTY CLAY LOAM"],
	['Middlesex', 'CAD', 'WELL', 'RAPID', 4, 1, 'L', "CALEDON SANDY LOAM"],
	['Middlesex', 'CMB', 'POOR', 'SLOW', 2, 3, 'M', "CROMBIE SILT LOAM"],
	['Middlesex', 'CML', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "CAMILLA SANDY LOAM"],
	['Middlesex', 'CWO', 'POOR', 'SLOW', 2, 3, 'M', "COLWOOD LOAM"],
	['Middlesex', 'EBR', 'IMPERFECT', 'SLOW', 2, 3, 'M', "EMBRO SILT LOAM"],
	['Middlesex', 'EKF', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "EKFRID SILTY CLAY"],
	['Middlesex', 'FAN', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "FANSHAWE SILT LOAM"],
	['Middlesex', 'FOX', 'WELL', 'RAPID', 4, 1, 'L', "FOX LOAMY SAND"],
	['Middlesex', 'GOB', 'IMPERFECT', 'SLOW', 3, 3, 'H', "GOBLES CLAY LOAM"],
	['Middlesex', 'HUO', 'WELL', 'SLOW', 2, 4, 'L', "HURON SILT LOAM"],
	['Middlesex', 'HYW', 'WELL', 'MODERATE', 1, 3, 'L', "HONEYWOOD SILT LOAM"],
	['Middlesex', 'KVN', 'POOR', 'SLOW', 3, 3, 'H', "KELVIN CLAY LOAM"],
	['Middlesex', 'MEL', 'WELL', 'SLOW', 2, 4, 'M', "MELBOURNE SILTY CLAY"],
	['Middlesex', 'MPW', 'POOR', 'SLOW', 2, 3, 'M', "MAPLEWOOD LOAM"],
	['Middlesex', 'MUI', 'WELL', 'SLOW', 2, 4, 'L', "MURIEL SILT LOAM"],
	['Middlesex', 'NDE', 'IMPERFECT', 'SLOW', 1, 3, 'L', "NORMANDALE FINE SANDY LOAM"],
	['Middlesex', 'NIS', 'POOR', 'SLOW', 2, 3, 'M', "NISSOURI SILT LOAM"],
	['Middlesex', 'OD', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC DEEP ORGANIC"],
	['Middlesex', 'OS', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC SHALLOW ORGANIC"],
	['Middlesex', 'OU', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC UNDIFFERENTIATED ORGANIC"],
	['Middlesex', 'PFD', 'WELL', 'RAPID', 4, 1, 'L', "PLAINFIELD FINE SAND"],
	['Middlesex', 'PTH', 'IMPERFECT', 'SLOW', 3, 3, 'H', "PERTH SILTY CLAY LOAM"],
	['Middlesex', 'SBN', 'POOR', 'VERY SLOW', 3, 3, 'H', "STRATHBURN SILTY CLAY"],
	['Middlesex', 'SIH', 'POOR', 'SLOW', 4, 2, 'M', "SILVER HILL SANDY LOAM"],
	['Middlesex', 'SIH.T', 'POOR', 'SLOW', 2, 3, 'M', "SILVER HILL SANDY LOAM"],
	['Middlesex', 'SLI', 'POOR', 'SLOW', 3, 3, 'M', "ST WILLIAMS VERY FINE SANDY LOAM"],
	['Middlesex', 'TEW', 'WELL', 'MODERATE', 4, 2, 'L', "TEESWATER SILT LOAM"],
	['Middlesex', 'THN', 'IMPERFECT', 'SLOW', 2, 3, 'M', "THORNDALE SILT LOAM"],
	['Middlesex', 'TLD', 'POOR', 'VERY SLOW', 3, 4, 'H', "TOLEDO SILTY CLAY LOAM"],
	['Middlesex', 'TUC', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA LOAM"],
	['Middlesex', 'TVK', 'IMPERFECT', 'SLOW', 2, 4, 'M', "TAVISTOCK LOAM"],
	['Middlesex', 'TVK.T', 'IMPERFECT', 'SLOW', 2, 4, 'M', "TAVISTOCK LOAM"],
	['Middlesex', 'VIT', 'IMPERFECT', 'SLOW', 2, 3, 'M', "VITTORIA FINE SANDY LOAM"],
	['Middlesex', 'VIT.T', 'IMPERFECT', 'SLOW', 3, 2, 'M', "VITTORIA FINE SANDY LOAM"],
	['Middlesex', 'WAM', 'IMPERFECT', 'RAPID', 4, 2, 'M', "WALSINGHAM LOAMY FINE SAND"],
	['Middlesex', 'WAT', 'WELL', 'SLOW', 1, 3, 'L', "WATTFORD FINE SANDY LOAM"],
	['Middlesex', 'WRN', 'POOR', 'SLOW', 4, 2, 'M', "WATERIN LOAMY FINE SAND"],
	['Middlesex', 'WSH', 'WELL', 'SLOW', 1, 3, 'L', "WALSHER FINE SANDY LOAM"],
	['Middlesex', 'WSH.T', 'WELL', 'SLOW', 1, 3, 'L', "WALSHER FINE SANDY LOAM"],
	['Middlesex', 'WUS', 'POOR', 'SLOW', 2, 3, 'M', "WAUSEON FINE SANDY LOAM"],
	['Middlesex', 'WUS.T', 'POOR', 'SLOW', 2, 3, 'M', "WAUSEON FINE SANDY LOAM"],
	['Muskoka', 'AYC', 'POOR', 'VERY SLOW', 3, 3, 'H', "ATHERLEY CLAY"],
	['Muskoka', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Muskoka', 'Tis', 'WELL', 'RAPID', 4, 1, 'L', "TIOGA LOAMY SAND"],
	['Niagara', 'BAY', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY LOAMY SAND"],
	['Niagara', 'BAY.R', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRADY FINE SANDY LOAM"],
	['Niagara', 'BFO', 'WELL', 'SLOW', 2, 4, 'M', "BRANTFORD SILTY CLAY LOAM"],
	['Niagara', 'BFO.L', 'WELL', 'SLOW', 2, 4, 'L', "BRANTFORD SILT LOAM"],
	['Niagara', 'BFO.LR', 'WELL', 'SLOW', 2, 4, 'L', "BRANTFORD SILTY CLAY LOAM"],
	['Niagara', 'BFO.R', 'WELL', 'SLOW', 2, 4, 'M', "BRANTFORD SILTY CLAY LOAM"],
	['Niagara', 'BNG', 'WELL', 'MODERATE', 2, 4, 'L', "BENNINGTON LOAM"],
	['Niagara', 'BNG.R', 'WELL', 'MODERATE', 2, 4, 'L', "BENNINGTON SANDY LOAM"],
	['Niagara', 'BOK.S', 'POOR', 'SLOW', 3, 4, 'H', "BROOKE CLAY LOAM"],
	['Niagara', 'BOK.V', 'POOR', 'SLOW', 3, 4, 'H', "BROOKE SILTY CLAY LOAM"],
	['Niagara', 'BOO', 'WELL', 'MODERATE', 2, 4, 'L', "BOOKTON FINE SANDY LOAM"],
	['Niagara', 'BOO.R', 'WELL', 'MODERATE', 2, 4, 'L', "BOOKTON LOAM"],
	['Niagara', 'BRR', 'IMPERFECT', 'SLOW', 3, 4, 'M', "BERRIEN VERY FINE SANDY LOAM"],
	['Niagara', 'BRR.R', 'IMPERFECT', 'SLOW', 3, 4, 'M', "BERRIEN FINE SANDY LOAM"],
	['Niagara', 'BRR.T', 'IMPERFECT', 'SLOW', 2, 4, 'M', "BERRIEN FINE SANDY LOAM"],
	['Niagara', 'BRT', 'WELL', 'MODERATE', 1, 3, 'L', "BRANT SILT LOAM"],
	['Niagara', 'BRT.R', 'WELL', 'MODERATE', 1, 3, 'L', "BRANT SILT LOAM"],
	['Niagara', 'BVY', 'IMPERFECT', 'SLOW', 3, 4, 'H', "BEVERLY SILTY CLAY LOAM"],
	['Niagara', 'BVY.C', 'IMPERFECT', 'SLOW', 3, 4, 'M', "BEVERLY FINE SANDY LOAM"],
	['Niagara', 'BVY.L', 'IMPERFECT', 'SLOW', 3, 4, 'M', "BEVERLY SILT LOAM"],
	['Niagara', 'BVY.LR', 'IMPERFECT', 'SLOW', 3, 4, 'M', "BEVERLY SILT LOAM"],
	['Niagara', 'BVY.R', 'IMPERFECT', 'SLOW', 3, 4, 'H', "BEVERLY SILT LOAM"],
	['Niagara', 'CGU', 'IMPERFECT', 'SLOW', 3, 3, 'H', "CHINGUACOUSY CLAY LOAM"],
	['Niagara', 'CGU.C', 'IMPERFECT', 'SLOW', 2, 4, 'M', "CHINGUACOUSY FINE SANDY LOAM"],
	['Niagara', 'CGU.L', 'IMPERFECT', 'SLOW', 2, 4, 'M', "CHINGUACOUSY SILT LOAM"],
	['Niagara', 'CGU.LR', 'IMPERFECT', 'SLOW', 2, 4, 'M', "CHINGUACOUSY CLAY LOAM"],
	['Niagara', 'CGU.R', 'IMPERFECT', 'SLOW', 3, 3, 'H', "CHINGUACOUSY CLAY LOAM"],
	['Niagara', 'CGU.RW', 'IMPERFECT', 'SLOW', 2, 3, 'M', "CHINGUACOUSY LOAM"],
	['Niagara', 'CGU.W', 'IMPERFECT', 'SLOW', 2, 3, 'M', "CHINGUACOUSY LOAM"],
	['Niagara', 'CSH', 'WELL', 'SLOW', 3, 3, 'M', "CASHEL SILTY CLAY LOAM"],
	['Niagara', 'CSH.HR', 'WELL', 'SLOW', 3, 3, 'M', "CASHEL CLAY"],
	['Niagara', 'CSH.R', 'WELL', 'SLOW', 2, 4, 'M', "CASHEL CLAY"],
	['Niagara', 'CSH.W', 'WELL', 'SLOW', 3, 3, 'M', "CASHEL LOAM"],
	['Niagara', 'CWO', 'POOR', 'SLOW', 2, 3, 'H', "COLWOOD LOAM"],
	['Niagara', 'CWO.R', 'POOR', 'SLOW', 2, 3, 'H', "COLWOOD SILT LOAM"],
	['Niagara', 'FKW.S', 'IMPERFECT', 'MODERATE', 2, 4, 'M', "FRANKTOWN LOAM"],
	['Niagara', 'FKW.V', 'IMPERFECT', 'MODERATE', 2, 4, 'M', "FRANKTOWN LOAM"],
	['Niagara', 'FMB', 'POOR', 'SLOW', 3, 3, 'H', "FLAMBOROUGH VERY FINE SANDY LOAM"],
	['Niagara', 'FMB.B', 'POOR', 'SLOW', 3, 3, 'H', "FLAMBOROUGH VERY FINE SANDY LOAM"],
	['Niagara', 'FNT', 'WELL', 'RAPID', 4, 1, 'L', "FONTHILL COARSE SANDY LOAM"],
	['Niagara', 'FOX', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Niagara', 'FOX.R', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Niagara', 'FRM', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON SILTY CLAY"],
	['Niagara', 'FRM.S', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Niagara', 'FRM.V', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Niagara', 'GMY', 'WELL', 'RAPID', 4, 2, 'L', "FLAMBOROUGH VERY FINE SANDY LOAM"],
	['Niagara', 'GMY.B', 'WELL', 'RAPID', 4, 2, 'L', "GRIMSBY VERY FINE SANDY LOAM"],
	['Niagara', 'GNY', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Niagara', 'GNY.PR', 'VERY POOR', 'SLOW', 3, 2, 'M', "GRANBY ORGANIC"],
	['Niagara', 'HIM', 'IMPERFECT', 'SLOW', 3, 4, 'H', "HALDIMAND SILTY CLAY"],
	['Niagara', 'HIM.C', 'IMPERFECT', 'SLOW', 3, 4, 'M', "HALDIMAND FINE SANDY LOAM"],
	['Niagara', 'HIM.L', 'IMPERFECT', 'SLOW', 3, 4, 'H', "HALDIMAND SILTY CLAY LOAM"],
	['Niagara', 'JDD', 'POOR', 'VERY SLOW', 3, 3, 'H', "JEDDO CLAY LOAM"],
	['Niagara', 'JDD.L', 'POOR', 'VERY SLOW', 3, 3, 'H', "JEDDO LOAM"],
	['Niagara', 'JDD.LR', 'POOR', 'VERY SLOW', 2, 3, 'H', "JEDDO LOAM"],
	['Niagara', 'JDD.R', 'POOR', 'VERY SLOW', 3, 4, 'H', "JEDDO SILTY CLAY LOAM"],
	['Niagara', 'JDD.RW', 'POOR', 'VERY SLOW', 2, 3, 'H', "JEDDO LOAM"],
	['Niagara', 'JDD.W', 'POOR', 'VERY SLOW', 2, 3, 'H', "JEDDO LOAM"],
	['Niagara', 'LIC', 'POOR', 'VERY SLOW', 3, 3, 'H', "LINCOLN CLAY"],
	['Niagara', 'LIC.C', 'POOR', 'VERY SLOW', 3, 3, 'H', "LINCOLN FINE SANDY LOAM"],
	['Niagara', 'LIC.L', 'POOR', 'VERY SLOW', 3, 4, 'H', "LINCOLN SILTY CLAY LOAM"],
	['Niagara', 'LIC.P', 'VERY POOR', 'SLOW', 3, 2, 'M', "LINCOLN ORGANIC"],
	['Niagara', 'MAT', 'POOR', 'VERY SLOW', 3, 3, 'H', "MALTON SILTY CLAY LOAM"],
	['Niagara', 'MAT.H', 'POOR', 'VERY SLOW', 3, 3, 'H', "MALTON SILTY CLAY LOAM"],
	['Niagara', 'MAT.HR', 'POOR', 'VERY SLOW', 3, 3, 'H', "MALTON SILTY CLAY"],
	['Niagara', 'MAT.LR', 'POOR', 'VERY SLOW', 3, 3, 'H', "MALTON SILTY CLAY LOAM"],
	['Niagara', 'MAT.R', 'POOR', 'VERY SLOW', 3, 3, 'H', "MALTON SILTY CLAY LOAM"],
	['Niagara', 'MOY', 'POOR', 'VERY SLOW', 3, 3, 'H', "MORLEY SILTY CLAY"],
	['Niagara', 'MOY.S', 'POOR', 'SLOW', 3, 4, 'H', "MORLEY SILTY CLAY LOAM"],
	['Niagara', 'MPW', 'POOR', 'SLOW', 2, 3, 'H', "MAPLEWOOD LOAM"],
	['Niagara', 'MPW.R', 'POOR', 'SLOW', 3, 3, 'H', "MAPLEWOOD LOAM"],
	['Niagara', 'MPW.T', 'POOR', 'SLOW', 2, 3, 'H', "MAPLEWOOD LOAM"],
	['Niagara', 'MPW.TR', 'POOR', 'SLOW', 2, 3, 'H', "MAPLEWOOD SILT LOAM"],
	['Niagara', 'NGR', 'IMPERFECT', 'SLOW', 3, 4, 'H', "NIAGARA SILTY CLAY LOAM"],
	['Niagara', 'NGR.L', 'IMPERFECT', 'SLOW', 3, 4, 'H', "NIAGARA SILTY CLAY LOAM"],
	['Niagara', 'OID', 'WELL', 'SLOW', 2, 4, 'M', "ONEIDA CLAY LOAM"],
	['Niagara', 'OID.L', 'WELL', 'SLOW', 2, 4, 'L', "ONEIDA LOAM"],
	['Niagara', 'OID.LR', 'WELL', 'SLOW', 2, 4, 'L', "ONEIDA LOAM"],
	['Niagara', 'OID.R', 'WELL', 'SLOW', 2, 4, 'M', "ONEIDA CLAY LOAM"],
	['Niagara', 'OID.RW', 'WELL', 'SLOW', 2, 4, 'L', "ONEIDA LOAM"],
	['Niagara', 'OID.W', 'WELL', 'SLOW', 1, 3, 'L', "ONEIDA LOAM"],
	['Niagara', 'OTI', 'WELL', 'SLOW', 3, 3, 'M', "ONTARIO SILTY CLAY"],
	['Niagara', 'PEL', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PEEL SILTY CLAY LOAM"],
	['Niagara', 'PEL.C', 'IMPERFECT', 'SLOW', 3, 4, 'M', "PEEL FINE SANDY LOAM"],
	['Niagara', 'PEL.H', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PEEL SILTY CLAY"],
	['Niagara', 'PEL.HR', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PEEL SILTY CLAY"],
	['Niagara', 'PEL.L', 'IMPERFECT', 'SLOW', 2, 4, 'M', "PEEL SILT LOAM"],
	['Niagara', 'PEL.LR', 'IMPERFECT', 'SLOW', 3, 4, 'M', "PEEL LOAM"],
	['Niagara', 'PEL.R', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PEEL SILTY CLAY"],
	['Niagara', 'PFD', 'WELL', 'RAPID', 4, 1, 'L', "PLAINFIELD FINE SAND"],
	['Niagara', 'PFD.D', 'WELL', 'RAPID', 4, 1, 'L', "PLAINFIELD FINE SAND"],
	['Niagara', 'RVE', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "RIDGEVILLE FINE SANDY LOAM"],
	['Niagara', 'SHV', 'WELL', 'SLOW', 3, 3, 'M', "SMITHVILLE SILTY CLAY"],
	['Niagara', 'SHV.L', 'WELL', 'SLOW', 2, 4, 'M', "SMITHVILLE SILTY CLAY LOAM"],
	['Niagara', 'SIH', 'POOR', 'MODERATE', 2, 3, 'H', "SILVER HILL FINE SANDY LOAM"],
	['Niagara', 'TFG', 'IMPERFECT', 'MODERATE', 3, 3, 'H', "TRAFALGAR SILTY CLAY LOAM"],
	['Niagara', 'TFG.L', 'IMPERFECT', 'MODERATE', 2, 4, 'M', "TRAFALGAR LOAM"],
	['Niagara', 'TFG.S', 'IMPERFECT', 'RAPID', 3, 4, 'H', "TRAFALGAR SILTY CLAY LOAM"],
	['Niagara', 'TLD', 'POOR', 'VERY SLOW', 3, 4, 'H', "TOLEDO SILTY CLAY LOAM"],
	['Niagara', 'TLD.C', 'POOR', 'VERY SLOW', 2, 3, 'H', "TOLEDO VERY FINE SANDY LOAM"],
	['Niagara', 'TLD.L', 'POOR', 'VERY SLOW', 3, 3, 'H', "TOLEDO LOAM"],
	['Niagara', 'TLD.LR', 'POOR', 'VERY SLOW', 3, 3, 'H', "TOLEDO LOAM"],
	['Niagara', 'TLD.P', 'VERY POOR', 'SLOW', 3, 2, 'M', "TOLEDO ORGANIC"],
	['Niagara', 'TLD.R', 'POOR', 'VERY SLOW', 3, 4, 'H', "TOLEDO SILTY CLAY LOAM"],
	['Niagara', 'TUC', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA LOAM"],
	['Niagara', 'TUC.R', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA LOAM"],
	['Niagara', 'TVK', 'IMPERFECT', 'SLOW', 3, 4, 'M', "TAVISTOCK LOAM"],
	['Niagara', 'TVK.H', 'IMPERFECT', 'SLOW', 3, 4, 'M', "TAVISTOCK LOAM"],
	['Niagara', 'TVK.R', 'IMPERFECT', 'SLOW', 2, 4, 'M', "TAVISTOCK VERY FINE SANDY LOAM"],
	['Niagara', 'TVK.T', 'IMPERFECT', 'SLOW', 3, 4, 'M', "TAVISTOCK LOAM"],
	['Niagara', 'TVK.TR', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TAVISTOCK VERY FINE SANDY LOAM"],
	['Niagara', 'VIT', 'IMPERFECT', 'SLOW', 2, 3, 'M', "VITTORIA VERY FINE SANDY LOAM"],
	['Niagara', 'VIT.R', 'IMPERFECT', 'SLOW', 2, 3, 'M', "VITTORIA VERY FINE SANDY LOAM"],
	['Niagara', 'VLD', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "VINELAND VERY FINE SANDY LOAM"],
	['Niagara', 'VLD.B', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "VINELAND VERY FINE SANDY LOAM"],
	['Niagara', 'WAM', 'IMPERFECT', 'RAPID', 4, 2, 'M', "WALSINGHAM VERY FINE SANDY LOAM"],
	['Niagara', 'WLL', 'POOR', 'VERY SLOW', 3, 3, 'H', "MALTON SILTY CLAY"],
	['Niagara', 'WLL.L', 'POOR', 'VERY SLOW', 3, 4, 'H', "WELLAND CLAY LOAM"],
	['Niagara', 'WLL.P', 'VERY POOR', 'SLOW', 3, 2, 'M', "WELLAND ORGANIC"],
	['Niagara', 'WSH', 'WELL', 'SLOW', 1, 3, 'L', "WALSHER VERY FINE SANDY LOAM"],
	['Niagara', 'WUS', 'POOR', 'SLOW', 3, 3, 'H', "WAUSEON FINE SANDY LOAM"],
	['Nipissing', 'Asil', 'POOR', 'VERY SLOW', 2, 3, 'H', "AZILDA SILT LOAM"],
	['Nipissing', 'Asl', 'POOR', 'SLOW', 4, 3, 'M', "AZILDA SANDY LOAM"],
	['Nipissing', 'Bbs', 'POOR', 'SLOW', 4, 2, 'M', "BALMER BAY SAND"],
	['Nipissing', 'Bfl', 'POOR', 'VERY SLOW', 3, 3, 'H', "BONFIELD LOAM"],
	['Nipissing', 'BH', 'VERY POOR', 'SLOW', 3, 2, 'M', "BLYTHE ORGANIC"],
	['Nipissing', 'Bnsil', 'POOR', 'VERY SLOW', 3, 3, 'H', "BAIN SILT LOAM"],
	['Nipissing', 'Bnsl', 'POOR', 'SLOW', 4, 2, 'M', "BAIN SANDY LOAM"],
	['Nipissing', 'Brl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRADLEY LOAM"],
	['Nipissing', 'Brsil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRADLEY SILT LOAM"],
	['Nipissing', 'Brsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADLEY SANDY LOAM"],
	['Nipissing', 'Brvfl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRADLEY VERY FINE SANDY LOAM"],
	['Nipissing', 'Btsil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "BOULTER SILT LOAM"],
	['Nipissing', 'Bx', 'VERY POOR', 'SLOW', 3, 2, 'M', "BURTON ORGANIC"],
	['Nipissing', 'Cacl', 'POOR', 'VERY SLOW', 3, 4, 'H', "CASIMAR CLAY LOAM"],
	['Nipissing', 'Cdfl', 'IMPERFECT', 'SLOW', 2, 4, 'M', "CEDAR FINE SANDY LOAM"],
	['Nipissing', 'Cdl', 'IMPERFECT', 'SLOW', 2, 4, 'M', "CEDAR LOAM"],
	['Nipissing', 'Cf', 'VERY POOR', 'SLOW', 3, 2, 'M', "CRYSTAL FALLS ORGANIC"],
	['Nipissing', 'Ci', 'VERY POOR', 'SLOW', 3, 2, 'M', "CHISWICK ORGANIC"],
	['Nipissing', 'Cl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CHARTRAND LOAM"],
	['Nipissing', 'Cnsl', 'POOR', 'SLOW', 4, 2, 'M', "CHERRIMAN SANDY LOAM"],
	['Nipissing', 'Cocl', 'POOR', 'VERY SLOW', 3, 3, 'H', "COLLINS CLAY LOAM"],
	['Nipissing', 'Col', 'POOR', 'VERY SLOW', 2, 3, 'H', "COLLINS LOAM"],
	['Nipissing', 'Cor', 'VERY POOR', 'SLOW', 3, 2, 'M', "CORBEIL ORGANIC"],
	['Nipissing', 'Cosic', 'POOR', 'VERY SLOW', 3, 3, 'H', "COLLINS SILTY CLAY"],
	['Nipissing', 'Cosil', 'POOR', 'VERY SLOW', 2, 3, 'H', "COLLINS SILT LOAM"],
	['Nipissing', 'Cpsil', 'POOR', 'SLOW', 3, 3, 'H', "CAPREOL SILT LOAM"],
	['Nipissing', 'Cpvfl', 'POOR', 'SLOW', 3, 3, 'H', "CAPREOL VERY FINE SANDY LOAM"],
	['Nipissing', 'Crsl', 'WELL', 'RAPID', 4, 1, 'L', "CUTLER SANDY LOAM"],
	['Nipissing', 'Csil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CHARTRAND SILT LOAM"],
	['Nipissing', 'Csl', 'IMPERFECT', 'SLOW', 3, 2, 'M', "CHARTRAND SANDY LOAM"],
	['Nipissing', 'Cysl', 'POOR', 'SLOW', 4, 2, 'M', "CHRISTY SANDY LOAM"],
	['Nipissing', 'Dhy', 'VERY POOR', 'SLOW', 3, 2, 'M', "DUCHESNAY ORGANIC"],
	['Nipissing', 'Dl', 'WELL', 'SLOW', 2, 4, 'L', "DELAMERE LOAM"],
	['Nipissing', 'Dnl', 'WELL', 'SLOW', 2, 4, 'L', "DUNNET LOAM"],
	['Nipissing', 'Dofl', 'WELL', 'RAPID', 4, 2, 'L', "DOKISE FINE SANDY LOAM"],
	['Nipissing', 'Dol', 'WELL', 'RAPID', 4, 2, 'L', "DOKISE LOAM"],
	['Nipissing', 'Dos', 'WELL', 'RAPID', 4, 1, 'L', "DOKISE SAND"],
	['Nipissing', 'Dosl', 'WELL', 'RAPID', 4, 1, 'L', "DOKISE SANDY LOAM"],
	['Nipissing', 'Dr', 'VERY POOR', 'SLOW', 3, 2, 'M', "DRURY ORGANIC"],
	['Nipissing', 'Dsil', 'WELL', 'SLOW', 2, 4, 'L', "DELAMERE SILT LOAM"],
	['Nipissing', 'Dsl', 'WELL', 'MODERATE', 4, 2, 'L', "DELAMERE SANDY LOAM"],
	['Nipissing', 'Ensil', 'POOR', 'VERY SLOW', 3, 3, 'H', "ENGLEHART SILT LOAM"],
	['Nipissing', 'Ensl', 'POOR', 'SLOW', 4, 3, 'M', "ENGLEHART SANDY LOAM"],
	['Nipissing', 'Es', 'VERY POOR', 'SLOW', 3, 2, 'M', "ESPANOLA ORGANIC"],
	['Nipissing', 'FL', 'VERY POOR', 'SLOW', 3, 2, 'M', "FRERE LAKE ORGANIC"],
	['Nipissing', 'Frsil', 'WELL', 'SLOW', 2, 4, 'L', "FRECHETTE SILT LOAM"],
	['Nipissing', 'Ge', 'VERY POOR', 'SLOW', 3, 2, 'M', "GENESEE ORGANIC"],
	['Nipissing', 'Har', 'VERY POOR', 'SLOW', 3, 2, 'M', "HAROLD ORGANIC"],
	['Nipissing', 'Hdcl', 'POOR', 'VERY SLOW', 3, 4, 'H', "HADDO CLAY LOAM"],
	['Nipissing', 'Hdsil', 'POOR', 'VERY SLOW', 3, 3, 'H', "HADDO SILT LOAM"],
	['Nipissing', 'Hhl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "HAMMERHEAD LOAM"],
	['Nipissing', 'Hsil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "HIMSWORTH SILT LOAM"],
	['Nipissing', 'Jcl', 'POOR', 'VERY SLOW', 3, 4, 'H', "JAMOT CLAY LOAM"],
	['Nipissing', 'Jsil', 'POOR', 'VERY SLOW', 3, 3, 'H', "JAMOT SILT LOAM"],
	['Nipissing', 'Jvfl', 'POOR', 'VERY SLOW', 3, 3, 'H', "JAMOT VERY FINE SANDY LOAM"],
	['Nipissing', 'Ke', 'VERY POOR', 'SLOW', 3, 2, 'M', "KELLAR ORGANIC"],
	['Nipissing', 'Kfs', 'POOR', 'SLOW', 4, 2, 'M', "KENABEEK FINE SAND"],
	['Nipissing', 'Kgs', 'POOR', 'SLOW', 4, 2, 'M', "KENABEEK GRAVELLY SAND"],
	['Nipissing', 'Ksl', 'POOR', 'SLOW', 4, 2, 'M', "KENABEEK SANDY LOAM"],
	['Nipissing', 'Ma', 'VERY POOR', 'SLOW', 3, 2, 'M', "MARSH ORGANIC"],
	['Nipissing', 'Mdfl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MALLARD FINE SANDY LOAM"],
	['Nipissing', 'Mdfs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD FINE SAND"],
	['Nipissing', 'Mdgsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD GRAVELLY SANDY LOAM"],
	['Nipissing', 'Mds', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD SAND"],
	['Nipissing', 'Mdsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD SANDY LOAM"],
	['Nipissing', 'Mefl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MEDETTE FINE SANDY LOAM"],
	['Nipissing', 'Mesl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MEDETTE SANDY LOAM"],
	['Nipissing', 'Mf', 'VERY POOR', 'SLOW', 3, 2, 'M', "MILFORD ORGANIC"],
	['Nipissing', 'Mfl', 'WELL', 'RAPID', 4, 2, 'L', "MONTEAGLE FINE SANDY LOAM"],
	['Nipissing', 'Mkgl', 'WELL', 'RAPID', 4, 1, 'L', "MUSKOSUNG GRAVELLY LOAM"],
	['Nipissing', 'Mkgsl', 'WELL', 'RAPID', 4, 1, 'L', "MUSKOSUNG GRAVELLY SANDY LOAM"],
	['Nipissing', 'Mks', 'WELL', 'RAPID', 4, 1, 'L', "MUSKOSUNG SAND"],
	['Nipissing', 'Msl', 'WELL', 'RAPID', 4, 1, 'L', "MONTEAGLE SANDY LOAM"],
	['Nipissing', 'Mufl', 'POOR', 'SLOW', 3, 3, 'H', "MUSSELLE FINE SANDY LOAM"],
	['Nipissing', 'Mufs', 'POOR', 'SLOW', 4, 2, 'M', "MUSSELLE FINE SAND"],
	['Nipissing', 'Mwsil', 'WELL', 'SLOW', 2, 4, 'L', "MAGNETAWAN SILT LOAM"],
	['Nipissing', 'Mwsl', 'WELL', 'MODERATE', 4, 2, 'L', "MAGNETAWAN SANDY LOAM"],
	['Nipissing', 'Nl', 'IMPERFECT', 'SLOW', 2, 4, 'M', "NOELVILLE LOAM"],
	['Nipissing', 'Nnvfl', 'WELL', 'RAPID', 4, 2, 'L', "NAIDEN VERY FINE SANDY LOAM"],
	['Nipissing', 'O', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC ORGANIC"],
	['Nipissing', 'Oul', 'POOR', 'VERY SLOW', 3, 3, 'H', "OUELLETTE LOAM"],
	['Nipissing', 'Ousl', 'POOR', 'SLOW', 4, 3, 'M', "OUELLETTE SANDY LOAM"],
	['Nipissing', 'Ped', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEDLOW ORGANIC"],
	['Nipissing', 'Phgl', 'WELL', 'RAPID', 4, 2, 'L', "PHELANS GRAVELLY LOAM"],
	['Nipissing', 'Posil', 'POOR', 'VERY SLOW', 2, 3, 'H', "POWASSAN SILT LOAM"],
	['Nipissing', 'Pysil', 'POOR', 'VERY SLOW', 2, 3, 'H', "PLAYFAIR SILT LOAM"],
	['Nipissing', 'Rbsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RIDOUT SANDY LOAM"],
	['Nipissing', 'Rf', 'VERY POOR', 'SLOW', 3, 2, 'M', "RAFT LAKE ORGANIC"],
	['Nipissing', 'Sfl', 'POOR', 'SLOW', 3, 3, 'H', "SMOKE FINE SANDY LOAM"],
	['Nipissing', 'Sl', 'POOR', 'SLOW', 3, 3, 'H', "SMOKE LOAM"],
	['Nipissing', 'Spg', 'POOR', 'SLOW', 4, 3, 'M', "SAN PIERRE GRAVEL"],
	['Nipissing', 'Spgsl', 'POOR', 'SLOW', 4, 3, 'M', "SAN PIERRE GRAVELLY SANDY LOAM"],
	['Nipissing', 'Spr', 'VERY POOR', 'SLOW', 3, 2, 'M', "SPRINGER LAKE ORGANIC"],
	['Nipissing', 'Stcl', 'WELL', 'SLOW', 4, 4, 'M', "STINSON CLAY LOAM"],
	['Nipissing', 'Stl', 'WELL', 'SLOW', 2, 4, 'L', "STINSON LOAM"],
	['Nipissing', 'Stsil', 'WELL', 'SLOW', 2, 4, 'L', "STINSON SILT LOAM"],
	['Nipissing', 'Tofl', 'POOR', 'VERY SLOW', 2, 3, 'H', "TOMIKO FINE SANDY LOAM"],
	['Nipissing', 'Tsi1', 'WELL', 'RAPID', 4, 2, 'L', "THISTLE SILT LOAM"],
	['Nipissing', 'Tvfl', 'WELL', 'RAPID', 4, 2, 'L', "THISTLE VERY FINE SANDY LOAM"],
	['Nipissing', 'Vnfs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "VERNER FINE SAND"],
	['Nipissing', 'Vsil', 'IMPERFECT', 'SLOW', 2, 4, 'M', "VEUVE RIVER SILT LOAM"],
	['Nipissing', 'Wafl', 'WELL', 'RAPID', 4, 2, 'L', "WAUSING FINE SANDY LOAM"],
	['Nipissing', 'Was', 'WELL', 'RAPID', 4, 1, 'L', "WAUSING SAND"],
	['Nipissing', 'Wasl', 'WELL', 'RAPID', 4, 1, 'L', "WAUSING SANDY LOAM"],
	['Nipissing', 'Wdfl', 'WELL', 'RAPID', 4, 2, 'L', "WENDIGO FINE SANDY LOAM"],
	['Nipissing', 'Wdsl', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO SANDY LOAM"],
	['Nipissing', 'Wesl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "WEMYSS SANDY LOAM"],
	['Nipissing', 'Wl', 'POOR', 'VERY SLOW', 2, 3, 'H', "WOLF LOAM"],
	['Nipissing', 'Wofl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "WOLSELEY FINE SANDY LOAM"],
	['Nipissing', 'Wosil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "WOLSELEY SILT LOAM"],
	['Nipissing', 'Wrfl', 'POOR', 'SLOW', 3, 3, 'H', "WARREN FINE SANDY LOAM"],
	['Nipissing', 'Wsl', 'POOR', 'SLOW', 4, 3, 'M', "WOLF SANDY LOAM"],
	['Nipissing', 'Ye', 'VERY POOR', 'SLOW', 3, 2, 'M', "YELLEK ORGANIC"],
	['Northumberland', 'Al', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ALLISTON SANDY LOAM"],
	['Northumberland', 'B', 'WELL', 'MODERATE', 4, 2, 'L', "BONDHEAD LOAM"],
	['Northumberland', 'Ba', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BAMFORD SANDY LOAM"],
	['Northumberland', 'Bb', 'WELL', 'MODERATE', 1, 4, 'L', "BURNBRAE LOAM"],
	['Northumberland', 'Be', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['Northumberland', 'Bk', 'POOR', 'SLOW', 3, 3, 'H', "BROOKE LOAM"],
	['Northumberland', 'Bo', 'WELL', 'MODERATE', 4, 2, 'L', "BOOKTON SANDY LOAM"],
	['Northumberland', 'Bp', 'WELL', 'RAPID', 1, 4, 'L', "BONDHEAD LOAM"],
	['Northumberland', 'Br', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON SANDY LOAM"],
	['Northumberland', 'Bs', 'WELL', 'MODERATE', 4, 1, 'L', "BONDHEAD SANDY LOAM"],
	['Northumberland', 'Cd', 'IMPERFECT', 'SLOW', 2, 3, 'M', "CODRINGTON SILT LOAM"],
	['Northumberland', 'Co', 'WELL', 'RAPID', 4, 1, 'L', "COLBORNE SANDY LOAM"],
	['Northumberland', 'Cs', 'WELL', 'RAPID', 4, 1, 'L', "CRAMAHE SANDY LOAM"],
	['Northumberland', 'D', 'WELL', 'MODERATE', 1, 3, 'L', "DUMMER LOAM"],
	['Northumberland', 'Dp', 'WELL', 'MODERATE', 1, 4, 'L', "DUMMER LOAM"],
	['Northumberland', 'Ds', 'WELL', 'MODERATE', 4, 1, 'L', "DUMMER SANDY LOAM"],
	['Northumberland', 'Du', 'WELL', 'MODERATE', 4, 1, 'M', "DUNDONALD SANDY LOAM"],
	['Northumberland', 'Ec', 'IMPERFECT', 'SLOW', 3, 4, 'H', "ELMBROOK CLAY"],
	['Northumberland', 'Em', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "EMILY LOAM"],
	['Northumberland', 'Es', 'IMPERFECT', 'SLOW', 4, 2, 'M', "EDENVALE SANDY LOAM"],
	['Northumberland', 'F', 'WELL', 'MODERATE', 1, 4, 'M', "FARMINGTON CLAY LOAM"],
	['Northumberland', 'Fa', 'POOR', 'SLOW', 2, 3, 'H', "FOXBORO FINE SANDY LOAM"],
	['Northumberland', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Northumberland', 'G', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "GUERIN LOAM"],
	['Northumberland', 'Gi', 'POOR', 'SLOW', 3, 3, 'H', "GILFORD LOAM"],
	['Northumberland', 'Gr', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Northumberland', 'Gs', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "GUERIN SANDY LOAM"],
	['Northumberland', 'Ly', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Northumberland', 'Mi', 'POOR', 'SLOW', 4, 2, 'M', "MILL SANDY LOAM"],
	['Northumberland', 'Mn', 'IMPERFECT', 'SLOW', 2, 3, 'M', "MATSON SILT LOAM"],
	['Northumberland', 'Mu', 'IMPERFECT', 'SLOW', 2, 3, 'M', "MURRAY SILT LOAM"],
	['Northumberland', 'Ne', 'WELL', 'MODERATE', 1, 3, 'L', "NEWCASTLE SILT LOAM"],
	['Northumberland', 'No', 'WELL', 'MODERATE', 2, 4, 'L', "NORHAM SILT LOAM"],
	['Northumberland', 'O', 'WELL', 'MODERATE', 1, 3, 'L', "MURRAY SILT LOAM"],
	['Northumberland', 'Op', 'WELL', 'RAPID', 1, 4, 'L', "OTONABEE LOAM"],
	['Northumberland', 'Os', 'WELL', 'MODERATE', 4, 1, 'L', "OTONABEE SANDY LOAM"],
	['Northumberland', 'P', 'WELL', 'RAPID', 4, 1, 'L', "PONTYPOOL GRAVELLY SAND"],
	['Northumberland', 'Pe', 'WELL', 'MODERATE', 4, 2, 'L', "PERCY FINE SANDY LOAM"],
	['Northumberland', 'Pk', 'POOR', 'SLOW', 2, 3, 'H', "PETHERWICK SILT LOAM"],
	['Northumberland', 'Pl', 'WELL', 'RAPID', 4, 1, 'L', "PERCY LOAM"],
	['Northumberland', 'Ps', 'WELL', 'RAPID', 4, 1, 'L', "PONTYPOOL SAND"],
	['Northumberland', 'S', 'WELL', 'SLOW', 2, 4, 'M', "SCHOMBERG SILTY CLAY LOAM"],
	['Northumberland', 'Sd', 'IMPERFECT', 'SLOW', 3, 4, 'M', "SMITHFIELD SILT LOAM"],
	['Northumberland', 'Se', 'POOR', 'VERY SLOW', 3, 3, 'H', "SIMCOE SILT LOAM"],
	['Northumberland', 'Sh', 'WELL', 'SLOW', 2, 4, 'L', "SCHOMBERG SILT LOAM"],
	['Northumberland', 'Si', 'POOR', 'VERY SLOW', 3, 4, 'H', "SIMCOE SILTY CLAY LOAM"],
	['Northumberland', 'Sm', 'IMPERFECT', 'SLOW', 3, 4, 'H', "SMITHFIELD SILTY CLAY LOAM"],
	['Northumberland', 'St', 'POOR', 'SLOW', 2, 3, 'H', "STOCKDALE SILT LOAM"],
	['Northumberland', 'Sy', 'POOR', 'VERY SLOW', 3, 3, 'H', "SIDNEY CLAY"],
	['Northumberland', 'Te', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "TECUMSETH SANDY LOAM"],
	['Northumberland', 'Ti', 'WELL', 'RAPID', 4, 1, 'L', "TIOGA SANDY LOAM"],
	['Northumberland', 'Tp', 'WELL', 'RAPID', 3, 2, 'L', "TIOGA SANDY LOAM"],
	['Northumberland', 'Tr', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TRENT FINE SANDY LOAM"],
	['Northumberland', 'Wa', 'POOR', 'SLOW', 4, 3, 'M', "WAUSEON SANDY LOAM"],
	['Northumberland', 'Wo', 'WELL', 'MODERATE', 1, 3, 'L', "WOOLER SILT LOAM"],
	['Ontario', 'Aycl', 'POOR', 'VERY SLOW', 3, 4, 'H', "ATHERLEY CLAY LOAM"],
	['Ontario', 'Bl', 'WELL', 'MODERATE', 1, 3, 'L', "BONDHEAD LOAM"],
	['Ontario', 'Brsl', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON SANDY LOAM"],
	['Ontario', 'Brsl/g', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON SANDY LOAM"],
	['Ontario', 'Brsl-st', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON SANDY LOAM"],
	['Ontario', 'Bs', 'WELL', 'MODERATE', 4, 1, 'L', "BONDHEAD SANDY LOAM"],
	['Ontario', 'Dal', 'WELL', 'MODERATE', 2, 4, 'L', "DARLINGTON LOAM"],
	['Ontario', 'Dsl', 'WELL', 'MODERATE', 4, 1, 'L', "DUNDONALD SANDY LOAM"],
	['Ontario', 'El', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "EMILY LOAM"],
	['Ontario', 'El-sh', 'IMPERFECT', 'RAPID', 2, 4, 'M', "EMILY LOAM"],
	['Ontario', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Ontario', 'Gsl', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Ontario', 'Gul', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "GUERIN LOAM"],
	['Ontario', 'Ks', 'POOR', 'SLOW', 4, 2, 'M', "KENABEEK SANDY LOAM"],
	['Ontario', 'Lcl', 'IMPERFECT', 'SLOW', 3, 4, 'H', "LOVERING CLAY LOAM"],
	['Ontario', 'Ll', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Ontario', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Ontario', 'Ml', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MILLIKEN LOAM"],
	['Ontario', 'Ml-st', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MILLIKEN LOAM"],
	['Ontario', 'Ms', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD SANDY LOAM"],
	['Ontario', 'Ol', 'WELL', 'MODERATE', 1, 3, 'L', "OTONABEE LOAM"],
	['Ontario', 'Osl', 'WELL', 'MODERATE', 4, 1, 'L', "OTONABEE SANDY LOAM"],
	['Ontario', 'P', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEAT ORGANIC"],
	['Ontario', 'Pec', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PEEL CLAY LOAM"],
	['Ontario', 'Ps', 'WELL', 'RAPID', 4, 1, 'L', "PONTYPOOL SAND"],
	['Ontario', 'Psl', 'WELL', 'RAPID', 4, 1, 'L', "PONTYPOOL SANDY LOAM"],
	['Ontario', 'Scl', 'IMPERFECT', 'SLOW', 3, 4, 'H', "SMITHFIELD CLAY LOAM"],
	['Ontario', 'Sg', 'WELL', 'RAPID', 4, 1, 'L', "SARGENT SANDY LOAM"],
	['Ontario', 'Shc', 'WELL', 'SLOW', 2, 4, 'M', "SCHOMBERG CLAY LOAM"],
	['Ontario', 'Shs', 'WELL', 'SLOW', 2, 4, 'L', "SCHOMBERG SILT LOAM"],
	['Ontario', 'Sic', 'POOR', 'VERY SLOW', 3, 4, 'H', "SIMCOE CLAY LOAM"],
	['Ontario', 'Tfsl', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TRENT FINE SANDY LOAM"],
	['Ontario', 'Tsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "TECUMSETH SANDY LOAM"],
	['Ontario', 'Tsl-st', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "TECUMSETH SANDY LOAM"],
	['Ontario', 'Vl', 'WELL', 'MODERATE', 1, 3, 'L', "VASEY LOAM"],
	['Ontario', 'Vs', 'WELL', 'MODERATE', 4, 1, 'L', "VASEY SANDY LOAM"],
	['Ontario', 'Wes', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO SANDY LOAM"],
	['Ontario', 'Whl', 'IMPERFECT', 'MODERATE', 2, 4, 'M', "WHITBY LOAM"],
	['Ontario', 'Wol', 'WELL', 'MODERATE', 2, 4, 'L', "WOBURN LOAM"],
	['Ontario', 'Wos', 'WELL', 'MODERATE', 3, 2, 'L', "WOBURN SANDY LOAM"],
	['Ottawa Urban Fringe', 'A1', 'WELL', 'MODERATE', 3, 2, 'L', "ANSTRUTHER LOAMY SAND"],
	['Ottawa Urban Fringe', 'B1', 'IMPERFECT', 'SLOW', 3, 4, 'H', "WENDOVER CLAY"],
	['Ottawa Urban Fringe', 'B2', 'POOR', 'VERY SLOW', 3, 4, 'H', "BEARBROOK CLAY"],
	['Ottawa Urban Fringe', 'B3', 'POOR', 'VERY SLOW', 3, 4, 'H', "BEARBROOK - R.G CLAY"],
	['Ottawa Urban Fringe', 'B4', 'POOR', 'VERY SLOW', 3, 4, 'H', "BEARBROOK - VARIANT PHASE CLAY"],
	['Ottawa Urban Fringe', 'BE1', 'VERY POOR', 'SLOW', 3, 2, 'M', "BORROMEE ORGANIC"],
	['Ottawa Urban Fringe', 'C1', 'WELL', 'SLOW', 2, 4, 'L', "CASTOR - O.MB LOAM"],
	['Ottawa Urban Fringe', 'C2', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CASTOR LOAM"],
	['Ottawa Urban Fringe', 'C3', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CASTOR LOAM"],
	['Ottawa Urban Fringe', 'C4', 'POOR', 'VERY SLOW', 3, 3, 'H', "BAINSVILLE VERY FINE SANDY LOAM"],
	['Ottawa Urban Fringe', 'C5', 'POOR', 'VERY SLOW', 3, 3, 'H', "BAINSVILLE VERY FINE SANDY LOAM"],
	['Ottawa Urban Fringe', 'C5.S', 'POOR', 'MODERATE', 3, 3, 'H', "BAINSVILLE VERY FINE SANDY LOAM"],
	['Ottawa Urban Fringe', 'C6', 'VERY POOR', 'SLOW', 3, 2, 'M', "CASTOR LOAM"],
	['Ottawa Urban Fringe', 'CH1', 'WELL', 'SLOW', 2, 3, 'M', "CHATEAUGUAY LOAM"],
	['Ottawa Urban Fringe', 'CH2', 'IMPERFECT', 'SLOW', 2, 3, 'H', "CHATEAUGUAY LOAM"],
	['Ottawa Urban Fringe', 'CH3', 'POOR', 'SLOW', 3, 3, 'H', "MACDONALD CLAY LOAM"],
	['Ottawa Urban Fringe', 'CH4', 'IMPERFECT', 'SLOW', 2, 3, 'H', "CHATEAUGUAY LOAM"],
	['Ottawa Urban Fringe', 'CH4.S', 'IMPERFECT', 'MODERATE', 2, 4, 'H', "CHATEAUGUAY LOAM"],
	['Ottawa Urban Fringe', 'CH5', 'IMPERFECT', 'SLOW', 2, 3, 'H', "CHATEAUGUAY LOAM"],
	['Ottawa Urban Fringe', 'CH6', 'POOR', 'SLOW', 3, 3, 'H', "CHATEAUGUAY LOAM"],
	['Ottawa Urban Fringe', 'CH7', 'POOR', 'SLOW', 3, 3, 'H', "CHATEAUGUAY LOAM"],
	['Ottawa Urban Fringe', 'D1', 'IMPERFECT', 'VERY SLOW', 2, 4, 'H', "DALHOUSIE SILTY CLAY LOAM"],
	['Ottawa Urban Fringe', 'D2', 'IMPERFECT', 'VERY SLOW', 2, 4, 'H', "DALHOUSIE SILTY CLAY LOAM"],
	['Ottawa Urban Fringe', 'D2.C', 'IMPERFECT', 'SLOW', 3, 4, 'M', "DALHOUSIE SILTY CLAY LOAM"],
	['Ottawa Urban Fringe', 'D3', 'POOR', 'VERY SLOW', 3, 4, 'H', "BRANDON SILTY CLAY LOAM"],
	['Ottawa Urban Fringe', 'D3.C', 'POOR', 'VERY SLOW', 3, 3, 'H', "BRANDON SILTY CLAY LOAM"],
	['Ottawa Urban Fringe', 'D3.S', 'POOR', 'SLOW', 3, 4, 'H', "BRANDON SILTY CLAY LOAM"],
	['Ottawa Urban Fringe', 'D4', 'VERY POOR', 'SLOW', 3, 3, 'H', "DALHOUSIE SILTY CLAY LOAM"],
	['Ottawa Urban Fringe', 'D4.O', 'VERY POOR', 'SLOW', 3, 2, 'M', "DALHOUSIE SILTY CLAY LOAM"],
	['Ottawa Urban Fringe', 'D5', 'POOR', 'SLOW', 3, 3, 'H', "BRANDON SILTY CLAY LOAM"],
	['Ottawa Urban Fringe', 'E1', 'WELL', 'MODERATE', 1, 4, 'M', "ELLWOOD CLAY LOAM"],
	['Ottawa Urban Fringe', 'E2', 'POOR', 'SLOW', 3, 4, 'H', "ELLWOOD - GLEYSOLIC VARIANT CLAY LOAM"],
	['Ottawa Urban Fringe', 'E3', 'POOR', 'SLOW', 3, 4, 'H', "ELLWOOD - GLEYSOLIC VARIANT CLAY LOAM"],
	['Ottawa Urban Fringe', 'F1', 'WELL', 'RAPID', 1, 4, 'L', "FARMINGTON SANDY LOAM"],
	['Ottawa Urban Fringe', 'F1.F', 'WELL', 'MODERATE', 1, 4, 'M', "FARMINGTON SANDY LOAM"],
	['Ottawa Urban Fringe', 'F2', 'IMPERFECT', 'RAPID', 2, 4, 'M', "FRANKTOWN SANDY LOAM"],
	['Ottawa Urban Fringe', 'F2.F', 'IMPERFECT', 'MODERATE', 2, 4, 'H', "FRANKTOWN SANDY LOAM"],
	['Ottawa Urban Fringe', 'F3', 'POOR', 'MODERATE', 3, 3, 'H', "BROOKE SANDY LOAM"],
	['Ottawa Urban Fringe', 'F3.O', 'VERY POOR', 'MODERATE', 3, 2, 'M', "BROOKE SANDY LOAM"],
	['Ottawa Urban Fringe', 'F4', 'WELL', 'RAPID', 1, 4, 'L', "FARMINGTON SANDY LOAM"],
	['Ottawa Urban Fringe', 'F4.F', 'WELL', 'MODERATE', 1, 4, 'M', "FARMINGTON SANDY LOAM"],
	['Ottawa Urban Fringe', 'F5', 'IMPERFECT', 'RAPID', 2, 4, 'M', "FRANKTOWN SANDY LOAM"],
	['Ottawa Urban Fringe', 'F6', 'WELL', 'RAPID', 1, 4, 'L', "FARMINGTON SANDY LOAM"],
	['Ottawa Urban Fringe', 'G1', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Ottawa Urban Fringe', 'G1.C', 'WELL', 'MODERATE', 4, 1, 'L', "GRENVILLE LOAM"],
	['Ottawa Urban Fringe', 'G1.S', 'WELL', 'RAPID', 1, 4, 'L', "GRENVILLE LOAM"],
	['Ottawa Urban Fringe', 'G2', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MATILDA LOAM"],
	['Ottawa Urban Fringe', 'G2.C', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "MATILDA LOAM"],
	['Ottawa Urban Fringe', 'G2.S', 'IMPERFECT', 'RAPID', 2, 4, 'M', "MATILDA LOAM"],
	['Ottawa Urban Fringe', 'G3', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Ottawa Urban Fringe', 'G3.C', 'POOR', 'SLOW', 4, 2, 'M', "LYONS LOAM"],
	['Ottawa Urban Fringe', 'G3.O', 'POOR', 'SLOW', 3, 2, 'M', "LYONS LOAM"],
	['Ottawa Urban Fringe', 'G3.S', 'POOR', 'MODERATE', 3, 3, 'H', "LYONS LOAM"],
	['Ottawa Urban Fringe', 'G4', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Ottawa Urban Fringe', 'G4.S', 'WELL', 'RAPID', 1, 4, 'L', "GRENVILLE LOAM"],
	['Ottawa Urban Fringe', 'G5', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MATILDA LOAM"],
	['Ottawa Urban Fringe', 'G5.S', 'IMPERFECT', 'SLOW', 2, 4, 'M', "MATILDA LOAM"],
	['Ottawa Urban Fringe', 'G6', 'VERY POOR', 'SLOW', 2, 3, 'H', "GRENVILLE LOAM"],
	['Ottawa Urban Fringe', 'G6.S', 'VERY POOR', 'MODERATE', 3, 3, 'H', "GRENVILLE LOAM"],
	['Ottawa Urban Fringe', 'GB', 'VERY POOR', 'SLOW', 3, 2, 'M', "GOULBOURN ORGANIC"],
	['Ottawa Urban Fringe', 'GB1', 'VERY POOR', 'SLOW', 3, 2, 'M', "GOULBOURN ORGANIC"],
	['Ottawa Urban Fringe', 'GY1', 'VERY POOR', 'SLOW', 3, 2, 'M', "GREELY ORGANIC"],
	['Ottawa Urban Fringe', 'H1', 'VERY POOR', 'SLOW', 3, 2, 'M', "HUNTLEY ORGANIC"],
	['Ottawa Urban Fringe', 'H2', 'VERY POOR', 'SLOW', 3, 2, 'M', "HUNTLEY ORGANIC"],
	['Ottawa Urban Fringe', 'H3', 'VERY POOR', 'SLOW', 3, 2, 'M', "HUNTLEY ORGANIC"],
	['Ottawa Urban Fringe', 'H4', 'VERY POOR', 'SLOW', 3, 2, 'M', "HUNTLEY ORGANIC"],
	['Ottawa Urban Fringe', 'I1', 'WELL', 'MODERATE', 4, 1, 'L', "IRONSIDE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'I1.S', 'WELL', 'MODERATE', 3, 2, 'L', "IRONSIDE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'I2', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "IRONSIDE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'I2.S', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "IRONSIDE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'I3', 'POOR', 'SLOW', 4, 2, 'M', "DWYER HILL LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'I3.S', 'POOR', 'SLOW', 4, 3, 'M', "DWYER HILL LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'I4', 'WELL', 'MODERATE', 4, 1, 'L', "IRONSIDE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'I5', 'POOR', 'SLOW', 4, 2, 'M', "IRONSIDE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'I6', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "IRONSIDE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'J1', 'WELL', 'RAPID', 4, 1, 'L', "JOCKVALE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'J1.S', 'WELL', 'MODERATE', 3, 2, 'L', "JOCKVALE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'J2', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ACHIGAN LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'J3', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ACHIGAN LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'J4', 'POOR', 'SLOW', 4, 2, 'M', "VAUDREUIL LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'J4.F', 'POOR', 'SLOW', 3, 3, 'H', "VAUDREUIL LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'J4.S', 'POOR', 'MODERATE', 4, 3, 'M', "VAUDREUIL LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'J5', 'POOR', 'SLOW', 4, 2, 'M', "VAUDREUIL LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'J5.S', 'POOR', 'MODERATE', 4, 3, 'M', "VAUDREUIL LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'J6', 'VERY POOR', 'SLOW', 4, 2, 'M', "JOCKVALE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'J6.O', 'VERY POOR', 'SLOW', 3, 2, 'M', "JOCKVALE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'J7', 'WELL', 'RAPID', 4, 1, 'L', "JOCKVALE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'J7.S', 'WELL', 'MODERATE', 3, 2, 'L', "JOCKVALE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'J8', 'WELL', 'RAPID', 4, 1, 'L', "JOCKVALE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'K1', 'WELL', 'RAPID', 4, 1, 'L', "KARS GRAVELLY SANDY LOAM"],
	['Ottawa Urban Fringe', 'K2', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "KARS GRAVELLY SANDY LOAM"],
	['Ottawa Urban Fringe', 'K3', 'WELL', 'RAPID', 4, 1, 'L', "KARS GRAVELLY SANDY LOAM"],
	['Ottawa Urban Fringe', 'K4', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "KARS GRAVELLY SANDY LOAM"],
	['Ottawa Urban Fringe', 'K5', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "KARS GRAVELLY SANDY LOAM"],
	['Ottawa Urban Fringe', 'L1', 'WELL', 'MODERATE', 3, 2, 'L', "LEITRIM LOAM"],
	['Ottawa Urban Fringe', 'L2', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "LEITRIM LOAM"],
	['Ottawa Urban Fringe', 'L3', 'WELL', 'MODERATE', 3, 2, 'L', "LEITRIM LOAM"],
	['Ottawa Urban Fringe', 'L4', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "LEITRIM LOAM"],
	['Ottawa Urban Fringe', 'L5', 'WELL', 'MODERATE', 3, 2, 'L', "VARS GRAVELLY LOAM"],
	['Ottawa Urban Fringe', 'L7', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "LEITRIM LOAM"],
	['Ottawa Urban Fringe', 'L8', 'WELL', 'MODERATE', 3, 2, 'L', "LEITRIM LOAM"],
	['Ottawa Urban Fringe', 'L9', 'WELL', 'MODERATE', 3, 2, 'L', "LEITRIM LOAM"],
	['Ottawa Urban Fringe', 'L9.S', 'WELL', 'MODERATE', 3, 2, 'L', "LEITRIM LOAM"],
	['Ottawa Urban Fringe', 'LE1', 'VERY POOR', 'SLOW', 3, 2, 'M', "LEMIEUX ORGANIC"],
	['Ottawa Urban Fringe', 'M1', 'WELL', 'SLOW', 4, 2, 'L', "MANOTICK SANDY LOAM"],
	['Ottawa Urban Fringe', 'M1.S', 'WELL', 'MODERATE', 3, 2, 'L', "MANOTICK SANDY LOAM"],
	['Ottawa Urban Fringe', 'M2', 'WELL', 'SLOW', 4, 2, 'L', "MANOTICK SANDY LOAM"],
	['Ottawa Urban Fringe', 'M3', 'IMPERFECT', 'SLOW', 3, 2, 'M', "MOUNTAIN SANDY LOAM"],
	['Ottawa Urban Fringe', 'M4', 'IMPERFECT', 'SLOW', 3, 2, 'M', "MOUNTAIN FINE SANDY LOAM"],
	['Ottawa Urban Fringe', 'M5', 'POOR', 'VERY SLOW', 4, 3, 'M', "ALLENDALE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'M6', 'POOR', 'VERY SLOW', 4, 3, 'M', "ALLENDALE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'M6.C', 'POOR', 'VERY SLOW', 4, 3, 'M', "ALLENDALE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'M7', 'VERY POOR', 'VERY SLOW', 4, 3, 'M', "MANOTICK SANDY LOAM"],
	['Ottawa Urban Fringe', 'M7.O', 'VERY POOR', 'SLOW', 3, 2, 'M', "MANOTICK SANDY LOAM"],
	['Ottawa Urban Fringe', 'M8', 'POOR', 'VERY SLOW', 4, 3, 'M', "ALLENDALE LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'MB1', 'VERY POOR', 'SLOW', 3, 2, 'M', "MER BLEUE ORGANIC"],
	['Ottawa Urban Fringe', 'MB2', 'VERY POOR', 'SLOW', 3, 2, 'M', "MER BLEUE ORGANIC"],
	['Ottawa Urban Fringe', 'MB3', 'VERY POOR', 'SLOW', 3, 2, 'M', "MER BLEUE ORGANIC"],
	['Ottawa Urban Fringe', 'MB4', 'VERY POOR', 'SLOW', 3, 2, 'M', "MER BLEUE ORGANIC"],
	['Ottawa Urban Fringe', 'MI1', 'WELL', 'RAPID', 4, 1, 'L', "MILLE ISLE LOAMY SAND"],
	['Ottawa Urban Fringe', 'MI2', 'WELL', 'RAPID', 4, 1, 'L', "MILLE ISLE LOAMY SAND"],
	['Ottawa Urban Fringe', 'MI3', 'POOR', 'SLOW', 4, 2, 'M', "MILLE ISLE LOAMY SAND"],
	['Ottawa Urban Fringe', 'MI3.O', 'POOR', 'SLOW', 3, 2, 'M', "MILLE ISLE LOAMY SAND"],
	['Ottawa Urban Fringe', 'MI4', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "HERBERT CORNER LOAMY SAND"],
	['Ottawa Urban Fringe', 'MI5', 'WELL', 'RAPID', 4, 1, 'L', "MILLE ISLE LOAMY SAND"],
	['Ottawa Urban Fringe', 'MI6', 'POOR', 'SLOW', 4, 2, 'M', "DUNROBIN SAND"],
	['Ottawa Urban Fringe', 'MK1', 'VERY POOR', 'SLOW', 3, 2, 'M', "MALAKOFF ORGANIC"],
	['Ottawa Urban Fringe', 'N1', 'WELL', 'MODERATE', 3, 2, 'L', "NEPEAN LOAMY SAND"],
	['Ottawa Urban Fringe', 'N2', 'WELL', 'MODERATE', 3, 2, 'L', "FALLOWFIELD LOAMY SAND"],
	['Ottawa Urban Fringe', 'N3', 'POOR', 'MODERATE', 4, 3, 'M', "BARRHAVEN SANDY LOAM"],
	['Ottawa Urban Fringe', 'N4', 'WELL', 'MODERATE', 3, 2, 'L', "NEPEAN LOAMY SAND"],
	['Ottawa Urban Fringe', 'N5', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "FALLOWFIELD LOAMY SAND"],
	['Ottawa Urban Fringe', 'NG1', 'IMPERFECT', 'VERY SLOW', 2, 4, 'H', "CARP CLAY LOAM"],
	['Ottawa Urban Fringe', 'NG1.S', 'IMPERFECT', 'MODERATE', 2, 4, 'H', "CARP CLAY LOAM"],
	['Ottawa Urban Fringe', 'NG2', 'POOR', 'VERY SLOW', 3, 4, 'H', "NORTH GOWER CLAY LOAM"],
	['Ottawa Urban Fringe', 'NG2.C', 'POOR', 'VERY SLOW', 3, 3, 'H', "NORTH GOWER CLAY LOAM"],
	['Ottawa Urban Fringe', 'NG2.CS', 'POOR', 'SLOW', 3, 4, 'H', "NORTH GOWER CLAY LOAM"],
	['Ottawa Urban Fringe', 'NG2.O', 'POOR', 'SLOW', 3, 2, 'M', "NORTH GOWER CLAY LOAM"],
	['Ottawa Urban Fringe', 'NG2.S', 'POOR', 'SLOW', 3, 4, 'H', "NORTH GOWER CLAY LOAM"],
	['Ottawa Urban Fringe', 'NG3', 'IMPERFECT', 'VERY SLOW', 2, 4, 'H', "CARP CLAY LOAM"],
	['Ottawa Urban Fringe', 'NG4', 'VERY POOR', 'VERY SLOW', 3, 4, 'H', "BELMEADE SILT LOAM"],
	['Ottawa Urban Fringe', 'NG4.O', 'VERY POOR', 'SLOW', 3, 2, 'M', "BELMEADE SILT LOAM"],
	['Ottawa Urban Fringe', 'NG5', 'POOR', 'VERY SLOW', 3, 4, 'H', "NORTH GOWER CLAY LOAM"],
	['Ottawa Urban Fringe', 'O1', 'WELL', 'RAPID', 4, 1, 'L', "OKA LOAMY SAND"],
	['Ottawa Urban Fringe', 'O1.S', 'WELL', 'MODERATE', 3, 2, 'L', "OKA LOAMY SAND"],
	['Ottawa Urban Fringe', 'O2', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "OKA LOAMY SAND"],
	['Ottawa Urban Fringe', 'O2.S', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "OKA LOAMY SAND"],
	['Ottawa Urban Fringe', 'O3', 'WELL', 'RAPID', 4, 1, 'L', "OKA LOAMY SAND"],
	['Ottawa Urban Fringe', 'O5', 'WELL', 'RAPID', 4, 1, 'L', "OKA LOAMY SAND"],
	['Ottawa Urban Fringe', 'OS', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "OSGOODE SILT LOAM"],
	['Ottawa Urban Fringe', 'OS1', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "PIPERVILLE SILT LOAM"],
	['Ottawa Urban Fringe', 'OS1.C', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "PIPERVILLE SILT LOAM"],
	['Ottawa Urban Fringe', 'OS2', 'POOR', 'SLOW', 4, 2, 'M', "OSGOODE LOAM"],
	['Ottawa Urban Fringe', 'OS3', 'POOR', 'SLOW', 4, 2, 'M', "OSGOODE LOAM"],
	['Ottawa Urban Fringe', 'OS3.C', 'POOR', 'SLOW', 4, 2, 'M', "OSGOODE LOAM"],
	['Ottawa Urban Fringe', 'OS3.F', 'POOR', 'SLOW', 3, 3, 'H', "OSGOODE LOAM"],
	['Ottawa Urban Fringe', 'OS3.FO', 'POOR', 'SLOW', 3, 2, 'M', "OSGOODE LOAM"],
	['Ottawa Urban Fringe', 'OS3.O', 'POOR', 'SLOW', 3, 2, 'M', "OSGOODE LOAM"],
	['Ottawa Urban Fringe', 'OS3.S', 'POOR', 'MODERATE', 4, 3, 'M', "OSGOODE LOAM"],
	['Ottawa Urban Fringe', 'OS3.SO', 'POOR', 'MODERATE', 4, 3, 'M', "OSGOODE LOAM"],
	['Ottawa Urban Fringe', 'OS4', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "PIPERVILLE SILT LOAM"],
	['Ottawa Urban Fringe', 'OT1', 'WELL', 'MODERATE', 2, 4, 'L', "BUCKHAM BAY SAND"],
	['Ottawa Urban Fringe', 'OT2', 'WELL', 'MODERATE', 2, 4, 'L', "BUCKHAM BAY SAND"],
	['Ottawa Urban Fringe', 'P1', 'IMPERFECT', 'SLOW', 2, 4, 'M', "PIPERVILLE SILT LOAM"],
	['Ottawa Urban Fringe', 'P2', 'IMPERFECT', 'SLOW', 2, 4, 'M', "PIPERVILLE SILT LOAM"],
	['Ottawa Urban Fringe', 'P3', 'POOR', 'SLOW', 2, 3, 'H', "CARSONBY SILT LOAM"],
	['Ottawa Urban Fringe', 'P4', 'POOR', 'SLOW', 2, 3, 'H', "CARSONBY SILT LOAM"],
	['Ottawa Urban Fringe', 'P5', 'VERY POOR', 'SLOW', 2, 3, 'H', "CARSONBY SILT LOAM"],
	['Ottawa Urban Fringe', 'Q1', 'WELL', 'MODERATE', 4, 1, 'L', "QUEENSWAY SANDY LOAM"],
	['Ottawa Urban Fringe', 'Q1.S', 'WELL', 'MODERATE', 3, 2, 'L', "QUEENSWAY SANDY LOAM"],
	['Ottawa Urban Fringe', 'Q2', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "QUEENSWAY SANDY LOAM"],
	['Ottawa Urban Fringe', 'Q3', 'POOR', 'SLOW', 4, 2, 'M', "QUEENSWAY SANDY LOAM"],
	['Ottawa Urban Fringe', 'Q4', 'WELL', 'MODERATE', 4, 1, 'L', "QUEENSWAY SANDY LOAM"],
	['Ottawa Urban Fringe', 'Q5', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "QUEENSWAY SANDY LOAM"],
	['Ottawa Urban Fringe', 'R1', 'IMPERFECT', 'SLOW', 3, 4, 'H', "RIDEAU CLAY"],
	['Ottawa Urban Fringe', 'R2', 'IMPERFECT', 'SLOW', 3, 4, 'H', "RIDEAU CLAY"],
	['Ottawa Urban Fringe', 'R3', 'POOR', 'VERY SLOW', 3, 4, 'H', "STE ROSALIE CLAY"],
	['Ottawa Urban Fringe', 'R3.C', 'POOR', 'VERY SLOW', 3, 4, 'H', "STE ROSALIE CLAY"],
	['Ottawa Urban Fringe', 'R3.S', 'POOR', 'SLOW', 2, 4, 'H', "STE ROSALIE CLAY"],
	['Ottawa Urban Fringe', 'R4.O', 'VERY POOR', 'SLOW', 3, 2, 'M', "LAPLAINE CLAY"],
	['Ottawa Urban Fringe', 'R5', 'POOR', 'VERY SLOW', 3, 4, 'H', "STE ROSALIE CLAY"],
	['Ottawa Urban Fringe', 'RE1', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "REEVECRAIG LOAMY VERY FINE SAND"],
	['Ottawa Urban Fringe', 'RE2', 'POOR', 'SLOW', 4, 2, 'M', "REEVECRAIG LOAMY VERY FINE SAND"],
	['Ottawa Urban Fringe', 'RE3', 'POOR', 'SLOW', 4, 2, 'M', "REEVECRAIG LOAMY VERY FINE SAND"],
	['Ottawa Urban Fringe', 'ST1', 'WELL', 'RAPID', 4, 1, 'L', "ST THOMAS FINE SAND"],
	['Ottawa Urban Fringe', 'ST2', 'WELL', 'RAPID', 4, 1, 'L', "ST THOMAS FINE SAND"],
	['Ottawa Urban Fringe', 'ST3', 'WELL', 'RAPID', 4, 1, 'L', "ST THOMAS FINE SAND"],
	['Ottawa Urban Fringe', 'ST4', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ACHIGAN LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'ST5', 'POOR', 'SLOW', 4, 2, 'M', "CHENEY LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'ST6', 'POOR', 'SLOW', 4, 2, 'M', "CHENEY LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'ST7', 'POOR', 'SLOW', 4, 2, 'M', "CHENEY LOAMY FINE SAND"],
	['Ottawa Urban Fringe', 'ST8', 'WELL', 'RAPID', 4, 1, 'L', "ST THOMAS FINE SAND"],
	['Ottawa Urban Fringe', 'U1', 'WELL', 'RAPID', 4, 1, 'L', "CARLSBAD LOAMY SAND"],
	['Ottawa Urban Fringe', 'U10', 'POOR', 'SLOW', 4, 2, 'M', "ST SAMUEL FINE SANDY LOAM"],
	['Ottawa Urban Fringe', 'U11', 'POOR', 'SLOW', 4, 2, 'M', "ST SAMUEL FINE SANDY LOAM"],
	['Ottawa Urban Fringe', 'U12', 'POOR', 'SLOW', 4, 2, 'M', "ST SAMUEL FINE SANDY LOAM"],
	['Ottawa Urban Fringe', 'U13', 'POOR', 'SLOW', 4, 2, 'M', "ST SAMUEL FINE SANDY LOAM"],
	['Ottawa Urban Fringe', 'U14', 'VERY POOR', 'SLOW', 4, 2, 'M', "BORTHWICK ORGANIC"],
	['Ottawa Urban Fringe', 'U15', 'WELL', 'RAPID', 4, 1, 'L', "ST THOMAS FINE SAND"],
	['Ottawa Urban Fringe', 'U2', 'WELL', 'RAPID', 4, 1, 'L', "CARLSBAD LOAMY SAND"],
	['Ottawa Urban Fringe', 'U3', 'WELL', 'RAPID', 4, 1, 'L', "UPLANDS LOAMY SAND"],
	['Ottawa Urban Fringe', 'U4', 'WELL', 'RAPID', 4, 1, 'L', "CARLSBAD LOAMY SAND"],
	['Ottawa Urban Fringe', 'U5', 'WELL', 'RAPID', 4, 1, 'L', "UPLANDS LOAMY SAND"],
	['Ottawa Urban Fringe', 'U6', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RAMSAYVILLE LOAMY SAND"],
	['Ottawa Urban Fringe', 'U7', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RAMSAYVILLE LOAMY SAND"],
	['Ottawa Urban Fringe', 'U8', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RUBICON LOAMY SAND"],
	['Ottawa Urban Fringe', 'U9', 'POOR', 'SLOW', 4, 2, 'M', "ST SAMUEL FINE SANDY LOAM"],
	['Ottawa-Carleton', 'A1', 'WELL', 'MODERATE', 3, 2, 'L', "ANSTRUTHER LOAMY SAND"],
	['Ottawa-Carleton', 'B1', 'IMPERFECT', 'SLOW', 3, 4, 'H', "WENDOVER CLAY"],
	['Ottawa-Carleton', 'B2', 'POOR', 'VERY SLOW', 3, 4, 'H', "BEARBROOK CLAY"],
	['Ottawa-Carleton', 'B3', 'POOR', 'VERY SLOW', 3, 4, 'H', "BEARBROOK - R.G CLAY"],
	['Ottawa-Carleton', 'B4', 'POOR', 'VERY SLOW', 3, 4, 'H', "BEARBROOK - VARIANT PHASE CLAY"],
	['Ottawa-Carleton', 'BE1', 'VERY POOR', 'SLOW', 3, 2, 'M', "BORROMEE ORGANIC"],
	['Ottawa-Carleton', 'C1', 'WELL', 'SLOW', 2, 4, 'L', "CASTOR - O.MB LOAM"],
	['Ottawa-Carleton', 'C2', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CASTOR LOAM"],
	['Ottawa-Carleton', 'C3', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CASTOR LOAM"],
	['Ottawa-Carleton', 'C4', 'POOR', 'VERY SLOW', 3, 3, 'H', "BAINSVILLE VERY FINE SANDY LOAM"],
	['Ottawa-Carleton', 'C5', 'POOR', 'VERY SLOW', 3, 3, 'H', "BAINSVILLE VERY FINE SANDY LOAM"],
	['Ottawa-Carleton', 'C5.S', 'POOR', 'MODERATE', 3, 3, 'H', "BAINSVILLE VERY FINE SANDY LOAM"],
	['Ottawa-Carleton', 'C6', 'VERY POOR', 'SLOW', 3, 2, 'M', "CASTOR LOAM"],
	['Ottawa-Carleton', 'CH1', 'WELL', 'SLOW', 2, 3, 'M', "CHATEAUGUAY LOAM"],
	['Ottawa-Carleton', 'CH2', 'IMPERFECT', 'SLOW', 2, 3, 'H', "CHATEAUGUAY LOAM"],
	['Ottawa-Carleton', 'CH3', 'POOR', 'SLOW', 3, 3, 'H', "MACDONALD CLAY LOAM"],
	['Ottawa-Carleton', 'CH4', 'IMPERFECT', 'SLOW', 2, 3, 'H', "CHATEAUGUAY LOAM"],
	['Ottawa-Carleton', 'CH4.S', 'IMPERFECT', 'MODERATE', 2, 4, 'H', "CHATEAUGUAY LOAM"],
	['Ottawa-Carleton', 'CH5', 'IMPERFECT', 'SLOW', 2, 3, 'H', "CHATEAUGUAY LOAM"],
	['Ottawa-Carleton', 'CH6', 'POOR', 'SLOW', 3, 3, 'H', "CHATEAUGUAY LOAM"],
	['Ottawa-Carleton', 'CH7', 'POOR', 'SLOW', 3, 3, 'H', "CHATEAUGUAY LOAM"],
	['Ottawa-Carleton', 'D1', 'IMPERFECT', 'VERY SLOW', 2, 4, 'H', "DALHOUSIE SILTY CLAY LOAM"],
	['Ottawa-Carleton', 'D2', 'IMPERFECT', 'VERY SLOW', 2, 4, 'H', "DALHOUSIE SILTY CLAY LOAM"],
	['Ottawa-Carleton', 'D2.C', 'IMPERFECT', 'SLOW', 3, 4, 'M', "DALHOUSIE SILTY CLAY LOAM"],
	['Ottawa-Carleton', 'D3', 'POOR', 'VERY SLOW', 3, 4, 'H', "BRANDON SILTY CLAY LOAM"],
	['Ottawa-Carleton', 'D3.C', 'POOR', 'VERY SLOW', 3, 3, 'H', "BRANDON SILTY CLAY LOAM"],
	['Ottawa-Carleton', 'D3.S', 'POOR', 'SLOW', 3, 4, 'H', "BRANDON SILTY CLAY LOAM"],
	['Ottawa-Carleton', 'D4', 'VERY POOR', 'SLOW', 3, 3, 'H', "DALHOUSIE SILTY CLAY LOAM"],
	['Ottawa-Carleton', 'D4.O', 'VERY POOR', 'SLOW', 3, 2, 'M', "DALHOUSIE SILTY CLAY LOAM"],
	['Ottawa-Carleton', 'D5', 'POOR', 'SLOW', 3, 3, 'H', "BRANDON SILTY CLAY LOAM"],
	['Ottawa-Carleton', 'E1', 'WELL', 'MODERATE', 1, 4, 'M', "ELLWOOD CLAY LOAM"],
	['Ottawa-Carleton', 'E2', 'POOR', 'SLOW', 3, 4, 'H', "ELLWOOD - GLEYSOLIC VARIANT CLAY LOAM"],
	['Ottawa-Carleton', 'E3', 'POOR', 'SLOW', 3, 4, 'H', "ELLWOOD - GLEYSOLIC VARIANT CLAY LOAM"],
	['Ottawa-Carleton', 'F1', 'WELL', 'RAPID', 1, 4, 'L', "FARMINGTON SANDY LOAM"],
	['Ottawa-Carleton', 'F1.F', 'WELL', 'MODERATE', 1, 4, 'M', "FARMINGTON SANDY LOAM"],
	['Ottawa-Carleton', 'F2', 'IMPERFECT', 'RAPID', 2, 4, 'M', "FRANKTOWN SANDY LOAM"],
	['Ottawa-Carleton', 'F2.F', 'IMPERFECT', 'MODERATE', 2, 4, 'H', "FRANKTOWN SANDY LOAM"],
	['Ottawa-Carleton', 'F3', 'POOR', 'MODERATE', 3, 3, 'H', "BROOKE SANDY LOAM"],
	['Ottawa-Carleton', 'F3.O', 'VERY POOR', 'MODERATE', 3, 2, 'M', "BROOKE SANDY LOAM"],
	['Ottawa-Carleton', 'F4', 'WELL', 'RAPID', 1, 4, 'L', "FARMINGTON SANDY LOAM"],
	['Ottawa-Carleton', 'F4.F', 'WELL', 'MODERATE', 1, 4, 'M', "FARMINGTON SANDY LOAM"],
	['Ottawa-Carleton', 'F5', 'IMPERFECT', 'RAPID', 2, 4, 'M', "FRANKTOWN SANDY LOAM"],
	['Ottawa-Carleton', 'F6', 'WELL', 'RAPID', 1, 4, 'L', "FARMINGTON SANDY LOAM"],
	['Ottawa-Carleton', 'G1', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Ottawa-Carleton', 'G1.C', 'WELL', 'MODERATE', 4, 1, 'L', "GRENVILLE LOAM"],
	['Ottawa-Carleton', 'G1.S', 'WELL', 'RAPID', 1, 4, 'L', "GRENVILLE LOAM"],
	['Ottawa-Carleton', 'G2', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MATILDA LOAM"],
	['Ottawa-Carleton', 'G2.C', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "MATILDA LOAM"],
	['Ottawa-Carleton', 'G2.S', 'IMPERFECT', 'RAPID', 2, 4, 'M', "MATILDA LOAM"],
	['Ottawa-Carleton', 'G3', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Ottawa-Carleton', 'G3.C', 'POOR', 'SLOW', 4, 2, 'M', "LYONS LOAM"],
	['Ottawa-Carleton', 'G3.O', 'POOR', 'SLOW', 3, 2, 'M', "LYONS LOAM"],
	['Ottawa-Carleton', 'G3.S', 'POOR', 'MODERATE', 3, 3, 'H', "LYONS LOAM"],
	['Ottawa-Carleton', 'G4', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Ottawa-Carleton', 'G4.S', 'WELL', 'RAPID', 1, 4, 'L', "GRENVILLE LOAM"],
	['Ottawa-Carleton', 'G5', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MATILDA LOAM"],
	['Ottawa-Carleton', 'G5.S', 'IMPERFECT', 'SLOW', 2, 4, 'M', "MATILDA LOAM"],
	['Ottawa-Carleton', 'G6', 'VERY POOR', 'SLOW', 2, 3, 'H', "GRENVILLE LOAM"],
	['Ottawa-Carleton', 'G6.S', 'VERY POOR', 'MODERATE', 3, 3, 'H', "GRENVILLE LOAM"],
	['Ottawa-Carleton', 'GB', 'VERY POOR', 'SLOW', 3, 2, 'M', "GOULBOURN ORGANIC"],
	['Ottawa-Carleton', 'GB1', 'VERY POOR', 'SLOW', 3, 2, 'M', "GOULBOURN ORGANIC"],
	['Ottawa-Carleton', 'GY1', 'VERY POOR', 'SLOW', 3, 2, 'M', "GREELY ORGANIC"],
	['Ottawa-Carleton', 'H1', 'VERY POOR', 'SLOW', 3, 2, 'M', "HUNTLEY ORGANIC"],
	['Ottawa-Carleton', 'H2', 'VERY POOR', 'SLOW', 3, 2, 'M', "HUNTLEY ORGANIC"],
	['Ottawa-Carleton', 'H3', 'VERY POOR', 'SLOW', 3, 2, 'M', "HUNTLEY ORGANIC"],
	['Ottawa-Carleton', 'H4', 'VERY POOR', 'SLOW', 3, 2, 'M', "HUNTLEY ORGANIC"],
	['Ottawa-Carleton', 'I1', 'WELL', 'MODERATE', 4, 1, 'L', "IRONSIDE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'I1.S', 'WELL', 'MODERATE', 3, 2, 'L', "IRONSIDE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'I2', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "IRONSIDE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'I2.S', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "IRONSIDE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'I3', 'POOR', 'SLOW', 4, 2, 'M', "DWYER HILL LOAMY FINE SAND"],
	['Ottawa-Carleton', 'I3.S', 'POOR', 'SLOW', 4, 3, 'M', "DWYER HILL LOAMY FINE SAND"],
	['Ottawa-Carleton', 'I4', 'WELL', 'MODERATE', 4, 1, 'L', "IRONSIDE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'I5', 'POOR', 'SLOW', 4, 2, 'M', "IRONSIDE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'I6', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "IRONSIDE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'J1', 'WELL', 'RAPID', 4, 1, 'L', "JOCKVALE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'J1.S', 'WELL', 'MODERATE', 3, 2, 'L', "JOCKVALE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'J2', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ACHIGAN LOAMY FINE SAND"],
	['Ottawa-Carleton', 'J3', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ACHIGAN LOAMY FINE SAND"],
	['Ottawa-Carleton', 'J4', 'POOR', 'SLOW', 4, 2, 'M', "VAUDREUIL LOAMY FINE SAND"],
	['Ottawa-Carleton', 'J4.F', 'POOR', 'SLOW', 3, 3, 'H', "VAUDREUIL LOAMY FINE SAND"],
	['Ottawa-Carleton', 'J4.S', 'POOR', 'MODERATE', 4, 3, 'M', "VAUDREUIL LOAMY FINE SAND"],
	['Ottawa-Carleton', 'J5', 'POOR', 'SLOW', 4, 2, 'M', "VAUDREUIL LOAMY FINE SAND"],
	['Ottawa-Carleton', 'J5.S', 'POOR', 'MODERATE', 4, 3, 'M', "VAUDREUIL LOAMY FINE SAND"],
	['Ottawa-Carleton', 'J6', 'VERY POOR', 'SLOW', 4, 2, 'M', "JOCKVALE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'J6.O', 'VERY POOR', 'SLOW', 3, 2, 'M', "JOCKVALE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'J7', 'WELL', 'RAPID', 4, 1, 'L', "JOCKVALE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'J7.S', 'WELL', 'MODERATE', 3, 2, 'L', "JOCKVALE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'J8', 'WELL', 'RAPID', 4, 1, 'L', "JOCKVALE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'K1', 'WELL', 'RAPID', 4, 1, 'L', "KARS GRAVELLY SANDY LOAM"],
	['Ottawa-Carleton', 'K2', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "KARS GRAVELLY SANDY LOAM"],
	['Ottawa-Carleton', 'K3', 'WELL', 'RAPID', 4, 1, 'L', "KARS GRAVELLY SANDY LOAM"],
	['Ottawa-Carleton', 'K4', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "KARS GRAVELLY SANDY LOAM"],
	['Ottawa-Carleton', 'K5', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "KARS GRAVELLY SANDY LOAM"],
	['Ottawa-Carleton', 'L1', 'WELL', 'MODERATE', 3, 2, 'L', "LEITRIM LOAM"],
	['Ottawa-Carleton', 'L2', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "LEITRIM LOAM"],
	['Ottawa-Carleton', 'L3', 'WELL', 'MODERATE', 3, 2, 'L', "LEITRIM LOAM"],
	['Ottawa-Carleton', 'L4', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "LEITRIM LOAM"],
	['Ottawa-Carleton', 'L5', 'WELL', 'MODERATE', 3, 2, 'L', "VARS GRAVELLY LOAM"],
	['Ottawa-Carleton', 'L7', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "LEITRIM LOAM"],
	['Ottawa-Carleton', 'L8', 'WELL', 'MODERATE', 3, 2, 'L', "LEITRIM LOAM"],
	['Ottawa-Carleton', 'L9', 'WELL', 'MODERATE', 3, 2, 'L', "LEITRIM LOAM"],
	['Ottawa-Carleton', 'L9.S', 'WELL', 'MODERATE', 3, 2, 'L', "LEITRIM LOAM"],
	['Ottawa-Carleton', 'LE1', 'VERY POOR', 'SLOW', 3, 2, 'M', "LEMIEUX ORGANIC"],
	['Ottawa-Carleton', 'M1', 'WELL', 'SLOW', 4, 2, 'L', "MANOTICK SANDY LOAM"],
	['Ottawa-Carleton', 'M1.S', 'WELL', 'MODERATE', 3, 2, 'L', "MANOTICK SANDY LOAM"],
	['Ottawa-Carleton', 'M2', 'WELL', 'SLOW', 4, 2, 'L', "MANOTICK SANDY LOAM"],
	['Ottawa-Carleton', 'M3', 'IMPERFECT', 'SLOW', 3, 2, 'M', "MOUNTAIN FINE SANDY LOAM"],
	['Ottawa-Carleton', 'M4', 'IMPERFECT', 'SLOW', 3, 2, 'M', "MOUNTAIN FINE SANDY LOAM"],
	['Ottawa-Carleton', 'M5', 'POOR', 'VERY SLOW', 4, 3, 'M', "ALLENDALE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'M6', 'POOR', 'VERY SLOW', 4, 3, 'M', "ALLENDALE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'M6.C', 'POOR', 'VERY SLOW', 4, 3, 'M', "ALLENDALE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'M7', 'VERY POOR', 'VERY SLOW', 4, 3, 'M', "MANOTICK SANDY LOAM"],
	['Ottawa-Carleton', 'M7.O', 'VERY POOR', 'VERY SLOW', 3, 2, 'M', "MANOTICK SANDY LOAM"],
	['Ottawa-Carleton', 'M8', 'POOR', 'VERY SLOW', 4, 3, 'M', "ALLENDALE LOAMY FINE SAND"],
	['Ottawa-Carleton', 'MB1', 'VERY POOR', 'SLOW', 3, 2, 'M', "MER BLEUE ORGANIC"],
	['Ottawa-Carleton', 'MB2', 'VERY POOR', 'SLOW', 3, 2, 'M', "MER BLEUE ORGANIC"],
	['Ottawa-Carleton', 'MB3', 'VERY POOR', 'SLOW', 3, 2, 'M', "MER BLEUE ORGANIC"],
	['Ottawa-Carleton', 'MB4', 'VERY POOR', 'SLOW', 3, 2, 'M', "MER BLEUE ORGANIC"],
	['Ottawa-Carleton', 'MI1', 'WELL', 'RAPID', 4, 1, 'L', "MILLE ISLE LOAMY SAND"],
	['Ottawa-Carleton', 'MI2', 'WELL', 'RAPID', 4, 1, 'L', "MILLE ISLE LOAMY SAND"],
	['Ottawa-Carleton', 'MI3', 'POOR', 'SLOW', 4, 2, 'M', "DUNROBIN SAND"],
	['Ottawa-Carleton', 'MI3.O', 'POOR', 'SLOW', 3, 2, 'M', "DUNROBIN SAND"],
	['Ottawa-Carleton', 'MI4', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "HERBERT CORNER LOAMY SAND"],
	['Ottawa-Carleton', 'MI5', 'WELL', 'RAPID', 4, 1, 'L', "MILLE ISLE LOAMY SAND"],
	['Ottawa-Carleton', 'MI6', 'POOR', 'SLOW', 4, 2, 'M', "DUNROBIN SAND"],
	['Ottawa-Carleton', 'MK1', 'VERY POOR', 'SLOW', 3, 2, 'M', "MALAKOFF ORGANIC"],
	['Ottawa-Carleton', 'N1', 'WELL', 'MODERATE', 3, 2, 'L', "NEPEAN LOAMY SAND"],
	['Ottawa-Carleton', 'N2', 'WELL', 'MODERATE', 3, 2, 'L', "FALLOWFIELD LOAMY SAND"],
	['Ottawa-Carleton', 'N3', 'POOR', 'MODERATE', 4, 3, 'M', "BARRHAVEN SANDY LOAM"],
	['Ottawa-Carleton', 'N4', 'WELL', 'MODERATE', 3, 2, 'L', "NEPEAN LOAMY SAND"],
	['Ottawa-Carleton', 'N5', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "FALLOWFIELD LOAMY SAND"],
	['Ottawa-Carleton', 'NG1', 'IMPERFECT', 'VERY SLOW', 2, 4, 'H', "CARP CLAY LOAM"],
	['Ottawa-Carleton', 'NG1.S', 'IMPERFECT', 'MODERATE', 2, 4, 'H', "CARP CLAY LOAM"],
	['Ottawa-Carleton', 'NG2', 'POOR', 'VERY SLOW', 3, 4, 'H', "NORTH GOWER CLAY LOAM"],
	['Ottawa-Carleton', 'NG2.C', 'POOR', 'VERY SLOW', 3, 3, 'H', "NORTH GOWER CLAY LOAM"],
	['Ottawa-Carleton', 'NG2.CS', 'POOR', 'SLOW', 3, 4, 'H', "NORTH GOWER CLAY LOAM"],
	['Ottawa-Carleton', 'NG2.O', 'POOR', 'SLOW', 3, 2, 'M', "NORTH GOWER CLAY LOAM"],
	['Ottawa-Carleton', 'NG2.S', 'POOR', 'SLOW', 3, 4, 'H', "NORTH GOWER CLAY LOAM"],
	['Ottawa-Carleton', 'NG3', 'IMPERFECT', 'VERY SLOW', 2, 4, 'H', "CARP CLAY LOAM"],
	['Ottawa-Carleton', 'NG4', 'VERY POOR', 'VERY SLOW', 3, 4, 'H', "BELMEADE SILT LOAM"],
	['Ottawa-Carleton', 'NG4.O', 'VERY POOR', 'SLOW', 3, 2, 'M', "BELMEADE SILT LOAM"],
	['Ottawa-Carleton', 'NG5', 'POOR', 'VERY SLOW', 3, 4, 'H', "NORTH GOWER CLAY LOAM"],
	['Ottawa-Carleton', 'O1', 'WELL', 'RAPID', 4, 1, 'L', "OKA LOAMY SAND"],
	['Ottawa-Carleton', 'O1.S', 'WELL', 'MODERATE', 3, 2, 'L', "OKA LOAMY SAND"],
	['Ottawa-Carleton', 'O2', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "OKA LOAMY SAND"],
	['Ottawa-Carleton', 'O2.S', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "OKA LOAMY SAND"],
	['Ottawa-Carleton', 'O3', 'WELL', 'RAPID', 4, 1, 'L', "OKA LOAMY SAND"],
	['Ottawa-Carleton', 'O5', 'WELL', 'RAPID', 4, 1, 'L', "OKA LOAMY SAND"],
	['Ottawa-Carleton', 'OS', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "OSGOODE SILT LOAM"],
	['Ottawa-Carleton', 'OS1', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "PIPERVILLE SILT LOAM"],
	['Ottawa-Carleton', 'OS1.C', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "PIPERVILLE SILT LOAM"],
	['Ottawa-Carleton', 'OS2', 'POOR', 'SLOW', 4, 2, 'M', "OSGOODE LOAM"],
	['Ottawa-Carleton', 'OS3', 'POOR', 'SLOW', 4, 2, 'M', "OSGOODE LOAM"],
	['Ottawa-Carleton', 'OS3.C', 'POOR', 'SLOW', 4, 2, 'M', "OSGOODE LOAM"],
	['Ottawa-Carleton', 'OS3.F', 'POOR', 'SLOW', 3, 3, 'H', "OSGOODE LOAM"],
	['Ottawa-Carleton', 'OS3.FO', 'POOR', 'SLOW', 3, 2, 'M', "OSGOODE LOAM"],
	['Ottawa-Carleton', 'OS3.O', 'POOR', 'SLOW', 3, 2, 'M', "OSGOODE LOAM"],
	['Ottawa-Carleton', 'OS3.S', 'POOR', 'MODERATE', 4, 3, 'M', "OSGOODE LOAM"],
	['Ottawa-Carleton', 'OS3.SO', 'POOR', 'MODERATE', 4, 3, 'M', "OSGOODE LOAM"],
	['Ottawa-Carleton', 'OS4', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "PIPERVILLE SILT LOAM"],
	['Ottawa-Carleton', 'OT1', 'WELL', 'MODERATE', 2, 4, 'L', "BUCKHAM BAY SAND"],
	['Ottawa-Carleton', 'OT2', 'WELL', 'MODERATE', 2, 4, 'L', "BUCKHAM BAY SAND"],
	['Ottawa-Carleton', 'P1', 'IMPERFECT', 'SLOW', 2, 4, 'M', "PIPERVILLE SILT LOAM"],
	['Ottawa-Carleton', 'P2', 'IMPERFECT', 'SLOW', 2, 4, 'M', "PIPERVILLE SILT LOAM"],
	['Ottawa-Carleton', 'P3', 'POOR', 'SLOW', 2, 3, 'H', "CARSONBY SILT LOAM"],
	['Ottawa-Carleton', 'P4', 'POOR', 'SLOW', 2, 3, 'H', "CARSONBY SILT LOAM"],
	['Ottawa-Carleton', 'P5', 'VERY POOR', 'SLOW', 2, 3, 'H', "CARSONBY SILT LOAM"],
	['Ottawa-Carleton', 'Q1', 'WELL', 'MODERATE', 4, 1, 'L', "GALESBURG SANDY LOAM"],
	['Ottawa-Carleton', 'Q1.S', 'WELL', 'MODERATE', 3, 2, 'L', "GALESBURG SANDY LOAM"],
	['Ottawa-Carleton', 'Q2', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "QUEENSWAY SANDY LOAM"],
	['Ottawa-Carleton', 'Q3', 'POOR', 'SLOW', 4, 2, 'M', "QUEENSWAY SANDY LOAM"],
	['Ottawa-Carleton', 'Q4', 'WELL', 'MODERATE', 4, 1, 'L', "GALESBURG SANDY LOAM"],
	['Ottawa-Carleton', 'Q5', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "QUEENSWAY SANDY LOAM"],
	['Ottawa-Carleton', 'R1', 'IMPERFECT', 'SLOW', 3, 4, 'H', "RIDEAU CLAY"],
	['Ottawa-Carleton', 'R2', 'IMPERFECT', 'SLOW', 3, 4, 'H', "RIDEAU CLAY"],
	['Ottawa-Carleton', 'R3', 'POOR', 'VERY SLOW', 3, 4, 'H', "STE ROSALIE CLAY"],
	['Ottawa-Carleton', 'R3.C', 'POOR', 'VERY SLOW', 3, 4, 'H', "STE ROSALIE CLAY"],
	['Ottawa-Carleton', 'R3.S', 'POOR', 'SLOW', 2, 4, 'H', "STE ROSALIE CLAY"],
	['Ottawa-Carleton', 'R4.O', 'VERY POOR', 'SLOW', 3, 2, 'M', "LAPLAINE CLAY"],
	['Ottawa-Carleton', 'R5', 'POOR', 'VERY SLOW', 3, 4, 'H', "STE ROSALIE CLAY"],
	['Ottawa-Carleton', 'RE1', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "REEVECRAIG LOAMY VERY FINE SAND"],
	['Ottawa-Carleton', 'RE2', 'POOR', 'SLOW', 4, 2, 'M', "REEVECRAIG LOAMY VERY FINE SAND"],
	['Ottawa-Carleton', 'RE3', 'POOR', 'SLOW', 4, 2, 'M', "REEVECRAIG LOAMY VERY FINE SAND"],
	['Ottawa-Carleton', 'ST1', 'WELL', 'RAPID', 4, 1, 'L', "ST THOMAS FINE SAND"],
	['Ottawa-Carleton', 'ST2', 'WELL', 'RAPID', 4, 1, 'L', "ST THOMAS FINE SAND"],
	['Ottawa-Carleton', 'ST3', 'WELL', 'RAPID', 4, 1, 'L', "ST THOMAS FINE SAND"],
	['Ottawa-Carleton', 'ST4', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ACHIGAN LOAMY FINE SAND"],
	['Ottawa-Carleton', 'ST5', 'POOR', 'SLOW', 4, 2, 'M', "CHENEY LOAMY FINE SAND"],
	['Ottawa-Carleton', 'ST6', 'POOR', 'SLOW', 4, 2, 'M', "CHENEY LOAMY FINE SAND"],
	['Ottawa-Carleton', 'ST7', 'POOR', 'SLOW', 4, 2, 'M', "CHENEY LOAMY FINE SAND"],
	['Ottawa-Carleton', 'ST8', 'WELL', 'RAPID', 4, 1, 'L', "ST THOMAS FINE SAND"],
	['Ottawa-Carleton', 'U1', 'WELL', 'RAPID', 4, 1, 'L', "UPLANDS LOAMY SAND"],
	['Ottawa-Carleton', 'U10', 'POOR', 'SLOW', 4, 2, 'M', "ST SAMUEL FINE SANDY LOAM"],
	['Ottawa-Carleton', 'U11', 'POOR', 'SLOW', 4, 2, 'M', "ST SAMUEL FINE SANDY LOAM"],
	['Ottawa-Carleton', 'U12', 'POOR', 'SLOW', 4, 2, 'M', "ST SAMUEL FINE SANDY LOAM"],
	['Ottawa-Carleton', 'U13', 'POOR', 'SLOW', 4, 2, 'M', "ST SAMUEL FINE SANDY LOAM"],
	['Ottawa-Carleton', 'U14', 'VERY POOR', 'SLOW', 4, 2, 'M', "BORTHWICK ORGANIC"],
	['Ottawa-Carleton', 'U15', 'WELL', 'RAPID', 4, 1, 'L', "ST THOMAS FINE SAND"],
	['Ottawa-Carleton', 'U2', 'WELL', 'RAPID', 4, 1, 'L', "CARLSBAD LOAMY SAND"],
	['Ottawa-Carleton', 'U3', 'WELL', 'RAPID', 4, 1, 'L', "UPLANDS LOAMY SAND"],
	['Ottawa-Carleton', 'U4', 'WELL', 'RAPID', 4, 1, 'L', "CARLSBAD LOAMY SAND"],
	['Ottawa-Carleton', 'U5', 'WELL', 'RAPID', 4, 1, 'L', "UPLANDS LOAMY SAND"],
	['Ottawa-Carleton', 'U6', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RAMSAYVILLE LOAMY SAND"],
	['Ottawa-Carleton', 'U7', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RAMSAYVILLE LOAMY SAND"],
	['Ottawa-Carleton', 'U8', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RUBICON LOAMY SAND"],
	['Ottawa-Carleton', 'U9', 'POOR', 'SLOW', 4, 2, 'M', "ST SAMUEL FINE SANDY LOAM"],
	['Oxford', 'Bcl', 'POOR', 'VERY SLOW', 3, 4, 'H', "BROOKSTON CLAY LOAM"],
	['Oxford', 'Besl', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['Oxford', 'Bnsil', 'WELL', 'MODERATE', 2, 4, 'L', "BENNINGTON SILT LOAM"],
	['Oxford', 'Bosl', 'WELL', 'MODERATE', 4, 2, 'L', "BOOKTON SANDY LOAM"],
	['Oxford', 'Bsil', 'POOR', 'VERY SLOW', 3, 3, 'H', "BROOKSTON SILT LOAM"],
	['Oxford', 'Bul', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD LOAM"],
	['Oxford', 'Busl', 'WELL', 'RAPID', 4, 1, 'L', "BURFORD SANDY LOAM"],
	['Oxford', 'Bxsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRISBANE SANDY LOAM"],
	['Oxford', 'Byls', 'IMPERFECT', 'RAPID', 4, 2, 'M', "BRADY LOAMY SAND"],
	['Oxford', 'Bysl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SANDY LOAM"],
	['Oxford', 'Crsil', 'POOR', 'SLOW', 2, 3, 'H', "CROMBIE SILT LOAM"],
	['Oxford', 'Dosl', 'WELL', 'RAPID', 4, 1, 'L', "DONNYBROOK SANDY LOAM"],
	['Oxford', 'Emsil', 'IMPERFECT', 'SLOW', 2, 3, 'M', "EMBRO SILT LOAM"],
	['Oxford', 'Fsfsl', 'WELL', 'RAPID', 4, 2, 'L', "FOX FINE SANDY LOAM"],
	['Oxford', 'Fxls', 'WELL', 'RAPID', 4, 1, 'L', "FOX LOAMY SAND"],
	['Oxford', 'Fxls-r', 'WELL', 'RAPID', 4, 1, 'L', "FOX LOAMY SAND"],
	['Oxford', 'Fxsl', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Oxford', 'Fxsl-r', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Oxford', 'Gisl', 'POOR', 'SLOW', 4, 2, 'M', "GILFORD SANDY LOAM"],
	['Oxford', 'Gl', 'WELL', 'MODERATE', 1, 3, 'L', "GUELPH LOAM"],
	['Oxford', 'Grsl', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Oxford', 'Gsil', 'WELL', 'MODERATE', 1, 3, 'L', "GUELPH SILT LOAM"],
	['Oxford', 'Hosil', 'WELL', 'MODERATE', 1, 3, 'L', "HONEYWOOD SILT LOAM"],
	['Oxford', 'Hucl', 'WELL', 'SLOW', 2, 4, 'M', "HURON CLAY LOAM"],
	['Oxford', 'Husil', 'WELL', 'SLOW', 2, 4, 'L', "HURON SILT LOAM"],
	['Oxford', 'Lol', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LONDON LOAM"],
	['Oxford', 'Losil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LONDON SILT LOAM"],
	['Oxford', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Oxford', 'Mwsil', 'POOR', 'SLOW', 3, 3, 'H', "MAPLEWOOD SILT LOAM"],
	['Oxford', 'Pal', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL LOAM"],
	['Oxford', 'Pasil', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL SILT LOAM"],
	['Oxford', 'Pcl', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PERTH CLAY LOAM"],
	['Oxford', 'Psil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "PERTH SILT LOAM"],
	['Oxford', 'Tasil', 'IMPERFECT', 'SLOW', 2, 4, 'M', "TAVISTOCK SILT LOAM"],
	['Oxford', 'Wasl', 'POOR', 'SLOW', 4, 3, 'M', "WAUSEON SANDY LOAM"],

	// ['Parry Sound', 'Berriedale', 'GOOD', 'RAPID', 4, 2, 'L', "BERRIEDALE FINE SANDY LOAM"],
	['Parry Sound', 'Berriedale', 'WELL', 'RAPID', 4, 2, 'L', "BERRIEDALE FINE SANDY LOAM"],

	['Parry Sound', 'Ensl', 'POOR', 'SLOW', 4, 3, 'M', "ENGLEHART SANDY LOAM"],
	['Parry Sound', 'Hsil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "HIMSWORTH SILT LOAM"],
	['Parry Sound', 'Kfs', 'POOR', 'SLOW', 4, 2, 'M', "KENABEEK FINE SANDY LOAM"],
	['Parry Sound', 'Ksl', 'POOR', 'SLOW', 4, 2, 'M', "KENABEEK SANDY LOAM"],
	['Parry Sound', 'Ma', 'VERY POOR', 'SLOW', 3, 2, 'M', "MARSH ORGANIC"],
	['Parry Sound', 'Mdfs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD FINE SANDY LOAM"],
	['Parry Sound', 'Mdsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD SANDY LOAM"],

	// ['Parry Sound', 'Msl', 'GOOD', 'RAPID', 4, 1, 'L', "MONTEAGLE SANDY LOAM"],
	// ['Parry Sound', 'Mwsil', 'GOOD', 'SLOW', 2, 4, 'L', "MAGNETAWAN SILT LOAM"],
	['Parry Sound', 'Msl', 'WELL', 'RAPID', 4, 1, 'L', "MONTEAGLE SANDY LOAM"],
	['Parry Sound', 'Mwsil', 'WELL', 'SLOW', 2, 4, 'L', "MAGNETAWAN SILT LOAM"],

	['Parry Sound', 'O', 'VERY POOR', 'SLOW', 3, 2, 'M', "OTTERSKIN SANDY LOAM"],
	['Parry Sound', 'Ots', 'IMPERFECT', 'SLOW', 3, 2, 'M', "OTTERSKIN SANDY LOAM"],
	['Parry Sound', 'Posil', 'POOR', 'VERY SLOW', 2, 3, 'H', "POWASSAN SILT LOAM"],

	// ['Parry Sound', 'St.Peter', 'GOOD', 'RAPID', 4, 1, 'L', "ST PETERS GRAVELLY SANDY LOAM"],
	// ['Parry Sound', 'Wdsl', 'GOOD', 'RAPID', 4, 1, 'L', "WENDIGO SANDY LOAM"],
	['Parry Sound', 'St.Peter', 'WELL', 'RAPID', 4, 1, 'L', "ST PETERS GRAVELLY SANDY LOAM"],
	['Parry Sound', 'Wdsl', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO SANDY LOAM"],

	['Peel', 'Bes', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['Peel', 'Bkc', 'WELL', 'MODERATE', 1, 4, 'M', "BROCKPORT CLAY LOAM"],
	['Peel', 'Bos', 'WELL', 'MODERATE', 4, 2, 'L', "BOOKTON SANDY LOAM"],
	['Peel', 'Brsl', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON SANDY LOAM"],
	['Peel', 'Bsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SANDY LOAM"],
	['Peel', 'Cac', 'WELL', 'SLOW', 3, 3, 'M', "CASHEL CLAY"],
	['Peel', 'Cg', 'WELL', 'RAPID', 4, 2, 'L', "CALEDON LOAM"],
	['Peel', 'Chc', 'IMPERFECT', 'SLOW', 3, 4, 'H', "CHINGUACOUSY CLAY LOAM"],
	['Peel', 'Ckc', 'IMPERFECT', 'MODERATE', 3, 4, 'H', "COOKSVILLE CLAY LOAM"],
	['Peel', 'Dl', 'WELL', 'RAPID', 4, 2, 'L', "DUMFRIES LOAM"],
	['Peel', 'Ds', 'WELL', 'RAPID', 4, 1, 'L', "DUMFRIES SANDY LOAM"],
	['Peel', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Peel', 'Fs', 'WELL', 'RAPID', 4, 1, 'L', "FOX SAND"],
	['Peel', 'Fsl', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Peel', 'Gil', 'POOR', 'SLOW', 3, 3, 'H', "GILFORD LOAM"],
	['Peel', 'Hl', 'WELL', 'MODERATE', 1, 3, 'L', "HARRISTON LOAM"],
	['Peel', 'Jc', 'POOR', 'VERY SLOW', 3, 4, 'H', "JEDDO CLAY LOAM"],
	['Peel', 'Kic', 'WELL', 'SLOW', 2, 4, 'M', "KING CLAY LOAM"],
	['Peel', 'Lil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LISTOWEL LOAM"],
	['Peel', 'Ll', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Peel', 'Loc', 'WELL', 'MODERATE', 2, 4, 'M', "LOCKPORT CLAY"],
	['Peel', 'Lyl', 'POOR', 'SLOW', 2, 3, 'H', "LILY LOAM"],
	['Peel', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Peel', 'Mac', 'POOR', 'VERY SLOW', 3, 3, 'H', "MALTON CLAY"],
	['Peel', 'Mic', 'POOR', 'VERY SLOW', 3, 4, 'H', "MISSISSAUGA CLAY LOAM"],
	['Peel', 'Ml', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MILLIKEN LOAM"],
	['Peel', 'Moc', 'IMPERFECT', 'SLOW', 3, 4, 'H', "MONOGHAN CLAY LOAM"],
	['Peel', 'Oc', 'WELL', 'SLOW', 3, 4, 'H', "ONEIDA CLAY LOAM"],
	['Peel', 'Pal', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL LOAM"],
	['Peel', 'Pec', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PEEL CLAY"],
	['Peel', 'Psl', 'WELL', 'RAPID', 4, 1, 'L', "PONTYPOOL SANDY LOAM"],
	['Peel', 'Trc', 'IMPERFECT', 'MODERATE', 2, 4, 'H', "TRAFALGAR CLAY"],
	['Peel', 'Wol', 'WELL', 'MODERATE', 1, 3, 'L', "WOBURN LOAM"],
	['Perth', 'Bc', 'POOR', 'VERY SLOW', 3, 4, 'H', "BROOKSTON CLAY LOAM"],
	['Perth', 'Bg', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD LOAM"],
	['Perth', 'Bs', 'POOR', 'VERY SLOW', 3, 3, 'H', "BROOKSTON SILT LOAM"],
	['Perth', 'Dsl', 'WELL', 'RAPID', 4, 1, 'L', "DONNYBROOK SANDY LOAM"],
	['Perth', 'Gil', 'POOR', 'SLOW', 3, 3, 'H', "GILFORD LOAM"],
	['Perth', 'Gl', 'WELL', 'MODERATE', 1, 3, 'L', "GUELPH LOAM"],
	['Perth', 'Hsi', 'WELL', 'MODERATE', 1, 3, 'L', "HARRISTON SILT LOAM"],
	['Perth', 'Huc', 'WELL', 'SLOW', 2, 4, 'M', "HURON CLAY LOAM"],
	['Perth', 'Hus', 'WELL', 'SLOW', 2, 4, 'L', "HURON SILT LOAM"],
	['Perth', 'Ll', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LONDON LOAM"],
	['Perth', 'Lsi', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LISTOWEL SILT LOAM"],
	['Perth', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Perth', 'Pc', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PERTH CLAY LOAM"],
	['Perth', 'Pl', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL LOAM"],
	['Perth', 'Ps', 'IMPERFECT', 'SLOW', 3, 4, 'M', "PERTH SILT LOAM"],
	['Perth', 'Wsl', 'WELL', 'RAPID', 4, 1, 'L', "WATERLOO SANDY LOAM"],
	['Peterborough', 'An', 'VERY POOR', 'SLOW', 3, 2, 'M', "ANGERS ORGANIC"],
	['Peterborough', 'Ay', 'VERY POOR', 'SLOW', 3, 2, 'M', "ANSON ORGANIC"],
	['Peterborough', 'Bl', 'WELL', 'SLOW', 4, 2, 'L', "BONDHEAD LOAM"],
	['Peterborough', 'Bls', 'WELL', 'RAPID', 3, 2, 'L', "BALMER SANDY LOAM"],
	['Peterborough', 'Bmsl', 'WELL', 'RAPID', 3, 2, 'L', "BELMONT SANDY LOAM"],
	['Peterborough', 'Bol', 'POOR', 'SLOW', 3, 3, 'H', "BROOKE LOAM"],
	['Peterborough', 'Brs', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON SANDY LOAM"],
	['Peterborough', 'Brsl', 'WELL', 'RAPID', 4, 1, 'L', "BRINCO SANDY LOAM"],
	['Peterborough', 'Bsl', 'WELL', 'RAPID', 4, 1, 'L', "BONDHEAD FINE SANDY LOAM"],
	['Peterborough', 'By', 'VERY POOR', 'SLOW', 3, 2, 'M', "BAILEY ORGANIC"],
	['Peterborough', 'Cb', 'VERY POOR', 'SLOW', 3, 2, 'M', "COLBAR ORGANIC"],
	['Peterborough', 'Cds', 'WELL', 'MODERATE', 1, 4, 'L', "CHANDOS LOAMY SAND"],
	['Peterborough', 'Cos', 'WELL', 'RAPID', 4, 1, 'L', "COLBORNE SANDY LOAM"],
	['Peterborough', 'Cs', 'WELL', 'RAPID', 4, 1, 'L', "CRAMAHE GRAVELLY SANDY LOAM"],
	['Peterborough', 'Ct', 'VERY POOR', 'SLOW', 3, 2, 'M', "COATES ORGANIC"],
	['Peterborough', 'Del', 'WELL', 'MODERATE', 1, 3, 'L', "DELORO LOAM"],
	['Peterborough', 'Dl', 'WELL', 'MODERATE', 4, 2, 'L', "DUMMER LOAM"],
	['Peterborough', 'Drl', 'WELL', 'MODERATE', 1, 4, 'L', "DOURO LOAM"],
	['Peterborough', 'Drsl', 'WELL', 'MODERATE', 1, 4, 'L', "DOURO SANDY LOAM"],
	['Peterborough', 'Dsl', 'WELL', 'RAPID', 4, 1, 'L', "DUMMER SANDY LOAM"],
	['Peterborough', 'Dusl', 'WELL', 'MODERATE', 4, 1, 'L', "DUNDONALD SANDY LOAM"],
	['Peterborough', 'El', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "EMILY LOAM"],
	['Peterborough', 'Eol', 'WELL', 'MODERATE', 1, 3, 'L', "ELDORADO LOAM"],
	['Peterborough', 'Es', 'VERY POOR', 'SLOW', 3, 2, 'M', "EDENVALE SANDY LOAM"],
	['Peterborough', 'Ffsl', 'POOR', 'SLOW', 1, 3, 'L', "FOXBORO FINE SANDY LOAM"],
	['Peterborough', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Peterborough', 'Fns', 'IMPERFECT', 'MODERATE', 2, 4, 'M', "FRANKTOWN LOAM"],
	['Peterborough', 'Fsil', 'POOR', 'SLOW', 2, 3, 'H', "FOXBORO SILT LOAM"],
	['Peterborough', 'Fsl', 'WELL', 'MODERATE', 3, 2, 'L', "FARMINGTON SANDY LOAM"],
	['Peterborough', 'Grsl', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Peterborough', 'Gws', 'IMPERFECT', 'RAPID', 3, 2, 'M', "GALWAY SANDY LOAM"],
	['Peterborough', 'Hrl', 'POOR', 'MODERATE', 3, 3, 'H', "HARNEY LOAM"],
	['Peterborough', 'Hs', 'VERY POOR', 'SLOW', 3, 2, 'M', "HASTY ORGANIC"],
	['Peterborough', 'Ks', 'POOR', 'SLOW', 4, 2, 'M', "KENABEEK SANDY LOAM"],
	['Peterborough', 'Lo', 'VERY POOR', 'SLOW', 3, 2, 'M', "LORETTO ORGANIC"],
	['Peterborough', 'Lol', 'WELL', 'MODERATE', 1, 4, 'L', "LONG LOAM"],
	['Peterborough', 'Lyl', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Peterborough', 'Ma', 'VERY POOR', 'SLOW', 3, 2, 'M', "MARSH ORGANIC"],
	['Peterborough', 'Mes', 'WELL', 'RAPID', 3, 2, 'L', "METHUEN SANDY LOAM"],
	['Peterborough', 'Mh', 'VERY POOR', 'SLOW', 3, 2, 'M', "MINDEN ORGANIC"],
	['Peterborough', 'Ms', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD SANDY LOAM"],
	['Peterborough', 'Msl', 'WELL', 'RAPID', 4, 1, 'L', "MONTEAGLE SANDY LOAM"],
	['Peterborough', 'Np', 'VERY POOR', 'SLOW', 3, 2, 'M', "NAVAN ORGANIC"],
	['Peterborough', 'O', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC ORGANIC"],
	['Peterborough', 'Ol', 'WELL', 'MODERATE', 4, 2, 'L', "OTONABEE LOAM"],
	['Peterborough', 'Osl', 'WELL', 'MODERATE', 4, 1, 'L', "OTONABEE SANDY LOAM"],
	['Peterborough', 'Pf', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEFFERLAW ORGANIC"],
	['Peterborough', 'Rkl', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "ROCKCROFT SANDY LOAM"],
	['Peterborough', 'SCl', 'POOR', 'MODERATE', 4, 3, 'M', "SMITHFIELD CLAY LOAM"],
	['Peterborough', 'SCsil', 'POOR', 'MODERATE', 3, 3, 'H', "ST CROIX SILT LOAM"],
	['Peterborough', 'Sd', 'VERY POOR', 'SLOW', 3, 2, 'M', "SHELDRAKE ORGANIC"],
	['Peterborough', 'Sic', 'POOR', 'VERY SLOW', 3, 4, 'H', "SIMCOE SILTY CLAY LOAM"],
	['Peterborough', 'Sm', 'VERY POOR', 'SLOW', 3, 2, 'M', "SEVERN ORGANIC"],
	['Peterborough', 'Socl', 'IMPERFECT', 'SLOW', 2, 3, 'H', "SOLMESVILLE CLAY LOAM"],
	['Peterborough', 'Sosicl', 'IMPERFECT', 'SLOW', 2, 3, 'H', "SOLMESVILLE SILTY CLAY LOAM"],
	['Peterborough', 'SPg', 'WELL', 'RAPID', 4, 1, 'L', "ST PETERS GRAVELLY SANDY LOAM"],
	['Peterborough', 'Tes', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "TECUMSETH SANDY LOAM"],
	['Peterborough', 'Tfsl', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TRENT FINE SANDY LOAM"],
	['Peterborough', 'Tis', 'WELL', 'RAPID', 4, 1, 'L', "TIOGA SANDY LOAM"],
	['Peterborough', 'To', 'VERY POOR', 'SLOW', 3, 2, 'M', "TALLON ORGANIC"],
	['Peterborough', 'Tsil', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TRENT SILT LOAM"],
	['Peterborough', 'Tws', 'WELL', 'RAPID', 3, 2, 'L', "TWEED SANDY LOAM"],
	['Peterborough', 'Uh', 'VERY POOR', 'SLOW', 3, 2, 'M', "UHTHOFF ORGANIC"],
	['Peterborough', 'Vr', 'VERY POOR', 'SLOW', 3, 2, 'M', "VESPRA ORGANIC"],
	['Peterborough', 'Vt', 'VERY POOR', 'SLOW', 3, 2, 'M', "VALLENTYNE ORGANIC"],
	['Peterborough', 'Wes', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO LOAMY SAND"],
	['Peterborough', 'Wh', 'VERY POOR', 'SLOW', 3, 2, 'M', "WASHAGO ORGANIC"],
	['Peterborough', 'WLs', 'WELL', 'RAPID', 4, 1, 'L', "WHITE LAKE GRAVELLY SANDY LOAM"],
	['Peterborough', 'Ws', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "WEMYSS LOAMY SAND"],
	['Peterborough', 'Wsc', 'WELL', 'SLOW', 2, 4, 'M', "WAUPOOS SILTY CLAY LOAM"],
	['Prescott', 'Afsl', 'POOR', 'SLOW', 3, 3, 'H', "ALLENDALE FINE SANDY LOAM"],
	['Prescott', 'Bc', 'POOR', 'VERY SLOW', 3, 3, 'H', "BEARBROOK CLAY"],
	['Prescott', 'Bc-ss', 'POOR', 'VERY SLOW', 4, 3, 'M', "ALLENDALE FINE SANDY LOAM"],
	['Prescott', 'Bsicl', 'POOR', 'VERY SLOW', 3, 4, 'H', "BEARBROOK SILTY CLAY LOAM"],
	['Prescott', 'Bsil', 'POOR', 'SLOW', 3, 3, 'H', "BAINSVILLE SILT LOAM"],
	['Prescott', 'Cfsl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CASTOR FINE SANDY LOAM"],
	['Prescott', 'Cfsl-sh', 'IMPERFECT', 'MODERATE', 2, 4, 'M', "CASTOR FINE SANDY LOAM"],
	['Prescott', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Prescott', 'Gl', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Prescott', 'Gl-sh', 'WELL', 'RAPID', 1, 4, 'L', "GRENVILLE LOAM"],
	['Prescott', 'Gl-st', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Prescott', 'Kgsl', 'WELL', 'RAPID', 4, 1, 'L', "KARS GRAVELLY SANDY LOAM"],
	['Prescott', 'Ll', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Prescott', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Prescott', 'Mnfsl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "MOUNTAIN FINE SANDY LOAM"],
	['Prescott', 'Mtl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MATILDA LOAM"],
	['Prescott', 'Mtl-sh', 'IMPERFECT', 'RAPID', 2, 4, 'M', "MATILDA LOAM"],
	['Prescott', 'Mtl-st', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MATILDA LOAM"],
	['Prescott', 'NGcl', 'POOR', 'VERY SLOW', 3, 4, 'H', "CARP CLAY LOAM"],
	['Prescott', 'P', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEAT ORGANIC"],
	['Prescott', 'Rfs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ACHIGAN FINE SAND"],
	['Prescott', 'Rfsl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "ACHIGAN FINE SANDY LOAM"],
	['Prescott', 'Roc', 'POOR', 'VERY SLOW', 3, 3, 'H', "RIDEAU CLAY"],
	['Prescott', 'Sfs', 'POOR', 'SLOW', 4, 2, 'M', "ACHIGAN FINE SAND"],
	['Prescott', 'Ufs', 'WELL', 'RAPID', 4, 1, 'L', "CHENEY FINE SAND"],
	['Prescott', 'Ufsl', 'WELL', 'RAPID', 4, 2, 'L', "ST THOMAS FINE SAND"],
	['Prescott', 'Wc', 'IMPERFECT', 'VERY SLOW', 3, 4, 'L', "ERODED VARIABLE"],
	['Prescott', 'Wc-ss', 'IMPERFECT', 'VERY SLOW', 3, 2, 'M', "MOUNTAIN FINE SANDY LOAM"],
	['Prince Edward', 'Acl', 'WELL', 'VERY SLOW', 1, 4, 'M', "AMELIASBURG CLAY LOAM"],
	['Prince Edward', 'Al', 'WELL', 'VERY SLOW', 1, 4, 'L', "AMELIASBURG LOAM"],
	['Prince Edward', 'Asl', 'WELL', 'RAPID', 3, 2, 'L', "ATHOL SANDY LOAM"],
	['Prince Edward', 'Bg', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON GRAVELLY SANDY LOAM"],
	['Prince Edward', 'Bs', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON SANDY LOAM"],
	['Prince Edward', 'Dl', 'WELL', 'MODERATE', 2, 4, 'L', "DARLINGTON LOAM"],
	['Prince Edward', 'Ec', 'IMPERFECT', 'SLOW', 3, 4, 'H', "ELMBROOK CLAY"],
	['Prince Edward', 'Ecl', 'IMPERFECT', 'SLOW', 3, 4, 'H', "ELMBROOK CLAY LOAM"],
	['Prince Edward', 'Es', 'WELL', 'RAPID', 4, 1, 'L', "EASTPORT SAND"],
	['Prince Edward', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Prince Edward', 'Gc', 'POOR', 'SLOW', 3, 4, 'H', "GEROW CLAY LOAM"],
	['Prince Edward', 'Gs', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Prince Edward', 'Hc', 'WELL', 'MODERATE', 1, 4, 'M', "HILLIER CLAY LOAM"],
	['Prince Edward', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Prince Edward', 'Pfs', 'WELL', 'MODERATE', 2, 4, 'L', "PERCY FINE SANDY LOAM"],
	['Prince Edward', 'Ps', 'WELL', 'RAPID', 4, 1, 'L', "PONTYPOOL SAND"],
	['Prince Edward', 'Psl', 'WELL', 'RAPID', 4, 1, 'L', "PONTYPOOL SANDY LOAM"],
	['Prince Edward', 'SBc', 'WELL', 'SLOW', 3, 3, 'M', "SOUTH BAY CLAY"],
	['Prince Edward', 'SBcl', 'WELL', 'SLOW', 2, 4, 'M', "SOUTH BAY CLAY LOAM"],
	['Prince Edward', 'Sc', 'IMPERFECT', 'SLOW', 3, 3, 'H', "SOLMESVILLE CLAY LOAM"],
	['Prince Edward', 'Tsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "TECUMSETH SANDY LOAM"],
	['Prince Edward', 'Wc', 'WELL', 'SLOW', 3, 3, 'M', "WAUPOOS CLAY"],
	['Rainy River', 'Acl', 'IMPERFECT', 'SLOW', 4, 4, 'H', "ATHOL CLAY LOAM"],
	['Rainy River', 'Arcl', 'POOR', 'VERY SLOW', 3, 4, 'H', "ARBOR VITAE CLAY LOAM"],
	['Rainy River', 'Arfl', 'POOR', 'VERY SLOW', 3, 3, 'H', "ARBOR VITAE FINE SANDY LOAM"],
	['Rainy River', 'Arsl', 'POOR', 'SLOW', 4, 3, 'M', "ARBOR VITAE SANDY LOAM"],
	['Rainy River', 'Bgfl', 'POOR', 'SLOW', 3, 3, 'H', "BERGLAND FINE SANDY LOAM"],
	['Rainy River', 'Bgl', 'POOR', 'SLOW', 3, 3, 'H', "BERGLAND LOAM"],
	['Rainy River', 'Bhcl', 'IMPERFECT', 'SLOW', 4, 3, 'H', "BARNHART CLAY LOAM"],
	['Rainy River', 'Bhsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BARNHART SANDY LOAM"],
	['Rainy River', 'Blc', 'VERY POOR', 'SLOW', 3, 2, 'M', "BLUETT CLAY"],
	['Rainy River', 'Blcl', 'VERY POOR', 'SLOW', 3, 2, 'M', "BLUETT CLAY LOAM"],
	['Rainy River', 'Bl-P', 'VERY POOR', 'SLOW', 3, 2, 'M', "BLUETT ORGANIC"],
	['Rainy River', 'Blsl', 'VERY POOR', 'SLOW', 3, 2, 'M', "BLUETT SANDY LOAM"],
	['Rainy River', 'Bucl', 'POOR', 'VERY SLOW', 4, 4, 'H', "BURDITT CLAY LOAM"],
	['Rainy River', 'Bul', 'POOR', 'SLOW', 3, 3, 'H', "BURDITT LOAM"],
	['Rainy River', 'Cbg', 'POOR', 'SLOW', 4, 2, 'M', "CRANBERRY GRAVELLY SANDY LOAM"],
	['Rainy River', 'Cbsl', 'POOR', 'SLOW', 4, 2, 'M', "CRANBERRY SANDY LOAM"],
	['Rainy River', 'Cl-P', 'POOR', 'SLOW', 4, 2, 'M', "CLAXTON ORGANIC"],
	['Rainy River', 'Clsl', 'POOR', 'SLOW', 4, 2, 'M', "CLAXTON SANDY LOAM"],
	['Rainy River', 'Cn', 'VERY POOR', 'SLOW', 3, 2, 'M', "CURRAN ORGANIC"],
	['Rainy River', 'Cpc', 'POOR', 'VERY SLOW', 3, 3, 'H', "CARPENTER CLAY"],
	['Rainy River', 'Cpcl', 'POOR', 'VERY SLOW', 3, 3, 'H', "CARPENTER CLAY LOAM"],
	['Rainy River', 'Cpsicl', 'POOR', 'VERY SLOW', 3, 3, 'H', "CARPENTER SILTY CLAY LOAM"],
	['Rainy River', 'Cpsl', 'POOR', 'SLOW', 4, 3, 'M', "CARPENTER SANDY LOAM"],
	['Rainy River', 'Cwgsl', 'WELL', 'RAPID', 4, 1, 'L', "CLEARWAY GRAVELLY SANDY LOAM"],
	['Rainy River', 'Cwsl', 'WELL', 'RAPID', 4, 1, 'L', "CLEARWAY SANDY LOAM"],
	['Rainy River', 'Czc', 'POOR', 'VERY SLOW', 3, 3, 'H', "CROZIER CLAY"],
	['Rainy River', 'Czcl', 'POOR', 'VERY SLOW', 3, 3, 'H', "CROZIER CLAY LOAM"],
	['Rainy River', 'Czl', 'POOR', 'VERY SLOW', 2, 3, 'H', "CROZIER LOAM"],
	['Rainy River', 'Cz-P', 'POOR', 'VERY SLOW', 2, 3, 'H', "CROZIER ORGANIC"],
	['Rainy River', 'Dcsl', 'IMPERFECT', 'SLOW', 3, 2, 'M', "DANCE SANDY LOAM"],
	['Rainy River', 'Dkfl', 'POOR', 'SLOW', 3, 3, 'H', "DILKE FINE SANDY LOAM"],
	['Rainy River', 'Dksl', 'POOR', 'SLOW', 4, 2, 'M', "DILKE SANDY LOAM"],
	['Rainy River', 'Dmc', 'WELL', 'SLOW', 3, 3, 'M', "DUCHARME CLAY"],
	['Rainy River', 'Dmcl', 'WELL', 'SLOW', 4, 4, 'M', "DUCHARME CLAY LOAM"],
	['Rainy River', 'Dmsl', 'WELL', 'MODERATE', 4, 2, 'L', "DUCHARME SANDY LOAM"],
	['Rainy River', 'Dvc', 'IMPERFECT', 'SLOW', 3, 3, 'H', "DEVLIN CLAY"],
	['Rainy River', 'Dvcl', 'IMPERFECT', 'SLOW', 3, 3, 'H', "DEVLIN CLAY LOAM"],
	['Rainy River', 'Dvsil', 'IMPERFECT', 'SLOW', 2, 4, 'M', "DEVLIN SILT LOAM"],
	['Rainy River', 'Dvsl', 'IMPERFECT', 'SLOW', 3, 2, 'M', "DEVLIN SANDY LOAM"],
	['Rainy River', 'Dwfl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "DEWART FINE SANDY LOAM"],
	['Rainy River', 'Dwsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "DEWART SANDY LOAM"],
	['Rainy River', 'Ec', 'POOR', 'VERY SLOW', 3, 3, 'H', "EMO CLAY"],
	['Rainy River', 'Ecl', 'POOR', 'VERY SLOW', 3, 4, 'H', "EMO CLAY LOAM"],
	['Rainy River', 'El', 'POOR', 'VERY SLOW', 3, 3, 'H', "EMO LOAM"],
	['Rainy River', 'E-P', 'POOR', 'VERY SLOW', 3, 3, 'H', "EMO ORGANIC"],
	['Rainy River', 'Esil', 'POOR', 'VERY SLOW', 3, 3, 'H', "EMO SILT LOAM"],
	['Rainy River', 'Fc', 'WELL', 'SLOW', 3, 3, 'M', "FLEMINGO CLAY"],
	['Rainy River', 'Fcl', 'WELL', 'SLOW', 4, 4, 'M', "FLEMINGO CLAY LOAM"],
	['Rainy River', 'FFc', 'VERY POOR', 'SLOW', 3, 2, 'M', "FORT FRANCES CLAY"],
	['Rainy River', 'FFcl', 'VERY POOR', 'SLOW', 3, 4, 'M', "FORT FRANCES CLAY LOAM"],
	['Rainy River', 'FF-P', 'VERY POOR', 'SLOW', 3, 2, 'M', "FORT FRANCES ORGANIC"],
	['Rainy River', 'Fsil', 'WELL', 'SLOW', 2, 4, 'L', "FLEMINGO SILT LOAM"],
	['Rainy River', 'Fsl', 'WELL', 'MODERATE', 3, 2, 'L', "FLEMINGO SANDY LOAM"],
	['Rainy River', 'Gdfl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "GAMELAND FINE SANDY LOAM"],
	['Rainy River', 'Gdsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "GAMELAND SANDY LOAM"],
	['Rainy River', 'Gel', 'IMPERFECT', 'SLOW', 3, 4, 'M', "GRACE LOAM"],
	['Rainy River', 'Gesil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "GRACE SILT LOAM"],
	['Rainy River', 'Gysl', 'WELL', 'MODERATE', 4, 1, 'L', "GRASSEY SANDY LOAM"],
	['Rainy River', 'Hfl', 'POOR', 'VERY SLOW', 2, 3, 'H', "HARREY FINE SANDY LOAM"],
	['Rainy River', 'HH', 'VERY POOR', 'SLOW', 3, 2, 'M', "HARRIS HILL ORGANIC"],
	['Rainy River', 'HN', 'VERY POOR', 'SLOW', 3, 2, 'M', "HARRIS HILL ORGANIC"],
	['Rainy River', 'Hvsl', 'POOR', 'VERY SLOW', 2, 3, 'H', "HARREY VERY FINE SANDY LOAM"],
	['Rainy River', 'IN', 'VERY POOR', 'SLOW', 3, 2, 'M', "INNES LAKE ORGANIC"],
	['Rainy River', 'Kdcl', 'WELL', 'SLOW', 4, 4, 'M', "KINGSFORD CLAY LOAM"],
	['Rainy River', 'Kdfl', 'WELL', 'SLOW', 2, 4, 'L', "KINGSFORD FINE SANDY LOAM"],
	['Rainy River', 'Kdsl', 'WELL', 'MODERATE', 3, 2, 'L', "KINGSFORD SANDY LOAM"],
	['Rainy River', 'LVsil', 'WELL', 'SLOW', 2, 4, 'L', "LA VALLEE SILT LOAM"],
	['Rainy River', 'Ma', 'VERY POOR', 'SLOW', 3, 2, 'M', "MARSH ORGANIC"],
	['Rainy River', 'Me-P', 'VERY POOR', 'SLOW', 3, 2, 'M', "MARIE ORGANIC"],
	['Rainy River', 'Mifl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MISCAMPBELL FINE SANDY LOAM"],
	['Rainy River', 'Misl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MISCAMPBELL SANDY LOAM"],
	['Rainy River', 'Mlg', 'WELL', 'RAPID', 4, 1, 'L', "MORLEY GRAVEL"],
	['Rainy River', 'Mlsl', 'WELL', 'RAPID', 4, 1, 'L', "MORLEY SANDY LOAM"],
	['Rainy River', 'Mofl', 'VERY POOR', 'SLOW', 3, 2, 'M', "MORSON FINE SANDY LOAM"],
	['Rainy River', 'Mosl', 'VERY POOR', 'SLOW', 3, 2, 'M', "MORSON SANDY LOAM"],
	['Rainy River', 'Mysl', 'WELL', 'RAPID', 4, 1, 'L', "MENARY SANDY LOAM"],
	['Rainy River', 'Mz', 'VERY POOR', 'SLOW', 3, 2, 'M', "MURILLO ORGANIC"],
	['Rainy River', 'NBc', 'IMPERFECT', 'SLOW', 4, 4, 'H', "NORTH BRANCH CLAY"],
	['Rainy River', 'NBcl', 'IMPERFECT', 'SLOW', 4, 4, 'H', "NORTH  BRANCH CLAY LOAM"],
	['Rainy River', 'NBl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "NORTH  BRANCH LOAM"],
	['Rainy River', 'O', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC ORGANIC"],
	['Rainy River', 'Ofsl', 'WELL', 'MODERATE', 4, 1, 'L', "OFF SANDY LOAM"],
	['Rainy River', 'Pa', 'VERY POOR', 'SLOW', 3, 2, 'M', "PASS ORGANIC"],
	['Rainy River', 'Pwg', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "PINEWOOD GRAVEL"],
	['Rainy River', 'Pwsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "PINEWOOD SANDY LOAM"],
	['Rainy River', 'Rifl', 'WELL', 'RAPID', 4, 2, 'L', "RICHARDSON FINE SANDY LOAM"],
	['Rainy River', 'Rocl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "ROSE CLAY LOAM"],
	['Rainy River', 'Rofl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "ROSE FINE SANDY LOAM"],
	['Rainy River', 'Rosil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "ROSE SILT LOAM"],
	['Rainy River', 'Rosl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ROSE SANDY LOAM"],
	['Rainy River', 'RR', 'VERY POOR', 'SLOW', 3, 2, 'M', "RAINY RIVER ORGANIC"],
	['Rainy River', 'Rwfl', 'WELL', 'RAPID', 4, 2, 'L', "ROWE FINE SANDY LOAM"],
	['Rainy River', 'Rwsl', 'WELL', 'RAPID', 4, 1, 'L', "ROWE SANDY LOAM"],
	['Rainy River', 'Sdsil', 'VERY POOR', 'SLOW', 3, 2, 'M', "SUNDERLAND SILT LOAM"],
	['Rainy River', 'Sf-P', 'VERY POOR', 'SLOW', 3, 2, 'M', "SIFLET ORGANIC"],
	['Rainy River', 'Sfsicl', 'VERY POOR', 'SLOW', 3, 2, 'M', "SIFLET SILTY CLAY LOAM"],
	['Rainy River', 'Sfsil', 'VERY POOR', 'SLOW', 3, 2, 'M', "SIFLET SILT LOAM"],
	['Rainy River', 'Slsl', 'WELL', 'RAPID', 4, 1, 'L', "SLEEMAN SANDY LOAM"],
	['Rainy River', 'Ss', 'VERY POOR', 'SLOW', 3, 2, 'M', "SHENSTON ORGANIC"],
	['Rainy River', 'Tkc', 'WELL', 'SLOW', 3, 3, 'M', "THOMPKIN CLAY"],
	['Rainy River', 'Tkl', 'WELL', 'SLOW', 2, 4, 'L', "THOMPKIN LOAM"],
	['Rainy River', 'Tv-P', 'VERY POOR', 'SLOW', 3, 2, 'M', "TOVELL ORGANIC"],
	['Rainy River', 'Tvsl', 'VERY POOR', 'SLOW', 3, 2, 'M', "TOVELL SANDY LOAM"],
	['Rainy River', 'Wdsl', 'WELL', 'MODERATE', 3, 2, 'L', "WEIRD SANDY LOAM"],
	['Rainy River', 'Wg', 'WELL', 'RAPID', 4, 1, 'L', "WORTHINGTON GRAVEL"],
	['Rainy River', 'Wocl', 'POOR', 'VERY SLOW', 3, 3, 'H', "WOOD CLAY LOAM"],
	['Rainy River', 'Wofl', 'POOR', 'VERY SLOW', 2, 3, 'H', "WOOD FINE SANDY LOAM"],
	['Rainy River', 'Wsil', 'WELL', 'RAPID', 4, 2, 'L', "WORTHINGTON SILT LOAM"],
	['Rainy River', 'Wsl', 'WELL', 'RAPID', 4, 1, 'L', "WORTHINGTON SANDY LOAM"],
	['Rainy River', 'Wycl', 'POOR', 'VERY SLOW', 2, 3, 'H', "WOODYATT CLAY LOAM"],
	['Rainy River', 'Wyl', 'POOR', 'VERY SLOW', 2, 3, 'H', "WOODYATT LOAM"],
	['Rainy River', 'Wysil', 'POOR', 'VERY SLOW', 2, 3, 'H', "WOODYATT SILT LOAM"],
	['Renfrew', 'Afsl', 'POOR', 'SLOW', 3, 3, 'H', "ALLENDALE FINE SANDY LOAM"],
	['Renfrew', 'Bl', 'WELL', 'MODERATE', 4, 2, 'L', "BURNSTOWN LOAM"],
	['Renfrew', 'Bl-r', 'WELL', 'MODERATE', 4, 2, 'L', "BURNSTOWN LOAM"],
	['Renfrew', 'Bl-sh', 'WELL', 'RAPID', 1, 4, 'L', "BURNSTOWN LOAM"],
	['Renfrew', 'Egl', 'WELL', 'MODERATE', 1, 3, 'L', "EGANVILLE LOAM"],
	['Renfrew', 'Egl-r', 'WELL', 'MODERATE', 1, 3, 'L', "EGANVILLE LOAM"],
	['Renfrew', 'Egl-sh', 'WELL', 'RAPID', 1, 4, 'L', "EGANVILLE LOAM"],
	['Renfrew', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Renfrew', 'Grsl', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Renfrew', 'Kg', 'WELL', 'RAPID', 4, 1, 'L', "KARS GRAVELLY SANDY LOAM"],
	['Renfrew', 'Lyl', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Renfrew', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Renfrew', 'Masl', 'WELL', 'MODERATE', 4, 2, 'L', "MANOTICK SANDY LOAM"],
	['Renfrew', 'Mns', 'IMPERFECT', 'SLOW', 3, 2, 'M', "MOUNTAIN SANDY LOAM"],
	['Renfrew', 'Msl', 'WELL', 'MODERATE', 4, 1, 'L', "MONTEAGLE SANDY LOAM"],
	['Renfrew', 'Ol', 'POOR', 'SLOW', 2, 3, 'H', "OSGOODE LOAM"],
	['Renfrew', 'P', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEAT ORGANIC"],
	['Renfrew', 'Rc', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "RIDEAU CLAY"],
	['Renfrew', 'Rc-r', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "RIDEAU CLAY"],
	['Renfrew', 'Rc-S', 'IMPERFECT', 'SLOW', 3, 3, 'M', "RIDEAU CLAY"],
	['Renfrew', 'Rec', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "RENFREW CLAY"],
	['Renfrew', 'Rec-r', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "RENFREW CLAY"],
	['Renfrew', 'Rec-S', 'IMPERFECT', 'SLOW', 3, 3, 'M', "RENFREW CLAY"],
	['Renfrew', 'Roc', 'POOR', 'VERY SLOW', 3, 3, 'H', "STE ROSALIE CLAY"],
	['Renfrew', 'Rs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RUBICON SANDY LOAM"],
	['Renfrew', 'Sfs', 'POOR', 'SLOW', 3, 3, 'H', "ST SAMUEL FINE SANDY LOAM"],
	['Renfrew', 'SPg', 'WELL', 'RAPID', 4, 1, 'L', "ST PETERS GRAVELLY SANDY LOAM"],
	['Renfrew', 'SPg-r', 'WELL', 'RAPID', 4, 1, 'L', "ST PETERS GRAVELLY SANDY LOAM"],
	['Renfrew', 'Stl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "STAFFORD LOAM"],
	['Renfrew', 'Tws', 'WELL', 'MODERATE', 3, 2, 'L', "TWEED SANDY LOAM"],
	['Renfrew', 'Ufs', 'WELL', 'RAPID', 4, 2, 'L', "UPLANDS FINE SANDY LOAM"],
	['Renfrew', 'Us', 'WELL', 'RAPID', 4, 1, 'L', "UPLANDS LOAMY SAND"],
	['Renfrew', 'Us-r', 'WELL', 'RAPID', 4, 1, 'L', "UPLANDS LOAMY SAND"],
	['Renfrew', 'Wgs', 'WELL', 'RAPID', 4, 1, 'L', "WESTMEATH GRAVELLY SANDY LOAM"],
	['Renfrew', 'WLs', 'WELL', 'RAPID', 4, 1, 'L', "WHITE LAKE GRAVELLY SANDY LOAM"],
	['Renfrew', 'WLs-r', 'WELL', 'RAPID', 4, 1, 'L', "WHITE LAKE GRAVELLY SANDY LOAM"],
	['Russell', 'Afsl', 'POOR', 'SLOW', 3, 3, 'H', "ALLENDALE FINE SANDY LOAM"],
	['Russell', 'Bc', 'POOR', 'VERY SLOW', 3, 3, 'H', "BEARBROOK CLAY"],
	['Russell', 'Bc-ss', 'POOR', 'VERY SLOW', 4, 3, 'M', "ALLENDALE FINE SANDY LOAM"],
	['Russell', 'Bfsl', 'POOR', 'VERY SLOW', 3, 3, 'H', "BEARBROOK FINE SANDY LOAM"],
	['Russell', 'Bsil', 'POOR', 'SLOW', 3, 3, 'H', "BAINSVILLE SILT LOAM"],
	['Russell', 'Ccl', 'IMPERFECT', 'SLOW', 3, 4, 'H', "CARP CLAY LOAM"],
	['Russell', 'Cfsl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CASTOR FINE SANDY LOAM"],
	['Russell', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Russell', 'Gl', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Russell', 'Gl-sh', 'WELL', 'RAPID', 1, 4, 'L', "GRENVILLE LOAM"],
	['Russell', 'Gl-st', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Russell', 'Kgsl', 'WELL', 'RAPID', 4, 1, 'L', "KARS GRAVELLY SANDY LOAM"],
	['Russell', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Russell', 'Mnfsl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "MOUNTAIN FINE SANDY LOAM"],
	['Russell', 'Mtl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MATILDA LOAM"],
	['Russell', 'NGcl', 'POOR', 'VERY SLOW', 3, 4, 'H', "CARP CLAY LOAM"],
	['Russell', 'P', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEAT ORGANIC"],
	['Russell', 'Rfs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ACHIGAN FINE SAND"],
	['Russell', 'Roc', 'POOR', 'VERY SLOW', 3, 3, 'H', "RIDEAU CLAY"],
	['Russell', 'Roc-ss', 'POOR', 'VERY SLOW', 4, 3, 'M', "STE ROSALIE CLAY"],
	['Russell', 'Sfs', 'POOR', 'SLOW', 4, 2, 'M', "ACHIGAN FINE SAND"],
	['Russell', 'Ufs', 'WELL', 'RAPID', 4, 1, 'L', "CHENEY FINE SAND"],
	['Russell', 'Vgl', 'WELL', 'MODERATE', 4, 1, 'L', "VARS GRAVELLY LOAM"],
	['Russell', 'Wc', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "ERODED VARIABLE"],
	['Russell', 'Wc-ss', 'IMPERFECT', 'VERY SLOW', 3, 2, 'M', "MOUNTAIN FINE SANDY LOAM"],
	['Simcoe', 'Anf', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "ALLISTON FINE SANDY LOAM"],
	['Simcoe', 'Ans', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "ALLISTON SANDY LOAM"],
	['Simcoe', 'Ans-b', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "ALLISTON SANDY LOAM"],
	['Simcoe', 'Ayc', 'POOR', 'VERY SLOW', 3, 3, 'H', "ATHERLY CLAY"],
	['Simcoe', 'Aysc', 'POOR', 'VERY SLOW', 3, 4, 'H', "ATHERLY SILTY CLAY LOAM"],
	['Simcoe', 'Aysc-b', 'POOR', 'VERY SLOW', 3, 4, 'H', "ATHERLY SILTY CLAY LOAM"],
	['Simcoe', 'Bef', 'IMPERFECT', 'SLOW', 3, 4, 'M', "BERRIEN FINE SANDY LOAM"],
	['Simcoe', 'Bes', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['Simcoe', 'Bg', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD GRAVELLY LOAM"],
	['Simcoe', 'Bl', 'WELL', 'MODERATE', 1, 3, 'L', "BONDHEAD LOAM"],
	['Simcoe', 'Bnf', 'WELL', 'MODERATE', 2, 4, 'L', "BENNINGTON FINE SANDY LOAM"],
	['Simcoe', 'Bof', 'WELL', 'MODERATE', 2, 4, 'L', "BOOKTON FINE SANDY LOAM"],
	['Simcoe', 'Bos', 'WELL', 'MODERATE', 4, 2, 'L', "BOOKTON SANDY LOAM"],
	['Simcoe', 'Bs', 'WELL', 'MODERATE', 4, 1, 'L', "BONDHEAD SANDY LOAM"],
	['Simcoe', 'Bs-b', 'WELL', 'MODERATE', 4, 1, 'L', "BONDHEAD SANDY LOAM"],
	['Simcoe', 'Bs-s', 'WELL', 'MODERATE', 4, 1, 'L', "BONDHEAD SANDY LOAM"],
	['Simcoe', 'Cg', 'WELL', 'RAPID', 4, 2, 'L', "CALEDON GRAVELLY LOAM"],
	['Simcoe', 'Df', 'WELL', 'MODERATE', 1, 3, 'L', "DUNDONALD FINE SANDY LOAM"],
	['Simcoe', 'Ds', 'WELL', 'MODERATE', 4, 1, 'L', "DUNDONALD SANDY LOAM"],
	['Simcoe', 'Duc', 'WELL', 'SLOW', 3, 3, 'M', "DUNEDIN CLAY"],
	['Simcoe', 'Es', 'IMPERFECT', 'SLOW', 3, 2, 'M', "EDENVALE SANDY LOAM"],
	['Simcoe', 'Ets', 'WELL', 'RAPID', 4, 1, 'L', "EASTPORT SAND"],
	['Simcoe', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Simcoe', 'Gf', 'POOR', 'SLOW', 3, 3, 'H', "GRANBY FINE SANDY LOAM"],
	['Simcoe', 'Gg', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "GWILLIMBURY GRAVELLY SANDY LOAM"],
	['Simcoe', 'Gg-b', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "GWILLIMBURY GRAVELLY SANDY LOAM"],
	['Simcoe', 'Gil', 'POOR', 'SLOW', 3, 3, 'H', "GILFORD GRAVELLY LOAM"],
	['Simcoe', 'Gsl', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Simcoe', 'Gsl-b', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Simcoe', 'Gul', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "GUERIN LOAM"],
	['Simcoe', 'Gul-b', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "GUERIN LOAM"],
	['Simcoe', 'Gus', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "GUERIN SANDY LOAM"],
	['Simcoe', 'Gus-b', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "GUERIN SANDY LOAM"],
	['Simcoe', 'Hal', 'WELL', 'MODERATE', 1, 3, 'L', "HARKAWAY LOAM"],
	['Simcoe', 'Hg', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "HENDRIE GRAVELLY SANDY LOAM"],
	['Simcoe', 'Hl', 'WELL', 'MODERATE', 1, 3, 'L', "HARRISTON LOAM"],
	['Simcoe', 'Hl-s', 'WELL', 'MODERATE', 1, 3, 'L', "HARRISTON LOAM"],
	['Simcoe', 'Hs', 'WELL', 'MODERATE', 1, 3, 'L', "HARRISTON SILT LOAM"],
	['Simcoe', 'Kc', 'IMPERFECT', 'SLOW', 3, 3, 'H', "KEMBLE CLAY LOAM"],
	['Simcoe', 'Kc-sh', 'IMPERFECT', 'MODERATE', 3, 4, 'H', "KEMBLE CLAY LOAM"],
	['Simcoe', 'Ll', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Simcoe', 'Ll-b', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Simcoe', 'Lvc', 'IMPERFECT', 'SLOW', 3, 4, 'H', "LOVERING CLAY"],
	['Simcoe', 'Lvc-b', 'IMPERFECT', 'SLOW', 3, 4, 'H', "LOVERING CLAY"],
	['Simcoe', 'Lvs', 'IMPERFECT', 'SLOW', 3, 4, 'H', "LOVERING SILTY CLAY LOAM"],
	['Simcoe', 'Lvs-b', 'IMPERFECT', 'SLOW', 3, 4, 'H', "LOVERING SILTY CLAY LOAM"],
	['Simcoe', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Simcoe', 'Mes', 'WELL', 'SLOW', 2, 4, 'L', "MEDONTE SILT LOAM"],
	['Simcoe', 'Mesc', 'WELL', 'SLOW', 2, 4, 'M', "MEDONTE SILTY CLAY LOAM"],
	['Simcoe', 'Mmc', 'POOR', 'VERY SLOW', 3, 3, 'H', "MINESING CLAY"],
	['Simcoe', 'Mms', 'POOR', 'VERY SLOW', 3, 4, 'H', "MINESING SILTY CLAY LOAM"],
	['Simcoe', 'Ol', 'WELL', 'MODERATE', 1, 3, 'L', "OTONABEE LOAM"],
	['Simcoe', 'Ol-b', 'WELL', 'MODERATE', 1, 3, 'L', "OTONABEE LOAM"],
	['Simcoe', 'Ol-s', 'WELL', 'MODERATE', 1, 3, 'L', "OTONABEE LOAM"],
	['Simcoe', 'Opl', 'WELL', 'MODERATE', 1, 3, 'L', "OSPREY LOAM"],
	['Simcoe', 'Pal', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL LOAM"],
	['Simcoe', 'Pfs', 'WELL', 'MODERATE', 1, 3, 'L', "PERCY FINE SANDY LOAM"],
	['Simcoe', 'Pfs-s', 'WELL', 'MODERATE', 1, 3, 'L', "PERCY FINE SANDY LOAM"],
	['Simcoe', 'Shs', 'WELL', 'SLOW', 2, 4, 'L', "SCHOMBERG SILT LOAM"],
	['Simcoe', 'Shsc', 'WELL', 'SLOW', 2, 4, 'M', "SCHOMBERG SILTY CLAY LOAM"],
	['Simcoe', 'Shsc-b', 'WELL', 'SLOW', 2, 4, 'M', "SCHOMBERG SILTY CLAY LOAM"],
	['Simcoe', 'Shsc-s', 'WELL', 'SLOW', 2, 4, 'M', "SCHOMBERG SILTY CLAY LOAM"],
	['Simcoe', 'Sis', 'POOR', 'VERY SLOW', 3, 3, 'H', "SIMCOE SILT LOAM"],
	['Simcoe', 'Sisc', 'POOR', 'VERY SLOW', 3, 4, 'H', "SIMCOE SILTY CLAY LOAM"],
	['Simcoe', 'Sisc-b', 'POOR', 'VERY SLOW', 3, 4, 'H', "SIMCOE SILTY CLAY LOAM"],
	['Simcoe', 'Sms', 'IMPERFECT', 'SLOW', 3, 4, 'M', "SMITHFIELD SILT LOAM"],
	['Simcoe', 'Smsc', 'IMPERFECT', 'SLOW', 3, 4, 'H', "SMITHFIELD SILTY CLAY LOAM"],
	['Simcoe', 'Stsl', 'WELL', 'RAPID', 4, 1, 'L', "SARGENT GRAVELLY SANDY LOAM"],
	['Simcoe', 'Stsl-s', 'WELL', 'RAPID', 4, 1, 'L', "SARGENT GRAVELLY SANDY LOAM"],
	['Simcoe', 'Tif', 'WELL', 'RAPID', 4, 2, 'L', "TIOGA FINE SANDY LOAM"],
	['Simcoe', 'Tis', 'WELL', 'RAPID', 4, 1, 'L', "TIOGA LOAMY SAND"],
	['Simcoe', 'Tis-b', 'WELL', 'RAPID', 4, 1, 'L', "TIOGA LOAMY SAND"],
	['Simcoe', 'Tis-e', 'WELL', 'RAPID', 4, 1, 'L', "TIOGA LOAMY SAND"],
	['Simcoe', 'Tisl', 'WELL', 'RAPID', 4, 1, 'L', "TIOGA SANDY LOAM"],
	['Simcoe', 'Tis-s', 'WELL', 'RAPID', 4, 1, 'L', "TIOGA LOAMY SAND"],
	['Simcoe', 'Vasl', 'WELL', 'MODERATE', 4, 1, 'L', "VASEY SANDY LOAM"],
	['Simcoe', 'Vasl-b', 'WELL', 'MODERATE', 4, 1, 'L', "VASEY SANDY LOAM"],
	['Simcoe', 'Vasl-s', 'WELL', 'MODERATE', 4, 1, 'L', "VASEY SANDY LOAM"],
	['Simcoe', 'Vc', 'WELL', 'SLOW', 2, 4, 'M', "VINCENT CLAY LOAM"],
	['Simcoe', 'Waf', 'POOR', 'SLOW', 3, 3, 'H', "WAUSEON FINE SANDY LOAM"],
	['Simcoe', 'Was', 'POOR', 'SLOW', 4, 3, 'M', "WAUSEON SANDY LOAM"],
	['Simcoe', 'Wg', 'WELL', 'RAPID', 4, 1, 'L', "WYEVALE GRAVELLY SANDY LOAM"],
	['Simcoe', 'Wl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "WIARTON LOAM"],
	['Simcoe', 'Wl-b', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "WIARTON LOAM"],
	['Stormont', 'Asl', 'POOR', 'SLOW', 4, 3, 'M', "ALLENDALE SANDY LOAM"],
	['Stormont', 'Bm', 'VERY POOR', 'SLOW', 3, 2, 'M', "BELMEADE ORGANIC"],
	['Stormont', 'Ccl', 'IMPERFECT', 'SLOW', 3, 3, 'H', "CARP CLAY LOAM"],
	['Stormont', 'El', 'WELL', 'MODERATE', 1, 3, 'L', "EAMER LOAM"],
	['Stormont', 'F', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Stormont', 'Gl', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Stormont', 'Gl-b', 'WELL', 'MODERATE', 1, 3, 'L', "GRENVILLE LOAM"],
	['Stormont', 'Gl-s', 'WELL', 'RAPID', 1, 4, 'L', "GRENVILLE LOAM"],
	['Stormont', 'Gs', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SAND"],
	['Stormont', 'Gsl', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Stormont', 'Kg', 'WELL', 'RAPID', 4, 1, 'L', "KARS GRAVELLY SANDY LOAM"],
	['Stormont', 'Ll', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Stormont', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Stormont', 'Masl', 'WELL', 'MODERATE', 3, 2, 'L', "MANOTICK SANDY LOAM"],
	['Stormont', 'Mfsl', 'POOR', 'SLOW', 3, 3, 'H', "MARIONVILLE FINE SANDY LOAM"],
	['Stormont', 'Ml', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MATILDA LOAM"],
	['Stormont', 'Mrl', 'IMPERFECT', 'SLOW', 3, 3, 'H', "MORRISBURG LOAM"],
	['Stormont', 'Msl', 'IMPERFECT', 'SLOW', 4, 2, 'M', "MOUNTAIN SANDY LOAM"],
	['Stormont', 'NGcl', 'POOR', 'VERY SLOW', 3, 3, 'H', "NORTH GOWER CLAY LOAM"],
	['Stormont', 'Obcl', 'POOR', 'VERY SLOW', 3, 3, 'H', "OSNABRUCK CLAY LOAM"],
	['Stormont', 'Ol', 'POOR', 'SLOW', 2, 3, 'H', "OSGOODE LOAM"],
	['Stormont', 'Osil', 'POOR', 'SLOW', 2, 3, 'H', "OSGOODE SILT LOAM"],
	['Stormont', 'Rs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RUBICON SAND"],
	['Stormont', 'Rsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RUBICON SANDY LOAM"],
	['Stormont', 'Us', 'WELL', 'RAPID', 4, 1, 'L', "UPLANDS SAND"],
	['Stormont', 'Usl', 'WELL', 'RAPID', 4, 1, 'L', "UPLANDS SANDY LOAM"],
	['Stormont', 'Wl', 'WELL', 'SLOW', 2, 4, 'M', "WOLFORD LOAM"],
	['Sudbury', 'Abc', 'IMPERFECT', 'SLOW', 4, 4, 'H', "ALBANY CLAY"],
	['Sudbury', 'Abcl', 'IMPERFECT', 'SLOW', 4, 4, 'H', "ALBANY CLAY LOAM"],
	['Sudbury', 'Abfs', 'IMPERFECT', 'SLOW', 3, 2, 'M', "ALBANY FINE SAND"],
	['Sudbury', 'Absil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "ALBANY SILT LOAM"],
	['Sudbury', 'Absl', 'IMPERFECT', 'SLOW', 3, 2, 'M', "ALBANY SANDY LOAM"],
	['Sudbury', 'Acl', 'POOR', 'SLOW', 3, 3, 'H', "AZILDA CLAY LOAM"],
	['Sudbury', 'Afl', 'POOR', 'SLOW', 2, 3, 'H', "AZILDA FINE SANDY LOAM"],
	['Sudbury', 'Afs', 'POOR', 'SLOW', 4, 2, 'M', "AZILDA FINE SAND"],
	['Sudbury', 'Agsil', 'POOR', 'VERY SLOW', 3, 4, 'H', "AGNEWE SILT LOAM"],
	['Sudbury', 'Agsl', 'POOR', 'SLOW', 4, 3, 'M', "AGNEWE SANDY LOAM"],
	['Sudbury', 'Asil', 'POOR', 'SLOW', 2, 3, 'H', "AZILDA SILT LOAM"],
	['Sudbury', 'Bfl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRADLEY FINE SANDY LOAM"],
	['Sudbury', 'Bh', 'VERY POOR', 'SLOW', 3, 2, 'M', "BLYTHE ORGANIC"],
	['Sudbury', 'Bl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRADLEY LOAM"],
	['Sudbury', 'Bpfs', 'VERY POOR', 'SLOW', 3, 2, 'M', "BURPEE FINE SAND"],
	['Sudbury', 'Bpl', 'VERY POOR', 'SLOW', 3, 2, 'M', "BURPEE LOAM"],
	['Sudbury', 'Bsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADLEY SANDY LOAM"],
	['Sudbury', 'Bvl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRADLEY VERY FINE SANDY LOAM"],
	['Sudbury', 'Bwgsl', 'WELL', 'MODERATE', 3, 2, 'L', "BALDWIN GRAVELLY SANDY LOAM"],
	['Sudbury', 'Bwsil', 'WELL', 'SLOW', 2, 4, 'L', "BALDWIN SILT LOAM"],
	['Sudbury', 'Bwsl', 'WELL', 'MODERATE', 3, 2, 'L', "BALDWIN SANDY LOAM"],
	['Sudbury', 'Bx', 'VERY POOR', 'SLOW', 3, 2, 'M', "BURTON ORGANIC"],
	['Sudbury', 'By', 'VERY POOR', 'SLOW', 3, 2, 'M', "BERRY LAKE ORGANIC"],
	['Sudbury', 'Cac', 'POOR', 'VERY SLOW', 3, 3, 'H', "CASIMAR CLAY"],
	['Sudbury', 'Cacl', 'POOR', 'VERY SLOW', 3, 4, 'H', "CASIMAR CLAY LOAM"],
	['Sudbury', 'Cafs', 'POOR', 'VERY SLOW', 3, 3, 'H', "CASIMAR FINE SAND"],
	['Sudbury', 'Cal', 'POOR', 'VERY SLOW', 3, 3, 'H', "CASIMAR SAND"],
	['Sudbury', 'Casil', 'POOR', 'VERY SLOW', 3, 3, 'H', "CASIMAR SILT LOAM"],
	['Sudbury', 'Casl', 'POOR', 'SLOW', 4, 3, 'M', "CASIMAR SANDY LOAM"],
	['Sudbury', 'Cc', 'IMPERFECT', 'SLOW', 3, 3, 'H', "CHARTRAND CLAY"],
	['Sudbury', 'Ccl', 'IMPERFECT', 'SLOW', 3, 3, 'H', "CHARTRAND CLAY LOAM"],
	['Sudbury', 'Ch', 'VERY POOR', 'SLOW', 3, 2, 'M', "CACHE LAKE ORGANIC"],
	['Sudbury', 'Cl', 'IMPERFECT', 'SLOW', 2, 4, 'M', "CHARTRAND LOAM"],
	['Sudbury', 'Cm', 'VERY POOR', 'SLOW', 3, 2, 'M', "CALLUM ORGANIC"],
	['Sudbury', 'Co', 'VERY POOR', 'SLOW', 3, 2, 'M', "CORBEIL ORGANIC"],
	['Sudbury', 'Cpfl', 'POOR', 'SLOW', 3, 3, 'H', "CAPREOL FINE SANDY LOAM"],
	['Sudbury', 'Cpl', 'POOR', 'SLOW', 3, 3, 'H', "CAPREOL LOAM"],
	['Sudbury', 'Cpsil', 'POOR', 'SLOW', 3, 3, 'H', "CAPREOL SILT LOAM"],
	['Sudbury', 'Cpsl', 'POOR', 'SLOW', 4, 2, 'M', "CAPREOL SANDY LOAM"],
	['Sudbury', 'Cpvl', 'POOR', 'SLOW', 3, 3, 'H', "CAPREOL VERY FINE SANDY LOAM"],
	['Sudbury', 'Csil', 'IMPERFECT', 'SLOW', 2, 4, 'M', "CHARTRAND SILT LOAM"],
	['Sudbury', 'Csl', 'IMPERFECT', 'SLOW', 3, 2, 'M', "CHARTRAND SANDY LOAM"],
	['Sudbury', 'Ct', 'VERY POOR', 'SLOW', 3, 2, 'M', "CODERETTE ORGANIC"],
	['Sudbury', 'Cusl', 'WELL', 'RAPID', 4, 1, 'L', "CUTLER SANDY LOAM"],
	['Sudbury', 'Dasil', 'VERY POOR', 'SLOW', 3, 2, 'M', "DAYTON SILT LOAM"],
	['Sudbury', 'Dcl', 'WELL', 'SLOW', 4, 4, 'M', "DELAMERE CLAY LOAM"],
	['Sudbury', 'Desl', 'WELL', 'RAPID', 4, 1, 'L', "DENMAN SANDY LOAM"],
	['Sudbury', 'Dfl', 'WELL', 'SLOW', 2, 4, 'L', "DELAMERE FINE SANDY LOAM"],
	['Sudbury', 'Dkfl', 'WELL', 'RAPID', 4, 2, 'L', "DOKISE FINE SANDY LOAM"],
	['Sudbury', 'Dkfs', 'WELL', 'RAPID', 4, 1, 'L', "DOKISE FINE SAND"],
	['Sudbury', 'Dksl', 'WELL', 'RAPID', 4, 1, 'L', "DOKISE SANDY LOAM"],
	['Sudbury', 'Dr', 'VERY POOR', 'SLOW', 3, 2, 'M', "DRURY ORGANIC"],
	['Sudbury', 'Dsil', 'WELL', 'SLOW', 2, 4, 'L', "DELAMERE SILT LOAM"],
	['Sudbury', 'Eacl', 'POOR', 'VERY SLOW', 3, 3, 'H', "EAKETT CLAY LOAM"],
	['Sudbury', 'Eal', 'POOR', 'SLOW', 3, 3, 'H', "EAKETT LOAM"],
	['Sudbury', 'Eals', 'POOR', 'SLOW', 4, 2, 'M', "EAKETT LOAMY SAND"],
	['Sudbury', 'Eas', 'POOR', 'SLOW', 4, 2, 'M', "EAKETT SAND"],
	['Sudbury', 'Easl', 'POOR', 'SLOW', 4, 2, 'M', "EAKETT SILT LOAM"],
	['Sudbury', 'Ee', 'VERY POOR', 'SLOW', 3, 2, 'M', "ESTAIRE ORGANIC"],
	['Sudbury', 'El', 'WELL', 'RAPID', 4, 2, 'L', "ELLICE LOAM"],
	['Sudbury', 'Es', 'VERY POOR', 'SLOW', 3, 2, 'M', "ESPANOLA ORGANIC"],
	['Sudbury', 'Etsil', 'POOR', 'SLOW', 2, 3, 'H', "EVEREND SILT LOAM"],
	['Sudbury', 'Fesil', 'WELL', 'MODERATE', 1, 3, 'L', "FRECHETTE SILT LOAM"],
	['Sudbury', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Sudbury', 'Fycl', 'WELL', 'SLOW', 4, 4, 'M', "FYNXAL CLAY LOAM"],
	['Sudbury', 'Fyl', 'WELL', 'SLOW', 2, 4, 'L', "FYNXAL LOAM"],
	['Sudbury', 'Fysil', 'WELL', 'SLOW', 2, 4, 'L', "FYNXAL SILT LOAM"],
	['Sudbury', 'Fysl', 'WELL', 'MODERATE', 4, 2, 'L', "FYNXAL SANDY LOAM"],
	['Sudbury', 'Ge', 'VERY POOR', 'SLOW', 3, 2, 'M', "GENESEE ORGANIC"],
	['Sudbury', 'Gofl', 'POOR', 'SLOW', 3, 3, 'H', "GOUVEREAU FINE SANDY LOAM"],
	['Sudbury', 'Gosl', 'POOR', 'SLOW', 4, 2, 'M', "GOUVEREAU SANDY LOAM"],
	['Sudbury', 'Gsil', 'VERY POOR', 'SLOW', 3, 2, 'M', "GOULAIS SILT LOAM"],
	['Sudbury', 'Gsl', 'VERY POOR', 'SLOW', 3, 2, 'M', "GOULAIS SANDY LOAM"],
	['Sudbury', 'Hasl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "HALLAM SANDY LOAM"],
	['Sudbury', 'Hdcl', 'POOR', 'VERY SLOW', 3, 4, 'H', "HADDO CLAY LOAM"],
	['Sudbury', 'Hdfl', 'POOR', 'VERY SLOW', 3, 3, 'H', "HADDO FINE SANDY LOAM"],
	['Sudbury', 'Hdsicl', 'POOR', 'VERY SLOW', 3, 4, 'H', "HADDO SILTY CLAY LOAM"],
	['Sudbury', 'Hdsil', 'POOR', 'VERY SLOW', 3, 3, 'H', "HADDO SILT LOAM"],
	['Sudbury', 'Hfl', 'WELL', 'RAPID', 4, 2, 'L', "HARFRED FINE SANDY LOAM"],
	['Sudbury', 'Hgfl', 'POOR', 'SLOW', 3, 3, 'H', "HAGAR FINE SANDY LOAM"],
	['Sudbury', 'HL', 'VERY POOR', 'SLOW', 3, 2, 'M', "HILTON LAKE ORGANIC"],
	['Sudbury', 'Hnsl', 'WELL', 'RAPID', 4, 1, 'L', "HANNA SANDY LOAM"],
	['Sudbury', 'Hsil', 'WELL', 'RAPID', 4, 2, 'L', "HARFRED SILT LOAM"],
	['Sudbury', 'Il', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "ILFORD LOAM"],
	['Sudbury', 'Jgsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "JANDEN GRAVELLY SANDY LOAM"],
	['Sudbury', 'Jl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "JANDEN LOAM"],
	['Sudbury', 'Kfl', 'POOR', 'SLOW', 3, 3, 'H', "KENABEEK FINE SANDY LOAM"],
	['Sudbury', 'Kgsl', 'POOR', 'SLOW', 4, 2, 'M', "KENABEEK GRAVELLY SANDY LOAM"],
	['Sudbury', 'Kml', 'POOR', 'VERY SLOW', 2, 3, 'H', "KIM LOAM"],
	['Sudbury', 'Ks', 'POOR', 'SLOW', 4, 2, 'M', "KENABEEK SAND"],
	['Sudbury', 'Ksil', 'POOR', 'SLOW', 3, 3, 'H', "KENABEEK SILT LOAM"],
	['Sudbury', 'Ksl', 'POOR', 'SLOW', 4, 2, 'M', "KENABEEK SANDY LOAM"],
	['Sudbury', 'Kvl', 'POOR', 'SLOW', 3, 3, 'H', "KENABEEK VERY FINE SANDY LOAM"],
	['Sudbury', 'Ma', 'VERY POOR', 'SLOW', 3, 2, 'M', "MARSH ORGANIC"],
	['Sudbury', 'Mdfl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MALLARD FINE SANDY LOAM"],
	['Sudbury', 'Mdgsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD GRAVELLY SANDY LOAM"],
	['Sudbury', 'Mdls', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD LOAMY SAND"],
	['Sudbury', 'Mds', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD SAND"],
	['Sudbury', 'Mdsil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MALLARD SILT LOAM"],
	['Sudbury', 'Mdsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD SANDY LOAM"],
	['Sudbury', 'Mdvl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MALLARD VERY FINE SANDY LOAM"],
	['Sudbury', 'Mefl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MEDETTE FINE SANDY LOAM"],
	['Sudbury', 'Mefs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MEDETTE FINE SAND"],
	['Sudbury', 'Mels', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MEDETTE SANDY LOAM"],
	['Sudbury', 'Me-R', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MEDETTE SANDY LOAM"],
	['Sudbury', 'Mes', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MEDETTE SAND"],
	['Sudbury', 'Mesl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MEDETTE SANDY LOAM"],
	['Sudbury', 'Mgsl', 'WELL', 'RAPID', 4, 1, 'L', "MONTEAGLE GRAVELLY SANDY LOAM"],
	['Sudbury', 'Mic', 'VERY POOR', 'SLOW', 3, 2, 'M', "MILLERAND CLAY"],
	['Sudbury', 'Micl', 'VERY POOR', 'SLOW', 3, 4, 'M', "MILLERAND CLAY LOAM"],
	['Sudbury', 'Mi-P', 'VERY POOR', 'SLOW', 3, 2, 'M', "MILLERAND ORGANIC"],
	['Sudbury', 'Misicl', 'VERY POOR', 'SLOW', 3, 4, 'M', "MILLERAND SILTY CLAY LOAM"],
	['Sudbury', 'Misil', 'VERY POOR', 'SLOW', 3, 2, 'M', "MILLERAND SILT LOAM"],
	['Sudbury', 'Misl', 'VERY POOR', 'SLOW', 3, 2, 'M', "MILLERAND SANDY LOAM"],
	['Sudbury', 'Ms', 'WELL', 'RAPID', 4, 1, 'L', "MONTEAGLE SAND"],
	['Sudbury', 'Msl', 'WELL', 'RAPID', 4, 1, 'L', "MONTEAGLE SANDY LOAM"],
	['Sudbury', 'Mtsil', 'WELL', 'SLOW', 2, 4, 'L', "MARRITT SILT LOAM"],
	['Sudbury', 'Mucl', 'POOR', 'VERY SLOW', 3, 3, 'H', "MUSSELLE CLAY LOAM"],
	['Sudbury', 'Mud', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUDDY LAKE ORGANIC"],
	['Sudbury', 'Muvl', 'POOR', 'SLOW', 3, 3, 'H', "MUSSELLE VERY FINE SANDY LOAM"],
	['Sudbury', 'Nd', 'VERY POOR', 'SLOW', 3, 2, 'M', "NOTRE DAME ORGANIC"],
	['Sudbury', 'Nnfs', 'WELL', 'RAPID', 4, 1, 'L', "NAIDEN FINE SAND"],
	['Sudbury', 'Nnvl', 'WELL', 'RAPID', 4, 2, 'L', "NAIDEN VERY FINE SANDY LOAM"],
	['Sudbury', 'Nsil', 'IMPERFECT', 'SLOW', 2, 4, 'M', "NOELVILLE SILT LOAM"],
	['Sudbury', 'Nsl', 'IMPERFECT', 'SLOW', 3, 2, 'M', "NOELVILLE SANDY LOAM"],
	['Sudbury', 'O', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC ORGANIC"],
	['Sudbury', 'Oasicl', 'WELL', 'SLOW', 4, 4, 'M', "OUELLETTE SILTY CLAY LOAM"],
	['Sudbury', 'Oasil', 'WELL', 'SLOW', 2, 4, 'L', "OAK SILT LOAM"],
	['Sudbury', 'Ouc', 'POOR', 'VERY SLOW', 3, 3, 'H', "OUELLETTE CLAY"],
	['Sudbury', 'Oucl', 'POOR', 'VERY SLOW', 3, 4, 'H', "OUELLETTE CLAY LOAM"],
	['Sudbury', 'Oul', 'POOR', 'VERY SLOW', 3, 3, 'H', "OUELLETTE LOAM"],
	['Sudbury', 'Ous', 'POOR', 'SLOW', 4, 3, 'M', "OUELLETTE SAND"],
	['Sudbury', 'Ousicl', 'POOR', 'VERY SLOW', 3, 4, 'H', "OUELLETTE SILTY CLAY LOAM"],
	['Sudbury', 'Ousil', 'POOR', 'VERY SLOW', 3, 3, 'H', "OUELLETTE SILT LOAM"],
	['Sudbury', 'Ousl', 'POOR', 'SLOW', 4, 3, 'M', "OUELLETTE SANDY LOAM"],
	['Sudbury', 'Phcl', 'WELL', 'SLOW', 3, 3, 'M', "PHELANS CLAY LOAM"],
	['Sudbury', 'Phg', 'WELL', 'RAPID', 4, 1, 'L', "PHELANS GRAVEL"],
	['Sudbury', 'Phgsl', 'WELL', 'RAPID', 4, 1, 'L', "PHELANS GRAVELLY SANDY LOAM"],
	['Sudbury', 'Phs', 'WELL', 'RAPID', 4, 1, 'L', "PHELANS SAND"],
	['Sudbury', 'Phsil', 'WELL', 'RAPID', 4, 2, 'L', "PHELANS SILT LOAM"],
	['Sudbury', 'Phsl', 'WELL', 'RAPID', 4, 1, 'L', "PHELANS SANDY LOAM"],
	['Sudbury', 'Phvs', 'WELL', 'RAPID', 4, 1, 'L', "PHELANS VERY FINE SAND"],
	['Sudbury', 'Prfl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "PARRISH FINE SANDY LOAM"],
	['Sudbury', 'Prsil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "PARRISH SILT LOAM"],
	['Sudbury', 'Prsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "PARRISH SANDY LOAM"],
	['Sudbury', 'Psl', 'POOR', 'SLOW', 4, 3, 'M', "PARKET SANDY LOAM"],
	['Sudbury', 'Ql', 'POOR', 'VERY SLOW', 3, 3, 'H', "QUIRE LOAM"],
	['Sudbury', 'Rdsl', 'POOR', 'SLOW', 4, 2, 'M', "RIDGER SANDY LOAM"],
	['Sudbury', 'Rf', 'VERY POOR', 'SLOW', 3, 2, 'M', "RAFT LAKE ORGANIC"],
	['Sudbury', 'Risl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "RIDOUT SANDY LOAM"],
	['Sudbury', 'Rocl', 'VERY POOR', 'SLOW', 3, 4, 'M', "ROBITAILLE CLAY LOAM"],
	['Sudbury', 'Rosl', 'VERY POOR', 'SLOW', 3, 2, 'M', "ROBITAILLE SANDY LOAM"],
	['Sudbury', 'Rovl', 'VERY POOR', 'SLOW', 3, 2, 'M', "ROBITAILLE VERY FINE SANDY LOAM"],
	['Sudbury', 'Sal', 'POOR', 'VERY SLOW', 3, 3, 'H', "SAN PIERRE LOAM"],
	['Sudbury', 'Scl', 'POOR', 'VERY SLOW', 3, 3, 'H', "STURGEON FALLS CLAY LOAM"],
	['Sudbury', 'Ssil', 'POOR', 'SLOW', 3, 3, 'H', "STURGEON FALLS SILT LOAM"],
	['Sudbury', 'Ssl', 'POOR', 'SLOW', 4, 2, 'M', "STURGEON FALLS SANDY LOAM"],
	['Sudbury', 'Stsil', 'WELL', 'SLOW', 2, 4, 'L', "STINSON SILT LOAM"],
	['Sudbury', 'Tasl', 'POOR', 'SLOW', 4, 2, 'M', "TARBUTT SANDY LOAM"],
	['Sudbury', 'Tl', 'WELL', 'RAPID', 4, 2, 'L', "THISTLE LOAM"],
	['Sudbury', 'Val', 'WELL', 'RAPID', 1, 4, 'L', "VASEY LOAM"],
	['Sudbury', 'Vc', 'IMPERFECT', 'SLOW', 3, 3, 'H', "VEUVE RIVER CLAY"],
	['Sudbury', 'Vcl', 'IMPERFECT', 'SLOW', 3, 3, 'H', "VEUVE RIVER CLAY LOAM"],
	['Sudbury', 'Vfl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "VEUVE RIVER FINE SANDY LOAM"],
	['Sudbury', 'Vnfs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "VERNER FINE SAND"],
	['Sudbury', 'Vnsil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "VERNER SILT LOAM"],
	['Sudbury', 'Vrl', 'WELL', 'RAPID', 4, 2, 'L', "VERMILLION LOAM"],
	['Sudbury', 'Vrsl', 'WELL', 'RAPID', 4, 1, 'L', "VERMILLION SANDY LOAM"],
	['Sudbury', 'Vsil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "VEUVE RIVER SILT LOAM"],
	['Sudbury', 'Wdg', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO GRAVEL"],
	['Sudbury', 'Wdgsl', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO GRAVELLY SANDY LOAM"],
	['Sudbury', 'Wdq', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO GRAVEL"],
	['Sudbury', 'Wdqsl', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO GRAVELLY SANDY LOAM"],
	['Sudbury', 'Wds', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO SAND"],
	['Sudbury', 'Wdsl', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO SANDY LOAM"],
	['Sudbury', 'Wfl', 'POOR', 'VERY SLOW', 3, 3, 'H', "WARREN FINE SANDY LOAM"],
	['Sudbury', 'Wisl', 'POOR', 'SLOW', 4, 2, 'M', "WILLBANK SANDY LOAM"],
	['Sudbury', 'Wivl', 'POOR', 'SLOW', 3, 3, 'H', "WILLBANK VERY FINE SANDY LOAM"],
	['Sudbury', 'Wocl', 'POOR', 'VERY SLOW', 3, 3, 'H', "WOLF CLAY LOAM"],
	['Sudbury', 'Wol', 'POOR', 'VERY SLOW', 2, 3, 'H', "WOLF LOAM"],
	['Sudbury', 'Wo-P', 'POOR', 'SLOW', 4, 3, 'M', "WOLF ORGANIC"],
	['Sudbury', 'Wosicl', 'POOR', 'VERY SLOW', 3, 3, 'H', "WOLF SILTY CLAY LOAM"],
	['Sudbury', 'Wosil', 'POOR', 'VERY SLOW', 2, 3, 'H', "WOLF SILT LOAM"],
	['Sudbury', 'Wosl', 'POOR', 'SLOW', 4, 3, 'M', "WOLF SANDY LOAM"],
	['Sudbury', 'Wovl', 'POOR', 'VERY SLOW', 2, 3, 'H', "WOLF VERY FINE SANDY LOAM"],
	['Sudbury', 'Ws', 'POOR', 'SLOW', 4, 2, 'M', "WARREN SAND"],
	['Sudbury', 'Wsil', 'POOR', 'VERY SLOW', 3, 3, 'H', "WARREN SILT LOAM"],
	['Sudbury', 'Wsl', 'POOR', 'SLOW', 4, 2, 'M', "WARREN SANDY LOAM"],
	['Sudbury', 'Wt', 'VERY POOR', 'SLOW', 3, 2, 'M', "WAHNAPITNE ORGANIC"],
	['Thunder Bay', 'Afl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "ARTHUR FINE SANDY LOAM"],
	['Thunder Bay', 'Al', 'IMPERFECT', 'SLOW', 3, 4, 'M', "ARTHUR LOAM"],
	['Thunder Bay', 'BBcl', 'POOR', 'VERY SLOW', 3, 4, 'H', "BLACK BAY CLAY LOAM"],
	['Thunder Bay', 'BBl', 'POOR', 'VERY SLOW', 3, 3, 'H', "BLACK BAY LOAM"],
	['Thunder Bay', 'BBsicl', 'POOR', 'VERY SLOW', 3, 4, 'H', "BLACK BAY SILTY CLAY LOAM"],
	['Thunder Bay', 'Bd', 'VERY POOR', 'SLOW', 3, 2, 'M', "BAIRD ORGANIC"],
	['Thunder Bay', 'Bfl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BLAKE FINE SANDY LOAM"],
	['Thunder Bay', 'Bifl', 'WELL', 'SLOW', 2, 4, 'L', "BINABICH FINE SANDY LOAM"],
	['Thunder Bay', 'Bisl', 'WELL', 'MODERATE', 4, 2, 'L', "BINABICH SANDY LOAM"],
	['Thunder Bay', 'Bl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BLAKE LOAM"],
	['Thunder Bay', 'Bsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BLAKE SANDY LOAM"],
	['Thunder Bay', 'Cb', 'VERY POOR', 'SLOW', 3, 2, 'M', "CABETT ORGANIC"],
	['Thunder Bay', 'Cl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "CURRENT RIVER LOAM"],
	['Thunder Bay', 'Csil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "CURRENT RIVER SILT LOAM"],
	['Thunder Bay', 'Csl', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "CURRENT RIVER SANDY LOAM"],
	['Thunder Bay', 'Dcl', 'IMPERFECT', 'SLOW', 4, 4, 'H', "DORION CLAY LOAM"],
	['Thunder Bay', 'Del', 'IMPERFECT', 'SLOW', 4, 4, 'H', "DORION CLAY LOAM"],
	['Thunder Bay', 'Dfl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "DORION FINE SANDY LOAM"],
	['Thunder Bay', 'Dl', 'IMPERFECT', 'SLOW', 3, 4, 'M', "DORION LOAM"],
	['Thunder Bay', 'Dsil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "DORION SILT LOAM"],
	['Thunder Bay', 'Fg', 'WELL', 'RAPID', 4, 1, 'L', "FORMAL GRAVEL"],
	['Thunder Bay', 'Fgsl', 'WELL', 'RAPID', 4, 1, 'L', "FORMAL GRAVELLY SANDY LOAM"],
	['Thunder Bay', 'Fl', 'WELL', 'RAPID', 4, 2, 'L', "FORMAL LOAM"],
	['Thunder Bay', 'Fsl', 'WELL', 'RAPID', 4, 1, 'L', "FORMAL SANDY LOAM"],
	['Thunder Bay', 'FWl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "FORT WILLIAM LOAM"],
	['Thunder Bay', 'FWs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "FORT WILLIAM SAND"],
	['Thunder Bay', 'FWsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "FORT WILLIAM SANDY LOAM"],
	['Thunder Bay', 'Ggsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "GLEN GRAVELLY SANDY LOAM"],
	['Thunder Bay', 'Gp', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "GLEN ORGANIC"],
	['Thunder Bay', 'Gs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "GLEN SAND"],
	['Thunder Bay', 'Gsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "GLEN SANDY LOAM"],
	['Thunder Bay', 'Gtl', 'WELL', 'RAPID', 4, 2, 'L', "GIANTE LOAM"],
	['Thunder Bay', 'Gtsil', 'WELL', 'RAPID', 4, 2, 'L', "GIANTE SILT LOAM"],
	['Thunder Bay', 'In', 'VERY POOR', 'SLOW', 3, 2, 'M', "INNES LAKE ORGANIC"],
	['Thunder Bay', 'Jc', 'WELL', 'SLOW', 3, 3, 'M', "JARVIS RIVER CLAY"],
	['Thunder Bay', 'Jcl', 'WELL', 'SLOW', 4, 4, 'M', "JARVIS RIVER CLAY LOAM"],
	['Thunder Bay', 'Je', 'WELL', 'SLOW', 3, 3, 'M', "JARVIS RIVER CLAY"],
	['Thunder Bay', 'Jel', 'WELL', 'SLOW', 4, 4, 'M', "JARVIS RIVER LOAM"],
	['Thunder Bay', 'Jo', 'VERY POOR', 'SLOW', 3, 2, 'M', "JUMBON ORGANIC"],
	['Thunder Bay', 'Jsil', 'WELL', 'SLOW', 2, 4, 'L', "JARVIS RIVER SILT LOAM"],
	['Thunder Bay', 'Lc', 'POOR', 'VERY SLOW', 3, 3, 'H', "LAPPE CLAY"],
	['Thunder Bay', 'Lcl', 'POOR', 'VERY SLOW', 3, 4, 'H', "LAPPE CLAY LOAM"],
	['Thunder Bay', 'Le', 'POOR', 'VERY SLOW', 3, 3, 'H', "LAPPE CLAY"],
	['Thunder Bay', 'Lel', 'POOR', 'VERY SLOW', 3, 4, 'H', "LAPPE CLAY LOAM"],
	['Thunder Bay', 'Ln', 'VERY POOR', 'SLOW', 3, 2, 'M', "LINKOR ORGANIC"],
	['Thunder Bay', 'Lp', 'POOR', 'VERY SLOW', 3, 3, 'H', "LAPPE ORGANIC"],
	['Thunder Bay', 'Lsil', 'POOR', 'VERY SLOW', 3, 3, 'H', "LAPPE SILT LOAM"],
	['Thunder Bay', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Thunder Bay', 'Ma', 'VERY POOR', 'SLOW', 3, 2, 'M', "MARSH ORGANIC"],
	['Thunder Bay', 'Mc', 'VERY POOR', 'SLOW', 3, 2, 'M', "MACINTYRE ORGANIC"],
	['Thunder Bay', 'Mg', 'WELL', 'RAPID', 4, 1, 'L', "MIETZLE GRAVEL"],
	['Thunder Bay', 'Mip', 'POOR', 'SLOW', 4, 2, 'M', "MISSION ORGANIC"],
	['Thunder Bay', 'Mis', 'POOR', 'SLOW', 4, 2, 'M', "MISSION SAND"],
	['Thunder Bay', 'Misl', 'POOR', 'SLOW', 4, 2, 'M', "MISSION SANDY LOAM"],
	['Thunder Bay', 'Mkfl', 'POOR', 'SLOW', 3, 3, 'H', "MCKELLAR FINE SANDY LOAM"],
	['Thunder Bay', 'Mkl', 'POOR', 'SLOW', 3, 3, 'H', "MCKELLAR LOAM"],
	['Thunder Bay', 'Mkp', 'POOR', 'SLOW', 3, 3, 'H', "MCKELLAR ORGANIC"],
	['Thunder Bay', 'Mksl', 'POOR', 'SLOW', 4, 2, 'M', "MCKELLAR SANDY LOAM"],
	['Thunder Bay', 'Mls', 'WELL', 'RAPID', 4, 1, 'L', "MIETZLE LOAMY SAND"],
	['Thunder Bay', 'Mo', 'VERY POOR', 'SLOW', 3, 2, 'M', "MURILLO ORGANIC"],
	['Thunder Bay', 'Ms', 'WELL', 'RAPID', 4, 1, 'L', "MIETZLE SAND"],
	['Thunder Bay', 'Msl', 'WELL', 'RAPID', 4, 1, 'L', "MIETZLE SANDY LOAM"],
	['Thunder Bay', 'Negsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "NEEBING GRAVELLY SANDY LOAM"],
	['Thunder Bay', 'Nesl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "NEEBING SANDY LOAM"],
	['Thunder Bay', 'Ngsl', 'WELL', 'MODERATE', 4, 1, 'L', "NOLALU GRAVELLY SANDY LOAM"],
	['Thunder Bay', 'Nl', 'WELL', 'MODERATE', 1, 3, 'L', "NOLALU LOAM"],
	['Thunder Bay', 'Nsil', 'WELL', 'MODERATE', 1, 3, 'L', "NOLALU SILT LOAM"],
	['Thunder Bay', 'Nsl', 'WELL', 'MODERATE', 4, 1, 'L', "NOLALU SANDY LOAM"],
	['Thunder Bay', 'Oc', 'IMPERFECT', 'SLOW', 4, 4, 'H', "OSKONDOGA CLAY"],
	['Thunder Bay', 'Or', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORBIT LAKE ORGANIC"],
	['Thunder Bay', 'Os', 'IMPERFECT', 'SLOW', 3, 2, 'M', "OSKONDOGA SAND"],
	['Thunder Bay', 'Osicl', 'IMPERFECT', 'SLOW', 4, 4, 'H', "OSKONDOGA SILTY CLAY LOAM"],
	['Thunder Bay', 'Osil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "OSKONDOGA SILT LOAM"],
	['Thunder Bay', 'Ouc', 'IMPERFECT', 'SLOW', 4, 4, 'H', "OUIMET CLAY"],
	['Thunder Bay', 'Oucl', 'IMPERFECT', 'SLOW', 4, 4, 'H', "OUIMET CLAY LOAM"],
	['Thunder Bay', 'Ousil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "OUIMET SILT LOAM"],
	['Thunder Bay', 'P', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEAT ORGANIC"],
	['Thunder Bay', 'Pasicl', 'WELL', 'SLOW', 4, 4, 'M', "PAIPOONGE SILTY CLAY LOAM"],
	['Thunder Bay', 'Pasil', 'WELL', 'SLOW', 2, 4, 'L', "PAIPOONGE SILT LOAM"],
	['Thunder Bay', 'Pegsl', 'WELL', 'RAPID', 4, 1, 'L', "PEARL GRAVELLY SANDY LOAM"],
	['Thunder Bay', 'Pes', 'WELL', 'RAPID', 4, 1, 'L', "PEARL SAND"],
	['Thunder Bay', 'Pesl', 'WELL', 'RAPID', 4, 1, 'L', "PEARL SANDY LOAM"],
	['Thunder Bay', 'Pgsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "PEARSON GRAVELLY SANDY LOAM"],
	['Thunder Bay', 'Pn', 'VERY POOR', 'SLOW', 3, 2, 'M', "PENASSEN ORGANIC"],
	['Thunder Bay', 'Ps', 'VERY POOR', 'SLOW', 3, 2, 'M', "PASSER ORGANIC"],
	['Thunder Bay', 'Ra', 'VERY POOR', 'SLOW', 3, 2, 'M', "RAITHE ORGANIC"],
	['Thunder Bay', 'Sbsil', 'POOR', 'VERY SLOW', 2, 3, 'H', "SIBLEY SILT LOAM"],
	['Thunder Bay', 'Sesil', 'WELL', 'SLOW', 2, 4, 'L', "SELLARS SILT LOAM"],
	['Thunder Bay', 'SRfl', 'WELL', 'MODERATE', 1, 3, 'L', "SLATE RIVER FINE SANDY LOAM"],
	['Thunder Bay', 'SRs', 'WELL', 'MODERATE', 4, 1, 'L', "SLATE RIVER SAND"],
	['Thunder Bay', 'SRsl', 'WELL', 'MODERATE', 4, 1, 'L', "SLATE RIVER SANDY LOAM"],
	['Thunder Bay', 'Ssicl', 'IMPERFECT', 'SLOW', 4, 4, 'H', "SILVER SILTY CLAY LOAM"],
	['Thunder Bay', 'Ssil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "SILVER SILT LOAM"],
	['Thunder Bay', 'Stl', 'POOR', 'SLOW', 2, 3, 'H', "STRAWBERRY LOAM"],
	['Thunder Bay', 'Stp', 'POOR', 'SLOW', 4, 2, 'M', "STRAWBERRY ORGANIC"],
	['Thunder Bay', 'Sts', 'POOR', 'SLOW', 4, 2, 'M', "STRAWBERRY SANDY LOAM"],
	['Thunder Bay', 'Tfl', 'POOR', 'SLOW', 2, 3, 'H', "TWIN CITIES FINE SANDY LOAM"],
	['Thunder Bay', 'Tp', 'POOR', 'SLOW', 2, 3, 'H', "TWIN CITIES ORGANIC"],
	['Thunder Bay', 'Wafl', 'WELL', 'MODERATE', 1, 3, 'L', "WAMSLEY FINE SANDY LOAM"],
	['Thunder Bay', 'Wal', 'WELL', 'MODERATE', 1, 3, 'L', "WAMSLEY LOAM"],
	['Thunder Bay', 'Wals', 'WELL', 'MODERATE', 4, 1, 'L', "WAMSLEY LOAMY SAND"],
	['Thunder Bay', 'Wavl', 'WELL', 'MODERATE', 1, 3, 'L', "WAMSLEY VERY FINE SANDY LOAM"],
	['Thunder Bay', 'Wf', 'VERY POOR', 'SLOW', 3, 2, 'M', "WOLF RIVER ORGANIC"],
	['Thunder Bay', 'Wgs', 'WELL', 'RAPID', 4, 1, 'L', "WOLFPUP GRAVELLY SAND"],
	['Thunder Bay', 'Wgsl', 'WELL', 'RAPID', 4, 1, 'L', "WOLFPUP GRAVELLY SANDY LOAM"],
	['Thunder Bay', 'Wsl', 'WELL', 'RAPID', 4, 1, 'L', "WOLFPUP SANDY LOAM"],
	['Timiskaming', 'Abgs', 'WELL', 'RAPID', 4, 1, 'L', "ABITIBI GRAVELLY SAND"],
	['Timiskaming', 'Abgsl', 'WELL', 'RAPID', 4, 1, 'L', "ABITIBI GRAVELLY SANDY LOAM"],
	['Timiskaming', 'Abs', 'WELL', 'RAPID', 4, 1, 'L', "ABITIBI SAND"],
	['Timiskaming', 'Absl', 'WELL', 'RAPID', 4, 1, 'L', "ABITIBI SANDY LOAM"],
	['Timiskaming', 'Anfs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ANSONVILLE FINE SAND"],
	['Timiskaming', 'Angs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ANSONVILLE GRAVELLY SAND"],
	['Timiskaming', 'Anls', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ANSONVILLE LOAMY SAND"],
	['Timiskaming', 'Ansl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "ANSONVILLE SANDY LOAM"],
	['Timiskaming', 'Bafs', 'POOR', 'SLOW', 4, 2, 'M', "BAIN FINE SAND"],
	['Timiskaming', 'Besil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BEARLE SILT LOAM"],
	['Timiskaming', 'Bl', 'POOR', 'SLOW', 2, 3, 'H', "BIZ LOAM"],
	['Timiskaming', 'Bp', 'POOR', 'SLOW', 2, 3, 'H', "BIZ ORGANIC"],
	['Timiskaming', 'Bsil', 'POOR', 'SLOW', 2, 3, 'H', "BIZ SILT LOAM"],
	['Timiskaming', 'Bul', 'WELL', 'SLOW', 2, 4, 'L', "BUCKE LOAM"],
	['Timiskaming', 'Bus', 'WELL', 'MODERATE', 4, 2, 'L', "BUCKE SAND"],
	['Timiskaming', 'BV', 'VERY POOR', 'SLOW', 3, 2, 'M', "BELLE VALLEE ORGANIC"],
	['Timiskaming', 'Cnc', 'POOR', 'VERY SLOW', 3, 3, 'H', "CANE SILTY CLAY LOAM"],
	['Timiskaming', 'Cns', 'POOR', 'SLOW', 4, 3, 'M', "CANE SILT LOAM"],
	['Timiskaming', 'Cnsil', 'POOR', 'VERY SLOW', 2, 3, 'H', "CANE SILT LOAM"],
	['Timiskaming', 'Cysil', 'IMPERFECT', 'SLOW', 3, 4, 'M', "CASEY SILT LOAM"],
	['Timiskaming', 'Dws', 'WELL', 'MODERATE', 4, 1, 'L', "DAWSON SANDY LOAM"],
	['Timiskaming', 'Dwsl', 'WELL', 'MODERATE', 4, 1, 'L', "DAWSON SANDY LOAM"],
	['Timiskaming', 'Eac', 'IMPERFECT', 'SLOW', 3, 3, 'H', "EARLTON SILTY CLAY LOAM"],
	['Timiskaming', 'Eas', 'IMPERFECT', 'SLOW', 3, 2, 'M', "EARLTON SILT LOAM"],
	['Timiskaming', 'Easil', 'IMPERFECT', 'SLOW', 2, 4, 'M', "EARLTON SILT LOAM"],
	['Timiskaming', 'El', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "ECCLESTONE LOAM"],
	['Timiskaming', 'Ensl', 'POOR', 'SLOW', 4, 2, 'M', "ENGLISH SANDY LOAM"],
	['Timiskaming', 'EPs', 'WELL', 'RAPID', 4, 1, 'L', "ELK PIT SAND"],
	['Timiskaming', 'Esil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "ECCLESTONE SILT LOAM"],
	['Timiskaming', 'Evs', 'WELL', 'MODERATE', 4, 1, 'L', "EVANTUREL SILT LOAM"],
	['Timiskaming', 'Evsl', 'WELL', 'MODERATE', 4, 1, 'L', "EVANTUREL SANDY LOAM"],
	['Timiskaming', 'Fac', 'POOR', 'VERY SLOW', 3, 3, 'H', "FAVAREAU CLAY"],
	['Timiskaming', 'Fasil', 'POOR', 'VERY SLOW', 3, 4, 'H', "FAVAREAU SILT LOAM"],
	['Timiskaming', 'Gafs', 'POOR', 'SLOW', 4, 2, 'M', "GAFFNEY FINE SAND"],
	['Timiskaming', 'Gal', 'POOR', 'SLOW', 3, 3, 'H', "GAFFNEY LOAM"],
	['Timiskaming', 'Gas', 'POOR', 'SLOW', 4, 2, 'M', "GAFFNEY SAND"],
	['Timiskaming', 'Gasl', 'POOR', 'SLOW', 4, 2, 'M', "GAFFNEY SANDY LOAM"],
	['Timiskaming', 'Gr', 'VERY POOR', 'SLOW', 3, 2, 'M', "GRASS ORGANIC"],
	['Timiskaming', 'Hac', 'WELL', 'SLOW', 3, 3, 'M', "HAILEYBURY CLAY"],
	['Timiskaming', 'Hasc', 'WELL', 'SLOW', 3, 3, 'M', "HAILEYBURY SILTY CLAY"],
	['Timiskaming', 'Hasic', 'WELL', 'SLOW', 3, 3, 'M', "HAILEYBURY SILTY CLAY"],
	['Timiskaming', 'Hngs', 'WELL', 'RAPID', 4, 1, 'L', "HANNA GRAVELLY SAND"],
	['Timiskaming', 'Hnl', 'WELL', 'RAPID', 4, 2, 'L', "HANNA LOAM"],
	['Timiskaming', 'Hns', 'WELL', 'RAPID', 4, 1, 'L', "HANBURY SILTY CLAY LOAM"],
	['Timiskaming', 'Hnsl', 'WELL', 'RAPID', 4, 1, 'L', "HANNA SANDY LOAM"],
	['Timiskaming', 'Hs', 'WELL', 'RAPID', 4, 1, 'L', "HENWOOD SAND"],
	['Timiskaming', 'Hsil', 'WELL', 'RAPID', 4, 2, 'L', "HARFRED SILT LOAM"],
	['Timiskaming', 'Htcl', 'WELL', 'SLOW', 4, 4, 'M', "HAULTAIN CLAY LOAM"],
	['Timiskaming', 'Htl', 'WELL', 'SLOW', 2, 4, 'L', "HAULTAIN LOAM"],
	['Timiskaming', 'Htsil', 'WELL', 'SLOW', 2, 4, 'L', "HAULTAIN SILT LOAM"],
	['Timiskaming', 'Htsl', 'WELL', 'MODERATE', 4, 2, 'L', "HAULTAIN SANDY LOAM"],
	['Timiskaming', 'Hyc', 'IMPERFECT', 'SLOW', 4, 4, 'H', "HANBURY CLAY"],
	['Timiskaming', 'Hycl', 'IMPERFECT', 'SLOW', 4, 4, 'H', "HANBURY CLAY LOAM"],
	['Timiskaming', 'Hys', 'IMPERFECT', 'SLOW', 3, 2, 'M', "HANBURY SAND"],
	['Timiskaming', 'Hysic', 'IMPERFECT', 'SLOW', 4, 4, 'H', "HANBURY SILTY CLAY"],
	['Timiskaming', 'Jngsl', 'WELL', 'RAPID', 4, 1, 'L', "JEANNIE GRAVELLY SANDY LOAM"],
	['Timiskaming', 'Jnsl', 'WELL', 'RAPID', 4, 1, 'L', "JEANNIE SANDY LOAM"],
	['Timiskaming', 'Jnvfl', 'WELL', 'RAPID', 4, 2, 'L', "JEANNIE VERY FINE SANDY LOAM"],
	['Timiskaming', 'Kasl', 'POOR', 'SLOW', 4, 2, 'M', "KEENOA SANDY LOAM"],
	['Timiskaming', 'Kg', 'VERY POOR', 'SLOW', 3, 2, 'M', "KETCHINIG ORGANIC"],
	['Timiskaming', 'Ksil', 'WELL', 'MODERATE', 1, 3, 'L', "KAPUSKASING SILT LOAM"],
	['Timiskaming', 'Ku', 'VERY POOR', 'SLOW', 3, 2, 'M', "KUSHOG ORGANIC"],
	['Timiskaming', 'Ld', 'VERY POOR', 'SLOW', 3, 2, 'M', "LARDER ORGANIC"],
	['Timiskaming', 'Ma', 'VERY POOR', 'SLOW', 3, 2, 'M', "MARSH ORGANIC"],
	['Timiskaming', 'Mkl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MAKOBE LOAM"],
	['Timiskaming', 'Mks', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "MAKOBE SAND"],
	['Timiskaming', 'Mksl', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "MAKOBE SANDY LOAM"],
	['Timiskaming', 'Ms', 'POOR', 'SLOW', 4, 2, 'M', "MALLARD SAND"],
	['Timiskaming', 'Msl', 'POOR', 'SLOW', 4, 2, 'M', "MOOSE LOAM"],
	['Timiskaming', 'Mtsl', 'WELL', 'RAPID', 4, 1, 'L', "MARTEN SANDY LOAM"],
	['Timiskaming', 'Myls', 'WELL', 'RAPID', 4, 1, 'L', "MARY LOAMY SAND"],
	['Timiskaming', 'Myp', 'WELL', 'RAPID', 4, 1, 'L', "MARY ORGANIC"],
	['Timiskaming', 'Mys', 'WELL', 'RAPID', 4, 1, 'L', "MARY SAND"],
	['Timiskaming', 'Mysl', 'WELL', 'RAPID', 4, 1, 'L', "MARY SANDY LOAM"],
	['Timiskaming', 'Necl', 'WELL', 'SLOW', 4, 4, 'M', "NELLIE CLAY LOAM"],
	['Timiskaming', 'NLc', 'POOR', 'VERY SLOW', 3, 3, 'H', "NEW LISKEARD CLAY"],
	['Timiskaming', 'O', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC ORGANIC"],
	['Timiskaming', 'Ots', 'IMPERFECT', 'SLOW', 3, 2, 'M', "OTTERSKIN SANDY LOAM"],
	['Timiskaming', 'Oucl', 'POOR', 'VERY SLOW', 3, 4, 'H', "OUELLETTE CLAY LOAM"],
	['Timiskaming', 'Pec', 'IMPERFECT', 'SLOW', 3, 3, 'H', "PENSE SILTY CLAY LOAM"],
	['Timiskaming', 'Pes', 'IMPERFECT', 'SLOW', 3, 2, 'M', "PENSE SILT LOAM"],
	['Timiskaming', 'Pesil', 'IMPERFECT', 'SLOW', 2, 4, 'M', "PENSE SILT LOAM"],
	['Timiskaming', 'Pyfs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "PYNE FINE SAND"],
	['Timiskaming', 'Pygs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "PYNE GRAVELLY SAND"],
	['Timiskaming', 'Pyl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "PYNE LOAM"],
	['Timiskaming', 'Pys', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "PYNE SAND"],
	['Timiskaming', 'Scsl', 'WELL', 'RAPID', 4, 1, 'L', "SCADEN SANDY LOAM"],
	['Timiskaming', 'Shcl', 'POOR', 'VERY SLOW', 3, 4, 'H', "SHETLAND CLAY LOAM"],
	['Timiskaming', 'Shsicl', 'POOR', 'VERY SLOW', 3, 4, 'H', "SHETLAND SILTY CLAY LOAM"],
	['Timiskaming', 'Sos', 'POOR', 'SLOW', 4, 2, 'M', "SOLVAN SAND"],
	['Timiskaming', 'Sosil', 'POOR', 'SLOW', 2, 3, 'H', "BIZ SILT LOAM"],
	['Timiskaming', 'Su', 'VERY POOR', 'SLOW', 3, 2, 'M', "SUNSTRUM ORGANIC"],
	['Timiskaming', 'TFp', 'VERY POOR', 'SLOW', 3, 2, 'M', "TWIN FALLS ORGANIC"],
	['Timiskaming', 'Toc', 'POOR', 'VERY SLOW', 3, 3, 'H', "THORNLOE CLAY"],
	['Timiskaming', 'U', 'VERY POOR', 'SLOW', 3, 2, 'M', "UNO PARK ORGANIC"],
	['Timiskaming', 'VCcl', 'WELL', 'SLOW', 4, 4, 'M', "VAL COTE CLAY LOAM"],
	['Timiskaming', 'Wdgs', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO GRAVELLY SAND"],
	['Timiskaming', 'Wdgsl', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO GRAVELLY SANDY LOAM"],
	['Timiskaming', 'Wdl', 'WELL', 'RAPID', 4, 2, 'L', "WENDIGO SANDY LOAM"],
	['Timiskaming', 'Wds', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO SAND"],
	['Timiskaming', 'Wdsl', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO SANDY LOAM"],
	['Timiskaming', 'Wngrs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "WITHINGTON GRAVELLY SAND"],
	['Timiskaming', 'Wnls', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "WITHINGTON LOAMY SAND"],
	['Timiskaming', 'Wns', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "WITHINGTON SAND"],
	['Timiskaming', 'Wnsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "WITHINGTON SANDY LOAM"],
	['Timiskaming', 'Wos', 'WELL', 'RAPID', 4, 1, 'L', "WOOLLEY SAND"],
	['Timiskaming', 'Wosl', 'WELL', 'RAPID', 4, 1, 'L', "WOOLLEY SANDY LOAM"],
	['Victoria', 'Acl', 'WELL', 'VERY SLOW', 1, 4, 'M', "AMELIASBURG CLAY LOAM"],
	['Victoria', 'Aycl', 'POOR', 'VERY SLOW', 3, 4, 'H', "ATHERLY CLAY LOAM"],
	['Victoria', 'Bes', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['Victoria', 'Bl', 'WELL', 'MODERATE', 1, 3, 'L', "BONDHEAD LOAM"],
	['Victoria', 'Bos', 'WELL', 'MODERATE', 4, 2, 'L', "BOOKTON SANDY LOAM"],
	['Victoria', 'Brsl', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON SANDY LOAM"],
	['Victoria', 'Bs', 'WELL', 'MODERATE', 4, 1, 'L', "BONDHEAD SANDY LOAM"],
	['Victoria', 'Cg', 'WELL', 'RAPID', 4, 1, 'L', "CRAMAHE GRAVELLY SANDY LOAM"],
	['Victoria', 'Dasl', 'IMPERFECT', 'SLOW', 3, 2, 'M', "DALTON SANDY LOAM"],
	['Victoria', 'Dl', 'WELL', 'MODERATE', 1, 3, 'L', "DUMMER LOAM"],
	['Victoria', 'Dl-sh', 'WELL', 'RAPID', 1, 4, 'L', "DUMMER LOAM"],
	['Victoria', 'Dsl', 'WELL', 'MODERATE', 4, 1, 'L', "DUNDONALD SANDY LOAM"],
	['Victoria', 'El', 'IMPERFECT', 'MODERATE', 2, 4, 'M', "EMILY LOAM"],
	['Victoria', 'El-sh', 'IMPERFECT', 'RAPID', 2, 4, 'M', "EMILY LOAM"],
	['Victoria', 'Fl', 'VARIABLE', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Victoria', 'Gsl', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Victoria', 'Gul', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "GUERIN LOAM"],
	['Victoria', 'Ks', 'POOR', 'SLOW', 4, 2, 'M', "KENABEEK SANDY LOAM"],
	['Victoria', 'Lcl', 'POOR', 'VERY SLOW', 3, 3, 'H', "LINDSAY CLAY LOAM"],
	['Victoria', 'Ll', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['Victoria', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Victoria', 'Ms', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "MALLARD SANDY LOAM"],
	['Victoria', 'Ol', 'WELL', 'MODERATE', 1, 3, 'L', "OTONABEE LOAM"],
	['Victoria', 'Ol-s', 'WELL', 'MODERATE', 1, 3, 'L', "OTONABEE LOAM"],
	['Victoria', 'Ol-sh', 'WELL', 'RAPID', 1, 4, 'L', "OTONABEE LOAM"],
	['Victoria', 'Osl', 'WELL', 'MODERATE', 4, 1, 'L', "OTONABEE SANDY LOAM"],
	['Victoria', 'P', 'VERY POOR', 'SLOW', 3, 2, 'M', "PEAT ORGANIC"],
	['Victoria', 'Ps', 'WELL', 'RAPID', 4, 1, 'L', "PONTYPOOL SANDY LOAM"],
	['Victoria', 'Scl', 'IMPERFECT', 'SLOW', 3, 4, 'H', "SMITHFIELD CLAY LOAM"],
	['Victoria', 'Sic', 'POOR', 'VERY SLOW', 3, 3, 'H', "SIMCOE CLAY"],
	['Victoria', 'Socl', 'IMPERFECT', 'SLOW', 3, 3, 'H', "SOLMESVILLE CLAY LOAM"],
	['Victoria', 'Tsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "TECUMSETH SANDY LOAM"],
	['Victoria', 'Was', 'POOR', 'SLOW', 4, 3, 'M', "WAUSEON SANDY LOAM"],
	['Victoria', 'Wes', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO SAND"],
	['Victoria', 'Wes-st', 'WELL', 'RAPID', 4, 1, 'L', "WENDIGO SAND"],
	['Victoria', 'Wsc', 'WELL', 'SLOW', 2, 4, 'M', "WAUPOOS CLAY LOAM"],
	['Waterloo', 'Ay', 'POOR', 'SLOW', 4, 2, 'M', "AYR SANDY LOAM"],
	['Waterloo', 'Ba', 'WELL', 'MODERATE', 1, 3, 'L', "BRANT LOAM"],
	['Waterloo', 'Bc', 'POOR', 'VERY SLOW', 3, 4, 'H', "BROOKSTON CLAY LOAM"],
	['Waterloo', 'Be', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['Waterloo', 'Bg', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD GRAVELLY LOAM"],
	['Waterloo', 'Bi', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRISBANE LOAM"],
	['Waterloo', 'Bk', 'IMPERFECT', 'SLOW', 2, 4, 'M', "BROOKE LOAM"],
	['Waterloo', 'Bm', 'WELL', 'MODERATE', 4, 2, 'L', "BOOMER LOAM"],
	['Waterloo', 'Bn', 'WELL', 'MODERATE', 2, 4, 'L', "BENNINGTON LOAM"],
	['Waterloo', 'Bo', 'WELL', 'MODERATE', 3, 2, 'L', "BOOKTON SANDY LOAM"],
	['Waterloo', 'Br', 'POOR', 'VERY SLOW', 3, 3, 'H', "BROOKSTON LOAM"],
	['Waterloo', 'Bs', 'POOR', 'VERY SLOW', 4, 3, 'M', "BROOKSTON LOAM"],
	['Waterloo', 'Bu', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD LOAM"],
	['Waterloo', 'By', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SANDY LOAM"],
	['Waterloo', 'Ca', 'WELL', 'RAPID', 4, 1, 'L', "CALEDON SANDY LOAM"],
	['Waterloo', 'Cd', 'POOR', 'SLOW', 2, 3, 'H', "COLWOOD LOAM"],
	['Waterloo', 'Cm', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "CAMILLA SANDY LOAM"],
	['Waterloo', 'Co', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "CONESTOGA LOAM"],
	['Waterloo', 'Dn', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "DONALD LOAM"],
	['Waterloo', 'Do', 'VERY POOR', 'VERY SLOW', 3, 3, 'H', "DORKING SILT LOAM"],
	['Waterloo', 'Du', 'WELL', 'RAPID', 4, 2, 'L', "DUMFRIES LOAM"],
	['Waterloo', 'El', 'POOR', 'SLOW', 2, 3, 'H', "ELMIRA LOAM"],
	['Waterloo', 'Fa', 'WELL', 'MODERATE', 3, 2, 'L', "FARMINGTON SANDY LOAM"],
	['Waterloo', 'Fl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "FLORADALE LOAM"],
	['Waterloo', 'Fo', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Waterloo', 'Fr', 'WELL', 'MODERATE', 4, 1, 'L', "FREEPORT SANDY LOAM"],
	['Waterloo', 'Gr', 'WELL', 'MODERATE', 4, 2, 'L', "GRAND LOAM"],
	['Waterloo', 'Gs', 'WELL', 'MODERATE', 4, 1, 'L', "GUELPH SANDY LOAM"],
	['Waterloo', 'Gu', 'WELL', 'MODERATE', 1, 3, 'L', "GUELPH LOAM"],
	['Waterloo', 'Gy', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Waterloo', 'Ha', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "HAYSVILLE SANDY LOAM"],
	['Waterloo', 'Hc', 'WELL', 'SLOW', 2, 4, 'M', "HURON CLAY LOAM"],
	['Waterloo', 'He', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "HEIDELBERG FINE SANDY LOAM"],
	['Waterloo', 'Hk', 'POOR', 'SLOW', 3, 3, 'H', "HAWKESVILLE LOAM"],
	['Waterloo', 'Hr', 'POOR', 'SLOW', 4, 2, 'M', "HESPELER SANDY LOAM"],
	['Waterloo', 'Hu', 'WELL', 'SLOW', 2, 4, 'L', "HURON LOAM"],
	['Waterloo', 'Ki', 'WELL', 'RAPID', 4, 1, 'L', "KIRKLAND SANDY LOAM"],
	['Waterloo', 'Ko', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "KOSSUTH SANDY LOAM"],
	['Waterloo', 'Li', 'WELL', 'RAPID', 4, 1, 'L', "LISBON SANDY LOAM"],
	['Waterloo', 'Lo', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LONDON LOAM"],
	['Waterloo', 'Ma', 'WELL', 'MODERATE', 4, 2, 'L', "MANNHEIM LOAM"],
	['Waterloo', 'Mc', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC ORGANIC"],
	['Waterloo', 'Md', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC ORGANIC"],
	['Waterloo', 'Mf', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC ORGANIC"],
	['Waterloo', 'Mp', 'POOR', 'SLOW', 3, 3, 'H', "MAPLEWOOD LOAM"],
	['Waterloo', 'Mr', 'POOR', 'SLOW', 2, 3, 'H', "MARYHILL LOAM"],
	['Waterloo', 'Ms', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC ORGANIC"],
	['Waterloo', 'Mt', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MACTON LOAM"],
	['Waterloo', 'Pc', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PERTH CLAY LOAM"],
	['Waterloo', 'Pe', 'IMPERFECT', 'SLOW', 3, 4, 'M', "PERTH LOAM"],
	['Waterloo', 'Pr', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "PRESTON SANDY LOAM"],
	['Waterloo', 'Ps', 'IMPERFECT', 'SLOW', 3, 2, 'M', "PERTH SANDY LOAM"],
	['Waterloo', 'Sc', 'WELL', 'SLOW', 2, 4, 'L', "ST CLEMENTS SILT LOAM"],
	['Waterloo', 'Sj', 'WELL', 'RAPID', 4, 2, 'L', "ST JACOBS LOAM"],
	['Waterloo', 'Ss', 'WELL', 'SLOW', 3, 2, 'L', "ST CLEMENTS SANDY LOAM"],
	['Waterloo', 'Ta', 'IMPERFECT', 'SLOW', 3, 4, 'M', "TAVISTOCK LOAM"],
	['Waterloo', 'To', 'POOR', 'VERY SLOW', 3, 3, 'H', "TOLEDO SILTY CLAY LOAM"],
	['Waterloo', 'Tu', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA LOAM"],
	['Waterloo', 'Wa', 'WELL', 'RAPID', 4, 2, 'L', "WATERLOO FINE SANDY LOAM"],
	['Waterloo', 'We', 'IMPERFECT', 'SLOW', 2, 4, 'M', "WELLESLEY SILT LOAM"],
	['Waterloo', 'Wi', 'POOR', 'VERY SLOW', 3, 3, 'H', "WILMOT SILTY CLAY LOAM"],
	['Waterloo', 'Wo', 'WELL', 'MODERATE', 1, 3, 'L', "WOOLWICH LOAM"],
	['Waterloo', 'Ws', 'IMPERFECT', 'SLOW', 3, 2, 'M', "WELLESLEY SANDY LOAM"],
	['Waterloo', 'Wu', 'POOR', 'SLOW', 4, 3, 'M', "WAUSEON SANDY LOAM"],
	['Waterloo', 'Wx', 'POOR', 'VERY SLOW', 4, 3, 'M', "WILMOT SANDY LOAM"],
	['Wellington', 'Bg', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD LOAM"],
	['Wellington', 'Bl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "BRISBANE LOAM"],
	['Wellington', 'Bnl', 'POOR', 'VERY SLOW', 2, 3, 'H', "BROOKSTON LOAM"],
	['Wellington', 'Bns', 'POOR', 'VERY SLOW', 2, 3, 'H', "BROOKSTON SILT LOAM"],
	['Wellington', 'Bs', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SANDY LOAM"],
	['Wellington', 'Btf', 'WELL', 'MODERATE', 1, 3, 'L', "BRANT FINE SANDY LOAM"],
	['Wellington', 'Cg', 'WELL', 'RAPID', 4, 2, 'L', "CALEDON FINE SANDY LOAM"],
	['Wellington', 'Cof', 'POOR', 'SLOW', 2, 3, 'H', "COLWOOD FINE SANDY LOAM"],
	['Wellington', 'Cos', 'POOR', 'SLOW', 2, 3, 'H', "COLWOOD SILT LOAM"],
	['Wellington', 'Db', 'WELL', 'RAPID', 4, 1, 'L', "DONNYBROOK SANDY LOAM"],
	['Wellington', 'Df', 'WELL', 'RAPID', 4, 1, 'L', "DUMFRIES LOAM"],
	['Wellington', 'Fl', 'WELL', 'MODERATE', 1, 4, 'L', "FARMINGTON LOAM"],
	['Wellington', 'Fs', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['Wellington', 'Gil', 'POOR', 'SLOW', 3, 3, 'H', "GILFORD LOAM"],
	['Wellington', 'Gl', 'WELL', 'MODERATE', 1, 3, 'L', "GUELPH LOAM"],
	['Wellington', 'Grs', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Wellington', 'Gsl', 'WELL', 'MODERATE', 4, 1, 'L', "GUELPH SANDY LOAM"],
	['Wellington', 'Hif', 'WELL', 'RAPID', 4, 2, 'L', "HILLSBURGH FINE SANDY LOAM"],
	['Wellington', 'His', 'WELL', 'RAPID', 4, 2, 'L', "HILLSBURGH SANDY LOAM"],
	['Wellington', 'Hl', 'WELL', 'MODERATE', 1, 3, 'L', "HARRISTON LOAM"],
	['Wellington', 'Hs', 'WELL', 'MODERATE', 1, 3, 'L', "HARRISTON SILT LOAM"],
	['Wellington', 'Hul', 'WELL', 'SLOW', 2, 4, 'L', "HURON LOAM"],
	['Wellington', 'Hus', 'WELL', 'SLOW', 2, 4, 'L', "HURON SILT LOAM"],
	['Wellington', 'Kl', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "KILLEAN LOAM"],
	['Wellington', 'Lil', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LISTOWEL LOAM"],
	['Wellington', 'Lis', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LISTOWEL SILT LOAM"],
	['Wellington', 'Ll', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "LONDON LOAM"],
	['Wellington', 'Lyl', 'POOR', 'SLOW', 3, 3, 'H', "LILY LOAM"],
	['Wellington', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['Wellington', 'Pal', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL LOAM"],
	['Wellington', 'Pas', 'POOR', 'SLOW', 2, 3, 'H', "PARKHILL SILT LOAM"],
	['Wellington', 'Pl', 'IMPERFECT', 'SLOW', 2, 4, 'M', "PERTH LOAM"],
	['Wellington', 'Ps', 'IMPERFECT', 'SLOW', 2, 4, 'M', "PERTH SILT LOAM"],
	['Wellington', 'Tc', 'POOR', 'VERY SLOW', 3, 3, 'H', "TOLEDO CLAY LOAM"],
	['Wellington', 'Tes', 'WELL', 'MODERATE', 4, 2, 'L', "TEESWATER SILT LOAM"],
	['Wellington', 'Tus', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA SILT LOAM"],
	['York', 'Bes', 'IMPERFECT', 'SLOW', 3, 2, 'M', "BERRIEN SANDY LOAM"],
	['York', 'Bg', 'WELL', 'RAPID', 4, 2, 'L', "BURFORD LOAM"],
	['York', 'Bis', 'WELL', 'RAPID', 4, 1, 'L', "BRIDGEMAN SAND"],
	['York', 'Bl', 'WELL', 'MODERATE', 1, 3, 'L', "BONDHEAD LOAM"],
	['York', 'Bos', 'WELL', 'MODERATE', 4, 2, 'L', "BOOKTON SANDY LOAM"],
	['York', 'Brsl', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON SANDY LOAM"],
	['York', 'Brsl/g', 'WELL', 'RAPID', 4, 1, 'L', "BRIGHTON SANDY LOAM"],
	['York', 'Bs', 'WELL', 'MODERATE', 4, 1, 'L', "BONDHEAD SANDY LOAM"],
	['York', 'Bsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SANDY LOAM"],
	['York', 'Cac', 'WELL', 'SLOW', 3, 3, 'M', "CASHEL CLAY"],
	['York', 'Chc', 'IMPERFECT', 'SLOW', 3, 4, 'H', "CHINGUACOUSY CLAY LOAM"],
	['York', 'Dsl', 'WELL', 'MODERATE', 4, 1, 'L', "DUNDONALD SANDY LOAM"],
	['York', 'El', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "EMILY LOAM"],
	['York', 'Fsl', 'WELL', 'RAPID', 4, 1, 'L', "FOX SANDY LOAM"],
	['York', 'Gg', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "GWILLIMBURY SANDY LOAM"],
	['York', 'Gil', 'POOR', 'SLOW', 3, 3, 'H', "GILFORD LOAM"],
	['York', 'Gsl', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['York', 'Gul', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "GUERIN LOAM"],
	['York', 'GuS', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "GUERIN SANDY LOAM"],
	['York', 'Jc', 'POOR', 'VERY SLOW', 3, 4, 'H', "JEDDO CLAY LOAM"],
	['York', 'Kic', 'WELL', 'SLOW', 2, 4, 'M', "KING CLAY LOAM"],
	['York', 'Kis', 'WELL', 'SLOW', 2, 4, 'L', "KING SILT LOAM"],
	['York', 'Ki-s', 'WELL', 'SLOW', 2, 4, 'M', "KING CLAY LOAM"],
	['York', 'Ll', 'POOR', 'SLOW', 2, 3, 'H', "LYONS LOAM"],
	['York', 'M', 'VERY POOR', 'SLOW', 3, 2, 'M', "MUCK ORGANIC"],
	['York', 'Mac', 'POOR', 'VERY SLOW', 3, 3, 'H', "MALTON CLAY"],
	['York', 'Ml', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "MILLIKEN LOAM"],
	['York', 'Moc', 'IMPERFECT', 'SLOW', 3, 4, 'H', "MONOGHAN CLAY LOAM"],
	['York', 'Mos', 'IMPERFECT', 'SLOW', 3, 4, 'M', "MONOGHAN SILT LOAM"],
	['York', 'Ms', 'IMPERFECT', 'MODERATE', 3, 2, 'M', "MILLIKEN SANDY LOAM"],
	['York', 'Oc', 'WELL', 'SLOW', 2, 4, 'M', "ONEIDA CLAY LOAM"],
	['York', 'Ol', 'WELL', 'MODERATE', 1, 3, 'L', "OTONABEE LOAM"],
	['York', 'Osl', 'WELL', 'MODERATE', 4, 1, 'L', "OTONABEE SANDY LOAM"],
	['York', 'Pec', 'IMPERFECT', 'SLOW', 3, 4, 'H', "PEEL CLAY"],
	['York', 'Pfs', 'WELL', 'MODERATE', 4, 2, 'L', "PERCY FINE SANDY LOAM"],
	['York', 'Ps', 'WELL', 'RAPID', 4, 1, 'L', "PONTYPOOL SAND"],
	['York', 'Psl', 'WELL', 'RAPID', 4, 1, 'L', "PONTYPOOL SANDY LOAM"],
	['York', 'Rsl', 'WELL', 'MODERATE', 4, 1, 'L', "RUBICON SANDY LOAM"],
	['York', 'Scl', 'IMPERFECT', 'SLOW', 3, 4, 'H', "SMITHFIELD CLAY LOAM"],
	['York', 'Sg', 'WELL', 'RAPID', 4, 1, 'L', "SARGENT SANDY LOAM"],
	['York', 'Shc', 'WELL', 'SLOW', 2, 4, 'M', "SCHOMBERG CLAY LOAM"],
	['York', 'Shc-s', 'WELL', 'SLOW', 2, 4, 'M', "SCHOMBERG CLAY LOAM"],
	['York', 'Shs', 'WELL', 'SLOW', 2, 4, 'L', "SCHOMBERG SILT LOAM"],
	['York', 'Sic', 'POOR', 'VERY SLOW', 3, 4, 'H', "SIMCOE CLAY LOAM"],
	['York', 'Tisl', 'WELL', 'RAPID', 4, 1, 'L', "TIOGA SANDY LOAM"],
	['York', 'Tsl', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "TECUMSETH SANDY LOAM"],
	['York', 'Tsl/g', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "TECUMSETH SANDY LOAM"],
	['York', 'Was', 'POOR', 'SLOW', 4, 3, 'M', "WAUSEON SANDY LOAM"],
	['York', 'Wol', 'WELL', 'MODERATE', 1, 3, 'L', "WOBURN LOAM"],
	['York', 'Wos', 'WELL', 'MODERATE', 4, 1, 'L', "WOBURN SANDY LOAM"],
	['Middlesex', 'BA8/B, b: BCW', 'POOR', 'VERY SLOW', 3, 3, 'H', "BLACKWELL SILTY CLAY"],
	['Middlesex', 'BF4/A, B, b, C, c: BVY', 'IMPERFECT', 'SLOW', 3, 4, 'H', "BEVERLY SILTY CLAY LOAM"],
	['Middlesex', 'BF4/D,d: BFO', 'WELL', 'SLOW', 2, 4, 'M', "BRANTFORD SILTY CLAY LOAM"],
	['Middlesex', 'BF6/A, B, b, c: BVY', 'IMPERFECT', 'SLOW', 3, 4, 'H', "BEVERLY SILTY CLAY LOAM"],
	['Middlesex', 'BF6/d: BFO', 'WELL', 'SLOW', 2, 4, 'M', "BRANTFORD SILTY CLAY LOAM"],
	['Middlesex', 'BF8/B, b, c: TLD', 'POOR', 'VERY SLOW', 3, 4, 'H', "TOLEDO SILTY CLAY LOAM"],
	['Middlesex', 'BN4.T/b, C, c: TVK', 'IMPERFECT', 'SLOW', 2, 4, 'M', "TAVISTOCK LOAM"],
	['Middlesex', 'BN4.T/D, d: BNG', 'WELL', 'MODERATE', 2, 4, 'L', "TAVISTOCK LOAM"],
	['Middlesex', 'BN4/B, b, C, c: TVK', 'IMPERFECT', 'SLOW', 2, 4, 'M', "TAVISTOCK LOAM"],
	['Middlesex', 'BN4/D: BNG', 'WELL', 'MODERATE', 2, 4, 'L', "BENNINGTON SILT LOAM"],
	['Middlesex', 'BN6.T/B, b, C, c: TVK', 'IMPERFECT', 'SLOW', 2, 4, 'M', "TAVISTOCK LOAM"],
	['Middlesex', 'BN6/B, b, c: TVK', 'IMPERFECT', 'SLOW', 2, 4, 'M', "TAVISTOCK LOAM"],
	['Middlesex', 'BN8.P/B: MPW', 'POOR', 'SLOW', 2, 3, 'M', "MAPLEWOOD LOAM"],
	['Middlesex', 'BN8.T/B, b: MPW', 'POOR', 'SLOW', 2, 3, 'M', "MAPLEWOOD LOAM"],
	['Middlesex', 'BN8/B, b: MPW', 'POOR', 'SLOW', 2, 3, 'M', "MAPLEWOOD LOAM"],
	['Middlesex', 'BN9.T/B, b: MPW', 'POOR', 'SLOW', 2, 3, 'M', "MAPLEWOOD LOAM"],
	['Middlesex', 'BN9/b: MPW', 'POOR', 'SLOW', 2, 3, 'M', "MAPLEWOOD LOAM"],
	['Middlesex', 'BO4.T/B, b, C, c: BRR', 'IMPERFECT', 'SLOW', 2, 4, 'M', "BERRIEN FINE SANDY LOAM"],
	['Middlesex', 'BO4.T/D, d: BOO', 'WELL', 'MODERATE', 2, 4, 'L', "BOOKTON FINE SANDY LOAM"],
	['Middlesex', 'BO4/B, b, C, c: BRR', 'IMPERFECT', 'SLOW', 2, 4, 'M', "BERRIEN FINE SANDY LOAM"],
	['Middlesex', 'BO4/d: BOO', 'WELL', 'MODERATE', 2, 4, 'L', "BOOKTON FINE SANDY LOAM"],
	['Middlesex', 'BO6.T/B, b, c: BRR', 'IMPERFECT', 'SLOW', 2, 4, 'M', "BERRIEN FINE SANDY LOAM"],
	['Middlesex', 'BO6/b, c: BRR', 'IMPERFECT', 'SLOW', 2, 4, 'M', "BERRIEN FINE SANDY LOAM"],
	['Middlesex', 'BO6/d: BOO', 'WELL', 'MODERATE', 2, 4, 'L', "BOOKTON FINE SANDY LOAM"],
	['Middlesex', 'BO8.T/B, b: WUS', 'POOR', 'SLOW', 2, 3, 'M', "WAUSEON FINE SANDY LOAM"],
	['Middlesex', 'BO8/B, b, c: WUS', 'POOR', 'SLOW', 2, 3, 'M', "WAUSEON FINE SANDY LOAM"],
	['Middlesex', 'BO9.T/b: WUS', 'POOR', 'SLOW', 2, 3, 'M', "WAUSEON FINE SANDY LOAM"],
	['Middlesex', 'BO9/b: WUS', 'POOR', 'SLOW', 2, 3, 'M', "WAUSEON FINE SANDY LOAM"],
	['Middlesex', 'BR4/B, b, C, c: THN', 'IMPERFECT', 'SLOW', 2, 3, 'M', "THORNDALE SILT LOAM"],
	['Middlesex', 'BR4/D, d, E: BRY', 'WELL', 'MODERATE', 1, 3, 'L', "BRYANSTON SILT LOAM"],
	['Middlesex', 'BR6/B, b, C, c: THN', 'IMPERFECT', 'SLOW', 2, 3, 'M', "THORNDALE SILT LOAM"],
	['Middlesex', 'BR6/d: BRY', 'WELL', 'MODERATE', 1, 3, 'L', "BRYANSTON SILT LOAM"],
	['Middlesex', 'BR8/B: NIS', 'POOR', 'SLOW', 2, 3, 'M', "NISSOURI SILT LOAM"],
	['Middlesex', 'BR9/B, b: NIS', 'POOR', 'SLOW', 2, 3, 'M', "NISSOURI SILT LOAM"],
	['Middlesex', 'BT4/B, b, C, c: TUC', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA LOAM"],
	['Middlesex', 'BT4/D, d: BRT', 'WELL', 'MODERATE', 1, 3, 'L', "BRANT SILT LOAM"],
	['Middlesex', 'BT4/e: BRT', 'WELL', 'MODERATE', 1, 3, 'L', "BRANT SILT LOAM"],
	['Middlesex', 'BT6/B, b, C, c: TUC', 'IMPERFECT', 'SLOW', 2, 3, 'M', "TUSCOLA LOAM"],
	['Middlesex', 'BT8.P/B: CWO', 'POOR', 'SLOW', 2, 3, 'M', "COLWOOD LOAM"],
	['Middlesex', 'BT8/A, B, b, c: CWO', 'POOR', 'SLOW', 2, 3, 'M', "COLWOOD LOAM"],
	['Middlesex', 'BT9/B, b, c: CWO', 'POOR', 'SLOW', 2, 3, 'M', "COLWOOD LOAM"],
	['Middlesex', 'BU4/B, b, C, c, D, d, E, e, F, f: BUF', 'WELL', 'RAPID', 4, 1, 'L', "BURFORD GRAVELLY SANDY LOAM"],
	['Middlesex', 'BU8/b: GFD', 'POOR', 'SLOW', 4, 2, 'M', "GILFORD SANDY LOAM"],
	['Middlesex', 'CA4/B, b: CML', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "CAMILLA SANDY LOAM"],
	['Middlesex', 'CA4/C, c, D, d, E: CAD', 'WELL', 'RAPID', 4, 1, 'L', "CALEDON SANDY LOAM"],
	['Middlesex', 'CA6/c: CAD', 'WELL', 'RAPID', 4, 1, 'L', "CALEDON SANDY LOAM"],
	['Middlesex', 'CA8/b: AYR', 'POOR', 'SLOW', 3, 3, 'M', "AYR FINE SANDY LOAM"],
	['Middlesex', 'F04/C: FOX', 'WELL', 'RAPID', 4, 1, 'L', "FOX LOAMY SAND"],
	['Middlesex', 'FO4/B, b: BAY', 'IMPERFECT', 'MODERATE', 4, 2, 'M', "BRADY SANDY LOAM"],
	['Middlesex', 'FO4/C, c, D, d: FOX', 'WELL', 'RAPID', 4, 1, 'L', "FOX LOAMY SAND"],
	['Middlesex', 'FO6/c: FOX', 'WELL', 'RAPID', 4, 1, 'L', "FOX LOAMY SAND"],
	['Middlesex', 'FO8/B: GNY', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SANDY LOAM"],
	['Middlesex', 'FO9/B, b: GNY', 'POOR', 'SLOW', 4, 2, 'M', "GRANBY SILTY CLAY LOAM"],
	['Middlesex', 'HU4/B, b, C, c: PTH', 'IMPERFECT', 'SLOW', 3, 3, 'H', "PERTH SILTY CLAY LOAM"],
	['Middlesex', 'HU4/D, d, E, e: HUO', 'WELL', 'SLOW', 2, 4, 'L', "HURON SILT LOAM"],
	['Middlesex', 'HU6/B, b, C, c: PTH', 'IMPERFECT', 'SLOW', 3, 3, 'H', "PERTH SILTY CLAY LOAM"],
	['Middlesex', 'HU6/D, d: HUO', 'WELL', 'SLOW', 2, 4, 'L', "HURON SILT LOAM"],
	['Middlesex', 'HU8/B, b: BKN', 'POOR', 'VERY SLOW', 3, 3, 'H', "BROOKSTON SILTY CLAY LOAM"],
	['Middlesex', 'HU9/B, b, c: BKN', 'POOR', 'VERY SLOW', 3, 3, 'H', "BROOKSTON SILTY CLAY LOAM"],
	['Middlesex', 'HY4/B, b, C, c: EBR', 'IMPERFECT', 'SLOW', 2, 3, 'M', "EMBRO SILT LOAM"],
	['Middlesex', 'HY4/D, d, e: HYW', 'WELL', 'MODERATE', 1, 3, 'L', "HONEYWOOD SILT LOAM"],
	['Middlesex', 'HY6/B, b, C, c: EBR', 'IMPERFECT', 'SLOW', 2, 3, 'M', "EMBRO SILT LOAM"],
	['Middlesex', 'HY8/b: CMB', 'POOR', 'SLOW', 2, 3, 'M', "EMBRO SILT LOAM"],
	['Middlesex', 'HY9/B, b: CMB', 'POOR', 'SLOW', 2, 3, 'M', "CROMBIE SILT LOAM"],
	['Middlesex', 'ME4/B, b, C, c, d: EKF', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "EKFRID SILTY CLAY"],
	['Middlesex', 'ME8/b: SBN', 'POOR', 'VERY SLOW', 3, 3, 'H', "STRATHBURN SILTY CLAY"],
	['Middlesex', 'MU4/B, b, C, c: GOB', 'IMPERFECT', 'SLOW', 3, 3, 'H', "GOBLES CLAY LOAM"],
	['Middlesex', 'MU4/D, d, E, e, F: MUI', 'WELL', 'SLOW', 2, 4, 'L', "MURIEL SILT LOAM"],
	['Middlesex', 'MU6/B, b, C, c: GOB', 'IMPERFECT', 'SLOW', 3, 3, 'H', "GOBLES CLAY LOAM"],
	['Middlesex', 'MU6/D, d: EKF', 'IMPERFECT', 'VERY SLOW', 3, 4, 'H', "EKFRID SILTY CLAY"],
	['Middlesex', 'MU8/B, b: KVN', 'POOR', 'SLOW', 3, 3, 'H', "KELVIN CLAY LOAM"],
	['Middlesex', 'MU9/B, b: KVN', 'POOR', 'SLOW', 3, 3, 'H', "KELVIN CLAY LOAM"],
	['Middlesex', 'OD1/B: OD', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC DEEP ORGANIC"],
	['Middlesex', 'OD2/A: OD', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC DEEP ORGANIC"],
	['Middlesex', 'OD2/B: OD', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC DEEP ORGANIC"],
	['Middlesex', 'OD3/b: OD', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC DEEP ORGANIC"],
	['Middlesex', 'OD3/B: OD', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC DEEP ORGANIC"],
	['Middlesex', 'OS1/A: OS', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC SHALLOW ORGANIC"],
	['Middlesex', 'OS1/B: OS', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC SHALLOW ORGANIC"],
	['Middlesex', 'OS2/b: OS', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC SHALLOW ORGANIC"],
	['Middlesex', 'OS2/B: OS', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC SHALLOW ORGANIC"],
	['Middlesex', 'OU1/B: OU', 'VERY POOR', 'SLOW', 3, 2, 'M', "ORGANIC UNDIFFERENTIATED ORGANIC"],
	['Middlesex', 'PL4/B, b: WAM', 'IMPERFECT', 'RAPID', 4, 2, 'M', "WALSINGHAM LOAMY FINE SAND"],
	['Middlesex', 'PL4/C, c, d: PFD', 'WELL', 'RAPID', 4, 1, 'L', "PLAINFIELD FINE SAND"],
	['Middlesex', 'PL6/b: WAM', 'IMPERFECT', 'RAPID', 4, 2, 'M', "WALSINGHAM LOAMY FINE SAND"],
	['Middlesex', 'PL6/c: PFD', 'WELL', 'RAPID', 4, 1, 'L', "PLAINFIELD FINE SAND"],
	['Middlesex', 'PL8/B, b: WRN', 'POOR', 'SLOW', 4, 2, 'M', "WATERIN LOAMY FINE SAND"],
	['Middlesex', 'PL9/B, b: WRN', 'POOR', 'SLOW', 4, 2, 'M', "WATERIN LOAMY FINE SAND"],
	['Middlesex', 'TE4/B, b, C, c: FAN', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "FANSHAWE SILT LOAM"],
	['Middlesex', 'TE4/D, d: TEW', 'WELL', 'MODERATE', 4, 2, 'L', "TEESWATER SILT LOAM"],
	['Middlesex', 'TE6/B, C: FAN', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "FANSHAWE SILT LOAM"],
	['Middlesex', 'TE8/B: BLL', 'POOR', 'SLOW', 3, 3, 'M', "BALLYMOTE LOAM"],
	['Middlesex', 'TE9/b: BLL', 'POOR', 'SLOW', 3, 3, 'M', "BALLYMOTE LOAM"],
	['Middlesex', 'TEC/c: FAN', 'IMPERFECT', 'MODERATE', 2, 3, 'M', "FANSHAWE SILT LOAM"],
	['Middlesex', 'WA4.T/B, b, C, c: VIT', 'IMPERFECT', 'SLOW', 2, 3, 'M', "VITTORIA FINE SANDY LOAM"],
	['Middlesex', 'WA4.T/d, e: WSH', 'WELL', 'SLOW', 1, 3, 'L', "WALSHER FINE SANDY LOAM"],
	['Middlesex', 'WA4/B, b, C, c, d: VIT', 'IMPERFECT', 'SLOW', 2, 3, 'M', "VITTORIA FINE SANDY LOAM"],
	['Middlesex', 'WA6.T/C, c: VIT', 'IMPERFECT', 'SLOW', 2, 3, 'M', "VITTORIA FINE SANDY LOAM"],
	['Middlesex', 'WA6/b: VIT', 'IMPERFECT', 'SLOW', 2, 3, 'M', "VITTORIA FINE SANDY LOAM"],
	['Middlesex', 'WA8.T/B: SIH', 'POOR', 'SLOW', 4, 2, 'M', "SILVER HILL SANDY LOAM"],
	['Middlesex', 'WA8/b: SIH', 'POOR', 'SLOW', 4, 2, 'M', "SILVER HILL SANDY LOAM"],
	['Middlesex', 'WA9/B: SIH', 'POOR', 'SLOW', 4, 2, 'M', "SILVER HILL SANDY LOAM"],
	['Middlesex', 'WF4/B, b: NDE', 'IMPERFECT', 'SLOW', 1, 3, 'L', "NORMANDALE FINE SANDY LOAM"],
	['Middlesex', 'WF4/C, c, D, d: WAT', 'WELL', 'SLOW', 1, 3, 'L', "WATTFORD FINE SANDY LOAM"],
	['Middlesex', 'WF6/b: NDE', 'IMPERFECT', 'SLOW', 1, 3, 'L', "NORMANDALE FINE SANDY LOAM"],
	['Middlesex', 'WF6/C, c: WAT', 'WELL', 'SLOW', 1, 3, 'L', "WATTFORD FINE SANDY LOAM"],
	['Middlesex', 'WF8.P/B, b: SLI', 'POOR', 'SLOW', 3, 3, 'M', "ST WILLIAMS VERY FINE SANDY LOAM"],
	['Middlesex', 'WF8/B, b, C, c: SLI', 'POOR', 'SLOW', 3, 3, 'M', "ST WILLIAMS VERY FINE SANDY LOAM"],
]
