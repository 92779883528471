export const LANGUAGE_CODE_QUERY_PARAM = 'lang'

export type LanguageCode = 'en' | 'fr'

export const DEFAULT_LANGUAGE_CODE: LanguageCode = 'en'

export type LanguageCodeMap<T> = { [k in LanguageCode]: T }

export const LanguageNames: LanguageCodeMap<string> = {
	en: 'English', 
	fr: 'Français'
}

export function isLanguageCode(possibleLanguageCode: string): possibleLanguageCode is LanguageCode {
	return !!LanguageNames[possibleLanguageCode as LanguageCode]
}

const englishResources = {
	OntarioSoilsCalculator: "Ontario Soils Calculator", 
	SoilsCalculator: "Soils Calculator", 
	Calculate: "Calculate", 
	Input: "Input", 
	Output: "Output", 
	About: "About", 
	Region: "Region", 
	SoilMapSymbol: "Soil Map Symbol", 
	Topography: "Topography / Slope Class", 
	DepthToWaterTable: "Depth to Water Table", 
	Search: "Search...", 
	ShowMore: "Show More ...", 
	SearchSoils: "Search {count} soil symbols...", 
	GoToOutput: "Go to Output", 
	BackToInpput: "Back to Input", 
	ReturnToApplication: "Return to Application", 
	SoilType: "Soil Type", 
	Unknown: "Unknown", 
	HydrologicSoilGroup: "Hydrologic Soil Group", 
	ErosionFactor: "Erosion Factor", 
	SitesPotentialFor: "Site's Potential For", 
	SurfaceWaterContamination: "Surface Water Contamination",
	GroundWaterContamination: "Ground Water Contamination",
	WindErosion: "Wind Erosion",
	SoilCompaction: "Soil Compaction",
	WaterErosion: "Water Erosion",
	AdjustIfNeeded: "Adjust if needed", 
	OptionalFilter: "Optionally filter by county", 
	FilterByCounty: "Filter by county",
	SelectYourCounty: "Select your county", 
	SoilDetails: "Soil Details", 
	FindYourSoil: "Find Your Soil", 
	DescribeYourSite: "Describe Your Site", 
	Download: "Download", 
	LogoOsciaAlt: "Ontario Soil and Crop Association Logo", 
	LogoOntarioAlt: "Ontario Logo", 
	LogoCanadaAlt: "Canada Logo", 
	LogoCapAlt: "Canadian Agricultural Partnership Logo", 
	About1: "The Ontario Soils Calculator helps complete Worksheet #1: Soils and Site of the <a href='https://ontariosoilcrop.org/canada-ontario-environmental-farm-plan/' target='_blank'>Environmental Farm Plan</a>.  It is intended for use as part of the <a href='https://workshops.ontarioprograms.net/workshop/registration/21' target='_blank'>2-day workshop</a>.  It can be used to quickly identify the potential for erosion, potential for compaction and potential for water contamination, based on the soil types found on your farm.", 
	About2: "On the input screen, select your soil type.  If you choose, select your Region to refine the list of soil types.  Consider if the default values for topography and depth to the water table are appropriate, and adjust if required.  The Outputs will show the various factors needed to complete Worksheet 1.  This information can be saved via a screen shot, or downloaded and either saved or printed for future reference.  If you need help with any of these steps, please reach out to your <a href='https://ontariosoilcrop.org/contact/#staff-type1388' target='_blank'>workshop leader</a>.", 
	About3: "The Ontario Soils Calculator is produced by the <a href='https://www.ontariosoilcrop.org/' target='_blank'>Ontario Soil and Crop Improvement Association</a>, in partnership with the <a href='http://omafra.gov.on.ca/english/' target='_blank'>Ontario Ministry of Agriculture, Food and Rural Affairs</a>, and funded by the <a href='https://agriculture.canada.ca/en/about-our-department/key-departmental-initiatives/canadian-agricultural-partnership' target='_blank'>Canadian Agricultural Partnership</a>.  The Partnership is a five-year federal-provincial-territorial initiative to strengthen the agriculture, agri-food and agri-based products sector and increase its competitiveness, prosperity and sustainability.", 
	AboutFootnote: 'Data current as of January 2023', 
	FindSoilSymbol: "Find your soil type's map symbol to calculate its corresponding contamination, erosion, and compaction potentials", 
	Attention: "Attention", 
	CannotDownload: "Cannot Download Results", 
	BrowserDoesNotSupportPrinting: "This browser does not support printing. <br /><br />Please try again with another, such as Chrome or Safari.", 
	Downloading: "Downloading...", 
	Copyright: "© 2022 Ontario Soil and Crop Improvement Association", 
	Drainage: 'Drainage', 
	LogoLink: 'https://ontariosoilcrop.org'
}

export type LanguageResources = typeof englishResources

const frenchResources: LanguageResources = {
	OntarioSoilsCalculator: "Calculateur de sols de l'Ontario",
	SoilsCalculator: "Calculateur de sols  ",
	Calculate: "Calculer",
	Input: "Entrée de données",
	Output: "Résultats",
	About: "À propos de…",
	Region: "Région",
	SoilMapSymbol: "Symbole cartographique de votre sol",
	Topography: "Topographie / Classe de pente",
	DepthToWaterTable: "Profondeur de la nappe phréatique",
	Search: "Recherche…",
	ShowMore: "En montrer plus",
	SearchSoils: "Recherche {count} symbols des sols",
	GoToOutput: "Retourner aux résultats",
	BackToInpput: "Retourner à l'entrée de données",
	ReturnToApplication: "Retourner à l'application",
	SoilType: "Type de sol",
	Unknown: "Inconnue",
	HydrologicSoilGroup: "Groupe hydrologique de sol",
	ErosionFactor: "Facteur d'érosion",
	SitesPotentialFor: "Risque pour …",
	SurfaceWaterContamination: "Contamination des eaux de surface",
	GroundWaterContamination: "Contamination de l'eau souterraine",
	WindErosion: "Érosion éolienne",
	SoilCompaction: "Compactage du sol",
	WaterErosion: "Érosion hydrique",
	AdjustIfNeeded: "Ajuster si besoin",
	OptionalFilter: "Option pour filtrer par comté",
	FilterByCounty: "Filtrer par comté",
	SelectYourCounty: "Sélectionnez votre comté",
	SoilDetails: "Détails du sol",
	FindYourSoil: "Trouvez votre sol",
	DescribeYourSite: "Décrivez votre site",
	Download: "Télécharger",
	LogoOsciaAlt: "Logo Association pour l'Amélioration des Sols et Récoltes de l'Ontario",
	LogoOntarioAlt: "Logo Ontario",
	LogoCanadaAlt: "Logo Canada",
	LogoCapAlt: "Logo Partenariat Canadien pour l'Agriculture",
	About1: "Le calulateur de sols de l'ontario aide à remplir la fiche de travail # 1: évaluation des sites et de leurs sols du <a href='https://www.ontariosoilcrop.org/fr/programme-canada-ontario-des-plans-agroenvironnementaux/' target='_blank'>plan agroenvironnemental de la ferme</a>. Il est destiné à etre utilisé dans le cadre de <a href='https://workshops.ontarioprograms.net/workshop/registration/21?languageCode=fr-CA' target='_blank'>l'atelier de deux jours</a>. Il peut etre utilisé pour identifier rapidement le potentiel d'érosion, de compactage et de contimination de l'eau, en fonction du type de sol sur votre ferme.",
	About2: "Sur l'écran d'entrée de données, sélectionnez votre type de sol. Si vous voulez, sélectionnez votre Région pour affiner la liste des types de sols. Déterminez si les valeurs par défaut pour la topographie et la profondeur de la nappe phréatique sont appropriées et ajustez-les si nécessaire. Les résultats montreront les différents facteurs nécessaires pour remplir la fiche de travail # 1. Ces informations peuvent être enregistrées via une capture d'écran, ou téléchargées et enregistrées ou imprimées pour référence future. Si vous avez besoin d'aide pour l'une de ces étapes, veuillez contacter votre <a href='https://www.ontariosoilcrop.org/fr/pour-nous-joindre/#staff-type1402' target='_blank'>animateur d'atelier.</a>",
	About3: "Le calculateur des sols de l'Ontario est produit par <a href='https://www.ontariosoilcrop.org/fr/home-fr/' target='_blank'>l'Association pour l'amélioration des sols et des récoltes de l'Ontario</a>, en partenariat avec le <a href='http://omafra.gov.on.ca/french/' target='_blank'>ministère de l'Agriculture, de l'Alimentation et des Affaires rurales de l'Ontario</a>, et financé par le <a href='https://agriculture.canada.ca/fr/ministere/initiatives/partenariat-canadien-agriculture' target='_blank'>Partenariat canadien pour l'agriculture</a>. Le Partenariat est une initiative fédérale-provinciale-territoriale quinquennale visant à renforcer le secteur de l'agriculture, de l'agroalimentaire et des produits agro-industriels et à accroître sa compétitivité, sa prospérité et sa durabilité.",
	AboutFootnote: 'Données à jour dès Janvier 2023', 
	FindSoilSymbol: "Trouvez le symbole cartographique de votre type de sol pour calculer son potentiel de contamination, d'érosion et de compactage",
	Attention: "Attention",
	CannotDownload: "Impossible de télécharger les résultats",
	BrowserDoesNotSupportPrinting: "Ce navigateur ne supporte pas l'impression. Veuillez réessayer avec un autre, tel que Chrome ou Safari",
	Downloading: "Téléchargement",
	Copyright: "Droit d'auteur - Association pour l'Amélioration des Sols et Récoltes de l'Ontario",
	Drainage: 'Drainage', 
	LogoLink: 'https://ontariosoilcrop.org/fr/home-fr/'
}

const languageResources: LanguageCodeMap<LanguageResources> = {
	en: englishResources, fr: frenchResources
}

export const getLanguageResources = (languageCode: LanguageCode) => languageResources[languageCode]

export function getDescription<ValT extends string | number>(descriptions: LanguageCodeMap<Record<ValT, string>>, languageCode: LanguageCode, value: ValT | undefined, defaultValue: string): string {
	return value ? descriptions[languageCode][value] : defaultValue
}
