import React from 'react'

export interface TriggerDelegate {
	(): boolean
}

export interface EffectDelegate {
	(): void
}

export function useEffectSequence(trigger: TriggerDelegate, sequence: Array<EffectDelegate>) {
	const [stepIndex, setStepIndex] = React.useState<number>()

	// this effect handler could be written more cleanly, 
	// but it would then involve extra state/effect renders
	React.useEffect(() => {
		if (stepIndex !== undefined) {
			if (stepIndex < sequence.length) {
				// perform appropriate effect step
				sequence[stepIndex]()
				if (stepIndex === sequence.length - 1) {
					// reset sequence if we're at the end
					setStepIndex(undefined)
				}
				// or advance the sequence if we're not
				else setStepIndex(stepIndex + 1)
			}
		}
		else if (trigger()) {
			// perform first step in the sequence
			sequence[0]()
			if (sequence.length === 1) {
				// reset sequence if we're at the end
				setStepIndex(undefined)
			}
			// or advance the sequence if we're not
			else setStepIndex(1)
		}

	}, [stepIndex, trigger, sequence])
}

export default useEffectSequence
