import { getLanguageResources, LanguageCode, LanguageResources } from "./Language"

export type LanguageCodeHandler = (language: LanguageCode) => void

export type DownloadHandler = (downloadMode: boolean) => void

export interface AppContextType {
	languageCode: LanguageCode
	langResources: LanguageResources
	setLanguageCode: LanguageCodeHandler
	setDownloadMode: DownloadHandler
}

export const makeAppContext = (languageCode: LanguageCode, setLanguageCode: LanguageCodeHandler, setDownloadMode: DownloadHandler) => {
	const appContext: AppContextType = {
		languageCode, langResources: getLanguageResources(languageCode), setLanguageCode, setDownloadMode
	}

	return appContext
}
