import React from 'react'
import { jsPDF } from 'jspdf'
import html2canvas from 'html2canvas'

import { AppContext, AppViewProps } from './App'
import { getDescription } from './Language'
import { FieldRaw } from './components/FieldRaw'
import { Spinner } from './components/SpinnerRaw'
import { TopographyDescriptions } from './data/topographyTypes'
import { DepthToWaterTableDescriptions } from './data/depthToWaterTableTypes'
import { calcPotentialForGroundWaterContamination, calcPotentialForSurfaceWaterContamination, calcPotentialForWaterErosion, PotentialDescriptions } from './data/potentialData'
import { DrainageDescriptions, SoilCompactionDescriptions, getSoilInfo } from './data/soilData'

// import logo from './logo-desktop-cropped.png'
import './AppViewPrintJs.scss'
import { HydrologicalSoilGroupDescriptions } from './data/hydrologicalSoilGroupTypes'

export const AppViewPrint: React.FC<AppViewProps> = ({ inputData: data }) => {
	// hack to prevent double prints.  
	// TODO: look into why elements are rendering twice.
	const isPrintingRef = React.useRef(false)
	const { setDownloadMode } = React.useContext(AppContext)
	const { languageCode, langResources } = React.useContext(AppContext)

	const soilInfo = getSoilInfo(data.county!, data.soilMapSymbol!)

	React.useEffect(() => {

		const print = async () => {
			// jsPDF.html function was giving us too many issues.  
			// we use lower level html2canvas dependency directly instead
			const element = document.getElementById('print-raw')!
			const canvas = await html2canvas(element, { scale: 2 })
			const imgData = canvas.toDataURL("image/png", 1.0)
			const pdf = new jsPDF({ format: 'letter', unit: 'in', compress: true })
			pdf.addImage(imgData, 'PNG', 0, 0, 8.5, 11, '', 'MEDIUM') 
			await pdf.save("ontario-soils-calculator-output.pdf", { returnPromise: true })
		}

		// hack to prevent double prints.
		// TODO: look into why elements are rendering twice.
		if (!isPrintingRef.current) {
			isPrintingRef.current = true
			print().then(() => setDownloadMode(false))
		}

	}, [setDownloadMode])

	const county = data.county && 
		<FieldRaw label={langResources.Region} value={data.county!} />

	const soilType = soilInfo.soilName && 
		<FieldRaw label={langResources.SoilType} value={soilInfo.soilName} titleizeValue />

	return (
		<>
			<div id='print-raw'>
				<div className='header'>
					<div className='title'>
						<h1 onClick={() => setDownloadMode(false)}>{langResources.OntarioSoilsCalculator}</h1>
					</div>
				</div>

				<div className='body'>
					<h2>{langResources.Input}</h2>
					{county}
					<FieldRaw label={langResources.SoilMapSymbol} value={data.soilMapSymbol!} />
					<FieldRaw label={langResources.Topography} value={getDescription(TopographyDescriptions, languageCode, data.topography, langResources.Unknown)} />
					<FieldRaw label={langResources.DepthToWaterTable} value={getDescription(DepthToWaterTableDescriptions, languageCode, data.depthToGroundWater, langResources.Unknown)} />

					<h2>{langResources.Output}</h2>
					{soilType}
					<FieldRaw label={langResources.Drainage} value={getDescription(DrainageDescriptions, languageCode, soilInfo.drainage, langResources.Unknown)} titleizeValue />
					<FieldRaw label={langResources.HydrologicSoilGroup} value={getDescription(HydrologicalSoilGroupDescriptions, languageCode, soilInfo.hydrologicalGroup, langResources.Unknown)} titleizeValue />
					<FieldRaw label={langResources.ErosionFactor} value={soilInfo.erosionFactor?.toString() || langResources.Unknown} titleizeValue />
					<FieldRaw label={langResources.SurfaceWaterContamination} value={calcPotentialForSurfaceWaterContamination(languageCode, soilInfo, data.topography!)} />
					<FieldRaw label={langResources.GroundWaterContamination} value={calcPotentialForGroundWaterContamination(languageCode, soilInfo, data.depthToGroundWater!)} />
					<FieldRaw label={langResources.WindErosion} value={getDescription(PotentialDescriptions, languageCode, soilInfo.windErosion, langResources.Unknown)} />
					<FieldRaw label={langResources.SoilCompaction} value={getDescription(SoilCompactionDescriptions, languageCode, soilInfo.soilCompaction, langResources.Unknown)} />
					<FieldRaw label={langResources.WaterErosion} value={calcPotentialForWaterErosion(languageCode, soilInfo, data.topography!)} />
				</div>
			</div>
			<Spinner message={langResources.Downloading} />
		</>
	)
}
