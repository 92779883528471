// import { assertNever } from "../utility"

import { IonButton, IonItem, IonList } from "@ionic/react"
import React from "react"
import { LanguageCode, LanguageCodeMap } from "../Language"
import { Color } from "./shared"

export interface TextValue {
	value: string
	text: string
}

export type SearchItem = string | TextValue

export function isTextValue(item: any): item is TextValue {
	return !!(item.value && item.text)
}

export function makeTextValue(searchItem: SearchItem): TextValue {
	if (isTextValue(searchItem)) return searchItem
	else return { value: searchItem, text: searchItem }
}

export function makeTextValues<T>(localizedObj: LanguageCodeMap<T>, languageCode: LanguageCode): Array<TextValue> {
	const textValues: Array<TextValue> = Object.entries(localizedObj[languageCode] as any).map(([key, value]) => {
		return { value: key, text: String(value) }
	})

	return textValues
}

export interface SearchProps {
	label?: string
	note?: string
	value?: string
	items: Array<SearchItem>
	onChange: (value?: string) => void
	placeholder?: string
	showMorePrompt?: string
	color?: Color
}

export interface SearchListProps extends Pick<SearchProps, 'value' | 'items' | 'showMorePrompt'> {
	lastConfirmedValue?: string
	onItemSelect: (value?: string) => void
	maxItems: number
	onMoreItemsClick: () => void
	searchListTriggered: boolean
}

export const SearchList: React.FC<SearchListProps> = ({ value, items, lastConfirmedValue, onItemSelect, showMorePrompt, searchListTriggered, maxItems, onMoreItemsClick }) => {

	let list: React.ReactElement | null = null

	if (searchListTriggered) {

		let exactMatch = false
		let anotherExactMatchPossible = false

		const loweredVal = (value ?? '').toLowerCase()
		let listItems = items
			.map(makeTextValue)
			.filter(textValue => { 
				const loweredItemValue = textValue.value.toLocaleLowerCase()
				const loweredIndex = loweredItemValue.indexOf(loweredVal)
				if (textValue.value === value) exactMatch = true
				else if (loweredIndex === 0) anotherExactMatchPossible = true
				return loweredIndex >= 0
			})
			.slice(0, maxItems)
			.map((textValue, ix) => <IonItem key={ix} onClick={e => onItemSelect(textValue.value)}>{textValue.text}</IonItem>)

		if (listItems.length >= maxItems) listItems.push(
			<IonItem key={maxItems}>
				<IonButton onClick={onMoreItemsClick}>
					{showMorePrompt ?? ' ... '}
				</IonButton>
			</IonItem>
		)

		if ((!exactMatch || anotherExactMatchPossible) && (value === undefined || value !== lastConfirmedValue)) {
			list = (
				<IonList>
					{listItems}
				</IonList>
			)
		}
	}

	return list
}

