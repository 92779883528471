import React, { ReactElement } from 'react'

import {
  IonApp,
  setupIonicReact
} from '@ionic/react'

import { DEFAULT_LANGUAGE_CODE, isLanguageCode, LANGUAGE_CODE_QUERY_PARAM } from './Language'
import { AppViewMobile } from './AppViewMobile'
import { AppViewDesktop } from './AppViewDesktop'
import { AppViewPrint } from './AppViewPrintJs'

import { AppContextType, makeAppContext } from './AppContext'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

import { ALL_COUNTIES, countySoilMapSymbolPairIsValid } from './data/soilData'
import { Topography } from './data/topographyTypes'
import { DepthToWaterTable } from './data/depthToWaterTableTypes'
import { useWindowSize } from './hooks/WindowSize'

import './App.css'

/* Theme variables */
import './theme/variables.css'
import { useOverridableLocalStorage } from './hooks/OverridableLocalStorage'
import { useEffectSequence } from './hooks/EffectSequence'

export const ROUTES = {
  input: '/input', 
  output: '/output', 
  about: '/about'
}

export interface InputData {
  county?: string
  soilMapSymbol?: string
  topography?: Topography
  depthToGroundWater?: DepthToWaterTable
}

export type InputDataChangeHandler = (inputData: InputData) => void

export function isReadyToCompute(inputData: InputData): boolean {
  const allValuesSupplied: boolean = Object.entries(inputData).reduce((isReady, [key, val]) => {
    return isReady && (key === 'county' || !!val)
  }, true)

  return allValuesSupplied && countySoilMapSymbolPairIsValid(inputData.county, inputData.soilMapSymbol)
}

export interface AppViewProps {
  inputData: InputData
  setInputData: InputDataChangeHandler
}

setupIonicReact()


export const AppContext = React.createContext<AppContextType>(makeAppContext(DEFAULT_LANGUAGE_CODE, () => null, () => null))

const App: React.FC = () => {
  const [languageCode, setLanguageCode] = useOverridableLocalStorage(LANGUAGE_CODE_QUERY_PARAM, DEFAULT_LANGUAGE_CODE, isLanguageCode)
  const [isDownloading, setIsDownloading] = React.useState(false)
  const [inputData, setInputData] = React.useState<InputData>({ county: ALL_COUNTIES, topography: 'Level', depthToGroundWater: 'Moderate' })

  const [lastLanguageCode, setLastLanguageCode] = React.useState(languageCode)
  const lastInputData = React.useRef(inputData)

  // this is an ugly hack to force localizable select lists to update when there is a language change
  // TODO: file bug with Ionic regarding select updates not triggering when option list text changes
  useEffectSequence(
    // when we detect a language code
    () => languageCode !== lastLanguageCode, 
    [
      // we temporarily clear localized select lists
      () => {
        setLastLanguageCode(languageCode)
        lastInputData.current = inputData
        const tempInputData = Object.assign({}, inputData)
        delete tempInputData.topography
        delete tempInputData.depthToGroundWater
        setInputData(tempInputData)
      }, 
      // and then reinitialize them again
      () => {
        setInputData(lastInputData.current)
      }
  ])

  const windowSize = useWindowSize()

  let appView: ReactElement = <></>  

  if (isDownloading) {
    appView = <AppViewPrint inputData={inputData} setInputData={setInputData} />
  }
  else if (windowSize.isWideScreen) {
    appView = <AppViewDesktop inputData={inputData} setInputData={setInputData} />
  }
  else {
    appView = <AppViewMobile inputData={inputData} setInputData={setInputData} />
  }

  return (
    <IonApp>
      <AppContext.Provider value={makeAppContext(languageCode, setLanguageCode, (downloadMode) => setIsDownloading(downloadMode))}>
        {appView}
      </AppContext.Provider>
    </IonApp>
  )
}

export default App
