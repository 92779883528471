import React from "react"
import { IonLabel, IonSearchbar } from "@ionic/react"
import { SearchProps, SearchList } from './Search'
import { useWindowSize } from "../hooks/WindowSize"

import './SearchSelect.css'

const initialMaxItems = 25

const HANDLER_DEBOUNCE = 100

const MOBILE_SEARCH_LIST_RENDER_DELAY = 700

export const SearchSelect: React.FC<SearchProps> = ({ label, note, value, items, onChange, placeholder, color, showMorePrompt }) => {
  	const windowSize = useWindowSize()

	let [searchListTriggered, setSearchListTriggered] = React.useState(false)
	let [lastConfirmedValue, setLastConfirmedValue] = React.useState<string>()
	let [maxItems, setMaxItems] = React.useState(initialMaxItems)

	let inputElement = React.useRef<HTMLIonSearchbarElement>(null)

	const handleItemSelect = (newValue?: string) => {
		if (newValue !== value) {
			setLastConfirmedValue(newValue)
			setSearchListTriggered(false)
			onChange(newValue)
		}
	}

	const handleSearchChange = (newValue?: string) => {
		if (newValue !== value) {
			setLastConfirmedValue(undefined)
			onChange(newValue)
			// we scroll to the input on narrow screens because too many optiona are hidden by onscreen keyboard otherwise
			if (!windowSize.isWideScreen) inputElement.current?.scrollIntoView({behavior: 'smooth'})
		}
	}

	const handleSearchFocus = () => {
		setSearchListTriggered(true)

		if (!windowSize.isWideScreen) {
			setTimeout(() => {
				// we scroll to the input on narrow screens because too many optiona are hidden by onscreen keyboard otherwise
				inputElement.current?.scrollIntoView({ behavior: 'smooth' })
			}, MOBILE_SEARCH_LIST_RENDER_DELAY)
		}
	}

	// inspired by: https://muffinman.io/blog/catching-the-blur-event-on-an-element-and-its-children/
	const handleSearchBlur: React.FocusEventHandler = (e) => {
		const searchContainer = e.currentTarget
		setTimeout(( )=> {
			const clickedElement = e.relatedTarget || // Standard Chrome 
				(e.nativeEvent as any).explicitOriginalTarget || // FF/IE Hack 
				document.activeElement // FF/IE Hack 

			if (searchContainer && !searchContainer.contains(clickedElement)) {
				setSearchListTriggered(false)
			}
		}, HANDLER_DEBOUNCE)
	}

	const handleClearClick = () => {
		setMaxItems(initialMaxItems)
		onChange(undefined) 
	}

	const handleMoreItemsClick = () => {
		setMaxItems(maxItems * 2)

		setTimeout(() => {
			setSearchListTriggered(true)
		}, HANDLER_DEBOUNCE);
	}

	return (
		<div onBlur={handleSearchBlur}>
			<IonLabel color={color} position="stacked">{label}</IonLabel>
			<IonSearchbar 
				ref={inputElement} 
				className='oscia-search' 
				color={color} 
				value={value} 
				placeholder={placeholder} 
				onIonChange={e => handleSearchChange(e.detail.value)}
				onIonClear={handleClearClick}
				onIonFocus={handleSearchFocus}
			/>
			<SearchList 
				value={value} 
				items={items} 
				lastConfirmedValue={lastConfirmedValue} 
				onItemSelect={handleItemSelect} 
				showMorePrompt={showMorePrompt} 
				searchListTriggered={searchListTriggered}
				maxItems={maxItems}
				onMoreItemsClick={handleMoreItemsClick}
			/>
		</div>
	)
}

export default SearchSelect
