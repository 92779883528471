import { soilDataArray } from './soilDataArrayV3'
import { HydrologicalSoilGroup } from './hydrologicalSoilGroupTypes'
import { ErosionFactor } from './potentialData'
import { LanguageCodeMap } from '../Language'

export type SoilCompaction = 'H' | 'M' | 'L' | '–'

export type Drainage = 'WELL' | 'VARIABLE' | 'IMPERFECT' | 'POOR' | 'VERY POOR' | '–'

export const SoilCompactionDescriptions: LanguageCodeMap<Record<SoilCompaction, string>> = {
	en: {
		H: "H-High",
		M: "M-Medium",
		L: "L-Low", 
		'–': '–',
	}, 
	fr: {
		H: "E - élevé",
		M: "M - modéré",
		L: "F - faible",
		'–': '–',
	}, 
}

export const DrainageDescriptions: LanguageCodeMap<Record<Drainage, string>> = {
	en: {
		WELL: "Well",
		VARIABLE: "Variable",
		IMPERFECT: "Imperfect", 
		POOR: "Poor",
		'VERY POOR': "Very Poor", 
		'–': '–',
	}, 
	fr: {
		WELL: "Bon",
		VARIABLE: "Variable",
		IMPERFECT: "Imparfait", 
		POOR: "Pauvre",
		'VERY POOR': "Très pauvre", 
		'–': '–',
	}, 
}

export interface SoilRecord {
	county: string, 
	mapSymbol: string, 
	drainage: Drainage, 
	hydrologicalGroup: HydrologicalSoilGroup, 
	erosionFactor: ErosionFactor,
	windErosion: ErosionFactor,
	soilCompaction: SoilCompaction, 
	soilName: string | null
}

export interface SoilInfo extends SoilRecord {
	soilId: string
}

export type SoilDatum = [
	county: string, mapSymbol: string, drainage: Drainage, hydrologicGroup: HydrologicalSoilGroup, erosionFactor: ErosionFactor, windErosion: ErosionFactor, soilCompaction: SoilCompaction, soilName: string | null
]

function getSoilRecord(datum: SoilDatum): SoilRecord {
	const [
		county, mapSymbol, drainage, hydrologicGroup, erosionFactor, windErosion, soilCompaction, soilName
	] = datum

	const soilRecord: SoilRecord = {
		county, mapSymbol, drainage, hydrologicalGroup: hydrologicGroup, erosionFactor, windErosion, soilCompaction, soilName
	}

	return soilRecord
}

export function getSoilId(county: string, mapSymbol: string): string {
	return `${county} : ${mapSymbol}`
}

function makeSoilInfo(datum: SoilDatum): SoilInfo {
	const record = getSoilRecord(datum)
	const soilId = getSoilId(record.county, record.mapSymbol)
	const info: SoilInfo = {
		...record, ...{ soilId }
	}
	return info
}

export const countySoilMapSymbolSetsDictionary: { [county: string]: Set<string> } = {}
const soilMapSymbolSet = new Set<string>()

const soilMapSymbolInfoDictionary: { [val: string]: SoilInfo } = soilDataArray.reduce((dic, soilData) => {
	const soilInfo = makeSoilInfo(soilData)
	soilMapSymbolSet.add(soilInfo.mapSymbol)

	if (!countySoilMapSymbolSetsDictionary[soilInfo.county]) {
		countySoilMapSymbolSetsDictionary[soilInfo.county] = new Set<string>()
	}
	countySoilMapSymbolSetsDictionary[soilInfo.county].add(soilInfo.mapSymbol)

	if (!dic[soilInfo.soilId]) {

		dic[soilInfo.soilId] = soilInfo
	}
	return dic
}, {} as any)

export function getSoilInfo(county: string, mapSymbol: string) {
	return soilMapSymbolInfoDictionary[getSoilId(county, mapSymbol)]
}

export const countySoilMapSymbolPairIsValid = (county?: string, mapSymbol?: string) => !!countySoilMapSymbolSetsDictionary[county!]?.has(mapSymbol!)

export const soilMapSymbols: Array<string> = [...soilMapSymbolSet]

export const counties: Array<string> = 
	[...Object.keys(countySoilMapSymbolSetsDictionary)].sort()

// dictionary of counties mapping to all of their soil symboles
export const countySoilMapSymbolsDictionary = counties.reduce((dic, county) => {
	dic[county] = [...countySoilMapSymbolSetsDictionary[county]].sort()
	return dic
}, {} as { [county: string]: Array<string> })


export const ALL_COUNTIES = ''
counties.unshift(ALL_COUNTIES)
countySoilMapSymbolsDictionary[ALL_COUNTIES] = soilMapSymbols


