import { Redirect } from 'react-router'

import { MobilePageEnvelope, PageProps } from './Page'
import { InputData, isReadyToCompute, ROUTES } from '../App'
import { OutputContents } from './contents/OutputContents'

interface OutputPageProps extends PageProps {
  data: InputData
}

export const OutputPage: React.FC<OutputPageProps> = ({ data }) => {
  // the nav issue might be state latency combined with this redirect maybe?  Could try using history instead
  // if (!isReadyToCompute(data)) return 

  // Rendering just the redirect appears to confuse the tab navigation system.  
  // Wrapping it in a page appears to resolve the issue
  if (!isReadyToCompute(data)) return (
    <MobilePageEnvelope>
      <Redirect to={ROUTES.input} />
    </MobilePageEnvelope>
  )

  else return (
    <MobilePageEnvelope>
      <OutputContents data={data} />
    </MobilePageEnvelope>
  )
}

export default OutputPage
