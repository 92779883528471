
import React from "react"
import { SelectInterface } from '@ionic/core'
import { IonLabel, IonSelect, IonSelectOption } from "@ionic/react"
import { useWindowSize } from "../hooks/WindowSize"
import { SearchProps, makeTextValue } from './Search'
import { ListItem } from "./ListItem"

const LARGE_ITEM_LIST_LENGTH = 20

export const Select: React.FC<SearchProps> = ({ label, note, value, items, onChange, placeholder }) => {
	let windowSize = useWindowSize()

	let listItems = items
		.map(makeTextValue)
		.map((textValue, ix) => <IonSelectOption key={ix} value={textValue.value}>{textValue.text}</IonSelectOption>)

	// on phones the popover interface is very laggy for large item lists
	const selectInterface: SelectInterface = windowSize.isWideScreen || items.length < LARGE_ITEM_LIST_LENGTH ? 
		'popover' : 
		'action-sheet'

	return (
		<ListItem note={note}>
			<IonLabel position="stacked">{label}</IonLabel>
			<IonSelect value={value} placeholder={placeholder} onIonChange={e => onChange(e.detail.value)} interface={selectInterface}>
				{listItems}
			</IonSelect>
		</ListItem>
	)
}

export default Select
