import React from "react";
import classNames from 'classnames'

export interface FieldRawProps {
	label: string
	value: string
	titleizeValue?: boolean
}

export const FieldRaw: React.FC<FieldRawProps> = ({ label, value, titleizeValue }) => {
	let val = titleizeValue ? value.toLowerCase() : value
	let valueClass = classNames('value', { titleize: titleizeValue })
	return (
		<div className='field'>
			<div className='label'>{label}</div>
			<div className={valueClass}>{val}</div>
		</div>
	)
}
