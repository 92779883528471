import React from 'react'
import { IonItemGroup, IonList } from '@ionic/react'

import { PageProps } from '../Page'
import { getDescription } from '../../Language'
import { AppContext, InputData } from '../../App'
import { Field } from '../../components/Field'
import { DrainageDescriptions, SoilCompactionDescriptions, getSoilInfo } from '../../data/soilData'
import { calcPotentialForSurfaceWaterContamination, calcPotentialForGroundWaterContamination, calcPotentialForWaterErosion, PotentialDescriptions } from '../../data/potentialData'
import { ListDivider } from '../../components/ListDivider'
import DownloadButton from '../../components/DownloadButton'
import Header from '../../components/Header'
import { HydrologicalSoilGroupDescriptions } from '../../data/hydrologicalSoilGroupTypes'

export interface OutputContentsProps extends PageProps {
  data: InputData
}

export const OutputContents: React.FC<OutputContentsProps> = ({ data }) => {
  const { languageCode, langResources } = React.useContext(AppContext) 
  const soilInfo = getSoilInfo(data.county!, data.soilMapSymbol!)

  const soilType = soilInfo.soilName && 
          <Field label={langResources.SoilType} value={soilInfo.soilName || langResources.Unknown} titleizeValue></Field> 

  return (
    <>
      <Header headerText={langResources.Output}>
        <DownloadButton />
      </Header>
      <IonList>
        <IonItemGroup>
          <ListDivider text={langResources.SoilDetails} />
          {soilType}
          <Field label={langResources.Drainage} value={getDescription(DrainageDescriptions, languageCode, soilInfo.drainage, langResources.Unknown)} titleizeValue></Field>
          <Field label={langResources.HydrologicSoilGroup} value={getDescription(HydrologicalSoilGroupDescriptions, languageCode, soilInfo.hydrologicalGroup, langResources.Unknown)} titleizeValue></Field>
          <Field label={langResources.ErosionFactor} value={soilInfo.erosionFactor?.toString() || langResources.Unknown} titleizeValue></Field>
        </IonItemGroup>
        <IonItemGroup>
          <ListDivider text={langResources.SitesPotentialFor} />
          <Field label={langResources.SurfaceWaterContamination} value={calcPotentialForSurfaceWaterContamination(languageCode, soilInfo, data.topography!)}></Field>
          <Field label={langResources.GroundWaterContamination} value={calcPotentialForGroundWaterContamination(languageCode, soilInfo, data.depthToGroundWater!)}></Field>
          <Field label={langResources.WindErosion} value={getDescription(PotentialDescriptions, languageCode, soilInfo.windErosion, langResources.Unknown)}></Field>
          <Field label={langResources.SoilCompaction} value={getDescription(SoilCompactionDescriptions, languageCode, soilInfo.soilCompaction, langResources.Unknown)}></Field>
          <Field label={langResources.WaterErosion} value={calcPotentialForWaterErosion(languageCode, soilInfo, data.topography!)}></Field>
        </IonItemGroup>
      </IonList>
    </>
  )
}

export default OutputContents
