import React, { ReactElement } from 'react'

import { IonButton } from '@ionic/react'
import { LanguageCode, LanguageNames } from '../Language'
import { AppContext } from '../App'

import './LanguageLinks.css'

export const LanguageLinks: React.FC = () => {
	const { languageCode, setLanguageCode } = React.useContext(AppContext)

	const links = Object.entries(LanguageNames)
		.map(([langCode, langName]) => { 
			return (
					<IonButton 
						key={langCode}
						fill='clear' 
						color={langCode === languageCode ? 'oscia-green' : 'dark'}
						className={langCode === languageCode ? 'active' : ''} 
						disabled={langCode === languageCode} 
						strong={langCode === languageCode}
						onClick={() => setLanguageCode(langCode as LanguageCode)}>
							{langName}
					</IonButton>
			)
		})
		.reduce((elementArray, linkElement) => {
			const newArray = elementArray.length === 0 ? 
				[ linkElement ] : 
				[...elementArray, '|', linkElement]
			return newArray
		}, [] as Array<ReactElement | string>)

	return (
		<div className='language-links' >
			{links}
		</div>
	)
}
